.quantAppQueryLoader {
  width: 100px;
  aspect-ratio: 1;
  display: grid;
  grid: 50%/50%;
  color: #0B96F5;
  --_g: no-repeat linear-gradient(currentColor 0 0);
  background: var(--_g), var(--_g), var(--_g);
  background-size: 50.1% 50.1%;
  margin: auto;
  transform: translate(-50%, -50%);
  animation:
    l6-0 1.5s infinite steps(1) alternate,
    l6-0-0 3s infinite steps(1);
}

.quantAppQueryLoader::before {
  content: "";
  background: currentColor;
  transform: perspective(150px) rotateY(0deg) rotateX(0deg);
  transform-origin: bottom right;
  animation: l6-1 1.5s infinite linear alternate;
}

@keyframes l6-0 {
  0%  {background-position: 0    100%,100% 100%,100% 0}
  33% {background-position: 100% 100%,100% 100%,100% 0}
  66% {background-position: 100% 0   ,100% 0   ,100% 0}
}
@keyframes l6-0-0 {
  0%  {transform: scaleX(1)  rotate(0deg)}
  50% {transform: scaleX(-1) rotate(-90deg)}
}
@keyframes l6-1 {
  16.5%{transform:perspective(150px) rotateX(-90deg)  rotateY(0deg)    rotateX(0deg);filter:grayscale(0.6)}
  33%  {transform:perspective(150px) rotateX(-180deg) rotateY(0deg)    rotateX(0deg)}
  66%  {transform:perspective(150px) rotateX(-180deg) rotateY(-180deg) rotateX(0deg)}
  100% {transform:perspective(150px) rotateX(-180deg) rotateY(-180deg) rotateX(-180deg);filter:grayscale(0.6)}
}
/* HTML: <div class="loader"></div> */
.imageLoader {
  width: 40px;
  --b: 8px; 
  aspect-ratio: 1;
  border-radius: 50%;
  padding: 1px;
  background: conic-gradient(#0000 10%, var(--successColorDark)) content-box;
  -webkit-mask:
    repeating-conic-gradient(#0000 0deg,#000 1deg 20deg,#0000 21deg 36deg),
    radial-gradient(farthest-side,#0000 calc(100% - var(--b) - 1px),#000 calc(100% - var(--b)));
  -webkit-mask-composite: destination-in;
          mask-composite: intersect;
  animation:l4 1s infinite steps(10);
  margin: 40px;
}
@keyframes l4 {to{transform: rotate(1turn)}}