@import '../../styles/propertySets.css';
.modalContainer{
    max-width: 100vw;
    @apply --marketplaceModalBaseStyles;
    min-height: 100vh;
    height: 100%;
    padding: 24px 10px;
    border: none;

    @media (--viewportMedium) {
        flex-basis: 80vw;
        min-height: auto;
        height: auto;
        padding: 60px;
    }
}
.header{
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media(max-width: 460px) {
        margin-top: 2em;
    }

    & button{
        border: 1px solid grey;
        height: 36px;
        padding: 0px 15px;
        border-radius: 3px;
        margin-left: 10px;
        min-width: 112px;
    }
}
.alertModal{
    text-align: center;
    @media(max-width: 420px) {
		padding-top: 5em;
	}

	& p {
		font-size: 20px;
		font-weight: 400;
		line-height: normal;
		letter-spacing: -0.9px;
		text-align: center;
	}
}
.buttonWrapper{
    display: flex;
    gap: 10px;
    justify-content: space-between;
    margin-top: 3em;

    @media(max-width: 460px) {
        flex-wrap: wrap;
        width: 100%;
    }
    & div{
        padding: 16px 0;
        font-size: 19px;
        cursor: pointer;
    }
    & button{
        width: 185px;
    }
}
.copiedButton{
    background: orange;
    border: none !important;
    color: white;
}
.centeredButton{
    display: flex;
    justify-content: center;
    margin-top: 3em;
    @media(max-width: 460px) {
        flex-wrap: wrap;
        width: 100%;
    }
    & div{
        padding: 16px 0;
        font-size: 19px;
        cursor: pointer;
    }
    & button{
        width: 185px;
    }
}