@import '../../styles/propertySets.css';

.root {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.sectionContainer {
  padding: 0;
  margin-bottom: 34px;
  position: relative;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.sectionTitle {
  /* Font */
  color: var(--matterColorAnti);
  font-weight: var(--fontWeightSemiBold);
  margin-bottom: 12px;
  padding-top: 3px;
  padding-bottom: 3px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 24px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.lastSection {
  margin-bottom: 50px;

  @media (--viewportMedium) {
    margin-bottom: 50px;

    & .sectionTitle {
      margin-top: 55px;
      margin-bottom: 28px;
    }
  }
}

.companyName {
  margin-bottom: 34px;
}

.uploadAvatarInput {
  display: none;
}

.uploadAvatarWrapper {
  margin-top: 18px;
  /* margin-bottom: 18px; */
  position: relative;
  width: fit-content;

  &:hover{
    & .changeAvatar{
      display: block;
    }
  }

  @media (--viewportMedium) {
    margin-top: 44px;
    /* margin-bottom: 20px; */
  }
}

.label {
  width: var(--ProfileSettingsForm_avatarSize);
  border-radius: 50%;

  @media (--viewportMedium) {
    width: var(--ProfileSettingsForm_avatarSizeDesktop);
  }
}

.avatarPlaceholder,
.avatarContainer {
  /* Dimension */
  position: relative;
  width: var(--ProfileSettingsForm_avatarSize);
  height: var(--ProfileSettingsForm_avatarSize);

  /* Center content */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Initial coloring */
  background-color: var(--matterColorNegative);
  border-radius: calc(var(--ProfileSettingsForm_avatarSize) / 2);
  cursor: pointer;

  @media (--viewportMedium) {
    width: var(--ProfileSettingsForm_avatarSizeDesktop);
    height: var(--ProfileSettingsForm_avatarSizeDesktop);
    border-radius: calc(var(--ProfileSettingsForm_avatarSizeDesktop) / 2);
  }

  &>div {
    width: unset;
    height: unset;
  }
}

.avatarContainer {
  position: relative;

  &:hover {
    &:after {
      content: "";
      position: absolute;
      background-color: #97979799;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      z-index: 0;
    }

    & .uploadImage {
      z-index: 9;
      display: block;
      position: absolute;
    }
  }

  & .uploadImage {
    display: none;
  }
}

.avatarPlaceholder {
  /* Placeholder border */
  border-style: dashed;
  border-color: var(--matterColorNegative);
  border-width: 2px;

  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);

    & .uploadText {
      display: none;
    }

    & .uploadImage {
      display: block;
    }
  }

  & .uploadImage {
    display: none;
  }

  & .googleDisplayImage {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    & img{
      width: 100%;
      border-radius: 50%;
    }

    &:hover {
      &:after {
        content: "";
        position: absolute;
        background-color: #97979799;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        z-index: 0;
        left: 0;
      }
  
      & .uploadImage {
        z-index: 9;
        display: block;
        position: absolute;
      }
    }
  }
}

.avatarPlaceholderTextMobile {
  @media (--viewportMedium) {
    display: none;
  }
}

.avatarPlaceholderText {
  line-height: 24px;
  font-weight: var(--fontWeightRegular);
  display: none;

  @media (--viewportMedium) {
    display: block;
    max-width: 106px;
    text-align: center;
  }
}

.avatarUploadError {
  /* Placeholder border */
  border-style: dashed;
  border-color: var(--failColor);
  border-width: 2px;
}

.error {
  /* Font */
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  margin-top: 18px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 22px;
    margin-bottom: 2px;
  }
}

.avatar {
  width: 100%;
  height: 100%;
}

.changeAvatar {
  /* Font */
  display: none;
  position: absolute;
  padding: 12px 20px;
  background-color: var(--matterColorLight);
  background-position: 15px 12px;
  border: solid 1px var(--matterColorNegative);
  border-radius: 2px;
  z-index: 99;
  bottom: -20px;
  left: 10px;
  width: 220px;

  @media(max-width: 768px){
    padding: 8px 15px;
    bottom: -32px;
  }

  &:hover {
    box-shadow: 0px 8px 16px 0px #0000004D;
  }

  & .changeAvatarTitle {
    font-size: 12px;
    font-weight: 400;
    color: #3D3D3D;
    line-height: 30px;
    cursor: pointer;
  }

  & .revertGoogle {
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    line-height: 30px;
  }
  & .disable{
    color: #B2B2B2;
    pointer-events: none;
  }
}

.uploadingImage {
  /* Dimensions */
  width: var(--ProfileSettingsForm_avatarSize);
  height: var(--ProfileSettingsForm_avatarSize);

  /* Image fitted to container */
  object-fit: cover;
  background-color: var(--matterColorNegative);
  /* Loading BG color */
  border-radius: calc(var(--ProfileSettingsForm_avatarSize) / 2);
  overflow: hidden;

  display: block;
  position: relative;
  margin: 0;

  @media (--viewportMedium) {
    width: var(--ProfileSettingsForm_avatarSizeDesktop);
    height: var(--ProfileSettingsForm_avatarSizeDesktop);
    border-radius: calc(var(--ProfileSettingsForm_avatarSizeDesktop) / 2);
  }
}

.uploadingImageOverlay {
  /* Cover everything (overlay) */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  /* Overlay style */
  background-color: var(--matterColorLight);
  opacity: 0.8;

  /* Center content */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Avatar has square aspect ratio */
/* Default is 3:2 */
.squareAspectRatio {
  padding-bottom: 100%;
}

.avatarInvisible {
  visibility: hidden;
  position: absolute;
  top: -1000px;
  left: -1000px;
}

.tip {
  @apply --marketplaceDefaultFontStyles;
  color: var(--matterColorAnti);
  line-height: 24px;
  font-weight: var(--fontWeightRegular);
  margin-top: 0;
  margin-bottom: 12px;
  padding-bottom: 1px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 8px;
    padding-bottom: 4px;
    text-align: center;
  }
}

.fileInfo {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  font-size: 14px;
  line-height: 16px;
  font-weight: var(--fontWeightRegular);
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 1px;
  text-align: center;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 50px;
    padding-bottom: 2px;
  }
}

.nameContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;

  @media(max-width: 460px) {
    flex-wrap: wrap;
    gap: 20px;
  }

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}

.firstName {
  width: calc(34% - 9px);

  @media(max-width: 768px) {
    width: calc(50% - 9px);
  }

  @media(max-width: 460px) {
    width: 100%;
  }
}

.lastName {
  width: calc(66% - 9px);

  @media(max-width: 768px) {
    width: calc(50% - 9px);
  }

  @media(max-width: 460px) {
    width: 100%;
  }
}

.bioInfo {
  color: var(--matterColorAnti);
  margin-top: 11px;

  @media (--viewportMedium) {
    margin-top: 16px;
  }
}

.submitButton {
  width: 241px !important;
  margin-top: 24px !important;

  @media(max-width: 460px) {
    width: 100% !important;
  }
}

.dob {
  margin-bottom: 2rem;

  & label {
    margin-bottom: 10px;
  }
}

.removeButton {
  position: absolute;
  top: -8px;
  left: 42%;
  cursor: pointer;

  @media(max-width: 420px) {
    left: 27%;
    bottom: -10px;
  }

  & svg {
    width: 16px;
    height: 16px;

    @media(max-width: 420px) {
      width: 12px;
      height: 12px;
    }
  }
}

.infoText {
  @apply --marketplaceH4FontStyles;

  display: inline-block;
  padding-left: 18px;
  background-repeat: no-repeat;
  background-position: top 7px left;

  margin-top: 11px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 14px;
  }
}

.emailInput {
  margin-top: 32px;
}

.emailVerified {
  composes: infoText;
  color: var(--successColor);
  background-image: url('data:image/svg+xml;utf8,<svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M6.52 9.098c-.163.188-.397.3-.646.31h-.032c-.238 0-.466-.094-.635-.263L2.783 6.732c-.353-.35-.354-.92-.003-1.273.35-.353.92-.354 1.272-.004L5.794 7.19l4.59-5.278C9.287.738 7.73 0 6 0 2.686 0 0 2.686 0 6c0 3.313 2.686 6 6 6 3.313 0 6-2.687 6-6 0-.91-.21-1.772-.573-2.545L6.52 9.098z" fill="%232ECC71" fill-rule="evenodd"/></svg>');

  @media (--viewportMedium) {
    font-size: 15px;
    font-weight: 400;
  }
}

.emailUnverified {
  composes: infoText;
  color: var(--matterColorAnti);
  background-image: url('data:image/svg+xml;utf8,<svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M6.52 9.098c-.163.188-.397.3-.646.31h-.032c-.238 0-.466-.094-.635-.263L2.783 6.732c-.353-.35-.354-.92-.003-1.273.35-.353.92-.354 1.272-.004L5.794 7.19l4.59-5.278C9.287.738 7.73 0 6 0 2.686 0 0 2.686 0 6c0 3.313 2.686 6 6 6 3.313 0 6-2.687 6-6 0-.91-.21-1.772-.573-2.545L6.52 9.098z" fill="%23F00" fill-rule="evenodd"/></svg>');
}

.pendingEmailUnverified {
  composes: infoText;
  color: var(--failColor);
  width: 100%;
  background-image: url('data:image/svg+xml;utf8,<svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M6.52 9.098c-.163.188-.397.3-.646.31h-.032c-.238 0-.466-.094-.635-.263L2.783 6.732c-.353-.35-.354-.92-.003-1.273.35-.353.92-.354 1.272-.004L5.794 7.19l4.59-5.278C9.287.738 7.73 0 6 0 2.686 0 0 2.686 0 6c0 3.313 2.686 6 6 6 3.313 0 6-2.687 6-6 0-.91-.21-1.772-.573-2.545L6.52 9.098z" fill="%23F00" fill-rule="evenodd"/></svg>');
}

.confirmChangesSection {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
  margin-top: 20px;
  padding: 0;
  opacity: 0;
  visibility: hidden;
  height: 0;

  & p {
    margin-top: 0;
  }
}

.confirmChangesSectionVisible {
  opacity: 1;
  height: auto;
  visibility: visible;
}

.emailStyle {
  font-weight: var(--fontWeightBold);
  word-wrap: break-word;
}

.helperLink {
  color: var(--matterColor);
  border-bottom: solid 1px var(--matterColor);
  line-height: 20px;
  margin: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.phone {
  margin-bottom: 35px;
  margin-top: 30px;

  /* @media (--viewportMedium) {
    margin-top: 32px;
  } */
}

.timezoneSelector {
  margin-top: 30px;
}

.uploadImageModal{
  & h2{
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    letter-spacing: -0.9px;
  }

  & .dragContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2em 0 1em;

    & .uploadedImage{
      width: 260px;
      height: 260px;
      border-radius: 50%;
      border: 1px solid gainsboro;
      object-fit: cover;
    }

    & .dragCircle{
      cursor: pointer;
      width: 260px;
      height: 260px;
      border: 2px dashed rgba(178, 178, 178, 1);
      border-radius: 50%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      & span{
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.18px;
        text-align: center;
        width: 159px;
      }
    }
  }
}

.buttonWrapper{
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin-top: 3em;

  @media(max-width: 460px) {
    flex-wrap: wrap;
    width: 100%;
  }
  & div{
    padding: 16px 0;
    font-size: 19px;
    cursor: pointer;
    font-weight: 400;
  }
  & button{
    width: 185px;
  }
}