@import '../../styles/propertySets.css';

.root {
  color: var(--matterColorLight);
  text-align: center;
  background-color: #F6B430;
  width: 20px;
  min-width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  padding-top: 2px;
}