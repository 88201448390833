.box {
    /* width: 1000px; */
    border: 1px solid #E6E6E6;
    text-align: center;
    padding: 10px 0;
    margin-top: 10px;
    font-weight: 100;
    background: white;
}

.folderContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    & button {
        border: 1px solid #3096f5;
        padding: 5px 10px;
        border-radius: 5px;
        color: #3095f4;
        margin-right: 10px;
        font-size: 14px;
    }
}