.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 30px 24px 0 24px;
}

.form {
  flex-grow: 1;
}

.title {
  margin-bottom: 19px;

  @media (--viewportLarge) {
    margin-bottom: 38px;
    padding: 1px 0 7px 0;
  }

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.4px;
  }
}

.containerClassName {
  width: 560px;
  min-height: 590px;

  @media (max-width:768px) {
    width: 100%;
    min-height: 100%;
  }
}

.disableModalBorder {
  & [class*="Modal_container"] {
    border: none;
  }
}