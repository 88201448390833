@import '../../styles/propertySets.css';

.root {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
}

.imagesField {
  flex-shrink: 0;
  margin: 0;
}

.imageLabel {
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
}

.thumbnail {
  margin: 24px 0 0 0;

  @media (--viewportLarge) {
    width: calc(50% - 12px);
    margin: 0 24px 24px 0;

    &:nth-child(even) {
      margin: 0 0 24px 0;
    }
  }
}

.addImageWrapper {
  float: left;
  position: relative;
  width: 100%;
  margin: 24px 0 0 0;
  overflow: hidden;

  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  @media (--viewportMedium) {
    max-width: 225px;
    margin: 0 0 24px 0;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectRatioWrapper {
  padding-bottom: 58px;
}

.addImage {
  /* Layout */
  /* Maintain aspect ratio */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;

  /* Colors */
  background-color: var(--matterColorLight);

  @media (--viewportLarge) {
    background-color: var(--matterColorBright);
  }

  border-style: dashed;
  border-color: var(--matterColorNegative);
  border-width: 2px;
  border-radius: 2px;

  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.chooseImageText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chooseImage {
  @apply --marketplaceH4FontStyles;
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.imageTypes {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.addImageInput {
  display: none;
}

.imageRequiredWrapper {
  width: 100%;
  clear: both;
}

.tip {
  @apply --marketplaceH5FontStyles;
  flex-shrink: 0;
  color: var(--matterColorAnti);
  margin-top: 24px;
  margin-bottom: 60px;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.error {
  color: var(--failColor);
}

.submitButton {
  display: inline-block;
  width: 241px;
  margin: 30px 0 0px !important;
}



.contentWrapper {
  display: flex;
  flex-wrap: wrap;
  /*max-width: 300px;*/
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 0px;

  & .fieldWrapper{
    display: flex;
  }
}

@media (--viewportMedium) {
  .contentWrapper {
    padding-top: 0px;
    padding-bottom: 8px;
    margin-top: 0px;
  }
}

.currencySelector {
  margin-right: 20px;

  /* margin-bottom: 20px; */
  & select {
    border-bottom-color: var(--textFieldColor);
    padding: 4px 20px 4px 0;
    width: 100px;

    @media (--viewportMedium) {
      padding: 2px 20px 2px 0;
    }
  }
}

.label {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightSemiBold);
  padding: 11px 0 13px 0;
  margin: 0 20px 0 0;
}

@media (--viewportMedium) {
  .label {
    padding: 8px 0 16px 0;
  }
}

.inputsWrapper {
  display: flex;

  @media (--viewportMedium) {
    padding-bottom: 5px;
  }
}

.minPrice {
  @apply --marketplaceSearchFilterSublabelFontStyles;
  width: 55px;
  text-align: center;
  border-bottom-color: var(--textFieldColor);
  border-bottom-width: 3px;

  -moz-appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media (--viewportMedium) {
    padding: 1px 0 4px 0;
  }
}

.customerMaxValue {
  width: 80px !important;
}

.maxPrice {
  @apply --marketplaceSearchFilterSublabelFontStyles;
  width: 56px;
  text-align: center;
  border-bottom-color: var(--textFieldColor);
  border-bottom-width: 3px;

  -moz-appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media (--viewportMedium) {
    padding: 1px 0 4px 0;
  }
}

.priceSeparator {
  margin: 4px 8px 0 8px;
}

@media (--viewportMedium) {
  .priceSeparator {
    margin: 2px 6px 0 6px;
  }
}

.sliderWrapper {
  display: flex;
  padding: 17px 0 25px 0;
  max-width: 456px;

  @media(max-width: 600px) {
    max-width: 100%;
  }
}

@media (--viewportMedium) {
  .sliderWrapper {
    padding: 16px 0 24px 0;
    margin-bottom: 24px;
  }
}

.projectTimelines {
  margin-bottom: 50px;
  max-width: 456px;

  @media(max-width: 600px) {
    max-width: 100%;
  }
}

.countries {
  & legend {
    margin-bottom: 24px;
  }
}

.closer {
  padding-left: 8px;
  position: relative;
  right: 0x;
  top: 9px;
  width: 12px;
  height: 12px;
  opacity: 0.5;
}

.closer:hover {
  opacity: 1;
  cursor: pointer;
}

.closer:before,
.closer:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 12px;
  width: 2px;
  background-color: #333;
}

.closer:before {
  transform: rotate(45deg);
}

.closer:after {
  transform: rotate(-45deg);
}

.buttonBox {
  position: relative;
  display: inline-block;
  margin-top: 20px;

  @media(max-width: 460px) {
    width: 100%;
  }

  &:hover {
    & .toolTipText {
      visibility: visible;
    }
  }

  &:nth-child(2) {
    @media(max-width:460px) {
      margin-top: 0;
    }
  }

  & button {
    width: 215px;

    @media(max-width: 460px) {
      width: 100%;
    }
  }

  & .toolTipText {
    visibility: hidden;
    width: 390px;
    background-color: whitesmoke;
    border-radius: 6px;
    padding: 9px 11px;
    position: absolute;
    z-index: 1;
    left: -81%;
    margin-left: -60px;
    margin-bottom: 5px;
    bottom: -73px;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: -0.177778px;
    color: #3D3D3D;

    @media(max-width: 768px) {
      left: 0;
      width: 100%;
      margin: 0;
    }
  }
}

.submit {
  margin-right: 12px;
}

.privateButton {
  position: relative;
  display: inline-block;
  /* margin-top: 100px; */

  &:hover {
    & .toolTipText {
      visibility: visible;
    }
  }

  & .toolTipText {
    visibility: hidden;
    width: 390px;
    background-color: whitesmoke;
    border-radius: 6px;
    padding: 9px 11px;
    position: absolute;
    z-index: 1;
    right: -100%;
    left: unset;
    margin-left: -60px;
    margin-bottom: 5px;
    bottom: -71px;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: -0.177778px;
    color: #3D3D3D;

    @media(max-width: 768px) {
      right: 0;
      width: 100%;
    }
  }
}

.fieldButtons {
  display: flex;
  flex-direction: row;
  gap: 10px;
  /* margin-bottom: 6em; */

  @media(max-width: 1024px) {
    margin-bottom: 78px;
  }

  @media(max-width: 460px) {
    /* flex-direction: column; */
    flex-wrap: wrap;
  }
}

iframe {
  overflow: hidden;
  /* pointer-events: none; */
}

.documents {
  width: 300px;
  height: 260px;
  overflow: hidden;
  border: 1px solid #cecece;
}

.video {
  width: 10vw;
}

.fileUploadLimitMessage {
  color: red;
}

.countryButtons {
  display: flex;
  justify-content: flex-start;

  & .icon {
    margin-left: 10px;
    font-size: 26px;
    line-height: normal;
  }

  /* & button {
    margin-right: 10px;
    border: none;
    border-radius: var(--borderRadius);
    background-color: var(--successColorDark);
    color: var(--matterColorLight);
    text-align: center;
    text-decoration: none;
    transition: all var(--transitionStyleButton);
    cursor: pointer;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    padding: 5px 15px;
  } */
}

.addCountryButton {
  background: #FFFFFF;
  border: 2px solid #0B96F5;
  border-radius: 4px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.266667px;
  color: #0B96F5;
  width: 188px !important;

  &:hover {
    border: 1px solid var(--marketplaceColor);
  }

  &:disabled {
    background: #FFFFFF;
    border: 2px solid #E6E6E6;
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.266667px;
    color: #E6E6E6;
  }

  /* border: none;
  border-radius: 2rem;
  padding: .5rem;
  cursor: pointer;
  box-shadow: 2px 1px 2px 2px rgb(134, 171, 203); */
  &:hover {
    /* background-color: aqua; */
  }
}

.removeCountryButton {
  /* margin-left: 1rem;
  border: none;
  border-radius: 2rem;
  padding: .5rem;
  cursor: pointer;
  box-shadow: 2px 1px 2px 2px rgb(134, 171, 203); */
}

.removeCountryButton:hover {
  /* background-color: rgb(239, 95, 95); */
}

.locationBox {
  margin-bottom: 20px;

  & p {
    margin-top: 0;
    font-size: 14px;
    font-weight: var(--fontWeightSemiBold);
  }
}

.locationBoxWraper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & .deleteLink {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.266667px;
    color: #FF0000;
    cursor: pointer;
    position: absolute;
    right: -98px;

    @media(max-width: 768px) {
      right: 0;
    }
  }
}

.locationInputWrap {
  /* margin-bottom: 50px; */
  max-width: 456px;

  @media(max-width: 600px) {
    max-width: 100%;
  }
}

.inputLocation {
  margin-bottom: 30px;

  & .currencySelector {
    margin-right: 0;
  }

  &>div {
    margin-bottom: 20px;
    position: relative;

    &>div {
      width: 100%;
    }
  }

  & input {
    /* margin-bottom: 20px; */
  }
}

.pdfBox {
  display: flex;
  gap: 10px;
  align-items: center;

  & .pdfImage {
    & img {
      width: 30px;
      height: 41px;
    }
  }

  & .pdfRight {
    & .pdfName {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: -0.0875px;
      color: #3D3D3D;
    }

    & .pdfDate {
      font-weight: 400;
      font-size: 10px;
      line-height: 21px;
      letter-spacing: -0.0875px;
      color: #B2B2B2;

      & .fileSize {
        margin-left: 5px;
        position: relative;
        padding-left: 8px;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          background-color: #B2B2B2;
          height: 3px;
          width: 3px;
          top: 5px;
          border-radius: 50%;
        }
      }
    }
  }
}

.trashButton {
  background: #FFFFFF;
  border: 1px solid #E6E6E6;
  border-radius: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.333333px;
  color: #FF0000;
  height: 25px;
  width: 59px;
  justify-content: center;
  cursor: pointer;
}

.uploadPdfBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #E6E6E6;
  padding: 11px 0;
}

.uploadPdfList {
  & li {
    &:last-child {
      margin-bottom: 60px;
    }
  }
}