@import '../../styles/propertySets.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  /* Text positioning is done with paddings */
}

.content {
  display: flex;
  flex-direction: column;
  margin: 0 24px 0 24px;
  padding: 0 36px;
  width: 100%;
  margin: 0 auto;
  @media(max-width: 768px){
    padding: 0;
  }

  @media (--viewportMedium) {
    max-width: calc(1140px + 36px + 36px);
    padding: 0 36px;
  }
  @media(max-width: 460px){
    padding: 0;
  }

  /* Special viewport for adjusting the heroContent's placement */
}

.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (--viewportMedium) {
    padding-bottom: 123px;
  }
}

.title {
  @apply --marketplaceH4FontStyles;
  font-weight: 700;
  line-height: 54px;
  margin-bottom: 32px;
  composes: animation;
  animation-delay: 0.65s;
  text-align: center;

  @media (--viewportMedium) {
    font-size: 30px;
    max-width: var(--SectionHero_desktopTitleMaxWidth);
    margin-bottom: 0px;
  }
}

.paragraph {
  color: var(--matterColorDarker);
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 28px;
  max-width: 623px;
  font-weight: 400;
  text-align: center;
  @media(max-width: 460px){
    font-size: 16px;
    line-height: 24px;
  }
}

.signupLink {
  @apply --marketplaceButtonStyles;
  font-weight: var(--fontWeightBold);
  composes: animation;
  border-radius: 50px;
  animation-delay: 0.8s;
  padding: 18px 0;
  min-height: auto;
  /*margin-bottom: 50px;*/
  color: var(--matterColorDark);
  background-color: var(--marketplaceColor);
  &:hover,
  &:focus {
    background-color: var(--successColorDark);
    color: var(--matterColorLight);
  }

  @media (--viewportMedium) {
    display: block;
    width: 220px;
    font-size: 18px;
  }
}

.signupLinkPage {
  @media (--viewportMedium) {
    width: 262px;
  }
}

.learnMore {
  @apply --marketplaceButtonStyles;
  font-weight: var(--fontWeightBold);
  composes: animation;
  border-radius: 50px;
  animation-delay: 0.8s;
  padding: 18px 0;
  min-height: auto;
  color: var(--matterColorDark);
  margin-bottom: 35px;
  background-color: var(--marketplaceColor);
  &:hover,
  &:focus {
    background-color: var(--successColorDark);
    color: var(--matterColorLight);
  }
  @media (--viewportMedium) {
    display: block;
    width: 220px;
    font-size: 18px;
  }
}

.logo {
  display: none;
  @media (--viewportMedium) {
    display: block;
    margin-bottom: 12px;
  }
}
.contentBlock {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 200px;
  &:nth-child(3n) {
    @media (--viewportLarge) {
      margin-bottom: 123px;
      flex-direction: row-reverse;
    }
  }

  @media (--viewportLarge) {
    flex-direction: row;
    margin-bottom: 123px;
    grid-gap: 20px;
  }
  @media(max-width: 768px){
    margin-bottom: 90px;
  }
}
.contentBlockNoMargin {
  margin: 0 !important;
}

.contentBlockText {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 470px;
  margin-bottom: 65px;
  @media (--viewportLarge) {
    margin-bottom: 0;
  }
  @media(max-width: 1024px){
    max-width: 100%;
    width: 100%;
  }
}

.blockTitle {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightBold);
  line-height: 40px;
  max-width: 470px;
  margin-bottom: 24px;

  composes: animation;
  animation-delay: 0.65s;

  @media (--viewportMedium) {
    font-size: 30px;
    max-width: var(--SectionHero_desktopTitleMaxWidth);
    margin-bottom: 50px;
    margin-top: 0;
    text-align: center;
  }
  @media(max-width: 1024px){
    max-width: 100%;
  }
  @media(max-width: 768px){
    line-height: 36px;
    margin-top: 22px;
  }
  @media(max-width: 460px){
    line-height: 32px;
    margin-top: 22px;
    text-align:center;
  }
}

.advantage {
  border-radius: 10px;
  box-shadow: var(--boxShadowBig);
  font-weight: var(--fontWeightRegular);
  padding: 23px;
  text-align: center;
  font-size: 22px;
  margin-bottom: 20px;
  max-width: 470px;
  min-width: 470px;
  background: #fff;
  @media(max-width: 1024px){
    max-width: 100%;
    min-width: 100%;
  }
  @media(max-width: 768px){
    font-size: 18px;
    line-height: 26px;
  }
  @media(max-width: 460px){
    font-size: 16px;
    line-height: 22px;
  }
}

.yellowAdvantage {
  font-weight: var(--fontWeightBold);
  color: var(--marketplaceColor);
  box-shadow: none;
}
.noPaddingAdvantage {
  margin-bottom: 20px;
  font-weight: var(--fontWeightBold);
  max-width: 470px;
  min-width: 470px;
  text-align: center;
  font-size: 24px;
   @media(max-width: 460px){
    max-width: 100%;
    min-width: 100%;
    font-size: 20px;
   }
}

.blackAdvantage {
  font-weight: var(--fontWeightBold);
  box-shadow: none;
}

.contentBlockImage {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  & img {
    border-radius: 10px;
  }
  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    left: -85px;
    border-style: solid;
    border-width: 261px 480px 262px 0;
    border-color: transparent var(--marketplaceColor) transparent transparent;
  }
}
.contentBlockImageRevers {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  & img {
    border-radius: 10px;
  }
  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    left: 124px;
    border-style: solid;
    border-width: 261px 0 262px 480px;
    border-color: transparent transparent transparent var(--marketplaceColor);
    @media(max-width: 767px){
      border-width: 226px 0 256px 424px;
    }
  }
}

.getStarted {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 139px;
  @media(max-width: 460px){
    margin-bottom: 50px;
  }
}

.getStartedTitle {
  line-height: 40px;
  font-weight: var(--fontWeightBold);
  font-size: 30px;
  @media(max-width: 768px){
    font-size: 22px;
    line-height: 32px;
  }
  @media(max-width: 460px){
    font-size: 20px;
    line-height: 28px;
  }
}

.getStartedText {
  max-width: 660px;
  margin-bottom: 40px;
  font-size: 18px;
  font-weight: normal;
  @media(max-width: 460px){
    font-size: 16px;
  }
}

.gallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  margin-bottom: 30px;
  grid-gap: 15px;
  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: space-between;
  }
  @media(max-width: 768px){
    grid-template-columns: repeat(2, 1fr);
  }
  @media(max-width: 460px){
    width: 100%;
  }
}

.galleryImage {
  margin-right: 0;
  position: relative;
  text-align: center;
  flex-grow: 1;
  max-height: 274px;
  min-height: 274px;
  max-width: 274px;
  margin-bottom: 16px;
  width: 100%;
  &:last-child {
    margin-right: 0;
  }

  @media (--viewportLarge) {
    margin-right: 16px;
    margin-bottom: 0;
    /* width: 25%; */
  }

  & img {
    border-radius: 10px;
    display: block;
    height: 100%;
    object-fit: cover;
    max-width: 100%;
    @media(max-width: 460px){
      width: 100%;
    }
  }
  @media(max-width: 768px){
    max-height: 100%;
    min-height: 100%;
    margin-bottom: 0;
  }
  @media(max-width: 460px){
    width: 100%;
    max-width: 100%;
    max-height: 100%;
  }
}
