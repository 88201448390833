@import '../../styles/propertySets.css';

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 30px 24px 0 24px;
}

.form {
  flex-grow: 1;
}

.title {
  margin-bottom: 19px;
  letter-spacing: -1.25px;

  @media(max-width: 768px) {
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.4px;
  }

  @media (--viewportLarge) {
    margin-bottom: 38px;
    padding: 1px 0 7px 0;
  }
}

.verifyEmailMessageButton {
  @apply --TopbarDesktop_linkHover;
  @apply --marketplaceH5FontStyles;
  padding: 1rem;
  background-color: #2FA4F8;
  color: white;
  text-decoration: none;
  width: fit-content;

}