.briefGeneratorForm {
  padding: 40px;

  @media(max-width: 768px) {
    padding: 24px;
  }
}

.container {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px !important;
  width: 100%;
  margin: 0 auto;
  padding: 40px 100px;
  border: 1px solid #c2c2c2;
  border-radius: 5px;

  @media(min-width: 1200px) {
    width: 800px;
  }

  @media(max-width: 768px) {
    padding: 20px;
  }

  & .inputBox {
    margin-bottom: 30px;

    & input,
    & textarea {
      min-width: 100%;
      max-width: 100%;
      font-size: 14px !important;
      line-height: 22px;
      padding-bottom: 6px;

      &::placeholder {
        font-size: 14px;
      }
    }

    & label {
      margin-bottom: 5px;
      margin-right: 0px;
      color: rgb(0, 0, 0);
      font-size: 1rem;
      font-weight: 600;
      font-style: normal;
    }
  }
}

.budgetBox {
  display: flex;
}

.textareaBox {
  & label {
    margin-bottom: 5px;
  }
}

.briefGenerator {
  margin-bottom: 40px;

  & .generatorTitle {
    font-size: 36px;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -1.25px;
  }

  @media(max-width: 768px) {
    margin-bottom: 3em;

    & .generatorTitle {
      font-size: 24px;
      line-height: 28px;
    }
  }
}

.attachment {
  margin-bottom: 20px;
  padding-top: 15px;

  & .close {
    cursor: pointer;
  }

  & input {
    display: none;
  }

  & .inputLabel {
    width: 100%;
    box-sizing: border-box;
    border: 2px dashed gainsboro;
    border-radius: 2px;
    padding: 25px 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
    margin-bottom: 12px;
    align-items: center;
    gap: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.177778px;

    @media(max-width: 768px) {
      padding: 5px 20px;
      font-size: 12px;
    }

    & .dragDrop {
      color: #3D3D3D;
    }

    & .docType {
      color: #B2B2B2;
    }
  }
}

.budgetWrapper {
  & label {
    margin-bottom: 5px;
  }

  & .subTitle {
    margin: 12px 0;
  }
}

.budgetInputBox {
  & input {
    padding: 0px 10px;
    position: relative;
    top: 33px;
  }
}

.currencySelector {
  & select {
    background-position: 70%;
  }
}

.tooltip {
  background: #223133;
  border-radius: 50%;
  width: 16px;
  display: inline-block;
  height: 18px;
  color: white;
  padding-left: 5px;
  margin-left: 4px;
  font-size: 13px;
  cursor: help;
  position: relative;

  &:hover {
    &::before {
      display: block;
    }
  }

  &::before {
    display: none;
    content: 'Free-form text field, specify the timelines by just typing a specific or an approximation value.';
    width: 335px;
    position: absolute;
    top: -5.8em;
    left: -12em;
    background: #223133;
    padding: 10px 12px;
    border-radius: 5px;
    text-align: center;
  }
}

.actionButtons {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 3em;

  @media(max-width: 460px) {
    flex-wrap: wrap;
    width: 100%;
  }

  & button {
    min-height: 45px;
    max-height: 45px;
    width: 165px;
    font-size: 14px;

    @media(max-width: 460px) {
      width: 100%;
    }
  }

  & .submitButton {

    &:disabled,
    & [disabled] {
      background-color: #eeeded;
      color: #666666;
    }
  }
  & .disabledSubmitButton{
    background-color: #eeeded;
    color: #666666;
  }
}

.outputField {
  display: grid;
  grid-template-columns: 70% 20%;
  grid-gap: 10px;
  justify-content: space-between;
  margin-bottom: 18px;

  & textarea {
    min-width: 100%;
    max-width: 100%;
  }
}

.copyButton {
  width: 128px;
  min-height: 42px;
  height: 42px;
  align-self: flex-end;
}

.textareaBoxMinMax {
  display: flex;
  justify-content: space-between;

  & .rowBox {
    width: calc(50% - 9px);
  }
}

.checkboxWrapper {
  cursor: pointer;
  position: relative;
  border-radius: 50px;
  font-family: var(--font-familyLite);
  width: 28px;
  height: 16px;
  border: 1px solid #e6e6e6;
  background: gainsboro;
  margin: 0 6px;
}

.ball {
  position: absolute;
  border-radius: 50px;
  width: 15px;
  height: 16px;
  top: -1px;
  left: 0px;
  transition: transform 0.3s ease;
  background-color: #fff;

  @media(max-width: 768px) {
    /* width: 90px;
      height: 45px;
      top: -3px;
      left: -1px; */
  }

  &.toggled {
    transform: translateX(75%);
    background-color: var(--textFieldColor);
  }
}

.rangeLabel {
  display: flex;
  align-items: center;
}

.fieldCheckboxRange {
  margin-bottom: 20px;
}

.suggestedAnswerBox {
  border: 1px solid #ddd;
  padding: 10px;
  /* max-height: 200px;
    overflow-y: auto; */
}

.minMaxLabel {
  margin-bottom: 8px;
  font-weight: 400;
}