.supportBanner {
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: bottom;
  background-size: cover;
  background-image: url(../../assets/csm-banner.png);
  position: relative;
  width: 100%;

  @media(max-width: 768px) {
    background-position: right 0 bottom 100%;
  }

  & .supportBannerWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 277px;
    border-radius: 5px;
    border: 1px solid #E6E6E6;
    position: relative;
    padding: 25px;

    @media(max-width: 800px) {
      height: 100%;
    }

    @media(max-width: 768px) {
      padding: 40px 25px;
    }

    & .profileIcon {
      left: 0;
      bottom: 0;
      height: 140px;
      width: 140px;
      border-radius: 3px;
      position: absolute;

      @media(max-width: 800px) {
        display: none;
      }

      @media(max-width: 768px) {
        height: 65px;
        width: 65px;
      }

      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
      }
    }

    & h2 {
      font-size: 32px;
      font-weight: 500;
      line-height: 48px;
      letter-spacing: -0.08888888359069824px;
      text-align: center;
      margin-top: 0;
      margin-bottom: 24px;
    }

    & p {
      margin: 0;
      width: 61%;
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      text-align: center;
      letter-spacing: -0.25px;

      @media(max-width: 768px) {
        font-size: 16px;
        line-height: 24px;
        width: 100%;
      }
    }

    & button {
      width: 309px;
      height: 40px;
      margin-top: 24px;
      min-height: 40px;

      @media(max-width: 768px) {
        height: auto;
        line-height: 19px;
        font-weight: 500;
        font-size: 15px;
        padding: 8px;
      }

      @media(max-width: 460px) {
        width: 100%;
      }
    }
  }
}

.emailModalActionButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 43px;

  & button {
    &:nth-child(1) {
      color: #3d3d3d;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: -0.111px;
    }

    &:nth-child(2) {
      width: 200px;
    }
  }
}
.emailModalHeading {
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.8999999761581421px;
  text-align: center;
}
.thirdWrapper {
  width: 100%;
  margin-bottom: 32px;
}