.collaborationContainer {
  display: flex;
  justify-content: left;
  /* align-items: center; */
  flex-direction: column;
}

.collaborators {
  /* display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  overflow-x: auto;
  white-space: nowrap; */

  & th {
    text-align: left;
    padding: 10px 0 10px 5px;
  }
}

.collaboratorsHeadingSection,
.CollaborationPanelData,
.authorDetails {

  /* display: flex; */
  /* justify-content: space-between; */
  /* align-items: center; */
  /* border-bottom: .1px solid lightgray; */
  & td {
    height: 54px;
  }
}

/* .nameBox {
  width: 22%;
  padding: 10px 10px 10px 5px;
}

.emailBox {
  width: 25%;
  padding: 10px 10px 10px 5px;
}

.roleBox {
  width: 10%;
  padding: 10px 10px 10px 5px;
} */
.fullName {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: normal;
  letter-spacing: -0.0888889px;
  color: #3D3D3D;

  @media(max-width: 768px) {
    font-size: 14px;
    line-height: 24px;
  }
}

.firmTitle {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 15px;
  letter-spacing: -0.0875px;
  color: #B2B2B2;
  cursor: pointer;
}

.trashBox {
  /* width: 10%; */
  /* padding: 10px 0; */
  /* text-align: center; */

  & svg {
    cursor: pointer;
  }
}

.authorNameSection {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media(max-width:768px) {
    /* width: 200px; */
  }
}

.buttonBox {
  /* width: 20%; */
  padding: 10px 10px 10px 5px;

  & button {
    min-height: 34px;
    font-size: 12px;
    font-weight: 500;
    padding: 0 10px;
  }
}

.statusBox {
  /* width: 13%; */
  /* padding: 10px 10px 10px 5px; */
}

.providerName span {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  /* width: 60%; */
}

.providerName h6 {
  margin: 0;
  letter-spacing: 0.5px;
}

.profileImage {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-color: #42affa;
  color: white;
  /* margin-right: 5px; */
  /* box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.5); */
  text-align: center;
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
}

.authorNameDetails {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
}

.profileIcon {
  position: relative;
  margin-right: 10px;
}

.inviteButtonSection {
  font-weight: 400;
  letter-spacing: -0.266667px;
  color: #0B96F5;
  background: #FFFFFF;
  border: 2px solid #0B96F5;
  border-radius: 4px;
  width: 134px;
  height: 35px;
  margin-top: 20px;
  text-align: center;
  cursor: pointer;

  @media(max-width: 768px) {
    margin-left: 20px;
  }
}

.successToasterMessage {
  margin-bottom: 30px;
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  width: 611px;
}

.resendEmailButton {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.0888889px;
  text-transform: uppercase;
  color: #3D3D3D;
  cursor: pointer;
}

.trashLink {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.0888889px;
  text-transform: uppercase;
  color: #FF0000;
  cursor: pointer;
}

.removeButton {
  cursor: pointer;
  font-weight: 800;
}

.onlineDot {
  height: 10px;
  width: 10px;
  background-color: green;
  border-radius: 50%;
  position: absolute;
  right: 2px;
  bottom: 0;
}

.offlineDot {
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 50%;
  position: absolute;
  right: 2px;
  bottom: 0;
}


.headerSection {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 8px;

  @media(max-width:768px) {
    height: unset;
    flex-wrap: wrap;
    gap: 20px;
    margin: 20px;
  }

  @media(max-width: 460px) {
    flex-wrap: wrap;
    gap: 10px;
  }

  & span {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.0888889px;
    color: #858585;
    font-weight: 400;
  }

  & .activeView {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.0888889px;
    color: #3D3D3D;
  }
}

.ball {
  position: absolute;
  border-radius: 50px;
  width: 12px;
  height: 12px;
  top: -1px;
  left: 0px;
  transition: transform 0.3s ease;
  background-color: #0B96F5;

  @media(max-width: 768px) {
    /* width: 90px;
    height: 45px;
    top: -3px;
    left: -1px; */
  }

  &.toggled {
    transform: translateX(75%);
  }
}

.checkboxWrapper {
  cursor: pointer;
  position: relative;
  border-radius: 50px;
  font-family: var(--font-familyLite);
  width: 23px;
  height: 12px;
  border: 1px solid #e6e6e6;
  background: #E6E6E6;
  margin: 0 6px;
}

.collaborationGrid {
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat(3, 1fr);

  @media(max-width: 1300px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media(max-width: 768px) {
    padding: 0 24px;
  }

  @media(max-width: 660px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.collaborationCard {
  background: #FFFFFF;
  border: 1px solid #E6E6E6;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);

  & .cardTop {
    padding: 30px 43px 20px;
    text-align: center;

    & .cardImage {
      width: 67px;
      height: 67px;
      margin-bottom: 8px;
      border-radius: 50%;
    }

    & .profileImage {
      width: 67px;
      height: 67px;
      border-radius: 50%;
      background-color: #42affa;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      margin-bottom: 8px;
    }

    & .authorName {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.0888889px;
      color: #3D3D3D;
      margin-bottom: 8px;
    }

    & .dividerLine {
      background-color: #E6E6E6;
      height: 1px;
      margin-top: 20px;
      margin-bottom: 21px;
    }

    & .bottomBox {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      height: 111px;
    }

    & .projectTitle {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: -0.0875px;
      color: #B2B2B2;
      margin-bottom: 8px;
    }

    & .authorEmail {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: -0.0888889px;
      color: #858585;
      margin-bottom: 15px;
      width: 200px;
      word-wrap: break-word;
    }

    & .userRole {
      margin-bottom: 10px;

      & span {
        /* font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.1px;
        color: #FFFFFF;
        background: #F6B430;
        border-radius: 12px;
        height: 26px;
        padding: 3px 15px; */
      }
    }

    & .userStatus {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
      letter-spacing: -0.0888889px;
      color: #858585;
      margin-top: 10px;
    }
  }

  & .lightYellow {
    background: #fdf0d6;
    border-radius: 12px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.1px;
    color: #E49800;
    padding: 4px 15px;
  }

  & .lightBlue {
    background: #ceeafd;
    border-radius: 12px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.1px;
    color: #0B96F5;
    padding: 4px 15px;
  }

  & .lightGray {
    background: #b2b2b269;
    border-radius: 12px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.1px;
    color: #3d3d3d;
    padding: 4px 15px;
  }

  & .yellowBack {
    background: #F6B430;
    border-radius: 12px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.1px;
    color: #FFFFFF;
    padding: 4px 15px;
  }

  & .blueBack {
    background: #0B96F5;
    border-radius: 12px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.1px;
    color: #FFFFFF;
    padding: 4px 15px;
  }

  & .bottomLink {
    border-top: 1px solid #E6E6E6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    height: 46px;

    & .resendLink {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: -0.0888889px;
      text-transform: uppercase;
      color: #3D3D3D;
      cursor: pointer;
    }

    & .removeLink {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: -0.0888889px;
      text-transform: uppercase;
      color: #FF0000;
      cursor: pointer;
    }
  }
}

.collaborationTable {
  overflow-x: auto;
  padding-bottom: 10px;

  & table {
    & thead {
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      z-index: 1;
      border: 1px solid #E6E6E6;
      box-shadow: 0px 2px 2px rgb(0 0 0 / 5%);
      border-radius: 1px;
    }

    & tbody {
      border: 1px solid #E6E6E6;

      & tr {
        border: 1px solid #E6E6E6;
      }
    }

    & td {
      background-color: #fff;
      border: none;
      padding: 20px 30px;

      @media(max-width: 768px) {
        padding: 10px 15px;
      }
    }

    & th {
      border: none;
      padding: 20px 30px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 32px;
      letter-spacing: -0.0888889px;
      color: #3D3D3D;

      @media(max-width: 768px) {
        font-size: 14px;
        line-height: 20px;
      }

      &:nth-child(1) {
        min-width: 300px;
      }
    }

    & .authorEmail {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      letter-spacing: -0.0888889px;
      color: #858585;
    }

    & .roleBox {
      text-align: center;

      & span {
        width: 110px;
        display: block;
      }

      & .yellowBack {
        background: #F6B430;
        border-radius: 12px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        letter-spacing: -0.1px;
        color: #FFFFFF;
        padding: 4px 15px;
      }

      & .redBack {
        background: #fdf0d6;
        border-radius: 12px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        letter-spacing: -0.1px;
        color: #E49800;
        padding: 4px 15px;
      }

      & .skyblueBack {
        background: #ceeafd;
        border-radius: 12px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        letter-spacing: -0.1px;
        color: #0B96F5;
        padding: 4px 15px;
      }

      & .blueBack {
        background: #0B96F5;
        border-radius: 12px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        letter-spacing: -0.1px;
        color: #FFFFFF;
        padding: 4px 15px;
      }

      & .grayBack {
        background: #b2b2b269;
        border-radius: 12px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        letter-spacing: -0.1px;
        color: #3d3d3d;
        padding: 4px 15px;
      }
    }

    & .statusBox {
      text-align: center;

      & span {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        text-align: center;
        letter-spacing: -0.0888889px;
        color: #858585;
      }
    }
  }

  & .authorNameSection {}
}

.inviteButtonBox {
  background: rgba(11, 150, 245, 0.05);
  border: 1px solid #E6E6E6;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 350px;
}

.csmImageBoxCard {
  position: relative;
  display: block;
  cursor: pointer;

  & img {
    border: 3px solid #FA0;
  }
  &:hover{
    &::before{
      visibility: visible;
    }
  }
  &::before{
    visibility: hidden;
    content: attr(data-tooltip);
    background-color: white;
    color: #000;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    width: 400px;
    top: -66px;
    left: -8em;
    box-shadow: 0px 8px 16px rgb(0 0 0 / 30%);
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.25px;
  }
  &::after {
    content: "CSM";
    position: absolute;
    left: 50%;
    bottom: -3px;
    transform: translate(-50%, 0);
    background-color: #FDECCA;
    border-radius: 2px;
    width: 45px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #E49800;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.087px;
  }
  & .profileImage {
    border: 3px solid #FA0;
    position: relative;
  }
}

.csmImageBox {
  cursor: pointer;  

  &:hover{
      &::before{
        visibility: visible;
      }
  }
  &::before{
      visibility: hidden;
      content: attr(data-tooltip);
      background-color: white;
      color: #000;
      border-radius: 6px;
      padding: 10px;
      position: absolute;
      z-index: 1;
      width: 400px;
      top: -10px;
      left: 4em;
      box-shadow: 0px 8px 16px rgb(0 0 0 / 30%);
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.25px;
  }
  
  &::after {
    content: "CSM";
    position: absolute;
    left: 50%;
    bottom: -3px;
    transform: translate(-50%, 0);
    background-color: #FDECCA;
    border-radius: 2px;
    width: 30px;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #E49800;
    text-align: center;
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.087px;
    padding-top: 2px;
  }

  & .profileImage {
    border: 3px solid #FA0;
    position: relative;
  }
}

.alertModal {
	@media(max-width: 420px) {
		padding-top: 5em;
	}

	& h2 {
		font-size: 30px;
		font-weight: 600;
		line-height: 40px;
		letter-spacing: -0.9px;
		text-align: center;
	}

	& p {
		font-size: 20px;
		font-weight: 400;
		line-height: normal;
		letter-spacing: -0.9px;
		text-align: center;
	}

  & .buttonWrapper {
    margin-top: 4em;
  
    & button {
      font-size: 19px;
    }
  }
}