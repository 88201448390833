@import '../../styles/propertySets.css';

/* Desktop */
.root {
  /* Size */
  width: 100%;
  height: var(--topbarHeightDesktop);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: flex-start; */
  padding: 0 10px 0 0;

  /* fill */
  background-color: var(--matterColorLight);

  /* shadows */
  box-shadow: var(--boxShadowLight);

  /*@media (--viewportLarge) {*/
  /*  padding: 0 12px 0 0;*/
  /*}*/
}

.disabledLink{
  pointer-events: none;
}


/* logo */
.logoLink {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  padding: 21px 24px 21px 24px;
  height: 100%;

  @media (--viewportLarge) {
    padding: 21px 36px 21px 36px;
  }
}

.leftBar {
  display: flex;
  align-items: center;
}

.rightBar {
  display: flex;
  align-items: center;
}

.sectionLink {
  display: flex;
  justify-content: flex-end;
  min-width: 290px;
  align-items: center;
}

.logo {
  /* If height is increased, change link paddings accordingly */
  height: 38px;

  /* Good aspect ratio should be less than 160/27 (width/height) */
  /* NOTE: extremely wide logos, need special handling. */
  width: 95px;
  max-width: 220px;
  object-fit: contain;
  object-position: left center;
}

/* Search */
.searchLink {
  flex-grow: 1;
  height: 100%;
  /*margin-right: 24px;*/
  border-left-style: solid;
  border-left-width: 1px;
  border-bottom: none;
  border-left-color: var(--matterColorNegative);

  & input {
    border: none;
  }

  @media (--viewportLarge) {
    padding-left: 28px;
  }
}


.search {
  @apply --marketplaceH4FontStyles;
  @apply --TopbarDesktop_label;
  color: var(--matterColor);
}

.topBarSearchWithLeftPadding {
  padding-left: 24px;
  height: var(--topbarHeightDesktop);

  @media (--viewportLarge) {
    padding-left: 34px;
  }
}

/* Create listing (CTA for providers) */
.createListingLink {
  @apply --TopbarDesktop_linkHover;
  @apply --marketplaceH5FontStyles;

  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;
  color: var(--marketplaceColor);
  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColorDark);
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.createListing {
  color: var(--successColorDark);
  @apply --TopbarDesktop_label;
}

.createBrief {
  color: var(--matterColorDark);
  @apply --TopbarDesktop_label;
}

/* Inbox */
.inboxLink {
  @apply --TopbarDesktop_linkHover;
  @apply --marketplaceH5FontStyles;
  height: 100%;
  padding: 0 12px 0 12px;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--matterColor);

  &:hover {
    color: var(--matterColorDark);
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.inbox {
  @apply --TopbarDesktop_label;
  position: relative;
}

.notificationDot {
  /* Dimensions */
  width: 7px;
  height: 7px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 3px;
  right: -9px;

  /* Style: red dot */
  background-color: var(--failColor);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Profile menu */
.profileMenuLabel {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0 12px 0 12px;

  &:active {
    border-bottom: 0;
  }
}

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

.avatar {
  margin: 16px 0;
}

.profileMenuclient {
  right: 0px;
  /* background-color: #eee; */

  & ul {
    & .borderTop{
      border-top: 1px solid #E6E6E6;
    }
    & .paddingTop{
      padding-top: 0;
    }
    & .paddingBottom{
      padding-bottom: 0;
    }
    & .noPadding{
      padding: 0;
    }
    & li {
      padding: 15px 0;
      cursor: pointer;
      position: relative;
    }
  }
}

.profileMenuContent {
  min-width: 276px;
  right: 0px;
  /* background-color: #000; */

  & ul {
    & li {
      padding: 15px 0;
      cursor: pointer;

      &:nth-child(2) {
        padding-bottom: 0;
        display: none;
      }

      &:nth-child(3) {
        border-top: 1px solid #E6E6E6;
        border-bottom: 1px solid #E6E6E6;
      }

      &:nth-child(4) {
        /* padding-bottom: 0; */
      }

      &:nth-child(5) {
        border-top: 1px solid #E6E6E6;
      }

      &:nth-child(6) {
        border-top: 1px solid #E6E6E6;
      }

      &:nth-child(7) {
        border-top: 1px solid #E6E6E6;
        padding-bottom: 0;
      }

      &:nth-child(8) {
        padding-top: 0;
      }

      &:nth-child(9) {
        padding-top: 0;
        border-bottom: 1px solid #E6E6E6;
      }

      &:nth-child(10) {
        /* padding-top: 20px;
        padding-bottom: 20px; */
        border-bottom: 1px solid #E6E6E6;
      }

      &:last-child {
        padding: 0;
        border-top: 1px solid #E6E6E6;
      }
    }
  }


}

.findAnExpertLink,
.becomeAnExpertLink {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;
  color: var(--matterColor);

  &:hover {
    color: var(--matterColorDark);
  }
}

.findAnExpertLink,
.becomeAnExpertLink {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;
  color: var(--matterColor);

  &:hover {
    color: var(--matterColorDark);
  }
}

/* Authentication */
.signupLink {
  @apply --TopbarDesktop_linkHover;
  height: 48px;
  width: 97px;
  justify-content: center;
  background: #0B96F5;
  border-radius: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.266667px;
  color: #FFFFFF;

  &:hover {
    /* color: var(--matterColorDark); */
  }
}

.loginLink {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;

  color: var(--matterColor);

  &:hover {
    color: var(--matterColorDark);
  }
}

.signup,
.login {
  @apply --marketplaceH5FontStyles;
  @apply --TopbarDesktop_label;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.borderLines {
  border-top: 1px solid #E6E6E6;
  /* padding: 20px 0 ; */
}

.profileSettingsLink,
.yourListingsLink {
  font-weight: 400;
  font-size: 15px;
  line-height: 32px;
  letter-spacing: -0.09375px;
  color: #3D3D3D;
  padding: 0 30px;

  &:hover {
    color: var(--matterColorDark);
    text-decoration: none;

    & .menuItemBorder {
      /* width: 6px; */
      /* background-color: var(--marketplaceColor); */
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.logoutButton {
  @apply --marketplaceLinkStyles;
  /* Font is specific to this component */
  @apply --marketplaceH5FontStyles;

  font-weight: 400;
  font-size: 15px;
  line-height: 32px;
  /* identical to box height, or 213% */


  /* Dark grey */

  color: #3D3D3D !important;

  /* Dimensions */
  position: relative;
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 15px 30px;

  /* Layout details */
  color: var(--matterColorAnti);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);
  /* border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--matterColorNegative); */

  &:hover {
    color: var(--matterColorDark);
    text-decoration: none;

    & .menuItemBorder {
      height: 100%;
      width: 6px;
      top: 0;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    /* margin-top: 20px; */
    margin-bottom: 0;
  }
}

.currentPage {
  color: var(--matterColorDark);
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--matterColorDark);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--matterColorDark);
    }
  }
}

.offerBadge {
  scale: 0.68;
  position: absolute;
  top: -22px;
  right: 16em;
}

/* .clickForFree {
  display: none;

  @media only screen and (max-width: 960px) and (min-width: 768px) {
    display: block;
    color: #fff;
    font-weight: var(--fontWeightRegular);
    line-height: 19px;
    font-size: 12px;
    background: #0b96f5;
    border-radius: 5px;
    margin-top: 26px;
    width: 100px;
    text-align: center;
    padding: 3px 0;
  }
} */

.offerText {
  position: relative;

  /* @media(max-width: 960px) {
    display: none;
  } */

  & .offerMain {
    width: 230px;
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    position: relative;
    top: 0;
    right: 0.5em;
    background: #0b96f5;
    border-radius: 10px;
    transition: box-shadow 0.3s ease-in-out;

    @media only screen and (max-width: 960px) and (min-width: 768px) {
      width: 155px;
    }

    &:hover {
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    }

    &:after {
      content: "";
      position: absolute;
      height: 90%;
      width: 97%;
      top: 3px;
      left: 3px;
      border-radius: 10px;
      border: dashed;
      border-width: 1px;
      border-color: #fff;
    }

  }

  & .offerTopBox {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media only screen and (max-width: 960px) and (min-width: 768px) {
      line-height: normal;
      font-size: 10px;
      font-weight: bold;
      margin-right: 2px;
    }
  }

  & .offerHeadingText {
    display: inline-block;
    color: white;
    line-height: 0.99;
    font-size: 23px;
    font-weight: bold;
    margin-right: 5px;
    text-shadow: 0px 0px 6px #3c3636;
    /* margin-right: 5px; */
  }

  & .offerHeadingNumber {
    color: #ffffff;
    font-size: 36px;
    line-height: 43px;
    font-weight: bold;
    text-shadow: 0px 0px 6px #3c3636;

    @media only screen and (max-width: 960px) and (min-width: 768px) {
      font-size: 23px;
      line-height: 43px;
    }
  }

  & .offerSubText {
    color: #ffd60a;
    font-size: 12px;
    line-height: 14px;
    font-weight: 900;
    width: 125px;
    margin-left: 12px;
    text-shadow: 0px 0px 6px #00000082;
    letter-spacing: 0.3px;

    @media only screen and (max-width: 960px) and (min-width: 768px) {
      font-size: 10px;
      line-height: 13px;
      font-weight: 900;
      width: 85px;
      margin-left: 5px;
    }
  }

  & .clickHere {
    position: absolute;
    left: -140px;
    top: 10px;
    animation: blinker 1s linear infinite;

    @media(max-width: 1200px) {
      display: none;
    }

    &:after {
      content: "→";
      position: absolute;
      font-size: 50px;
      line-height: 15px;

    }
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.nonApprovedUserInfo {
  position: absolute;
  left: 0;
  top: 75px;
  width: 100%;
  background: var(--marketplaceColor);
  margin: 0;
  padding: 10px 24px;
  z-index: 3;
  box-shadow: var(--boxShadow);
  text-align: center;
}

.nonApprovedUserInfoLink {
  margin-left: 5px;
  color: var(--matterColorLight);
}

.disableLink{
  pointer-events: none;
}