.submitButton {
    flex: 1 1;
    max-width: 196px;

    @media(max-width: 460px) {
        max-width: 100%;
    }
}

.filteredList {
    position: absolute;
    top: 42px;
    box-shadow: 0px 4px 4px 0px #0000000d;
    border: 1px solid #E6E6E6;
    width: 100%;
    max-height: 140px;
    background: #fff;
    padding: 0;
    margin: 0;
    overflow-y: auto;

    & li {
        font-size: 14px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: -0.08888888359069824px;
        color: #3D3D3D;
        cursor: pointer;
        padding: 5px 18px;
        position: relative;

        &:hover {
            color: #000;

            &::before {
                content: "";
                position: absolute;
                left: 0;
                height: 100%;
                width: 2px;
                top: 0;
                background-color: #0B96F5;
            }
        }
    }
}


.firmWrapper {
    display: flex;
    gap: 33px;
    align-items: flex-end;

    @media(max-width: 620px) {
        flex-wrap: wrap;
        flex-direction: column;
        align-items: flex-start;
    }
}

.firmSerachBox {
    flex: 2;
    position: relative;

    @media(max-width: 620px) {
        flex: 1;
        width: 100%;
    }
}