.runNamedInputWrapper {
  & h3{
    cursor: pointer;
    position: relative;
    font-size: 15px;
    font-weight: 600;

    &:hover {
      &::before {
        display: block;
      }
    }

    &::before {
      display: none;
      content: 'Think of a short name which helps you identify this run later. For example, Project Amber';
      width: 400px;
      position: absolute;
      top: -5.5em;
      left: 20em;
      background: white;
      padding: 10px 12px;
      border-radius: 5px;
      text-align: center;
      box-shadow: 0px 8px 16px rgb(0 0 0 / 30%);
      font-weight: 100;
      font-size: 14px;
    }
  }
}

.actionButtons {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  margin-top: 3em;

  @media(max-width: 460px) {
      flex-wrap: wrap;
      width: 100%;
  }

  & button {
      min-height: 45px;
      max-height: 45px;
      width: 165px;
      font-size: 14px;

      @media(max-width: 460px) {
          width: 100%;
      }
  }

  & .submitButton {
      &:disabled,
      & [disabled] {
          background-color: #eeeded;
          color: #666666;
      }
  }
}