@import '../../styles/propertySets.css';

.root {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
}

.imagesField {
  flex-shrink: 0;
  margin: 0;


  @media(max-width: 460px) {
    width: 100% !important;
  }
}
.imageCover{
  flex-shrink: 0;
  margin: 0;


  @media(max-width: 460px) {
    width: 100% !important;
  }
  & img{
    object-fit: cover;
  }
}
.thumbnail {
  margin: 24px 0 0 0;

  @media (--viewportLarge) {
    /* width: calc(50% - 12px); */
    margin: 0 24px 24px 0;

    &:nth-child(even) {
      margin: 0 0 24px 0;
    }
  }
}

.addImageWrapper {
  float: left;
  position: relative;
  width: 100%;
  margin: 24px 0 0 0;
  overflow: hidden;

  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  @media (--viewportLarge) {
    /* width: calc(50% - 12px); */
    margin: 0 0 24px 0;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectRatioWrapper {
  padding-bottom: calc(100% * (2 / 3));
}

.addImage {
  /* Layout */
  /* Maintain aspect ratio */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;

  /* Colors */
  background-color: var(--matterColorLight);

  @media (--viewportLarge) {
    background-color: var(--matterColorBright);
  }

  border-style: dashed;
  border-color: var(--matterColorNegative);
  border-width: 2px;
  border-radius: 2px;

  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.chooseImageText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chooseImage {
  @apply --marketplaceH4FontStyles;
  color: var(--matterColor);
  font-weight: var(--fontWeightRegular);
  font-size: 15px;
  line-height: 20px;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.imageTypes {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);

  font-size: 13px;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.addImageInput {
  display: none;
}

.imageRequiredWrapper {
  width: 100%;
  clear: both;
}

.tip {
  @apply --marketplaceH5FontStyles;
  flex-shrink: 0;
  line-height: 24px;
  font-weight: var(--fontWeightRegular);
  color: var(--matterColorAnti);
  margin-top: 24px;
  margin-bottom: 60px;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.error {
  color: var(--failColor);
}

.submitButton {
  margin: 20px 0 30px !important;
  flex-shrink: 0;
  display: inline-block;
  width: 241px !important;

  @media(max-width: 460px) {
    width: 100% !important;
  }
}



.contentWrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 300px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 0px;
}

@media (--viewportMedium) {
  .contentWrapper {
    padding-top: 0px;
    padding-bottom: 8px;
    margin-top: 0px;
  }
}

.label {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightSemiBold);
  padding: 11px 0 13px 0;
  margin: 0 18px 0 0;
}

@media (--viewportMedium) {
  .label {
    padding: 8px 0 16px 0;
  }
}

.inputsWrapper {
  display: flex;

  @media (--viewportMedium) {
    padding-bottom: 5px;
  }
}

.minPrice {
  @apply --marketplaceSearchFilterSublabelFontStyles;
  width: 48px;
  text-align: center;
  border-bottom-color: var(--attentionColor);
  border-bottom-width: 3px;

  -moz-appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media (--viewportMedium) {
    padding: 1px 0 4px 0;
  }
}

.maxPrice {
  @apply --marketplaceSearchFilterSublabelFontStyles;
  width: 56px;
  text-align: center;
  border-bottom-color: var(--attentionColor);
  border-bottom-width: 3px;

  -moz-appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media (--viewportMedium) {
    padding: 1px 0 4px 0;
  }
}

.priceSeparator {
  margin: 4px 8px 0 8px;
}

@media (--viewportMedium) {
  .priceSeparator {
    margin: 2px 8px 0 8px;
  }
}

.sliderWrapper {
  display: flex;
  padding: 17px 0 25px 0;
}

@media (--viewportMedium) {
  .sliderWrapper {
    padding: 16px 0 24px 0;
    margin-bottom: 24px;
  }
}

.projectTimelines {
  margin-bottom: 50px;
}

.countries {
  & legend {
    margin-bottom: 24px;
  }
}

.imageGenrateButton {
  min-height: 52px !important;
  width: 190px;
  height: 52px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.266667px;
  color: #0B96F5;
}

.redStyle {
  color: var(--bannedColorLight);
}

.button {
  width: 230px;
  border: 1px solid #0B96F5;
  border-radius: 4px;
  color: #0B96F5;
}

.disabled {
  width: 230px;
  height: 63px;
  ;
  border: 1px solid #E6E6E6;
  border-radius: 4px;
  color: #E6E6E6;
}

.primaryButton {
  width: 230px;
}

.inputBox {
  width: 64%;
}

.form {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
}

.imageContainer {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  flex-direction: column;
  margin-bottom: 50px;

  & span {
    position: absolute;
    top: 0;
    right: 0;
    width: 33px;
    height: 33px;
    padding: 0;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    border-radius: 0;
    border-bottom-left-radius: 2px;
    cursor: pointer;
    color: var(--matterColorAnti);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .uploadImageBox {
    width: 456px;
    height: 302px;
    position: relative;
    border: 1px solid #eee;
    border-radius: 5px;

    @media(max-width: 460px) {
      width: 100%;
    }

    & .closeButton {
      &:hover {
        & svg {
          fill: #fff;
          stroke: #fff;
        }
      }

      & svg {
        position: absolute;
        top: 12px;
        left: 12px;
        width: 10px;
        height: 10px;
        fill: var(--matterColorAnti);
        stroke: var(--matterColorAnti);
      }
    }

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px;
    }
  }
}

.inputWrapper {
  display: flex;
  margin: 20px 0 50px;
  gap: 20px;
}

.inputWrapperBox {
  display: flex;
  margin: 20px 0 50px;
  gap: 20px;

  @media(max-width: 460px) {
    flex-wrap: wrap;
  }

  &>div {
    @media(max-width: 460px) {
      width: 100%;
    }
  }

  & button {
    max-width: 200px;
  }
}

.openaiRadio {
  position: relative;

  & .newText {
    position: absolute;
    top: 5px;
    left: 18.8em;
    width: 68px;
    height: 26px;
    background: #fbe5ba;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #E49800;
    letter-spacing: 1px;
    font-weight: 400;
  }
}