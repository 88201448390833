.title, .contractForm h2{
    font-size: 30px;
    line-height: 40px;
    letter-spacing: -0.9px;
    margin-bottom: 30px;
}
.contractForm{
  & h4 {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 5px;
  }

  & p {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 10px;
  }
  & ul {
    list-style-type: disc;
    margin-left: 25px;

    & li {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #3D3D3D;
        margin-bottom: 3px;

        & span {
            margin: 0 3px;
        }
    }
  }
}
.dividerLine {
  background: #E6E6E6;
  height: 0.5px;
  width: 100%;
  margin: 16px 0;
}
.unSureHeading {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  & a {
      color: #0B96F5;
      margin-left: 3px;
  }
}
.object {
  position: absolute;
  height: 500px;
  width: 1000px;
  left: -10em;
  top: 4em;
  z-index: 100;
}
.inputSection{
  & input {
    display: none;
  }
}
.inputLabel {
  width: 100%;
  box-sizing: border-box;
  border: 2px dashed gainsboro;
  border-radius: 2px;
  padding: 25px 20px;
  display: flex;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
  margin-bottom: 12px;
  align-items: center;
  gap: 10px;

  & .dragDrop {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.177778px;
      color: #3D3D3D;
  }

  & .docType {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.177778px;
      color: #B2B2B2;
  }
}
.removeFile {
  cursor: pointer;
  color: red;
  font-weight: 400;
}
.buttonWrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-top: 5em;

  & .buttonCancel{
    font-size: 20px;
    font-weight: 400;
  }

  & .buttonSubmit{
    width: 228px;
  }
}
.clientSignCheck{
  & h4 {
    margin-top: 20px;
  }
}
.error{
  color: red;
}