.firmDashboardPanel {
    width: 100%;
}

.userContainer{
    background: #FFFFFF;
    border: 1px solid #E6E6E6;
    padding: 30px 35px;
    margin-bottom: 30px;

    & .headerSection{
        display: flex;
        justify-content: space-between;
        
        & h3{
            font-family: Poppins;
            font-size: 18px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: -0.17777778208255768px;
            text-align: left;
            margin: 0 0 25px;
        }
        & .icon{
            cursor: pointer;
            position: relative;
            top: -10px;
        }
    }

}

.cardWrapper {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(4, 1fr);
    /* padding: 0 24px; */

    @media(max-width:1360px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media(max-width:1150px) {
        grid-template-columns: repeat(2, 1fr);

    }

    @media(max-width:1024px) {
        grid-template-columns: repeat(3, 1fr);
        gap: 5px;
    }

    @media(max-width:768px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media(max-width:460px) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.cardBox {
    background: #FFFFFF;
    padding: 10px;
    text-align: center;
    /* height: 165px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    box-shadow: 0px 5px 8px 4px #0000000D;

    &:hover {
        background-color: #ecf7f7;

        & .openPopup {
            display: block;
        }
    }

    & .openPopup {
        display: none;
        position: absolute;
        top: 0px;
        right: 13px;
        color: #b2b2b2;
        cursor: pointer;
    }
}

.cardName {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.1125px;
    color: #B2B2B2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    @media(max-width: 768px) {
        font-size: 12px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.112px;
    }
}

.daysTime {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.0888889px;
    color: #B2B2B2;
}

.countNumber {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    line-height: 96px;
    letter-spacing: -0.0888889px;
    color: #3D3D3D;

    @media(max-width: 768px) {
        font-size: 48px;
        line-height: normal;
        letter-spacing: -0.089px;
    }
}

.quarterPercent {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.0888889px;
    color: #B2B2B2;
}

.greenColor {
    color: #2ECC71;
    margin-right: 5px;
}

.redColor {
    color: #FF0000;
    margin-right: 5px;
}

.greyColor{
    color: #b2b2b2;
}

.blackColor {
    color: #000;
    margin-right: 5px;
}

.userStatesTable {
    margin-top: 35px;
}

.projectBottomWrapper {
    /* padding: 0 24px; */
}

.userStatesTable table th {
    padding: 12px 5px;

    @media(max-width: 768px) {
        padding: 10px 5px;
    }
}

.firstBox {
    width: 230px;
    padding: 0 30px !important;
}

.secondBox {
    min-width: 120px;
}

.headingBox {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.nameHeading {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.08888888359069824px;
    /* text-align: left; */
}

.userStatesTable .panelProfile {
    display: flex;
    gap: 10px;
    align-items: center;
}

.tableHeading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.1125px;
    color: #B2B2B2;
    margin-bottom: 8px;
}

.userStatesTable tbody td {
    background-color: #fff;
    padding: 12px 20px;
}

.userStatesTable table tr th {
    background: #FBFBFB;
}

.userStatesTable table thead tr:nth-child(1) {
    border-bottom: 2px solid #d5d2d2;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 1px;
}

.firstBox .headingBox {
    align-items: flex-start;
}

.gigAppBox {
    padding: 12px 20px !important;

    & .headingBox {
        align-items: flex-start;
    }
}

tbody .count {
    /* text-align: center; */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.0888889px;
    color: #858585;
}

.projectHeading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.1125px;
    color: #B2B2B2;
    margin: 10px 0;
}

.profileImage {
    max-width: 38px;
    max-height: 38px;
    border-radius: 50%;
    overflow: hidden;
    min-width: 38px;
    border: 1px solid #d5d5d5;

    & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.noImageWithText {
    width: 38px;
    height: 38px;
    max-width: 38px;
    max-height: 38px;
    min-width: 38px;
    border-radius: 50%;
    font-family: 'poppins', Helvetica, Arial, sans-serif;
    display: flex;
    align-items: center;
    /* border: 3px solid transparent; */
    justify-content: center;
    background: linear-gradient(180deg, #7AC9FF 0%, #0B96F5 100%);
    color: var(--matterColorLight);
}

.userName {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.0888889px;
    color: #3D3D3D;
    /* display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden; */
}

.userNameHeading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.0888889px;
    color: #3D3D3D;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.adminName {
    background: #f7b50f;
    border-radius: 50px;
    padding: 0 10px;
    color: #fff;
    font-size: 12px;
}

.projectList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 28px;
    margin-top: 8px;
    margin-bottom: 50px;

    @media(max-width: 768px) {
        gap: 15px;
    }

    @media(max-width: 560px) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.listingCards {
    padding: 0 24px 96px 24px;

    @media (--viewportMedium) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    @media (--viewportLarge) {
        padding: 0 36px 36px 36px;
    }
}

.listingCard {
    margin-bottom: 24px;
    flex-basis: 100%;
    background: #fcfcfc;
    box-shadow: 0px 0px 9px -4px rgb(0 0 0 / 26%);
    padding: 20px;
    border-radius: 5px;
    border: 1px solid #43434324;

    @media(max-width: 768px) {
        margin: 0;
    }

    @media (--viewportMedium) {
        /**
       * resultPanel: 62.5vw from 1024px = 640px
       * panelPaddings: - 2*36px = 72px
       *
       * columnCount: 2
       * gutterWidthBetweenColumns: 24px
       *
       * (resultPanel - (panelPaddings + gutterWidthBetweenColumns)) / columnCount = listingCardWidth
       * ergo => listingCardWidth: 272px
       *
       * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))
       */
        flex-basis: calc(50% - 12px);
        /* margin-right: 24px; */
    }

    @media (--viewportXLarge) {
        /**
       * resultPanelWidthRatio = 0.625 aka 62.5%
       *
       * resultPanel: 62.5vw from 1920px = 1200px
       * panelPaddings: - 2*36px = 72px
       *
       * columnCount: 3
       * guttersBetweenColumns: 2*24px = 48px
       *
       * (resultPanel - (panelPaddings + guttersBetweenColumns)) / columnCount = listingCardWidth
       * ergo => listingCardWidth: 360px
       *
       * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))
       */
        flex-basis: calc(33.33% - 16px);
        /* margin-right: 24px; */
    }
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    margin: 2em 0 1em;

    & .modalTitle {
        font-size: 30px;
        font-weight: 600;
    }

    & .tabSection {
        & .tabs {
            border: 1px solid #E6E6E6;
            padding: 10px;
            font-size: 13px;
            font-weight: 400;
            cursor: pointer;
        }

        & .selected {
            background: rgb(11, 150, 245, 0.1);
        }
    }
}

.count {
    font-size: 24px;
    font-weight: 500;
}

.percentage {
    margin-bottom: 13px;
    font-size: 10px;
    font-weight: 400;

    & .positive {
        background: #d9f3e4;
        padding: 5px 10px;
        color: #1BA054;
        border-radius: 25px;
    }

    & .negative {
        background: #ffdfe0;
        padding: 5px 10px;
        color: #C0392B;
        border-radius: 25px;
    }

    & .neutral {
        background: rgba(246, 180, 48, 0.2);
        padding: 5px 10px;
        color: rgba(228, 152, 0, 1);
        border-radius: 25px;
    }

    & p {
        display: inline-block;
        color: #B2B2B2;
        font-size: 11px;
        margin-left: 4px;
    }
}

.projectChart {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-top: 3em;

    & .row {
        display: grid;
        align-items: center;
        grid-template-columns: 1.3fr 4fr 1fr;
        font-weight: 400;

        & label {
            font-size: 12px;
            font-weight: 400;
        }

        & div {
            background-color: #FDF0D6;
            padding-left: 10px;
            height: 30px;
            font-size: 11px;
        }

        & span {
            text-align: end;
            font-size: 10px;
        }
    }
}

.statsTable {
    overflow-x: auto;
    padding-bottom: 10px;
    & td, th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
      }
}

.normalUser {
    border: 4px solid transparent;
}

.csmProfileIcon {
    border-radius: 50%;
    border: 4px solid #FA0;
    position: relative;
    cursor: pointer;

    &:hover{
        &::before{
          visibility: visible;
        }
    }

    &::before{
        visibility: hidden;
        content: attr(data-tooltip);
        background-color: white;
        color: #000;
        border-radius: 6px;
        padding: 10px;
        position: absolute;
        z-index: 1;
        width: 400px;
        top: -10px;
        left: 4em;
        box-shadow: 0px 8px 16px rgb(0 0 0 / 30%);
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.25px;
    }

    &::after {
        content: "CSM";
        position: absolute;
        left: 50%;
        bottom: -4px;
        transform: translate(-50%, 0);
        background-color: #FDECCA;
        border-radius: 2px;
        width: 27px;
        height: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #E49800;
        text-align: center;
        font-size: 6px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.087px;
    }
}

.gigAppsFilterButton{
    & select{
        width: 350px;
        border: 2px solid #E6E6E6;
        border-radius: 4px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.2666666805744171px;
        text-align: left;
        padding: 5px 10px;
        margin-bottom: 25px;
        background-position: center right 14px;
    }
}