.tosterMessage {
    background-color: #e8f7ee;
    padding: 8px 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.0888889px;
    color: #3D3D3D;
    width: 50%;
    margin: 8px auto auto;
    position: relative;
}

.memberCard {
    background-color: #e8f7ee;
    width: 64%;
    padding: 8px 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.0888889px;
    color: #3D3D3D;
    margin-top: 7px;
    top: 4.5em;
    position: fixed;
    z-index: 10;

}
.closeToster {
    position: absolute;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 5px;
    font-weight: 700;
    font-size: 12px;
    line-height: 30px;
    letter-spacing: 1.5px;
    cursor: pointer;
    color: #797979;
}

.closeToster:hover svg path {
    fill: #3D3D3D;
}

.closeToster:hover {
    color: #3D3D3D;
}

.closeToster svg {
    margin-left: 10px;
}

.closeToster svg path {
    fill: #797979;
}