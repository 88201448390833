.submitButton {
  /* margin: 1rem; */
}

.packageBox {
  margin-bottom: 20px;
}

.title {
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.9px;
  margin-bottom: 30px;
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-top: 5em;

  & .buttonCancel {
    font-size: 20px;
    font-weight: 400;
  }

  & .buttonSubmit {
    width: 228px;
  }
}

.closeIcon {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & div {
    font-size: 12px;
    font-weight: 700;
    line-height: 30px;
    /* 250% */
    letter-spacing: 1.5px;
    cursor: pointer;
    margin-top: -1px;
  }

  & svg {
    width: 12px;
    height: 12px;
    margin-left: 8px;
  }
}