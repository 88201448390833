.analyzeDataForm {
  padding: 40px;
  /* min-height: 61vh; */

  @media(max-width: 768px) {
    padding: 24px 0;
  }
}

.container {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px !important;
  width: 100%;
  margin: 0px auto;
  padding: 40px 80px;
  border: 1px solid #c2c2c2;
  border-radius: 5px;

  @media(min-width: 1200px) {
    width: 800px;
  }

  @media(max-width: 768px) {
    padding: 20px;
  }
}

.briefGenerator {
  margin-bottom: 40px;

  & .generatorTitle {
    font-size: 36px;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -1.25px;
  }

  @media(max-width: 768px) {
    margin-bottom: 3em;

    & .generatorTitle {
      font-size: 24px;
      line-height: 28px;
    }
  }
}

.mappedQuestions{
  background-color: #fff;
  padding: 40px 100px;
}

.stepsLabel{
  margin-bottom: 2em;
  & span{
    font-size: 19px;
  }
  & h3{
    text-align: center;
    font-weight: 500;
  }
}

.questionsHeading{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;

  & h3{
    font-weight: 600;
  }
}

.questionsWrapper{
  & .row{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 15px;
    margin-bottom: 18px;
    position: relative;
    
    & .left{
      display: flex;
      column-gap: 10px;
      align-items: center;

      & .dragIconWrapper{
        position: absolute;
        display: flex;
        gap: 10px;
        align-items: baseline;
        left: -40px;
      }

      & .count{
        font-size: 12px;
        font-weight: 600;
      }

      & .dragDots{
        cursor: grab;
      }
    }

    @media(max-width: 768px){
      display: block;
    }
    
    & .question, textarea{
      border: 1px solid gainsboro;
      border-radius: 5px;
      padding: 5px 12px;
      
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
    }
    & .question{
      opacity: 0.7;
      /* cursor: move; */
      width: -webkit-fill-available;
      height: -webkit-fill-available;
    }
    & textarea{
      resize: none;
      min-width: 100%;
      box-sizing: border-box;
    }
  }
}

.attachment {
  & label {
    margin-bottom: 15px;
  }

  & .close {
    cursor: pointer;
  }

  & .addImageInput {
    display: none;
  }

  & p {
    font-size: 14px;
    font-weight: 100;
    margin: 20px 0;
    line-height: 19px;
  }

  & .attachmentLabel {
    width: 100%;
    box-sizing: border-box;
    border: 2px dashed gainsboro;
    border-radius: 2px;
    padding: 25px 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
    margin: 0px 0 1em;
    align-items: center;
    gap: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.177778px;

    @media(max-width: 768px) {
      padding: 5px 20px;
      font-size: 12px;
    }

    & .dragDrop {
      color: #3D3D3D;
    }

    & .docType {
      color: #B2B2B2;
    }
  }
}

.fileSizeError {
  color: var(--failColor);
  margin: 0;
  font-weight: 500!important;
  font-size: 16px!important;
}

.actionButtons {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin-top: 3em;

  @media(max-width: 460px) {
      flex-wrap: wrap;
      width: 100%;
  }

  & button {
      min-height: 45px;
      max-height: 45px;
      width: 165px;
      font-size: 14px;

      @media(max-width: 460px) {
          width: 100%;
      }
  }

  & .submitButton {
      &:disabled,
      & [disabled] {
          background-color: #eeeded;
          color: #666666;
      }
  }
  & .disabledSubmitButton{
    background-color: #eeeded;
    color: #666666;
  }
}

.chatloadingdiv{
  font-size: 14px;
  font-weight: 600;
  margin: 10px auto 30px;
  text-align: center;
}

.alertModal{
  & h2{
  text-align: center;
  font-size: 30px;
      line-height: 40px;
      letter-spacing: -0.9px;
}
  & p{
    /* text-align: center; */
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.9px;
  }
  .closeButton{
      margin-top: 3em;
  }
}

.inputContainer {
  background-color: #fff;
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px !important; */
  width: 100%;
  margin: 3em auto 0;
  /* padding: 40px; */
  /* border: 1px solid #c2c2c2;
  border-radius: 5px; */

  @media(max-width: 768px) {
    padding: 10px;
  }
}

.chatContainer {
  margin-bottom: 2em;
  width: 100%;
  border: 1px solid gainsboro;
  border-radius: 4px;
  padding: 10px 20px;
  max-height: 500px;
  overflow: auto;
}

.chatButton {
  margin-top: 1.5em;
  display: flex;
  justify-content: flex-end;
  gap: 15px;

  & button {
    min-height: 45px;
    max-height: 45px;
    width: max-content;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 28px;

    @media(max-width: 460px) {
      width: 100%;
      padding: 0;
    }
  }

  & .clearButton{
    color: #0B96F5;
    border: 2px solid #0B96F5;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;

    &:hover {
      transform: translateY(-2px);
      transition: .2s;
  
      &:after {
        margin-left: 5px;
        transform: translateX(4px);
        vertical-align: middle;
      }
    }
  }

  & .loadingDots {
    --_g: no-repeat radial-gradient(circle closest-side, white 90%, #0000);
  }
}

.disableInput {
  opacity: 0.5;
}

.exportButtons{
  display: flex;
  justify-content: flex-end;

  & button{
    border: 1px solid grey;
    height: 36px;
    padding: 0px 15px;
    border-radius: 3px;
    margin-left: 10px;
    transition: all .3s ease;

    &:hover{
      transform: scale(1.03);
      background-color: orange;
      border: none;
      color: white;
    }
  }
}

.fileView {
  margin-top: 10px;
  & a {
    pointer-events: none;
  }
}

.closeButtonContainer{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  margin-bottom: 32px;

  & span {
      font-weight: 700;
      font-size: 12px;
      line-height: 30px;
      letter-spacing: 1.5px;
  }

  & svg {
      fill: black;
      scale: 1.2;
      margin-left: 7px;
  }
} 
.toastuiContainer *{
  font-family: 'poppins' !important;
}

.queryContainer{
  padding: 20px 40px 20px;
  background-color: var(--matterColorLight);

  & .container {
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px !important;
    width: 100%;
    margin: 1.5em auto 0;
    padding: 20px;
    border: 1px solid #c2c2c2;
    border-radius: 5px;
    height: 60vh;
    overflow-y: auto;
    position: relative;
  
    @media(max-width: 768px) {
      padding: 20px;
    }
  }

  & .chat {
    margin-bottom: 2em;
    width: 100%;
    /* border: 1px solid gainsboro; */
    border-radius: 4px;
    padding: 10px 20px;
    /* max-height: 500px;
    overflow: auto; */
  }

  & .textarea{
    min-width: 90%;
    resize: none;
    height: 100px;
    min-height: 100px;
    max-height: 200px;
    /* overflow-y: scroll; */
    padding: inherit;
  }
}

.stopButton{
  display: flex;
  justify-content: flex-end;

  & div{
    width: 30px;
    height: 30px;
    border: 2px solid red;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .5s;
    margin-top: 10px;
    
    & span{
      display: inline-block;
      height: 15px;
      width: 15px;
      border-radius: 3px;
      background: red;
      transition: all .5s;
    }
  }
}

.questionInputWrapper{
  margin-top: 1em;

  & .questionBar{
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: flex-end;

    @media(max-width: 768px){
      flex-direction: column;
    }

    & button {
      min-height: 45px;
      max-height: 45px;
      width: max-content;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 28px;
  
      @media(max-width: 460px) {
        width: 100%;
        padding: 0;
      }
    }
  }
}

.error {
  text-align: end;
  color: red;
  font-weight: 100;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 100px;
  aspect-ratio: 1;
  display: grid;
  grid: 50%/50%;
  color: #0B96F5;
  --_g: no-repeat linear-gradient(currentColor 0 0);
  background: var(--_g),var(--_g),var(--_g);
  background-size: 50.1% 50.1%;
  margin: auto;
  transform: translate(-50%, -50%);
  animation: 
    l6-0   1.5s infinite steps(1) alternate,
    l6-0-0 3s   infinite steps(1);
}
.loader::before {
  content: "";
  background: currentColor;
  transform: perspective(150px) rotateY(0deg) rotateX(0deg);
  transform-origin: bottom right; 
  animation: l6-1 1.5s infinite linear alternate;
}
@keyframes l6-0 {
  0%  {background-position: 0    100%,100% 100%,100% 0}
  33% {background-position: 100% 100%,100% 100%,100% 0}
  66% {background-position: 100% 0   ,100% 0   ,100% 0}
}
@keyframes l6-0-0 {
  0%  {transform: scaleX(1)  rotate(0deg)}
  50% {transform: scaleX(-1) rotate(-90deg)}
}
@keyframes l6-1 {
  16.5%{transform:perspective(150px) rotateX(-90deg)  rotateY(0deg)    rotateX(0deg);filter:grayscale(0.6)}
  33%  {transform:perspective(150px) rotateX(-180deg) rotateY(0deg)    rotateX(0deg)}
  66%  {transform:perspective(150px) rotateX(-180deg) rotateY(-180deg) rotateX(0deg)}
  100% {transform:perspective(150px) rotateX(-180deg) rotateY(-180deg) rotateX(-180deg);filter:grayscale(0.6)}
}

.toastui-editor-contents p{
  font-family: poppins;
}

.fontStyling *{
  font-family: var(--font-family) !important;
}

.fontStyling .toastui-editor-contents {
  font-family: var(--font-family) !important;
}
.fontStyling .toastui-editor-contents p{
  font-family: var(--font-family) !important;
}
.fontStyling em{
  font-style: normal !important;
}

/* @media (min-width: 768px) {
  .fontStyling img {
    max-width: 600px !important;
  }
}

@media (max-width: 767px) {
  .fontStyling img {
    max-width: 100% !important;
  }
} */

.askQuestionButton{
  &:disabled{
    background-color: #0B96F5;
  }
}

.contentLoader{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.buttonWrapper{
  display: flex;
  gap: 10px;
  justify-content: space-evenly;
  margin-top: 3em;

  @media(max-width: 460px) {
    flex-wrap: wrap;
    width: 100%;
  }
  & .cancelButton{
    padding: 16px 0;
    font-size: 19px;
    cursor: pointer;
    font-weight: 400;
    border-radius:5px;
    background-color:lightgray;
    width: 185px;
    text-align: center;
    color: white;
  }
  & .submitButton{
    width: 185px;
    font-size: 19px;
  }
}

.browserBack{
  cursor: pointer;
  &::after{
    content: '';
    display: inline-block;
    width: 15px;
    height: 2px;
    background: #3D3D3D;
    position: relative;
    top: -3px;
    left: -11px;
  }

  & svg{
    & path{
      fill: #3D3D3D;
    }
  }
}

.sessionExpiryText {
  margin-top: 2em;
  font-family: var(--font-familyLite);
  font-weight: var(--fontWeightSemiBold);
  font-size: 14px;

  & button {
    color: #0b96f5;
    transform: translateX(5px);
    display: inline-block;
    font-family: var(--font-familyLite);
    font-weight: var(--fontWeightSemiBold);
    font-size: 14px;
  }

  & .tooltip {
    background: #223133;
    border-radius: 50%;
    width: 18px;
    display: inline-block;
    height: 18px;
    color: white;
    padding-left: 5px;
    line-height: 20px;
    margin-left: 4px;
    font-size: 14px;
    cursor: help;
    position: relative;
    top: -1px;

    &:hover {
      &::before {
        display: block;
      }
    }

    &::before {
      display: none;
      content: attr(data-tooltip);
      width: 335px;
      position: absolute;
      top: -4em;
      left: -12em;
      background: white;
      padding: 10px 12px;
      border-radius: 6px;
      color: #3D3D3D;
      box-shadow: 0px 8px 16px rgb(0 0 0 / 30%);
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.25px;
    }
  }
}