@import '../../styles/propertySets.css';

.progressBoxWrapper {
    display: flex;
    gap: 20px;
    align-items: center;
}

progress {
    width: 100%;
    /* background: #FFFFFF; */
    accent-color: #FFB169;
}

progress {
    width: 100%;
    background-image: linear-gradient(rgba(255, 0, 0, 0.1), rgba(255, 0, 0, 0.1) 30%, rgba(0, 255, 0, 0.1) 30%, rgba(0, 255, 0, 0.1) 70%, rgba(255, 0, 0, 0.1) 70%);
    height: 21px;
    border: 2px solid #dddddd;
    border-radius: 0 !important;
}

progress[value]::-webkit-progress-bar {
    width: 100%;
    background: transparent;
    background-image:
        -webkit-linear-gradient(#fff,
            #fff);
    border: 0;
    height: 17px;
    border-radius: 0 !important;
}

progress[value] {
    background-color: transparent;
    border-radius: 5px;
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0 !important;
}

progress[value]::-moz-progress-bar {
    /* Firefox */
    border-radius: 0 !important;
    background-size: 65px, 100%, 100%;
    background-color: transparent;
    background-image:
        -webkit-linear-gradient(var(--marketplaceColor),
            var(--marketplaceColor));
}

progress[value]::-webkit-progress-value {
    /* Chrome */
    border-radius: 0 !important;
    background-size: 65px, 100%, 100%;
    background-color: transparent;
    background-image:
        -webkit-linear-gradient(var(--marketplaceColor),
            var(--marketplaceColor));
}

.progressLinks {
    & :global(.NamedLink_active) {
        color: #212121;
    }
}

.progressBox {
    display: flex;
    gap: 6px;
    align-items: center;

    & .unFill {
        height: 10px;
        background-color: #F5F4F4;
        flex: 1;
    }

    & .fill {
        height: 10px;
        background-color: #F6B430;
        flex: 1;
    }
}