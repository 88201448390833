@import '../../styles/propertySets.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  /* Text positioning is done with paddings */
}

.heroContent {
  margin: 0 24px 0 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media (--viewportMedium) {
    margin: 0 7.5vw 0 7.5vw;
  }

  /* Special viewport for adjusting the heroContent's placement */

  @media only screen and (min-width: 1025px) and (max-width: 1366px) {
    padding: 0 36px 0 36px;
  }

  @media only screen and (min-width: 1367px) {
    flex-direction: row;
    margin: 0 auto;
    max-width: calc(1300px - 36px - 36px);
    width: 100%;
    padding: 0 36px 0 36px;
  }
}

.heroContentText {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 32px;

  @media only screen and (min-width: 1367px) {
    display: block;
    margin-bottom: 0;
  }
 & .heroMainTitle {
  font-size: 40px;
  font-weight: 600;
  line-height: 56px;
  letter-spacing: -1.25px;
  text-align: center;

}
  & .heroSubTitle {
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.08888888359069824px;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 20px;
  }
}



.heroText {
  line-height: 36px;
  font-size: 24px;
  font-weight: var(--fontWeightRegular);
  margin-bottom: 40px;
  max-width: 532px;
  @media(max-width: 768px){
    font-size: 18px;
    line-height: 28px;
  }
  @media(max-width: 460px){
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
}

.heroButton {
  @apply --marketplaceButtonStyles;
  font-weight: var(--fontWeightBold);
  composes: animation;
  border-radius: 50px;
  animation-delay: 0.8s;
  padding: 18px 0;
  line-height: 24px;
  min-height: auto;
  font-size: 18px;
  color: var(--matterColorDark);
  background-color: var(--marketplaceColor);
  &:hover,
  &:focus {
   background-color: var(--successColorDark);
   color: var(--matterColorLight);

 }

  @media (--viewportMedium) {
    display: block;
    width: 220px;
  }
}

.heroContentImage {
  position: relative;
  & img{
    @media(max-width: 767px){
      margin: auto;
      width: 75%;
      display: flex;
    }
  }

  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    left: -94px;
    top: -15px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 265px 480px 240px 0;
    border-color: transparent var(--marketplaceColor) transparent transparent;
    @media(max-width: 460px){
      border-width: 265px 440px 240px 0;
    }
  }
}
