@import '../../styles/propertySets.css';

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 30px 24px 0 24px;
}

.form {
  flex-grow: 1;
}

/* ================ Modal ================ */

.modalHeaderWrapper {
  margin-top: 58px;
  margin-bottom: 36px;
  padding: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 34px;
    padding: 0;
  }
}

/* ================ Typography ================ */

/* Title of the modal */
.modalTitle {
  @apply --marketplaceModalTitleStyles;
}

/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;
}

/* This is the title for the Edit Photos component */
.title {
  /* width: 109%; */

  @media(max-width: 768px) {
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.4px;
  }

  @media (--viewportLarge) {
    margin-bottom: 25px;
    padding: 1px 0 7px 0;
  }

  & .headingTooltip {
    position: relative;
    display: inline-block;
    transition: all 0.15s ease;
    cursor: pointer;

    & .photoTitle {
      &:hover {
        /* text-decoration: underline; */
      }
    }

    & .titleTooltip {
      visibility: hidden;
      width: 415px;
      background-color: #0b96f5;
      color: #ffffff;
      text-align: center;
      border-radius: 6px;
      padding: 5px;
      position: absolute;
      z-index: 1;
      top: 64px;
      right: -180px;
      margin-left: -60px;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: normal;
      font-weight: normal;
    }

    &:hover {
      & .titleTooltip {
        visibility: visible;
      }
    }
  }
}

.description {
  margin: 5px 0 20px;
}

.toggle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.switchSelector {
  display: grid;
  margin-bottom: 80px;
  grid-template-columns: repeat(2, auto);
  justify-content: flex-start;
}

.option {
  font-size: 18px;
  font-weight: 700;
  padding: 16px 25px;
  text-align: center;
  border: 1px solid #E3E3E3;
  background-color: var(--matterColorLight);
  border-radius: 50px;
  cursor: pointer;
  width: 290px;
  margin-right: -55px;
  transition-duration: 0.2s;
}

.optionActive {
  background-color: blue;
  color: white;
  font-size: 18px;
  font-weight: 700;
  width: 290px;
  border-radius: 2rem;
  padding: 1rem;
  text-align: center;
  cursor: pointer;
  margin-right: -55px;
  transition-duration: 0.2s;
}