.tipsHeading {
    background: rgba(11, 150, 245, 0.05);
    border-radius: 3px;
    padding: 4px 12px;
    width: fit-content;
}

.tipsBox {
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin: 20px 0;

    &:hover {
        & .tooltipShown {
            visibility: visible;
        }
    }
}

.tooltipShown {
    width: fit-content;
    padding: 18px 22px;
    position: absolute;
    z-index: 1;
    left: 0;
    background: #FFFFFF;
    box-shadow: 0px 8px 16px rgb(0 0 0 / 30%);
    border-radius: 4px;
    display: flex;
    gap: 15px;
    visibility: hidden;
    margin-top: 8px;

    & .iconClose {

        & svg {
            cursor: pointer;
            width: 16px;
            height: 16px;

            & path {
                stroke: #3D3D3D;
            }
        }
    }

    & .tooltipText {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.25px;
        color: #3D3D3D;
    }
}