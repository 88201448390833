.applicationModal{
    & h2{
      font-size: 30px;
      line-height: 40px;
      letter-spacing: -0.9px;
      margin-bottom: 30px;
    }

    & .row{
      margin-bottom: 15px;
  
      & label{
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        display: inline;
        margin-right: 15px;
      }
      & p{
        margin: 0;
        font-size: 16px;
        font-weight: 400;
        display: inline-block;
      }
      & .reason{
        font-size: 16px;
        font-weight: 400;
      }
    }
    & .buttonWrapper{
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      margin-top: 4em;
  
      & .buttonCancel{
        font-size: 20px;
        font-weight: 400;
      }
  
      & .buttonSubmit{
        width: 228px;
      }
    }
}