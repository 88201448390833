.firmPanel {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  @media(max-width: 1400px) {
    width: 100%;
  }

  @media(max-width: 1024px) {
    /* width: 90%; */
  }
}

.organizationContainer,
.invitationCardContainer {
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.25);
  /* box-shadow: 0 4px 16px 0 rgba( 31, 38, 135, 0.37 ); */
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  width: 100%;
}

.headerSection {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 8px;
  /* margin-top: 30px; */

  @media(max-width:768px) {
    height: unset;
    flex-wrap: wrap;
    gap: 20px;
  }

  @media(max-width: 460px) {
    flex-wrap: wrap;
    gap: 10px;
  }

  & span {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.0888889px;
    color: #858585;
    font-weight: 400;
  }

  & .activeView {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.0888889px;
    color: #3D3D3D;
  }
}

.organizationMemberCardContent {
  display: flex;
  justify-content: space-between;
  max-height: 50vh;
  overflow: scroll;
  position: static;

}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}


tr:nth-child(even) {
  background-color: #dddddd;
}

.inviteSection {
  cursor: pointer;
  background: #0B96F5;
  border-radius: 5px;
  padding: 10px;
  color: #fff;
  display: flex;
  align-items: center;
  height: 41px;
  border: 1px solid #0b96f5;
  font-size: 14px;

  &:hover {
    outline: none;
    color: var(--matterColorDark);
    background-color: var(--marketplaceColor);
    text-decoration: none;
    border: 1px solid var(--marketplaceColor);
  }
}

.successMessage {
  margin-bottom: 30px;
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  position: fixed;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  top: 73px;
  z-index: 10;
}

.resendEmailButton {
  padding: 0;
  background: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: -0.0888889px;
  text-transform: uppercase;
  color: #3D3D3D;
  height: unset;
  min-height: unset;
  justify-content: center;

  &:hover {
    background-color: #f6b43000;
    box-shadow: none;
  }
}

.removeButton {
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.0888889px;
  text-transform: uppercase;
  color: #FF0000;
  cursor: pointer;
  font-family: 'Poppins';

  & svg {
    cursor: pointer;
    font-weight: 800;
    width: 50px;
    height: 30px;
  }
}

.firmTableBox {
  overflow-x: auto;
  padding-bottom: 30px;
}

.tableHeading {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1;
  border: 1px solid #E6E6E6;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 1px;

  & th {
    border: none;
    padding: 20px 30px;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: -0.0888889px;
    color: #3D3D3D;
    text-transform: capitalize;
    font-family: 'Poppins';
  }
}

.tableBodyBox {
  border: 1px solid #E6E6E6;

  & tr {
    border: 1px solid #E6E6E6;
  }

  & td {
    background-color: #fff;
    border: none;
    padding: 20px 30px;

    &:nth-child(1) {
      min-width: 245px;
    }

    &:nth-child(3) {
      min-width: 225px;
    }

    & img {
      /* margin: 0 10px 0 0; */
      width: 38px;
      height: 38px;
    }
  }

  & .authorName {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.0888889px;
    color: #3D3D3D;
    margin-left: 5px;
    font-family: 'Poppins';
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  & .authorEmail {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.0888889px;
    color: #858585;
    font-family: 'Poppins';
    text-align: center;
  }

  & .userRoleAdmin {
    text-align: center;

    & span {
      background: #f6b43040;
      border-radius: 12px;
      padding: 4px 30px;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.1px;
      color: #E49800;
      font-family: 'Poppins';
    }
  }

  & .userRoleTeam {
    text-align: center;

    & span {
      /* background: #b2b2b257;
      border-radius: 12px;
      padding: 4px 30px;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.1px;
      color: #3D3D3D;
      font-family: 'Poppins'; */
    }

    & .roleBox {
      border-radius: 12px;
      border: none;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.1px;
      color: #3D3D3D;
      text-align: center;
      width: -moz-fit-content;
      width: fit-content;
      margin: auto;
      padding: 5px 20px;
      display: flex;
      align-items: center;
      grid-gap: 9px;
      gap: 9px;
      -webkit-appearance: none;
      cursor: pointer;
      background-color: #efefef;
      position: relative;

      @media(max-width: 1500px) {
        padding: 5px 10px;
      }

      & img {
        width: 12px;
        height: 12px;
      }

      & ul {
        position: absolute;
        margin-top: 3px;
        width: 220px;
        top: 30px;
        left: -40px;
  
        & li {
          background: #FFFFFF;
          box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
          border-radius: 4px;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          letter-spacing: -0.177778px;
          color: #3D3D3D;
          padding: 10px;
        }
      }
    }

  }

  & .userStatus {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.0888889px;
    color: #858585;
    text-align: center;
    font-family: 'Poppins';
  }
}

.userNameSection {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 10px 0 0;
}

.authorNameSection {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media(max-width:768px) {
    width: 200px;
  }
}

.profileImage {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-color: #42affa;
  color: white;
  text-align: center;
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins';
  min-width: 38px;
}

.userRoleEditor {
  background: #efefef;
  border-radius: 12px;
  padding: 4px 30px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.1px;
  color: #3D3D3D;
}

.organizationListingButton {
  display: flex;
  gap: 5px;
  /* justify-content: right; */

  & button {
    width: 12em;
    /* background: transparent; */
    box-shadow: none;
    min-height: 41px;
    /* padding: 10px; */
    font-size: 14px;

    &:hover {
      /* background: transparent; */
      box-shadow: none;
    }

    &:focus {
      /* background: transparent; */
      box-shadow: none;
    }
  }
}

.switchHolder {
  position: relative;
  /* padding: 15px; */
  border-radius: 10px;
  width: 300px;
  /* box-shadow: -8px -8px 15px rgba(255, 255, 255, .7),
    10px 10px 10px rgba(0, 0, 0, .3),
    inset 8px 8px 15px rgba(255, 255, 255, .7),
    inset 10px 10px 10px rgba(0, 0, 0, .3); */

}

.switchLabel {
  /* width: 150px; */
}

.switchLabel i {
  margin-right: 5px;
}

.switchToggle {
  /* height: 40px; */
}

.switchToggle input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  z-index: -2;
}

.switchToggle input[type="checkbox"]+label {
  position: relative;
  display: inline-block;
  width: 325px;
  height: 50px;
  border-radius: 50px;
  margin: 0;
  cursor: pointer;
  box-shadow: 0px 0px 10px 0px rgb(144 143 143 / 40%);
  border: 1px solid #78787857;
}

.switchToggle input[type="checkbox"]+label::before {
  position: absolute;
  content: 'Open Firm';
  font-size: 13px;
  text-align: center;
  line-height: 25px;
  top: 4px;
  left: 8px;
  width: 152px;
  height: 40px;
  color: #fff;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0B96F5;
  box-shadow: -3px -3px 5px rgba(255, 255, 255, .5),
    3px 3px 5px rgba(0, 0, 0, .25);
  transition: .3s ease-in-out;
}

.switchToggle input[type="checkbox"]:checked+label::before {
  left: 50%;
  content: 'Close Firm';
  color: #fff;
  background-color: #f6b430;
  box-shadow: 0 1px 2px rgb(255 255 255 / 50%), 0 0px 5px #f6b430;
}

.firmButtons {
  display: flex;
  align-items: center;
  column-gap: 5px;

  @media(max-width: 460px) {
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
  }

  &>div {
    @media(max-width: 460px) {
      width: 100%;
    }
  }

  & button {
    @media(max-width: 460px) {
      width: 100%;
      justify-content: center;
    }
  }
}

.quitForm {
  cursor: pointer;
  background: #0B96F5;
  border-radius: 5px;
  padding: 5px 10px;
  color: #fff;
  height: 41px;
  display: flex;
  align-items: center;
  margin-left: 10px;
  border: 1px solid #0b96f5;
  font-size: 14px;
}

.firmDataBox {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
}

.selectedFirmUser {
  background: #eee;
  border-radius: 5px;
  border: 1px solid #c4c4c4;
  cursor: pointer;
}

.setStripeSubscriptionRedirectModalHeading {
  font-size: x-large;
  font-weight: 500;
}

.setStripeSubscriptionRedirectModalButton {}

.ball {
  position: absolute;
  border-radius: 50px;
  width: 12px;
  height: 12px;
  top: -1px;
  left: 0px;
  transition: transform 0.3s ease;
  background-color: #0B96F5;

  @media(max-width: 768px) {
    /* width: 90px;
    height: 45px;
    top: -3px;
    left: -1px; */
  }

  &.toggled {
    transform: translateX(75%);
  }
}

.titleBox {
  text-align: center;
}

.checkboxWrapper {
  cursor: pointer;
  position: relative;
  border-radius: 50px;
  font-family: var(--font-familyLite);
  width: 23px;
  height: 12px;
  border: 1px solid #e6e6e6;
  background: #E6E6E6;
  margin: 0 6px;
}

.borderLine {
  margin-top: 24px;
}

.closeFirmButton {
  margin-bottom: 80px;
  display: flex;
  column-gap: 15px;

  & button {
    background: #FFFFFF;
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.266667px;
    text-align: center;
    width: 134px;
    min-height: 35px;


    &:hover {
      background: #fff;
    }
  }
}

.inviteButton {
  color: #0B96F5;
  border: 2px solid #0B96F5;
}

.closeListing {
  color: #FF0000;
  border: 2px solid #FF0000;
}

.confirmModalBox {
  padding: 0 22px;

  & h2 {
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    text-align: center;
    letter-spacing: -0.9px;
    color: #3D3D3D;
  }

  & .bottomButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 60px;

    & .cancelButton {
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
      letter-spacing: -0.111111px;
      color: #3D3D3D;
      background: #fff;
      cursor: pointer;
      border: none;
    }

    & .confirmButton {
      background: #0B96F5;
      border-radius: 4px;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      text-align: center;
      letter-spacing: -0.3px;
      color: #FFFFFF;
      padding: 19px;
      width: 228px;
      border: 1px solid #0B96F5;
      cursor: pointer;
    }
  }
}

.cardViewBox {
  display: grid;
  gap: 25px;
  grid-template-columns: repeat(3, 1fr);

  @media(max-width: 1260px) {
    gap: 15px;
  }

  @media(max-width: 1150px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media(max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media(max-width: 920px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media(max-width: 520px) {
    grid-template-columns: repeat(1, 1fr);
  }

  & .inviteFirmCard {
    background: rgba(11, 150, 245, 0.05);
    border: 1px solid #E6E6E6;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 348px;
  }

  & .cardTop {
    padding: 30px 40px 20px 40px;
    @media(max-width: 768px) {
      padding: 20px;
    }
  }

  & .firmCard {
    background: #FFFFFF;
    border: 1px solid #E6E6E6;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* height: 336px; */

    & .cardImage {
      text-align: center;
      width: 67px;
      height: 67px;
      border-radius: 50%;
    }

    & .authorName {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.0888889px;
      color: #3D3D3D;
      margin-top: 8px;
    }

    & .projectTitle {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: -0.0875px;
      color: #B2B2B2;
      margin-top: 8px;
      border-bottom: 1px solid #E6E6E6;
      padding-bottom: 20px;
    }

    & .authorEmail {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: -0.0888889px;
      color: #858585;
      width: 240px;
      word-wrap: break-word;
      margin: 21px auto 0;
    }

    & .userRoleBox {
      margin-top: 10px;

      & span {
        border-radius: 12px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.1px;
        color: #3D3D3D;
        /* width: 100%;
        display: block; */
      }

      & .userRoleSelect {
        /* background: #b2b2b257; */
        border-radius: 12px;
        border: none;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.1px;
        color: #3D3D3D;
        text-align: center;
        width: fit-content;
        margin: auto;
        padding: 5px 30px;
        display: flex;
        align-items: center;
        gap: 9px;
        /* background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #efefef; */
        -webkit-appearance: none;
        /* background-position: 93%;
        background-size: 10px; */
        cursor: pointer;
        background-color: #efefef;
        position: relative;

        /* & .arrowIcon {
          position: absolute;
          right: 20px;
          top: 3px;
          width: unset;
        } */

        & option {
          background: red;
        }
      }
    }

    & .userRoleMember {
      margin-top: 12px;

      & span {
        border-radius: 12px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.1px;
        color: #3D3D3D;
        /* width: 100%;
        display: block; */
      }

      & .userRoleSelect {
        /* background: #b2b2b257; */
        border-radius: 12px;
        border: none;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.1px;
        color: #3D3D3D;
        text-align: center;
        width: fit-content;
        margin: auto;
        padding: 5px 30px;
        display: flex;
        align-items: center;
        gap: 9px;
        /* background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #efefef; */
        -webkit-appearance: none;
        /* background-position: 93%;
        background-size: 10px; */
        cursor: pointer;
        background-color: #efefef;
        position: relative;

        /* & .arrowIcon {
          position: absolute;
          right: 20px;
          top: 3px;
          width: unset;
        } */

        & option {
          background: red;
        }
      }
    }

    & .boxSelect {
      position: relative;

      & ul {
        position: absolute;
        margin-top: 3px;
        width: 220px;

        & li {
          background: #FFFFFF;
          box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
          border-radius: 4px;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          letter-spacing: -0.177778px;
          color: #3D3D3D;
          padding: 10px;
        }
      }
    }

    & .userRole {
      margin-top: 10px;

      & span {
        background: #f6b43040;
        border-radius: 12px;
        padding: 4px 30px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.1px;
        color: #E49800;
      }
    }

    & .userStatus {
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
      margin-top: 10px;
      letter-spacing: -0.0888889px;
      color: #858585;
    }

    & .userCardBoxBottom {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #E6E6E6;
      border-radius: 1px;
      padding: 12px 20px;
      height: 46px;

      & .resentInvite {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        cursor: pointer;
        letter-spacing: -0.0888889px;
        text-transform: uppercase;
        color: #3D3D3D;
      }

      & .removeCard {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: -0.0888889px;
        text-transform: uppercase;
        color: #FF0000;
        cursor: pointer;
      }
    }
  }
}

.userNameSectionBox {
  display: flex;
  align-items: center;
  flex-direction: column;

  & .profileImage {
    text-align: center;
    width: 67px;
    height: 67px;
    border-radius: 50%;
    margin: 0;
    object-fit: cover;
  }
}

.heaingHelpKit {
  background: #FFFFFF;
  border: 1px solid #E6E6E6;
  padding: 20px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
}

.helpHeadingText {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.0888889px;
  color: #3D3D3D;
}

.subscriptionLink {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.1125px;
  color: #0B96F5;
}

.bestTeamText {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.1125px;
  color: #3D3D3D;
}

.helpHeading {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  justify-content: space-between;
}

.helpListBox {
  margin-top: 26px;

  & .planDetail {
    margin-top: 0;
    margin-bottom: 3px;
  }

  & ul {
    list-style-type: none;
    margin: 0;

    & li {
      display: flex;
      align-items: center;
      margin-bottom: 3px;

      & .listBold {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.1125px;
        color: #858585;
        width: 28%;
      }

      & .listNormal {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.1125px;
        color: #3D3D3D;
      }
    }
  }
}

.readMore {
  display: block;
  text-align: end;
}

.csm {
  background: #f6b43040;
  border-radius: 12px;
  padding: 4px 30px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.1px;
  color: #E49800;
}

.rowBox {
  display: flex;
  align-items: center;


  /* &::after {
    content: "CSM";
    position: absolute;
    left: 50%;
    bottom: -3px;
    transform: translate(-50%, 0);
    background-color: #FDECCA;
    border-radius: 2px;
    width: 54px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #E49800;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.087px;
  } */
}


.csmImageBoxCard {
  display: flex;
  align-items: center;
  /* position: relative; */
  cursor: pointer;

  &:hover{
    &::before{
      visibility: visible;
    }
  }

  &::before{
    visibility: hidden;
    content: attr(data-tooltip);
    background-color: white;
    color: #000;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    width: 400px;
    left: 7em;
    box-shadow: 0px 8px 16px rgb(0 0 0 / 30%);
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.25px;
  }
  & .userNameSection {
    position: relative;

    & img {
      border: 3px solid #FA0;
      border-radius: 50%;
    }

    & .profileImage {
      border: 3px solid #FA0;
      border-radius: 50%;
      font-size: 12px;
    }

    &::after {
      content: "CSM";
      position: absolute;
      left: 50%;
      bottom: -3px;
      transform: translate(-50%, 0);
      background-color: #FDECCA;
      border-radius: 2px;
      width: 27px;
      height: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #E49800;
      text-align: center;
      font-size: 6px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.087px;
    }
  }
}

.csmImageBox {
  position: relative;
  margin: auto !important;
  cursor: pointer;

  &:hover{
    &::before{
      visibility: visible;
    }
  }

  &::before{
    visibility: hidden;
    content: attr(data-tooltip);
    background-color: white;
    color: #000;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    width: 400px;
    top: -6em;
    left: -12em;
    box-shadow: 0px 8px 16px rgb(0 0 0 / 30%);
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.25px;
  }


  & img {
    border: 3px solid #FA0;
    border-radius: 50%;
  }

  &::after {
    content: "CSM";
    position: absolute;
    left: 50%;
    bottom: -4px;
    transform: translate(-50%, 0);
    background-color: #FDECCA;
    border-radius: 2px;
    width: 54px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #E49800;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.087px;
  }
}

.alertModal {
	@media(max-width: 420px) {
		padding-top: 5em;
	}

	& h2 {
		font-size: 30px;
		font-weight: 600;
		line-height: 40px;
		letter-spacing: -0.9px;
		text-align: center;
	}

	& p {
		font-size: 20px;
		font-weight: 400;
		line-height: normal;
		letter-spacing: -0.9px;
		text-align: center;
	}

  & .buttonWrapper {
    margin-top: 4em;
  
    & button {
      font-size: 19px;
    }
  }
}