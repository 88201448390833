.title{
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -0.9px;
  margin-bottom: 30px;
}
.attachmentLabel {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin: 30px 0 10px;
}
.inputSection{
  & input {
    display: none;
  }
} 
.inputLabel {
  width: 100%;
  box-sizing: border-box;
  border: 2px dashed gainsboro;
  border-radius: 2px;
  padding: 25px 20px;
  display: flex;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
  margin-bottom: 12px;
  align-items: center;
  gap: 10px;

  & .dragDrop {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.177778px;
      color: #3D3D3D;
  }

  & .docType {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.177778px;
      color: #B2B2B2;
  }
}
.removeFile {
  cursor: pointer;
  color: red;
  font-weight: 400;
}
.dateField{
  margin-top: 25px;

  & input{
    width: auto;
  }
}
.buttonWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-top: 5em;

    & .buttonCancel{
      font-size: 20px;
      font-weight: 400;
    }

    & .buttonSubmit{
      width: 228px;
    }
  }

.errorMessage{
  color: red;
  font-weight: 100;
  font-size: 14px;
  text-align: center;
}
.deadlineInputBox{
  display: flex;
  align-items: flex-end;
  gap: 20px;
}
.deadlineInputBox tr{
  background-color: #FFF!important;
}
.okButton{
  background-color: #0B96F5;
  color: #FFFFFF;
  border-radius: 3px;
  padding: 0px 10px;
  font-size: 13px;
  position: relative;
  top: -6px;
  left: 27%;
  
  &:hover{
    cursor: pointer;
    background-color: orange;
  }
}
.calenderButtonDiv{
  display: flex;
  justify-content: space-between;
  padding: 20px 10px 10px;
}
.calenderCancelBtn{
  color: #0A96F5;
}
  
.calenderOkBtn{
  color: #FFF;
  border-radius: 5px;
  background-color:#0A96F5!important;
  width: 35%;
}

.deadlineInputBox tr{
  background-color: #FFF!important;
}

.disabledButton{
  background-color: #A4D6FB!important;
  cursor: not-allowed!important;
  color: #FFF;
}

.modalTitle{
  font-size: 30px!important;
  font-weight: 600!important;
}