.title{
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -0.9px;
  margin-bottom: 30px;
}
.row{
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 14px;
    display: grid;
    grid-template-columns: 1fr 1.5fr;

    & a{
        font-weight: 600;
    }
}
.attachmentLabel {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin: 30px 0 10px;
  border-top: 1px solid #e6e6e6;
  padding-top: 30px;
}
.inputSection{
    & input {
      display: none;
    }
}
.inputLabel {
    width: 100%;
    box-sizing: border-box;
    border: 2px dashed gainsboro;
    border-radius: 2px;
    padding: 25px 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
    margin-bottom: 12px;
    align-items: center;
    gap: 10px;
  
    & .dragDrop {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.177778px;
        color: #3D3D3D;
    }
  
    & .docType {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.177778px;
        color: #B2B2B2;
    }
}
.removeFile {
  cursor: pointer;
  color: red;
  font-weight: 400;
}
.buttonWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-top: 5em;

    & .buttonCancel{
      font-size: 20px;
      font-weight: 400;
    }

    & .buttonSubmit{
      width: 228px;
    }
  }
  .errorMessage{
    color: red;
    font-weight: 100;
    font-size: 14px;
    text-align: center;
  }