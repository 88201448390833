.jobDescriptionForm{
    padding: 40px;

    @media(max-width: 768px) {
        padding: 24px;
    }
}
.container{
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px !important;
    width: 100%;
    margin: 0 auto;
    padding: 40px 100px;
    border: 1px solid #c2c2c2;
    border-radius: 5px;

    @media(min-width: 1200px) {
        width: 800px;
    }

    @media(max-width: 768px) {
        padding: 20px;
    }
}
.briefGenerator{
    margin-bottom: 40px;

    & .generatorTitle {
        font-size: 36px;
        font-weight: 600;
        line-height: 56px;
        letter-spacing: -1.25px;
    }

    @media(max-width: 768px){
        margin-bottom: 3em;
        
        & .generatorTitle{
            font-size: 24px;
            line-height: 28px;
        }
    }
}
.disabled{
    opacity: 0.4;
}
.row{
    margin-bottom: 30px;

    & .inputBox{
        & input, textarea{
            min-width: 100%;
            
            &::placeholder{
                font-size: 14px;
                
                @media(max-width: 768px){
                    font-size: 13px;
                }
            }
        }
    }
}
.checkbox{
    margin-top: 10px;
    display: block;

    & label{
        width: unset;
    }
    & label span{
        font-size: 13px;
        font-weight: 400;
    }
}
.tooltip {
    background: #223133;
    border-radius: 50%;
    width: 16px;
    display: inline-block;
    height: 18px;
    color: white;
    padding-left: 4px;
    margin-left: 4px;
    font-size: 13px;
    cursor: help;
    position: relative;

    &:hover {
        &::before {
            display: block;
        }
    }

    &::before {
        display: none;
        content: 'Provide the length of employment/secondement in either months/years';
        width: 345px;
        position: absolute;
        top: -5.8em;
        left: -12em;
        background: #223133;
        padding: 10px 12px;
        border-radius: 5px;
    }
}

.experience{
    width: 300px;
    display: flex;
    gap: 15px;
    
    & span{
        font-weight: 400;
        align-self: end;
    }
}

.currencyWrapper{
    display: flex;
    column-gap: 6px;

    & input{
        padding-left: 10px;
    }
}
.currencySelector{
    & select{
        background-position: 84%;
        width: 50px;
        &:disabled{
            border-bottom-color: #7dc2ff;
        }
    }
}

.bottomWrapper{
    display: flex;
    margin-top: 3em;
    gap: 10px;
    justify-content: space-between;

    @media(max-width: 460px) {
        flex-wrap: wrap;
        width: 100%;
    }
    
    & button{
        min-height: 45px;
        max-height: 45px;
        width: 165px;
        font-size: 14px;

        @media(max-width: 460px) {
            width: 100%;
        }
    }
    & .submitButton{
        &:disabled,[disabled]{
          background-color: #eeeded;
          color: #666666;
        }
    }
    & .disabledSubmitButton{
        background-color: #eeeded;
        color: #666666;
    }
}

.radioSelect{
    & label{
        & span{
            & svg{
                & circle{
                    /* fill: var(--textFieldColor); */
                }
            }
        }
    }
}

.errorMessage{
    color: var(--failColor);
    margin: 6px auto 10px;
    animation: vibrate 10s infinite;
}

.validationBorder{
    & input{
        border-color: var(--failColor)!important;
    }
    & textarea{
        border-color: var(--failColor)!important;
    }
    & select{
        border-color: var(--failColor);
    }
}

  
@keyframes vibrate {
    0% { transform: translateX(0); }
    0.4% { transform: translateX(4px); }
    0.8% { transform: translateX(-4px); }
    1.2% { transform: translateX(3px); }
    1.6% { transform: translateX(-3px); }
    2.0% { transform: translateX(2px); }
    2.4% { transform: translateX(-2px); }
    2.8% { transform: translateX(1px); }
    3.2% { transform: translateX(-1px); }
    3.6% { transform: translateX(0); }
    25% { transform: translateX(0); }
    50% { transform: translateX(0); }
    75% { transform: translateX(0); }
    100% { transform: translateX(0); }
}