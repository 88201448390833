@import '../../styles/propertySets.css';

.label {
  @apply --marketplaceH5FontStyles;
  padding: 11px 0 13px 0;
  margin: 0 5px 0 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3D3D3D;
}

@media (--viewportMedium) {
  .label {
    padding: 8px 0 16px 0;
  }
}

.currencySelector {
  margin-right: 18px;

  & select {
    border-bottom-color: var(--textFieldColor);
    padding: 4px 20px 4px 0;
    width: 96px;

    @media (--viewportMedium) {
      padding: 2px 20px 2px 0;
    }
  }
}

.inputsWrapper {
  display: flex;

  @media (--viewportMedium) {
    padding-bottom: 5px;
  }
}

.minPrice {
  @apply --marketplaceSearchFilterSublabelFontStyles;
  width: 109px;
  text-align: center;
  border-bottom-color: var(--attentionColor);
  border-bottom-width: 3px;

  & label {
    text-align: left;
  }

  & input {
    text-align: left;
    padding: 2px 0;
  }

  -moz-appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.customerMaxValue {
  width: 80px !important;
}

.priceSeparator {
  display: block;
  height: 3px;
  width: 15px;
  background: #3D3D3D;
  align-self: center;
  position: relative;
  top: 8px;
  margin: 0 19px 0;
}

/* @media (--viewportMedium) {
  .priceSeparator {
    margin: 2px 8px 0 8px;
  }
} */

.maxPrice {
  @apply --marketplaceSearchFilterSublabelFontStyles;
  width: 109px;
  text-align: center;
  border-bottom-color: var(--attentionColor);
  border-bottom-width: 3px;

  & label {
    text-align: left;
  }

  & input {
    text-align: left;
    padding: 2px 0;
  }

  -moz-appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.sliderWrapper {
  display: flex;
  padding: 17px 0 25px 0;
  max-width: 456px;
}

@media (--viewportMedium) {
  .sliderWrapper {
    padding: 16px 0 24px 0;
    margin-bottom: 24px;
  }
}

.contentWrapper {
  /* display: flex; */
  flex-wrap: wrap;
  /*max-width: 300px;*/
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 25px 0;
}

@media (--viewportMedium) {
  .contentWrapper {
    padding-top: 0px;
    padding-bottom: 8px;
  }
}

.submitButton {
  margin: 20px 0 30px;
  flex-shrink: 0;
  display: inline-block;
  width: 241px;

  @media(max-width: 460px) {
    width: 100%;
  }
}

.toolTip {
  padding: 18px 22px;
  z-index: 1;
  background: #FFFFFF;
  box-shadow: 0px 8px 16px rgb(0 0 0 / 30%);
  border-radius: 4px;
  display: flex;
  gap: 15px;
  position: absolute;
  font-size: 14px;
  font-weight: 400;
}

.projectBased {
  position: relative;
  margin-bottom: 1em;

  &:hover {
    & .projectBasedText {
      visibility: visible;
    }
  }
}

.projectBasedText {
  visibility: hidden;
  top: -6.5em;
  left: 27px;
}

.secondMent {
  position: relative;
  margin-bottom: 1em;

  &:hover {
    & .secondMentText {
      visibility: visible;
    }
  }
}

.secondMentText {
  visibility: hidden;
  top: -10.5em;
  left: 27px;
}

.fullTime {
  position: relative;
  margin-bottom: 1em;

  &:hover {
    & .fullTimeText {
      visibility: visible;
    }
  }
}

.fullTimeText {
  visibility: hidden;
  top: -6em;
  left: 27px;
}

.checkbox1 {
  color: yellow
}

.bottomBorder {
  height: 1px;
  background: #E6E6E6;
  margin: 24px 0;
}