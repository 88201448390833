.title {
  margin-bottom: 19px;
  letter-spacing: -1.25px;

  @media (--viewportLarge) {
    margin-bottom: 38px;
    padding: 1px 0 7px 0;
    font-size: 40px;
    line-height: 48px;
  }
  @media(max-width: 420px) {
    padding: 0 15px;
  }
}

.error {
  color: var(--failColor);
}