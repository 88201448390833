.buttonSection {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
}
.buttonSave {
  width: 147px;
}
.buttonDelete {
  width: 147px;
  background-color: var(--matterColorLight);
  border: 1px solid var(--successColorLight);
  color: var(--marketplaceColorLight);
  &:hover {
     background-color: var(--successColorLight);
     color: var(--marketplaceColorLight);
   }
}
.titleModal {
  font-weight: var(--fontWeightSemiBold);
  font-size: 30px;
  line-height: 40px;
}
.description {
  border-bottom-color: var(--successColor);
  margin-top: 38px;
}
.dateContainer {
  margin-top: 38px;
  display: flex;
  justify-content: space-between;
}
.addWork {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FCFCFC;
  border: 2px dashed var(--matterColorAnti);
  box-sizing: border-box;
  border-radius: 2px;
  height: 99px;
  width: auto;
  @media (--viewportMedium) {
    margin-top: 47px;
    height: 99px;
    width: 450px;
  }

}
.textAddWork {
  color: var(--successColorDark);
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  line-height: 20px;
}
.submitButton {
  margin-top: 76px;
  width: 241px;
}
.containerCheckBox {
  user-select: none;
  padding-top: 24px;
  width: 152px;
}
.inputDate {
  width: 180px;
  & :global(.ant-picker) {
      padding: 6px 11px 0;
    }
}
.disabledInputDate {
  width: 180px;
  pointer-events: none;
  & :global(.ant-picker) {
      padding: 6px 11px 0;
    }
& :global(.ant-picker) {
    border-bottom: 3px solid var(--matterColorNegative);
  }
}
.disabledInputDate label,
.disabledInputDate input {
  color: var(--matterColorNegative);
}

.disabledDate {
  pointer-events: none;
  width: 180px;
  color: var(--matterColorNegative);
& :global(.DateInput_input) {
    border-bottom-color: var(--fontWeightSemiBold);
  }
}
.blockInfo {
  position: relative;
  padding: 16px 20px;
  background: #FCFCFC;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.177778px;
@media (--viewportMedium) {
  margin: 15px 0;
  width: 450px;
}
}
.blockTitle {
  font-weight: var(--fontWeightBold);
}
.blockCompany {
  margin-top: 3px;
  font-weight: var(--fontWeightRegular);
}
.blockExperience {
  margin-top: 3px;
  color: #858585;
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
}
.blockDescription {
  margin-top: 3px;
  font-weight: var(--fontWeightRegular);
}
.penEdit {
  position: absolute;
  top: 14px;
  right: 14px;
  cursor: pointer;
}
