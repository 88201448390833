.root {
}

.input {
  border-bottom-color: var(--textFieldColor);
}

.inputSuccess {
  border-bottom-color: var(--textFieldColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.textarea {
}

.underline {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  display: block;
  & a {
    color: inherit;
    text-decoration: underline;
  }
}