@import '../../styles/propertySets.css';

.root {
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 300px;
  border-right: 1px solid var(--matterColorNegative);
  padding-right: 15px;
  margin-right: 10px;
  cursor: pointer;
}

.checkboxWrapper {
  position: relative;
  margin: 27px 16px;
  border-radius: 50px;
  font-family: var(--font-familyLite);
  width: 36px;
  height: 21px;
  border: 1px solid var(--marketplaceColor);
  background: rgba(225, 150, 0, 0.1);
}

.ball {
  position: absolute;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  top: 2px;
  left: 2px;
  transition: transform 0.5s ease;
  background-color: var(--marketplaceColor);

  &.toggled {
    transform: translateX(100%);
  }
}

.textSwitcher {
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  line-height: 20px;

  @media(--viewportLarge){
    font-size: 14px;
    font-weight: var(--fontWeightRegular);
    line-height: 20px;
  }
}
