@import '../../styles/propertySets.css';


.modal{
  @apply --marketplaceModalBaseStyles;
  flex-basis: 1200px;
  border-bottom: none;
  min-height: auto;
}

.root {
  position: relative;
  /* height: 100vh; */
}

.descriptionDetail {
  width: 100%;
}

.chatDetail {
  display: flex;
  min-height: 80vh;
  gap: 12px;
  padding-left: 12px;

  @media(max-width: 1024px) {
    padding-left: 0;
  }

  @media(max-width: 768px) {
    flex-wrap: wrap;
  }
}

.chatSection {
  /* background-color: red; */
}

.gigAppTransactionPanel {
  /* display: flex;
  gap: 27px; */

  & .tabsComponent {
    background-color: red;
  }

  & .statusBar {
    background: #FFFFFF;
    padding: 30px 40px;
    border: 1px solid #E6E6E6;
    display: flex;
    align-items: center;
    margin-top: 5px;

    @media(max-width: 1024px) {
      flex-wrap: wrap;
      padding: 20px 25px;
    }

    & h4 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 32px;
      letter-spacing: -0.0888889px;
      color: #3D3D3D;
      margin: 0;
      min-width: 30%;

      @media(max-width: 768px) {
        font-size: 14px;
        line-height: 20px;
      }
    }

    & .rightSideStatus {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 19px;

      @media(max-width: 768px) {
        flex-wrap: wrap;
        row-gap: 10px;
      }

      & .responseText {
        display: flex;

        & .statusIcon {
          margin-right: 19px;

          @media(max-width: 768px) {
            margin-right: 9px;
          }

          & svg {
            @media(max-width: 768px) {
              width: 20px;
            }
          }
        }

        & .statusText {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #3D3D3D;
          align-self: center;

          & p {
            margin: 0;
            font-weight: 400;
            font-size: 14px;
          }

          @media(max-width: 768px) {
            font-size: 12px;
          }
        }
      }

      & .actionButton {
        display: flex;
        align-items: center;

        @media(max-width: 768px) {
          width: 100%;
          flex-wrap: wrap;
          gap: 10px;
        }

        & button {
          height: 48px;
          width: max-content;
          padding: 0 20px;
          min-height: 48px;
          margin-left: 15px;

          @media(max-width: 768px) {
            margin-left: 0;
            margin-right: 10px;
          }

          @media(max-width: 420px) {
            width: 100%;
          }
        }
      }
    }
  }
}

.titleName {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 32px;
  letter-spacing: -0.0888889px;
  color: #3D3D3D;
}

.rightWrapper {
  width: 30%;

  @media(max-width: 768px) {
    display: none;
    width: 100%;
  }
}

.mobileOnlineBox {
  display: none;

  @media(max-width: 768px) {
    display: block;
    text-align: right;
    width: 100%;
    /* box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 5%); */
    border-bottom: 1px solid #E6E6E6;
    padding: 20px 22px;
  }
}

.mobileRightWrapper {
  display: block;
  width: 25%;
}

.chatMembers {
  /* display: none; */

  & .membersHeading {
    font-size: 18px;
    font-weight: 500;
    color: #3D3D3D;
    line-height: 32px;
    font-style: normal;
    letter-spacing: -0.089px;
    margin-bottom: 11px;
  }

  & .liveChatMembers {
    gap: 11px;
    display: flex;
    align-items: center;

    & .membersIcon {
      width: 31px;
      height: 31px;
      object-fit: cover;
      position: relative;

      & img {
        width: 100%;
      }

      & .liveBar {
        right: 0;
        bottom: 0;
        width: 9px;
        height: 9px;
        content: "";
        position: absolute;
        background-color: #2ECC71;
        border-radius: 50px;
      }
    }
  }
}

.scheduleCall {
  margin-top: 29px;

  & button {
    width: 100%;
  }
}

.gigAppHomePageSection {
  & .gigAppInformation {
    display: flex;
    justify-content: space-between;
    margin-bottom: 38px;

    @media(max-width: 768px) {
      flex-wrap: wrap;
      gap: 20px;
    }

    & .leftApp {
      & .appTitle {
        margin: 0;
        color: #3D3D3D;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 56px;
        letter-spacing: -1.25px;
      }

      & p {
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: -0.25px;
        text-align: left;
        margin: 10px 0 0;

        @media(max-width: 768px) {
          margin: 0;
        }
      }
    }

    & .runNowButtonText {
      width: 154px;
      min-height: 44px;
      height: 44px;
    }
  }

  & .previousVersions {
    margin-bottom: 30px;

    & .previousVersionsHeading {
      color: #3D3D3D;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.089px;
      margin-bottom: 10px;
    }

    & .viewPrevious {
      color: #3D3D3D;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: -0.25px;
    }
  }
}

.chatContentMessage {
  height: auto;

  @media (--viewportLarge) {
    margin: 0;
    max-width: 494px;
  }
}

.askAndChatSection {
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid var(--matterColorNegative);
  /* border-left: 0; */
  padding: 20px;

  @media(max-width: 768px) {
    width: 100%;
  }

  @media(max-width: 920px) {
    padding: 0;
  }

  @media (--viewportLarge) {
    margin: 0;
    /* max-width: 494px; */
  }

  @media(max-width: 460px) {
    /* padding: 0 0 15px 0; */
  }

  & .chatContainerBlock {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 920px) {
      padding: 25px;
      padding-bottom: 10px;
      margin-bottom: 3em;
    }



    @media (--viewportLarge) {
      margin: 0;
      /* max-width: 494px; */
    }

    @media(max-width: 460px) {
      /* padding: 0 0 15px 0; */
    }
  }
}

.sendMessageForm {
  margin: 46px 0 0 0;

  &.textarea {
    line-height: 36px;
  }

  @media (--viewportMedium) {
    margin: 49px 0 0 0;
    border-top: 0;
  }

  @media (--viewportLarge) {
    padding-right: 13px;
    margin: 47px 0 0 0;
  }

  &>div {
    &:nth-child(1) {
      & textarea {
        min-width: 100%;
      }
    }
  }
}

.tableData {
  margin: 0 0 20px;

  & .tableHeader {
    background-color: #fff;
    font-family: 'Poppins';

    & th {
      padding: 10px 0;
      border: none;
      text-align: left;
      border-bottom: 1px solid #ddd;
      color: #3D3D3D;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: -0.25px;
    }
  }

  & .tableBody {
    padding: 10px;
    text-align: left;
    font-family: 'Poppins';

    & tr{
      background-color: #fff;
      position: relative;
    }

    & td {
      padding: 10px 0;
      color: #3D3D3D;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: -0.25px;
      border-bottom: 1px solid #E6E6E6;

      & span{
        cursor: pointer;
      }
    }
  }
}

.overviewSection {

  & .overviewSectionHeading {
    margin: 0 0 10px;
    color: #3D3D3D;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -1.25px;
  }

  & .overviewSectionDescription {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -0.25px;
    margin: 0 0 38px;
  }

  & .imageContainer {
    width: 100%;

    & img {
      width: 100%;
      height: 288px;
      border-radius: 5px;
      object-fit: cover;
    }
  }
}

.permissionsBlock {
  & p {
    color: #3D3D3D;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -0.25px;
  }

  & a {
    color: #0B96F5;
    /* text-align: right; */
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.25px;
  }

  & h3 {
    font-weight: 500;
  }
}

.featureBlock {
  border-bottom: 1px solid #D8D8D8;
}

.chatMembers {
  padding: 0 10px 0 16px;
  background: #fcfcfc;
  flex: 1;

  @media(max-width: 768px) {
    display: none;
  }

  & h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 7px;
    margin-top: 0;
  }

  & .memberTab {
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
    position: relative;
    cursor: pointer;

    &:hover {
      & .memberCard {
        visibility: visible;
      }
    }
  }

  & .memberCard {
    visibility: hidden;
    width: 223px;
    /* height: 174px; */
    background: #FFFFFF;
    border: 1px solid #E6E6E6;
    position: absolute;
    left: -14em;
    top: 3px;
    padding: 10px;
    box-sizing: border-box;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);

    & .memberAvatar {
      display: flex;
      column-gap: 10px;

      & h3 {
        margin: 0;
        grid-column: span 2;
        font-weight: 400;
        font-size: 16px;
        margin-bottom: -4px;
      }

      & a {
        color: #B2B2B2;
      }

      & h4 {
        margin: 0;
        grid-column: span 2;
        font-weight: 400;
        font-size: 12px;
        color: #B2B2B2;
      }
    }

    & .dividerLine {
      display: block;
      width: 100%;
      height: 1.7px;
      background: #E6E6E6;
      margin: 8px 0 8px;
    }

    & .memberDetails {
      margin-bottom: 10px;
      word-wrap: break-word;

      & h4 {
        margin: 0;
        font-weight: 400;
        font-size: 12px;
      }
    }
  }
}

.chatMembersMobile {
  display: block;
  padding-top: 10px !important;

  & .memberCard {
    right: 3em !important;
    z-index: 10;

    @media(max-width: 768px) {
      left: 3em;
    }
  }
}

.onlineDot {
  height: 10px;
  width: 10px;
  background-color: #2ECC71;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  bottom: 0;
  border: 1.7px solid white;
}

.offlineDot {
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  bottom: 0;
  border: 1.7px solid white;
}

.cardImage {
  position: relative;
  height: fit-content;

  & img {
    width: 47px;
    height: 47px;
    border-radius: 50%;
  }

  & .profileImage {
    width: 47px;
    height: 47px;
  }

  & .offlineDot,
  .onlineDot {
    bottom: 3px;
    right: 0px;
    z-index: 10;
  }
}

.profileImage {
  width: 31px;
  height: 31px;
  border-radius: 50%;
  background-color: #42affa;
  color: white;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imagesBox {
  margin-right: 11px;
  position: relative;
  border: 3px solid transparent;
  border-radius: 50%;

  & img {
    width: 31px;
    height: 31px;
    border-radius: 50%;
  }

  &::after {
    display: none !important;
  }
}

.csmImageBox {
  & img {
    border-radius: 50%;
    border: 3px solid #FA0;
  }

  &:hover {
    &::before {
      visibility: visible;
    }
  }

  &::before {
    visibility: hidden;
    content: attr(data-tooltip);
    background-color: white;
    color: #000;
    border-radius: 6px;
    padding: 20px 10px;
    position: absolute;
    z-index: 1;
    width: 400px;
    top: -5px;
    left: 7em;
    box-shadow: 0px 8px 16px rgb(0 0 0 / 30%);
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.25px;

    @media(max-width: 768px) {
      width: 250px;
    }
  }

  &::after {
    content: "CSM";
    position: absolute;
    left: 50%;
    bottom: -3px;
    transform: translate(-50%, 0);
    background-color: #FDECCA;
    border-radius: 2px;
    width: 54px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #E49800;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.087px;
  }
}

.csmBadge {
  border: 3px solid #FA0;
  border-radius: 50%;
  height: fit-content;

  &::after {
    content: "CSM";
    position: absolute;
    left: 13%;
    bottom: -4px;
    background-color: #FDECCA;
    border-radius: 2px;
    width: 35px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #E49800;
    font-size: 10px;
    font-weight: 600;
  }
}

& .csmTooltip {
  &:hover {
    &::before {
      visibility: visible;
    }
  }

  &::before {
    visibility: hidden;
    content: attr(data-tooltip);
    background-color: white;
    color: #000;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    width: 400px;
    left: -15em;
    top: -6em;
    box-shadow: 0px 8px 16px rgb(0 0 0 / 30%);
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.25px;
  }
}

.pointerEvents{
  pointer-events: none;
  opacity: .5;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.25px;
  text-align: left;
  color: #3d3d3d;
  text-decoration: underline;
  cursor: pointer;
  & span{
    color: #3d3d3d;
  }
}
.inprogress{
  opacity: .5;
}
.tabletd{
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.25px;
  text-align: left;
  color: #3d3d3d;
  text-decoration: underline;
  cursor: pointer;
  & a{
    color: #3d3d3d;
  }
}
.informationBlock{
	display: grid;
	grid-template-columns: 1fr 1fr;
	margin-bottom: 15px;

	& a{
    display: block;
		margin-bottom: 12px;
	}

	@media(max-width: 768px){
		display: block;
	}
}

.titleHeading{
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.25px;
}

.inputContainer{
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px !important;
  width: 100%;
  margin: 25px auto;
  padding: 40px 100px;
  border: 1px solid #c2c2c2;
  border-radius: 5px;

  @media(min-width: 1200px) {
    width: 800px;
  }

  @media(max-width: 768px) {
    padding: 20px;
  }

  & .generatorTitle {
    font-size: 36px;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -1.25px;
    margin-bottom: 20px;
  }

  & label{
    margin-bottom: 20px;
  }

  @media(max-width: 768px) {
    margin-bottom: 3em;

    & .generatorTitle {
      font-size: 24px;
      line-height: 28px;
    }
  }
}

.runTitle{
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  padding-right: 15px;

  &:hover {
    &::after {
      visibility: visible;
    }
  }

  &::after {
    visibility: hidden;
    content: attr(data-tooltip);
    width: fit-content;
    padding: 5px 15px;
    position: absolute;
    top: -3em;
    left: 15em;
    background: #FFFFFF;
    box-shadow: 0px 8px 16px rgb(0 0 0 / 30%);
    border-radius: 4px;
    margin-top: 8px;
    z-index: 10;
    text-align: center;
    font-size: 13px;
  }
}