.sendMessageForm {
  position: relative;
  margin: 46px 0 0 0;

  &.textarea {
    line-height: 36px;
  }

  @media (--viewportMedium) {
    margin: 49px 0 0 0;
    border-top: 0;
  }

  @media (--viewportLarge) {
    padding-right: 13px;
    margin: 47px 0 0 0;
  }
}

.chatContainerBlock {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid var(--matterColorNegative);
  /*max-width: 611px;*/
  min-height: 605px;
  padding: 20px 0 24px 21px;

  @media (--viewportLarge) {
    margin: 0;
    max-width: 494px;
  }

  @media(max-width: 460px) {
    padding: 0 0 15px 0;
  }
}

.chatBoxWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 450px;

  @media(max-width: 920px) {
    padding: 25px;
    padding-bottom: 10px;
    margin-bottom: 3em;
  }
}
