.root {
  display: flex;
  flex-direction: column;
}

.listingCards {
  padding: 0 24px 96px 24px;
  display: grid;
  gap: 30px;

  @media (--viewportMedium) {
    margin: 0 auto;

    grid-template-columns: repeat(3, 1fr);
  }

  @media(max-width: 920px) {
    grid-template-columns: repeat(2, 1fr);
    margin: unset;
  }
  @media(max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (--viewportLarge) {
    width: 1272px;
    padding: 0 36px 97px 36px;
  }
}

.listingCard {
  /* margin-bottom: 36px; */
  height: 100%;
  flex-basis: 100%;
  box-shadow: 0px 8px 16px rgb(0 0 0 / 30%);
  border-radius: 18px;
  transition: 0.2s all;

  &:hover {
    transform: translateY(-10px);
    box-shadow: var(--boxShadowListingCard);
  }

  &:nth-of-type(3n) {
    margin-right: 0;
  }

  @media (--viewportLarge) {
    /**
      * resultPanelWidthRatio = 0.625 aka 62.5%

      resultPanel: 62.5vw from 1024px = 640px
      panelPaddings: - 2*36px = 72px

      columnCount: 2
      guttersBetweenColumns: 24px

      (resultPanel - (panelPaddings + guttersBetweenColumns)) / columnCount = listingCardWidth
      ergo => listingCardWidth: 272px

    * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))
    */
    flex-basis: calc(27% - 13px);
    /* margin-right: 24px; */
  }

  @media (--viewportLargeWithPaddingsSearch) {
    /**
     * resultPanelWidthRatio = 0.625 aka 62.5%

      resultPanel: 62.5vw from 1920px = 1200px
      panelPaddings: - 2*36px = 72px

      columnCount: 3
      guttersBetweenColumns: 2*24px = 48px

      (resultPanel - (panelPaddings + guttersBetweenColumns)) / columnCount = listingCardWidth
      ergo => listingCardWidth: 360px

     * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))
     */

    flex-basis: calc(33% - 13px);
    /* margin-right: 24px; */
  }
}

.listingCardsClient {
  width: 100%;
  margin: 0 auto;
  padding: 0 24px;

  @media (--viewportLargeWithPaddings) {
    width: 1022px;
  }
}

.listingCardClient {
  margin-bottom: 10px;
  flex-basis: 100%;
}

.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin-top: auto;
  background-color: var(--matterColorLight);
  padding: 0 24px;

  @media (--viewportLargeWithPaddings) {
    padding: 0 36px;
  }
}
.actionButtons{
  display: flex;
  margin-top: 3em;
  margin-bottom: 2em;
  gap: 10px;
  justify-content: space-between;

  @media(max-width: 460px) {
      flex-wrap: wrap;
      width: 100%;
  }
  
  & button{
      min-height: 45px;
      max-height: 45px;
      width: 165px;
      font-size: 14px;
      @media(max-width: 460px) {
          width: 100%;
      }
  }
  & .submitButton{
      &:disabled,[disabled]{
        background-color: #eeeded;
        color: #666666;
      }
  }
}