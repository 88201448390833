.ScheduleMeetingContainer{
    /* display: flex; */
}

.connectZoom{
    color:rgb(32, 147, 147);
}

.meetingButtons{
    padding: 20px;
    &>div{
        margin-bottom: 30px;
        cursor: pointer;
    }
}

.zoomButtons{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-top: 10em;
}

.dates{
    display: flex;
    justify-content: space-between;
    &>div{
        width: 100%;
    }
}

.duration{
    margin-top: 0px;
}

.actionButtons{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.DayPicker_weekHeader{
    top: 40px
}

.dates table td, th{
    padding: 0;
}

.modalHeadingName {
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    letter-spacing: -0.9px;
    color: #3D3D3D;
    margin-bottom: 43px;
}

.inputBox{
    margin-bottom: 33px;
    & label{
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #3D3D3D;
    }
}
.bottomWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-top: 2rem;
}

.conditionLabel {
    color: #4a4a4a;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 5px;
}

.conditionInputWrapper {
    position: relative;
    & .conditionDropDown {
        background: #ffffff;
        box-shadow: 0px 2px 4px rgb(0 0 0 / 5%);
        padding: 10px;
        margin: 0 0 20px;
        left: 0;
        right: 0;
        display: flex;
        flex-wrap: wrap;
        border: 1px solid #eee;
    }
    & .iconArrow {
        position: absolute;
        right: 0;
        top: 0px;
    }
}

.conditionInput {
    margin: 0;
    border-bottom: 2px solid #aebecd;
    color: #b2b2b2;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    position: relative;
    cursor: pointer;
    &.conditionInputselected {
        color: #4a4a4a;
    }
    &:hover {
        border-bottom: 2px solid #4a90e2;
    }
   
}

.dropdownListBox {
    cursor: pointer;
    position: relative;
    width: auto;
    /* background: #9e9e9e; */
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 5px;
    color: #fff;
    text-align: center;

    & input:checked+label {
        background-color: var(--successColorDark);
        border: 1px solid var(--successColorDark);

        & span {
            color: #fff !important;
        }
    }

    & label {
        background-color: #eee;
        border: 1px solid #cfcfcf;
        border-radius: 5px;
        justify-content: center;
        padding: 0 5px;

        & span {
            font-size: 12px;
            line-height: 21px;
            margin-bottom: 0;

            &:nth-child(1) {
                margin-right: 0;
                opacity: 0;
                width: 0;
            }
        }


    }
}
.buttonCancel{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.111111px;
    color: #3D3D3D;
    cursor: pointer;
}
.modalContainer{
    & .heading{
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 40px;
    }
    & p{
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        letter-spacing: -0.087px;
    }
    & .buttonSubmit {
        width: 228px;
    }
}
.googleModal{
    height: 200px;

    & .googlePanel{
        display: flex;
        justify-content: center;
    }
}