.root {
  /* Expand to available space */
  flex-grow: 1;

  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 1px;
  }
}

.features {
  margin: 24px 0;

  @media (--viewportMedium) {
    margin: 35px 0;
  }
}

.features li {
  margin-bottom: 0;
}

.error {
  color: var(--failColor);
}

.description textarea {
  margin-bottom: 48px;
  border-bottom-color: var(--successColor);

  @media (--viewportLarge) {
    width: 564px;
  }
}

.submitButton {
  margin: 30px 0 30px !important;
  flex-shrink: 0;
  display: inline-block;
  width: 241px !important;

  @media(max-width: 460px) {
    width: 100% !important;
  }
}

.canselDescription {
  /* margin-bottom: 69px; */
  pointer-events: none;
  color: var(--successColorLight);
  /* height: 48px; */
}

.canselDescription textarea {
  border-bottom-color: var(--successColorLight);
}

.showListingFeatures textarea {
  @media (--viewportLarge) {
    width: 564px;
  }
}




.selectedQueries {
  margin-bottom: 40px;

  & .serviceHeading {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #3D3D3D;
    margin-bottom: 7px;
  }

  & .releventService {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.0875px;
    color: #3D3D3D;
    margin-bottom: 10px;
  }

  & .addServicesButton {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.266667px;
    color: #0B96F5;
    border: 1px solid #0B96F5;
    height: 35px;
    cursor: pointer;
    padding: 0 10px;
    margin-top: 11px;
    border-radius: 3px;
  }

  & .inputSelectCard {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    column-gap: 12px;
    row-gap: 10px;
    width: fit-content;

    & .servicesSelected {
      background: #fdf0d6;
      border-radius: 12px;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.1px;
      color: #E49800;
      padding: 4px 9px;

      & .closeItem {
        margin-left: 10px;
        line-height: 0;
        cursor: pointer;

        & svg{
          width: 11px;
        }
      }
    }
    & .servicesUspSelected{
      background: rgba(246, 180, 48, 1);
      color: #fff;
    }

    & .industrySelected {
      background: #ceeafd;
      border-radius: 12px;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.1px;
      color: #0081D9;
      padding: 4px 9px;
      position: relative;

      & .closeItem {
        margin-left: 10px;
        line-height: 0;
        cursor: pointer;
        font-size: 24px;

        & svg {
          width: 11px;
          & path {
            stroke: #0081D9
          }
        }
      }
    }

    & .industryUspSelected{
      background: #0B96F5;
      color: #fff;
    }
  }

  & .inputSelect {
    margin-top: 12px;

    & input {
      background: #FFFFFF;
      border: 2px solid #E6E6E6;
      padding: 3px 10px;
    }
  }
}
.softwareInsightsContainer {
  position: relative;

  & .addSoftwareButton {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.266667px;
    color: #0B96F5;
    border: 2px solid #0B96F5;
    height: 35px;
    cursor: pointer;
    padding: 0 10px;
    margin-top: 11px;
    border-radius: 4px;
  }

  & .languagesDropdown {
      overflow: auto;
      height: 50vh;
      border: 1px solid gainsboro;
      margin-top: 5px;

      & input {
        display: none;
      }

      & label{
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
      }

      & .languageListItem{
        border-bottom: 1px solid gainsboro;
        padding: 5px 0 5px 5px;
      }

      & .selected{
        background: rgb(226 226 226);
        cursor: pointer;
      }
   }
 
  /* Style for the language badge */
 & .languageBadge {
    display: inline-block;
    background-color: #0b95f4;
    color: #fff;
    padding: 5px 10px;
    /* margin: 5px; */
    border-radius: 5px;
  }

  /* Style for the remove button in the badge */
  & .removeBadge {
    margin-left: 5px;
    cursor: pointer;
    font-weight: bold;
  }

  & .removeBadge:hover {
    color: red;
  }
}
.inputSelect {
  margin-top: 12px;

  & input {
    background: #FFFFFF;
    padding: 3px 10px;

    &:hover,
    &:focus {
      border-color: #0b95f4;
      /* border: 2px solid #E6E6E6; */
    }
  }
  & .clearSearchButton{
    position: absolute;
    right: 5px;
    top: 6%;

    & svg{ 
      & path{
        stroke: #0b95f4;
      }
    }
  }
}
.searchContainer{
  position: relative;
}

.languagesDropdownBox{
  overflow-y: auto;
  max-height: 200px;
  border: 2px solid #E6E6E6;
  width: 100%;
  border-top: none;

  background: #FFFFFF;
  margin-top: 0;
  border-radius: 2px;
  z-index: 9;
  
  & li{
    border-top: 1px solid #E6E6E6;
    padding: 1px 10px;
    padding: 0 20px;
    cursor: pointer;
    color: #595959;

    &:hover{
      background-color: rgb(226 226 226 / 31%);
    }
  } 
  & .selectedList{
    background-color: rgb(226 226 226);
  }
}

.moreOptionPopup{
  position: absolute;
  background-color: #fff;
  width: 200px;
  left: 94%;
  text-align: left;
  border: 1px solid #fff;
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 20%);
  border-radius: 5px;
  padding: 10px;

}
.markAsUSPText{
  cursor: pointer;
  color: rgba(61, 61, 61, 1);
  text-align: left;
  padding: 0 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.25px;

  &:hover{
    text-decoration: underline;
  }
}
.menuLabel{
  display: inline-block;
  scale: 1.5;
  position: relative;
  left: 5px;
}
.menu{
  right: -5px;
  width: 170px;
  top: 28px;
}