.proposalGeneratorForm {
    padding: 40px;

    @media(max-width: 768px) {
        padding: 24px;
    }
}

.container {
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px !important;
    width: 100%;
    margin: 0px auto;
    padding: 40px 100px;
    border: 1px solid #c2c2c2;
    border-radius: 5px;

    @media(min-width: 1200px) {
        width: 800px;
    }

    @media(max-width: 768px) {
        padding: 20px;
    }
}

.briefGenerator {
    margin-bottom: 40px;

    & .generatorTitle {
        font-size: 36px;
        font-weight: 600;
        line-height: 56px;
        letter-spacing: -1.25px;
    }

    @media(max-width: 768px) {
        margin-bottom: 3em;

        & .generatorTitle {
            font-size: 24px;
            line-height: 28px;
        }
    }
}

.textareaBox {
    margin-bottom: 2em;

    & .inputBox {
        & textarea {
            min-width: 100%;

            &::placeholder {
                font-size: 14px;
            }
        }
    }
}

.attachmentRow {
    display: grid;
    grid-template-columns: 1fr 1fr 3fr;
    margin-bottom: 30px;
    align-items: flex-start;
}

.attachmentsContainer{
    margin-bottom: 2em;
}

.attachment {
    & label {
        margin-bottom: 10px;
        margin-top: 2em;
    }

    & .close {
        cursor: pointer;
    }

    & .addImageInput {
        display: none;
    }

    & .attachmentLabel {
        width: 100%;
        box-sizing: border-box;
        border: 2px dashed gainsboro;
        border-radius: 2px;
        padding: 25px 20px;
        display: flex;
        flex-direction: column;
        text-align: center;
        cursor: pointer;
        margin: 0px 0 0;
        align-items: center;
        gap: 10px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.177778px;

        @media(max-width: 768px) {
            padding: 5px 20px;
            font-size: 12px;
        }

        & .dragDrop {
            color: #3D3D3D;
        }

        & .docType {
            color: #B2B2B2;
        }
    }
}

.rowWrapper {
    display: flex;
    gap: 30px;
    justify-content: space-between;
    margin-top: 2em;
}

.fieldDateInput {
    flex: 1;

    & input {
        padding-left: 0px !important;
        background-image: none !important;
        border-color: var(--textFieldColor);

        &::placeholder {
            font-size: 14px;
        }

        &:focus {
            border-color: black !important;
        }
    }
}

.currencyWrapper {
    display: flex;
    align-items: flex-end;
    flex: 1;
}

.select {
    & select {
        width: 50px;
        background-position: 96%;
    }
}

.budgetInputBox {
    width: 100%;

    & input {
        padding: 0px 10px;
    }
}

.fieldSelector {
    flex: 1;
}

.actionButtons {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    margin-top: 3em;

    @media(max-width: 460px) {
        flex-wrap: wrap;
        width: 100%;
    }

    & button {
        min-height: 45px;
        max-height: 45px;
        width: 165px;
        font-size: 14px;

        @media(max-width: 460px) {
            width: 100%;
        }
    }

    & .submitButton {

        &:disabled,
        & [disabled] {
            background-color: #eeeded;
            color: #666666;
        }
    }
    & .disabledSubmitButton{
        background-color: #eeeded;
        color: #666666;
    }
}

.documentSwitcher {
    display: flex;
    align-items: center;
    /* margin-bottom: 2em; */

    & .switcherWrapper {
        cursor: pointer;
        border-radius: 50px;
        font-family: var(--font-familyLite);
        width: 30px;
        height: 15px;
        border: 1px solid #e6e6e6;
        background: #E6E6E6;
        margin: 0 6px;

        & .ball {
            border-radius: 50px;
            width: 13px;
            height: 13px;
            transition: transform 0.3s ease;
            background-color: white;
        }

    }

    & .toggled {
        background-color: #0B96F5;

        & .ball {
            transform: translateX(15px);
        }
    }
}

.briefCheckboxContainer{
    display: flex;
    justify-content: center;
    gap: 16px;
    flex-wrap: wrap;
    margin-top: 8px;
}

.briefCheckbox{
    display: flex;
}

.briefCheckbox input{
    width: auto;
    margin-right: 8px;
}

.briefAttachmentDiv {
    margin-bottom: 24px;
}

.tooltip {
    background: #223133;
    border-radius: 50%;
    width: 18px;
    display: inline-block;
    height: 18px;
    color: white;
    padding-left: 5px;
    margin-left: 4px;
    font-size: 13px;
    cursor: help;
    position: relative;

    &:hover {
        &::before {
            display: block;
        }
    }

    &::before {
        display: none;
        width: 335px;
        position: absolute;
        top: -5.8em;
        left: -12em;
        background: #223133;
        padding: 10px 12px;
        border-radius: 5px;
        text-align: center;
    }
}

.credentialContent:before{
    content:"Please attach any credentials document that you have explaining your services."
}

.outputContent:before{
    content:"Attach a file with the headings you would like to have. The output will be populated as per these headings."
}

.fileSizeError{
    color:var(--failColor);
    margin: 0;
}

.alertModal{
    & h2{
		text-align: center;
		font-size: 30px;
        line-height: 40px;
        letter-spacing: -0.9px;
	}
    & p{
      /* text-align: center; */
      font-size: 20px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.9px;
    }
    .closeButton{
        margin-top: 3em;
        margin-bottom: 2em;
    }
  }

.validationBorder{
    & select {
        border-bottom-color: var(--failColor)!important;
    }
    & input {
        border-bottom-color: var(--failColor)!important;
    }
}

.vibrate {
    animation: vibrate 10s infinite;
}

@keyframes vibrate {
    0% { transform: translateX(0); }
    0.4% { transform: translateX(4px); }
    0.8% { transform: translateX(-4px); }
    1.2% { transform: translateX(3px); }
    1.6% { transform: translateX(-3px); }
    2.0% { transform: translateX(2px); }
    2.4% { transform: translateX(-2px); }
    2.8% { transform: translateX(1px); }
    3.2% { transform: translateX(-1px); }
    3.6% { transform: translateX(0); }
    25% { transform: translateX(0); }
    50% { transform: translateX(0); }
    75% { transform: translateX(0); }
    100% { transform: translateX(0); }
}