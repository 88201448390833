@import '../../styles/propertySets.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  /* Text positioning is done with paddings */
}

.title {
  @apply --marketplaceH4FontStyles;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
  width: 100%;

  color: var(--matterColorDarker);
  margin: 0 0 32px 0;

  composes: animation;
  animation-delay: 0.65s;
  text-align: center;

  @media (--viewportMedium) {
    font-size: 30px;
    margin-bottom: 60px;
  }
}


.content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 19px 10px;
  justify-content: center;
  align-items: center;
  @media (--viewportLargeWithPaddings) {
    flex-direction: row;
    align-items: center;
    background: #F7FBFF;
    border-radius: 40px 20px;
    padding: 19px 10px;
  }
  @media(max-width: 460px){
    justify-content: unset;
    overflow-x: auto;
    width: 100%;
  }
}

.brand {
  width: 300px;
  object-fit: cover;
  font-size: 34px;
  line-height: 1.5;
  padding: 0 18px;
  /* margin-bottom: 30px; */
  @media(max-width: 1220px){
    width: 220px;
  }
  @media(max-width: 920px){
    width: 160px;
  }
  @media(max-width: 660px){
    width: 120px;
  }
}
