@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

/* 404 page */

.root {
  /* Expand to the available space */
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  max-width: 587px;
  width: 80%;
  margin-top: 60px;
  margin-bottom: 60px;

  @media (--viewportMedium) {
    margin-top: 10vh;
  }
}

.number {
  @apply --marketplaceHeroTitleFontStyles;
  text-align: center;
  color: var(--marketplaceColor);
  margin: 1px 0 0 0;
  font-size: 90px;
  line-height: 95px;
  letter-spacing: -2px;
  font-weight: 400;
  color: #ff0000;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.heading {
  @apply --marketplaceModalTitleStyles;
  text-align: center;
  margin-top: 16px;

  @media (--viewportMedium) {
    margin-top: 22px;
  }
}

.description {
  text-align: center;
  margin-bottom: 0;
  font-weight: 400;

  @media (--viewportMedium) {
    margin-top: 24px;
    margin-bottom: 0;
  }
}

.searchForm {
  max-width: 408px;
  margin: 62px auto 0 auto;

  background-color: var(--matterColorLight);
  border-bottom: 4px solid #ff0000;
  box-shadow: var(--boxShadowNotFoundPageSearch);

  @media (--viewportMedium) {
    margin-top: 64px;
  }
}
