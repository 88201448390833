.mainWrapper {
  margin: 0 auto;
  max-width: 1024px;

  @media (max-width: 1024px) {
    max-width: 100%;
  }
}

.disabled{
  pointer-events: none;
}

.tabsContainer {
  padding-bottom: 50px;

  & .navTabs {
    display: flex;
    gap: 8px;
    align-items: center;
    margin: 0 0 40px 0;
    padding-bottom: 10px;

    @media (max-width: 768px) {
      overflow-x: auto;
    }

    & .navItems {
      min-width: max-content;
      height: 43px;
      text-align: center;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      & a {
        border-radius: 100px;
        padding: 10px 20px;
        background-color: #ffff;
        border: 1px solid #e6e6e6;
        color: #4a4a4a;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.089px;

        &:hover {
          text-decoration: unset;
          border: 1px solid #0b96f5;
          background: rgba(11, 150, 245, 0.05);
        }
      }

      & .activeTab {
        border: 1px solid #0b96f5;
        background: rgba(11, 150, 245, 0.05);
      }
    }
  }
}

.cardContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  & .leftCardBox {
    flex: 1;
    display: flex;
    justify-content: space-between;
    gap: 12px;
    flex-direction: column;
  }

  & .rightCardBox {
    padding: 25px 30px;
    flex: 1;
    border-radius: 5px;
    border: 1px solid #e6e6e6;
    background: #fff;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05);
  }
}

.centerImage {
  text-align: center;

  & img {
    width: 40%;

    @media (max-width: 768px) {
      width: 30%;
    }
  }
}

.createABriefCard {
  padding: 25px 30px;
  border-radius: 5px;
  border: 1px solid #e6e6e6;
  background: #fff;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05);
}

.bottomButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.headingName {
  color: #3d3d3d;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.089px;
  margin-bottom: 10px;
  margin-top: 0;
}

.subHeading {
  color: #3d3d3d;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.112px;
  margin: 0;
  padding: 0;
}

.learnMore {
  color: #4a4a4a;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.112px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.emailModalActionButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 43px;

  & button {
    &:nth-child(1) {
      color: #3d3d3d;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: -0.111px;
    }

    &:nth-child(2) {
      width: 200px;
    }
  }
}

.emailModalHeading {
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.8999999761581421px;
  text-align: center;
}

.thirdWrapper {
  width: 100%;
  margin-bottom: 32px;
}

.welcomeTabs {
  margin: 35px 0;

  & .tabButtons {
    gap: 25px;
    display: flex;
    align-items: center;

    & .disabled {
      pointer-events: none;
      position: relative;
    }

    @media(max-width: 1024px) {
      flex-wrap: wrap;
      justify-content: center;
    }

    & button {
      flex: 1;
      border-radius: 5px;
      padding: 17px 35px;

      @media(max-width: 1024px) {
        flex: unset;
        width: 33%;
      }

      @media(max-width: 1024px) {
        width: 40%;
      }

      @media(max-width: 600px) {
        width: 46%;
        padding: 17px 30px;
      }

      @media(max-width: 500px) {
        padding: 17px 10px;
      }

      & .tabIcon {
        & svg {
          @media(max-width: 768px) {
            width: 30px;
            height: 30px;
          }
        }
      }

      & .tabName {
        font-size: 18px;
        font-weight: 500;
        line-height: 27px;
        letter-spacing: -0.08888888359069824px;
        height: 54px;
        display: flex;
        margin-top: 6px;
        align-items: center;
        justify-content: center;
      }
    }

    & .inActive {
      background-color: #fff;
      border: 1px solid #0b97f500;
      box-shadow: 0px 8px 8px 0px #0000000D;

      & .tabName {
        color: #3D3D3D;
      }
    }

    & .active {
      background: #0B96F50D;
      border: 1px solid #0B96F5;

      & .tabIcon {

        & svg {
          & path {
            fill: #0B96F5;
          }
        }
      }

      & .tabName {
        color: #0B96F5;
      }
    }
  }

  & .tabContent {
    margin-top: 40px;
  }
}

.marketPlaceHocs {
  margin-bottom: 40px;

  & .marketplaceRow {
    gap: 40px;
    display: flex;

    @media(max-width: 920px) {
      gap: 20px;
    }

    @media(max-width: 800px) {
      flex-wrap: wrap;
    }

    & .addHocProject {
      flex: 1;
      padding: 25px;
      box-shadow: 0px 8px 8px 0px #0000000D;

      @media(max-width: 800px) {
        flex: 100%;
      }

      & .marketIcon {
        & svg {

          & path {
            fill: #0B96F5;
          }
        }
      }

      & .marketBottom {

        & a,
        .linkName {
          color: #0B96F5;
        }
      }
    }

    & .longTermProject {
      flex: 1;
      padding: 25px;
      box-shadow: 0px 8px 8px 0px #0000000D;

      @media(max-width: 800px) {
        flex: 100%;
      }

      & .marketIcon {
        & svg {

          & path {
            fill: #F6B430;
          }
        }
      }

      & .marketBottom {
        & .linkName {
          color: #F6B430;
          cursor: pointer;
        }
      }
    }

    & .marketMainTitle {
      margin-bottom: 24px;

      & .bigTitle {
        font-size: 22px;
        font-weight: 500;
        line-height: 33px;
        letter-spacing: -0.9375px;
      }

      & .smallTitle {
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        letter-spacing: -0.9375px;
      }
    }

    & .marketplaceArray {
      & .marketplaceList {
        gap: 13px;
        display: flex;
        margin-bottom: 24px;
        padding-bottom: 24px;
        border-bottom: 1px solid #E6E6E6;

        &:last-child {
          margin-bottom: 0;
          padding-bottom: 0;
          border-bottom: none;
        }

        & .marketIcon {
          & svg {
            width: 22px;
          }
        }

        & .marketDetail {
          & .marketHeading {
            font-size: 18px;
            font-weight: 500;
            line-height: 24px;
            margin-bottom: 6px;
            letter-spacing: -0.08888888359069824px;
          }

          & .marketSubHeading {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            margin-bottom: 6px;
            letter-spacing: -0.08888888359069824px;
          }

          & .marketBottom {
            display: flex;
            justify-content: space-between;

            & .questionMark {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.insightToolkit {
  box-shadow: 0px 8px 8px 0px #0000000D;
  background: #0B96F508;
  padding: 32px;
  border-radius: 10px;
  margin-bottom: 40px;

  & .insightCard {
    gap: 48px;
    display: flex;

    @media(max-width: 620px) {
      flex-wrap: wrap;
      gap: 32px;
    }

    & .insightToolIcon {
      & svg {
        @media(max-width: 620px) {
          width: 120px;
          height: 108px;
        }
      }
    }

    & .insightRightBar {
      & .toolkitHeading {
        font-size: 22px;
        font-weight: 500;
        line-height: 33px;
        letter-spacing: -0.9375px;
      }

      & .toolkitSubHeading {
        margin-top: 24px;
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.08888888359069824px;
      }

      & .toolkitDescription {
        margin-top: 6px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.08888888359069824px;
      }

      & .toolkitBottom {
        margin-top: 6px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        & .gigappLink {
          font-size: 16px;
          font-weight: 500;
          color: #0B96F5;
          line-height: 24px;
          letter-spacing: -0.08888888359069824px;
        }

        & .questionMarkIcon {
          cursor: pointer;

          & svg {
            & react {
              fill: #0081D933;
            }
          }
        }
      }
    }
  }
}

.peerToolkit {
  box-shadow: 0px 8px 8px 0px #0000000D;
  background: #F6B43014;
  padding: 32px;
  border-radius: 10px;
  margin-bottom: 40px;

  & .insightCard {
    gap: 48px;
    display: flex;

    @media(max-width: 620px) {
      gap: 32px;
      flex-wrap: wrap;
      flex-direction: column-reverse;
    }

    & .insightToolIcon {
      & svg {
        @media(max-width: 620px) {
          width: 120px;
          height: 108px;
        }
      }
    }

    & .insightRightBar {
      & .toolkitHeading {
        font-size: 22px;
        font-weight: 500;
        line-height: 33px;
        letter-spacing: -0.9375px;
      }

      & .toolkitSubHeading {
        margin-top: 24px;
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.08888888359069824px;
      }

      & .toolkitDescription {
        margin-top: 6px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.08888888359069824px;
      }

      & .toolkitBottom {
        margin-top: 6px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        & .gigappLink {
          font-size: 16px;
          font-weight: 500;
          color: #E19600;
          line-height: 24px;
          letter-spacing: -0.08888888359069824px;
        }

        & .questionMarkIcon {
          cursor: pointer;

          & svg {
            & rect {
              fill: #F6B4304D;
            }
          }
        }
      }
    }
  }
}

.updateProfile {
  padding: 25px;
  margin-bottom: 40px;
  box-shadow: 0px 8px 8px 0px #0000000D;

  & .profileHeading {
    font-size: 22px;
    font-weight: 500;
    line-height: 33px;
    margin-bottom: 24px;
    letter-spacing: -0.9375px;
  }

  & .profileList {
    display: flex;
    gap: 13px;
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #E6E6E6;

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
    }

    & .profileRight {
      width: 100%;

      & .profileMainHeading {
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 6px;
        letter-spacing: -0.08888888359069824px;
      }

      & .profileSubHeading {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 6px;
        letter-spacing: -0.08888888359069824px;
      }

      & .profileBottomLink {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & .profileLink {
          font-size: 16px;
          font-weight: 500;
          color: #0B96F5;
          line-height: 24px;
          letter-spacing: -0.08888888359069824px;
        }

        & .questionMark {
          cursor: pointer;
        }
      }
    }
  }
}

.supportBanner {
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: bottom;
  background-size: cover;
  background-image: url(../../assets/csm-banner.png);
  position: relative;
  width: 100%;
  margin: 0 auto 40px;

  @media(max-width: 768px) {
    background-position: right 0 bottom 100%;
  }

  & .supportBannerWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 277px;
    border-radius: 5px;
    border: 1px solid #E6E6E6;
    position: relative;
    padding: 25px;

    @media(max-width: 800px) {
      height: 100%;
    }

    @media(max-width: 768px) {
      padding: 40px 25px;
    }

    & .profileIcon {
      left: 0;
      bottom: 0;
      height: 140px;
      width: 140px;
      border-radius: 3px;
      position: absolute;

      @media(max-width: 800px) {
        display: none;
      }

      @media(max-width: 768px) {
        height: 65px;
        width: 65px;
      }

      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
      }
    }

    & h2 {
      font-size: 32px;
      font-weight: 500;
      line-height: 48px;
      letter-spacing: -0.08888888359069824px;
      text-align: center;
      margin-top: 0;
      margin-bottom: 24px;
    }

    & p {
      margin: 0;
      width: 60%;
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      text-align: center;
      letter-spacing: -0.25px;

      @media(max-width: 768px) {
        font-size: 16px;
        line-height: 24px;
        width: 100%;
      }
    }

    & button {
      width: 309px;
      height: 40px;
      margin-top: 24px;
      min-height: 40px;

      @media(max-width: 768px) {
        height: auto;
        line-height: 19px;
        font-weight: 500;
        font-size: 15px;
        padding: 8px;
      }

      @media(max-width: 460px) {
        width: 100%;
      }
    }
  }
}

.ribbon {
  width: 105px;
  height: 100px;
  overflow: hidden;
  position: absolute;
  top: -11px;
  left: -10px;
}

.ribbon::before,
.ribbon::after {
  content: "";
  position: absolute;
  z-index: -1;
  border-style: solid;
  border-width: 5px;
}

.ribbon::before {
  top: 0;
  right: 0;
  border-top-color: transparent;
  border-right-color: transparent;
  border-color: #0c7cca;
}

.ribbon::after {
  bottom: 0;
  left: 0;
  border-top-color: transparent;
  border-right-color: transparent;
  border-color: #0c7cca;
}

.ribbonContent {
  left: -37px;
  top: 20px;
  transform: rotate(-43deg);
  position: absolute;
  width: 155px;
  padding: 0;
  background-color: #0b96f5;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
  color: #fff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
  font-size: 11px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchModal{
  & h3{
    font-size: 27px;
    font-weight: 600;
    letter-spacing: -0.5px;
  }

  & .tip{
    font-size: 13px;
    font-weight: 500;
    margin-top: 5px;
    display: inline-block;
  }
}

@media(min-width:1025px){
  .tabButtons{
    margin: auto 98px;
  }
}