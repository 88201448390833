.bottomWrapper {
    margin-top: 73px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & .buttonCancel{
        font-size: 20px;
        font-weight: 400;
        line-height: 32px;
    }
    & button{
        width: 228px;
    }
}

.modalHeadingName {
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    letter-spacing: -0.9px;
    color: #3D3D3D;
    margin-bottom: 43px;
}

.inputBox {
    margin-bottom: 33px;

    & label {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */
        /* Dark grey */

        color: #3D3D3D;
    }
}

.conditionLabel {
    color: #4a4a4a;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 10px;
}

.conditionInputWrapper {
    position: relative;

    & .conditionDropDown {
        margin-top: 20px;
    }

    & .iconArrow {
        position: absolute;
        right: 0;
        top: 0px;
    }
}

.conditionInput {
    margin: 0;
    border-bottom: 2px solid #aebecd;
    color: #b2b2b2;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    position: relative;
    cursor: pointer;

    &.conditionInputselected {
        color: #4a4a4a;
    }

    &:hover {
        border-bottom: 2px solid #4a90e2;
    }

}



.dropdownListBox {
    /* cursor: pointer;
    position: relative;
    width: auto;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 5px;
    color: #fff;
    text-align: center;

    & input:checked+label {
        background-color: var(--successColorDark);
        border: 1px solid var(--successColorDark);

        & span {
            color: #fff !important;
        }
    }

    & label {
        background-color: #eee;
        border: 1px solid #cfcfcf;
        border-radius: 5px;
        justify-content: center;
        padding: 0 5px;

        & span {
            font-size: 12px;
            line-height: 21px;
            margin-bottom: 0;

            &:nth-child(1) {
                margin-right: 0;
                opacity: 0;
                width: 0;
            }
        }
    } */
}

/* .dropdownListBox label:nth-child(2){
    background-color: red;
} */
.rowBox {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
}

.formRow {
    display: flex;
    gap: 20px;

    & .field {
        position: relative;
    }

    & .startField {
        position: relative;

        &>div {
            & label {
                color: #ff000000;
            }
        }

    }

    & select {
        width: 93px;
        text-align: center;
        padding-left: 10px;
    }

    & .timeIcon {
        position: absolute;
        top: 25px;
    }
}