@import '../../styles/propertySets.css';

.root {

  padding: 70px 0;
  @media (--viewportMedium) {
    padding: 30px 0;
  }
}

.title {
  @apply --marketplaceH1FontStyles;
  margin-top: 0;
  margin-bottom: 34px;
  text-align: center;

  @media (--viewportMedium) {
    margin-bottom: 40px;
    font-size: 30px;
  }
}

.steps {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  row-gap: 50px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(3, 1fr)
    /* flex-direction: row; */
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr)
    /* flex-direction: row; */
  }
}

.step {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--matterColorLight);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  height: 163px;
  padding: 15px;
  text-align: center;

  @media (--viewportMedium) {
    /* max-width: 210px; */
    /* margin-right: 24px; */
    /* width: calc(20% - 24px); */
  }
  @media(max-width: 460px){
    height: 150px;
  }
}

.step:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

.stepNumber {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  display: block;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--matterColorBlue);
  border-radius: 16px;
  color: var(--matterColorLight);
  text-align: center;
  font-size: 24px;
  font-weight: bold;

}

.stepTitle {
  font-size: 18px;
  font-weight: bold;
  margin-top: 30px;
  line-height: 24px;
  @media(max-width: 460px){
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
  }
  @media(max-width: 768px){
    font-size: 16px;
  }
}

.button {
  @apply --marketplaceButtonStyles;
  max-width: 327px;
  margin-bottom: 20px;
}

.roleTitle {
  display: grid;
  margin-bottom: 80px;
  grid-template-columns: repeat(2, auto);
  justify-content: center;
  @media (--viewportMedium) {
    flex-direction: row;
  }
  @media(max-width: 460px){
    grid-gap: 5px;
  }
}

.role {
  font-size: 24px;
  font-weight: 700;
  padding: 16px 80px;
  text-align: center;
  border: 1px solid #E3E3E3;
  background-color: var(--matterColorLight);
  border-radius: 50px;
  cursor: pointer;
  &:last-child {
    margin-left: -121px;
  }
  @media (--viewportMedium) {
    margin-right: -121px;
  }
  @media(max-width: 767px){
    padding: 16px 40px;
    font-size: 18px;
  }
  @media(max-width: 460px){
    padding: 5px 20px;
    font-size: 14px;
    margin-left: 0 !important;
    font-weight: 500;
  }
}

.activeRole {
  color: var(--matterColorLight);
  background-color: var(--marketplaceColor);
  z-index: 1;
}
