@import '../../styles/propertySets.css';

.openAIBriefGeneratorPanel {
	background: #f8f8f8
}

.error{
	color: red;
	width: 100%;
	margin: auto;
	transform: translateY(30px);
	font-weight: 100;
	padding: 0 10px;

	@media (min-width: 1200px){
		width: 800px;
		padding: 0;
	}
}

.browserBack {
	position: absolute;
	left: -1em;
	top: -3em;
	cursor: pointer;

	@media(max-width: 768px){
		display: none;
	}
}

.applicationBox {
	margin: 75px auto;
	width: 100%;
	padding: 0 40px;
	position: relative;

	@media(min-width:1024px) {
		width: 1090px;
	}

	@media(max-width: 768px) {
		padding: 0 20px;
		margin: 35px auto;
	}

	& h1{
		margin: 0;
	}
	& p{
		font-size: 15px;
		font-style: normal;
		font-weight: 400;
		line-height: 28px;
		letter-spacing: -0.25px;
		margin: 0;
		
		@media(max-width: 768px) {
			font-size: 14px;
		}
	}
}

.headerContainer {
	display: flex;
	padding-bottom: 2em;
	gap: 2em;
	position: relative;

	@media(min-width: 768px){
		border-bottom: 1px solid #D8D8D8;
	}

	@media(max-width: 768px){
		gap: 1em;
		padding-bottom: 1em;

		& img{
			width: 78px !important;
    	height: 78px !important;
		}
	}

	& img{
		width: 163px;
    height: 163px;
    border-radius: 5px;
		object-fit: cover;
	}

	& .headerWrapper{
		@media(max-width: 768px){
			& h1{
				font-size: 20px;
				font-weight: 500;
				line-height: normal;
			}
			& p{
				font-size: 12px;
				font-weight: 400;
				line-height: normal;
			}
			& button{
				display: none;
			}
		}
	}
	& h1{
		font-size: 36px;
		font-weight: 600;
		line-height: 44px;
		letter-spacing: -1.25px;
	}
	& button{
		position: absolute;
    bottom: 2em;
    width: 160px;
    letter-spacing: -0.267px;
    min-height: 42px;
	}
}

.headerButton{
	display: none;
	@media(max-width: 768px){
		display: block;
		min-height: 45px;
		margin: 20px 0 25px;
	}
}

.categories{
	margin: 1.5em 0;
	display: grid;
  grid-template-columns: repeat(4, 1fr);

	@media(max-width: 768px){
		display: flex;
		flex-direction: column;
		row-gap: 2em;
		& section{
			display: none;
		}
	}
	& section{

		& label{
			font-size: 12px;
			text-align: center;
			text-transform: uppercase;
			color: #B2B2B2;
		}

		& div{
			border-right: 1px solid gainsboro;
			min-height: 75%;
			padding: 0 15px;

			& .pricing{
				margin: 0;
				list-style: inside;

				& li{
					font-size: 12px;
					font-weight: 400;
					line-height: normal;
					letter-spacing: -0.1px;
				}
			}
		}
	}
}

.categoriesWrapper{
	display: flex;
	flex-wrap: wrap;
	gap: 5px;
	justify-content: center;
	padding: 0 !important;
	
	& span{
		background-color: rgba(216, 212, 212, 0.2);
		color: #E49800;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		padding: 3px 12px;
		border-radius: 12px;
		letter-spacing: 0.30px;
		height: 25px;
		display: flex;
		align-items: center;
	}

	@media(max-width: 768px){
		justify-content: flex-start;
	}
}

.mobileCategory{
	align-self: flex-start;
	@media(min-width: 768px){
		display: none;
	}

	& label{
		font-size: 12px;
		text-transform: uppercase;
		color: #B2B2B2;
		margin-bottom: 5px;
	}
	
	& .pricing{
		margin: 0;
		list-style: inside;

		& li{
			font-size: 13px;
			font-weight: 400;
			line-height: normal;
			letter-spacing: -0.1px;
		}
	}
}

.appImageContainer{
	display: flex;
	justify-content: center;
	overflow-x: auto;
	gap: 16px;

	& img{
		width: 100%;
    height: 350px;
    border-radius: 5px;
    object-fit: cover;

		@media(max-width: 768px){
			/* width: 327px; */
			height: 200px;
		}
	}
}

.alertModal {
	@media(max-width: 420px) {
		padding-top: 5em;
	}

	& h2 {
		font-size: 30px;
		font-weight: 600;
		line-height: 40px;
		letter-spacing: -0.9px;
		text-align: center;
	}

	& p {
		font-size: 20px;
		font-weight: 400;
		line-height: normal;
		letter-spacing: -0.9px;
		text-align: center;
	}
}

.buttonWrapper {
	display: flex;
	margin-top: 4em;
	justify-content: space-between;

	& .cancelButton{
    padding: 16px 0;
    font-size: 19px;
    cursor: pointer;
    font-weight: 400;
  }

	& button {
		width: 220px;
		font-size: 19px;
	}
}

.description{
	border-bottom: 1px solid #D8D8D8;
  padding-bottom: 1em;
}
.mainHeading {
	color: #1d1c1d;
	font-weight: 600;
	font-size: 15px;
	line-height: 1.46668;
	margin-top: 0;
	margin-bottom: 6px;

	& .subMainHeading{
		margin-left: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.25px;
    color: #858585;
	}
}

.summaryBlock{
	border-bottom: 1px solid #D8D8D8;
  padding-bottom: 2em;

	& h3{
		font-size: 20px;
		font-weight: 500;
		line-height: 28px;
		letter-spacing: -0.25px;
	}
	& p{
		line-height: 28px;
    margin-bottom: 10px;
		@media(min-width: 768px){
			width: 80%;
		}
	}
}
.descriptionDetail {
	margin-top: 20px;
	
	@media(min-width: 768px){
		width: 80%;
	}

	& p {
		margin-bottom: 20px;
		font-size: 15px;
		margin-top: 0;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -0.25px;
	}

	& ul {
		list-style-type: disc;
		padding-left: 22px;

		& li {
			margin-bottom: 10px;
			font-size: 15px;
			font-weight: 400;
			line-height: 28px;
			letter-spacing: -0.25px;
		}
	}
}

.informationBlock{
	display: grid;
	grid-template-columns: 1fr 1fr;
	margin-bottom: 15px;

	& a{
    display: block;
		margin-bottom: 12px;
	}

	@media(max-width: 768px){
		display: block;
	}
}

.lovedAppsContainer{
	display: flex;
  gap: 30px;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: 3em;

	@media(max-width: 768px){
		flex-wrap: nowrap;
		overflow-x: auto;
		gap: 15px;
	}
}

.newAppBox{
	width: 475px;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-top: 15px;
	cursor: pointer;

	& h3{
		font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.25px;
	}
	& h4{
		margin: 0;
	}
	& img{
		width: 100%;
		height: 288px;
		border-radius: 5px;
	}

	@media(max-width: 768px){
		min-width: 90%;
		& p{
			font-size: 12px;
			line-height: normal;
		}
		& img{
			height: 200px;
		}
	}
}

.successModalContainer{
	& h2{
		text-align: center;
		font-size: 30px;
    line-height: 40px;
    letter-spacing: -0.9px;
	}
	& p{
		text-align: center;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: -0.9px;
	}
}
.sessionsWrapper{
	border-bottom: 1px solid #D8D8D8;
  padding: 20px 0;

	& .sessionContainer{
		border: 1px solid gainsboro;
		border-radius: 5px;
		padding: 0px 16px;
		
		& .session{
			display: flex;
			column-gap: 3em;
			border-bottom: 1px solid gainsboro;
			margin: 12px 0;

			& .date{
				font-size: 16px;
				font-weight: 400;
				line-height: 28px;
				letter-spacing: -0.25px;
				display: inline-block;
    		width: 100px;
			}

			& .viewInput{
				height: 22px;
				cursor: pointer;
				&:hover{
					border-bottom: 1px solid #3D3D3D;
				}
			}

			& .processingText{
				color: #858585;
				cursor: unset;
			}

			&:last-child{
				border-bottom: none;
			}
		}
	}
}
.changePlanContent {
	& .headingTitle {
		margin-top: 0;
		color: #3D3D3D;
		font-size: 30px;
		font-style: normal;
		font-weight: 600;
		line-height: 40px;
		letter-spacing: -0.9px;
		margin-bottom: 30px;
	}

	& .downgradeHeading {
		color: #3D3D3D;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
		padding: 0;
		margin: 0 0 26px 0;
	}

	& .radioWrapper {
		display: flex;
		gap: 8px;
		margin-bottom: 20px;

		& .radioButton {
			& input[type='radio']:after {
				width: 15px;
				height: 15px;
				border-radius: 15px;
				top: -7px;
				left: -1px;
				position: relative;
				background-color: #d1d3d1;
				content: '';
				display: inline-block;
				visibility: visible;
				border: 2px solid white;
			}

			& input[type='radio']:checked:after {
				width: 15px;
				height: 15px;
				border-radius: 15px;
				top: -7px;
				left: -1px;
				position: relative;
				background-color: #ffa500;
				content: '';
				display: inline-block;
				visibility: visible;
				border: 2px solid white;
			}

			& .radioCheck{
				cursor: pointer;
			}
		}

		& .radioDetails {
			margin-top: -5px;

			& label {
				color: #3D3D3D;
				font-size: 14px;
				font-style: normal;
				font-weight: 600;
				line-height: 20px;
				cursor: pointer;
			}

			& .radioDescription {
				color: #3D3D3D;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px;
			}
		}
	}
}
.openAIAppsAssistedButtonActions {
	display: flex;
	margin-top: 40px;
	justify-content: space-between;

	& .cancelLink {
		padding: 0;
		margin: 0;
		border: none;
		width: fit-content;
		color: #3D3D3D;
		font-family: Poppins;
		font-size: 20px;
		font-style: normal;
		font-weight: 400;
		line-height: 32px;
		letter-spacing: -0.111px;
	}

	& .continueButton {
		width: 228px;
	}
}

.viewContentAnalysisForm{
	@apply --marketplaceModalBaseStyles;
	flex-basis: 756px;
	border-bottom: none;
	min-height: auto;
	background: #f8f8f8;
}

.formContainer{
	background-color: #fff;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px !important;
	width: 100%;
	margin: 2em auto 4em;
	padding: 40px;
	border: 1px solid #c2c2c2;
	border-radius: 5px;

	& h2{
		font-size: 36px;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -1.25px;
		margin-top: 0;
	}

	& .stepsLabel{
		margin-bottom: 2em;
	}
}