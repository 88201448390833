.files {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;
}

.pdfBox {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  /* align-items: flex-end; */

  @media (max-width: 768px) {
    align-items: flex-start;
  }

  & .pdfImage {
    & img {
      width: 30px;
      height: 41px;
    }
  }
}

.pdfRight {
  /* margin-bottom: 10px; */

  & .pdfName {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.0875px;
    color: #3d3d3d;

    @media (max-width: 768px) {
      font-size: 11px;
    }
  }

  & .pdfDate {
    font-weight: 400;
    font-size: 10px;
    line-height: 21px;
    letter-spacing: -0.0875px;
    color: #b2b2b2;

    & .fileSize {
      margin-left: 5px;
      position: relative;
      padding-left: 8px;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        background-color: #b2b2b2;
        height: 3px;
        width: 3px;
        top: 5px;
        border-radius: 50%;
      }
    }
  }
}
