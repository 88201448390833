@import '../../styles/propertySets.css';

.root {
  & .subTitle {
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    text-transform: uppercase;
    text-decoration: underline;
  }

  & p {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    text-align: justify;
  }
  & li {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
  }

  & strong {
    font-weight: 600;   
    line-height: 24px;
  }

  & em {
    text-decoration: underline;
    font-style: normal;
    font-size: 15px;
    line-height: 24px;
  }

  & ul {
    padding-left: 10px;
  }

  & .listStyleNone {
    padding-left: 10px;
    list-style: none;

    & li {
      margin-bottom: 20px;
    }
  }

  & ol {
    padding-left: 10px;
  }
}
