.similarProfiles {
  padding: 30px 20px;
  border-radius: 10px;
  margin-bottom: 30px;
  background-color: #fff;
  box-shadow: 0px 8px 8px 5px #0000000d;

  @media (max-width: 920px) {
    box-shadow: none;
    border-radius: 0;
    margin-bottom: 20px;
  }

  & .languageHeading {
    margin-top: 0;
    font-size: 22px;
    color: #3d3d3d;
    font-weight: 500;
    line-height: 33px;
    margin-bottom: 0;
    letter-spacing: -0.9375px;
  }

  & .similarProfileList {
    margin-top: 20px;

    & .similarList {
      gap: 16px;
      display: flex;
      cursor: pointer;
      margin-top: 20px;
      align-items: center;
			position: relative;

      &:first-child {
        margin-top: 0;
      }

      & .similarIcon {
        position: relative;

        & .status {
          bottom: 0;
          right: 2px;
          width: 14px;
          height: 14px;
          position: absolute;
          border-radius: 50%;
          border: 2px solid #ffffff;
        }

        & img {
          width: 64px;
          height: 64px;
          object-fit: cover;
          border-radius: 50%;
        }

        & .profileIcon {
          width: 64px;
          height: 64px;
          border-radius: 50%;
          display: flex;
          background: linear-gradient(180deg, #7ac9ff 0%, #0b96f5 100%);
          justify-content: center;
          align-items: center;
          color: white;
          font-size: 24px;
          font-weight: 500;
          letter-spacing: 0.48px;
        }
      }

      & .similarRight {
        & .headingName {
          font-size: 18px;
          font-weight: 600;
          line-height: 20px;
          margin-bottom: 6px;
          letter-spacing: -0.11249999701976776px;
        }

        & .customerExp {
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: -0.08888888359069824px;
        }
      }

			&:hover{
				& .listingCard{
					display: block;
				}
			}

			& .listingCard{
				display: none;
				position: absolute;
				top: 60px;
				left: 10em;
				border-radius: 10px;
				background: white;
				width: 310px;
				box-shadow: 1px 1px 8px 0px gray;
				z-index: 1;
			}
    }
  }
}

.cardInfo {
	padding: 0 10px;
}