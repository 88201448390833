.filterSearchForm{
    flex: 2;
}
.formWrapper {
    /* display: flex;
    justify-content: center;
    align-items: center;
    width: 50vw; */

    & .formSerachBox {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border: 1px solid lightgray;
        padding: 5px 10px;
        border-radius: 4px;
        background: white;
        box-shadow: 0px 0px 1px 0px rgb(171 167 167 / 22%);

        & div {
            width: 100%;
        }

        & input {
            border: none;
        }

        & span {
            cursor: pointer;

            & svg {
                & path {
                    stroke: orange
                }
            }
        }
    }
}