.title {
	font-size: 30px;
	line-height: 40px;
	letter-spacing: -0.9px;
	margin-bottom: 30px;
}

.paymentContainer {
	border: 1px solid #E6E6E6;
	padding: 10px 15px;
	width: 94%;
  transform: translateX(26px);
	margin: 5px 0 15px;

	& p {
		margin: 0;
		font-size: 14px;
		font-weight: 400;
		line-height: 24px;
	}

	& .feeTooltip {
		text-decoration-line: underline;
		cursor: pointer;
		display: inline-block;
		position: relative;

		&:hover {
			&::before {
				visibility: visible;
			}
		}

		&::before {
			visibility: hidden;
			content: 'The InsightGig fee is 10% of the annual salary or the value of the deal. You are being charged 10% because you are on the Small Team subscription plan. This fee helps us to run our platform. ';
			position: absolute;
			border-radius: 4px;
			background: #FFF;
			box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.30);
			padding: 15px 20px;
			text-decoration: none;
			font-size: 12px;
			line-height: normal;
			letter-spacing: 0.25px;
			top: -9em;
			left: -23em;
		}
	}

	& .row {
		padding-top: 12px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

.totalRow {
	color: #979797;
	font-size: 12px;
	font-weight: 400;
	border-bottom: 1px solid #E6E6E6;
	padding-bottom: 6px;
}

.paymentMethodLabel{
	margin-top: 2em;
}

.buttonWrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	margin-top: 2em;

	& .buttonCancel {
		font-size: 20px;
		font-weight: 400;
	}

	& .buttonSubmit {
		width: 228px;
	}
}

.disabled {
	opacity: 0.5;
	pointer-events: none;
	text-decoration: line-through;
}