.buttonSection {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  gap: 8em;

  @media (max-width:500px) {
    flex-wrap: wrap;
    gap:10px;
  }
}

.buttonSave {
  width: 147px;
}

.buttonDelete {
  width: 147px;
  background-color: var(--matterColorLight);
  border: 1px solid var(--successColorLight);
  color: var(--marketplaceColorLight);

  &:hover {
    background-color: var(--successColorLight);
    color: var(--marketplaceColorLight);
  }
}

.titleModal {
  font-weight: var(--fontWeightSemiBold);
  font-size: 30px;
  line-height: 40px;
}

.description {
  border-bottom-color: var(--successColor);
  margin-top: 38px;
  
  & textarea{
    min-width: 100%;
  }
}

.dateContainer {
  margin-top: 38px;
  display: flex;
  justify-content: space-between;

  @media(max-width: 560px) {
    flex-wrap: wrap;
    gap: 26px;
    margin-top: 26px;
  }
}

.addWork {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FCFCFC;
  border: 2px dashed var(--matterColorAnti);
  box-sizing: border-box;
  border-radius: 2px;
  height: 99px;
  width: auto;

  @media (--viewportMedium) {
    margin-top: 47px;
    height: 99px;
    width: 450px;
  }

}

.textAddWork {
  color: var(--successColorDark);
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  line-height: 20px;
}

.submitButton {
  margin: 20px 0 30px !important;
  flex-shrink: 0;
  display: inline-block;
  width: 241px !important;

  @media(max-width: 460px) {
    width: 100% !important;
  }
}

.containerCheckBox {
  user-select: none;
  padding-top: 24px;
  width: 152px;

  @media(max-width: 560px) {
    width: 100%;
  }
}

.inputDate {
  width: 180px;

  @media(max-width: 460px) {
    width: 100%;
  }

  & :global(.ant-picker) {
    padding: 6px 11px 0;

    @media(max-width: 460px) {
      width: 100%;
    }
  }
}

.disabledInputDate {
  width: 180px;
  pointer-events: none;

  & :global(.ant-picker) {
    padding: 6px 11px 0;
  }

  & :global(.ant-picker) {
    border-bottom: 3px solid var(--matterColorNegative);
  }
}

.disabledInputDate label,
.disabledInputDate input {
  color: var(--matterColorNegative);
}

.disabledDate {
  pointer-events: none;
  width: 180px;
  color: var(--matterColorNegative);

  & :global(.DateInput_input) {
    border-bottom-color: var(--fontWeightSemiBold);
  }
}

.blockInfo {
  position: relative;
  padding: 16px 0;
  background: #FCFCFC;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.177778px;

  @media (--viewportMedium) {
    margin: 15px 0;
    width: 450px;
  }
}

.blockTitle {
  font-weight: var(--fontWeightBold);
}

.blockCompany {
  margin-top: 3px;
  font-weight: var(--fontWeightRegular);
}

.blockExperience {
  margin-top: 3px;
  color: #858585;
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
}

.blockDescription {
  margin-top: 3px;
  font-weight: var(--fontWeightRegular);
}

.penEdit {
  position: absolute;
  top: 14px;
  right: 14px;
  cursor: pointer;
}