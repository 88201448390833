.transcriptionForm{
    padding: 40px;

    @media(max-width: 768px) {
        padding: 24px;
    }
}
.container{
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px !important;
    width: 100%;
    margin: 0px auto;
    padding: 40px 100px;
    border: 1px solid #c2c2c2;
    border-radius: 5px;

    @media(min-width: 1200px) {
        width: 800px;
    }

    @media(max-width: 768px) {
        padding: 20px;
    }
}

.briefGenerator{
    margin-bottom: 40px;

    & .generatorTitle{
        font-size: 36px;
        font-weight: 600;
        line-height: 56px;
        letter-spacing: -1.25px;
    }

    @media(max-width: 768px){
        margin-bottom: 3em;
        
        & .generatorTitle{
            font-size: 24px;
            line-height: 28px;
        }
    }
}
.actionButtons{
    display: flex;
    margin-top: 3em;
    gap: 10px;
    justify-content: space-between;

    @media(max-width: 460px) {
        flex-wrap: wrap;
        width: 100%;
    }
    
    & button{
        min-height: 45px;
        max-height: 45px;
        width: 165px;
        font-size: 14px;

        @media(max-width: 460px) {
            width: 100%;
        }
    }
    & .submitButton{
        &:disabled,[disabled]{
          background-color: #eeeded;
          color: #666666;
        }
    }
    & .disabledSubmitButton{
        background-color: #eeeded;
        color: #666666;
    }
}
.attachment{
    display: flex;
    column-gap: 5px;
    justify-content: space-between;
    
    & .addImageInput {
        display: none;
    }

    & .close{
        cursor: pointer;
    }
    & .attachmentLabel{
        width: 100%;
        box-sizing: border-box;
        border: 2px dashed gainsboro;
        border-radius: 2px;
        padding: 25px 20px;
        display: flex;
        flex-direction: column;
        text-align: center;
        cursor: pointer;
        align-items: center;
        gap: 10px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.177778px;

        @media(max-width: 768px){
            padding: 5px 20px;
            font-size: 12px;
        }
      
        & .dragDrop {
            color: #3D3D3D;
        }
      
        & .docType {
            color: #B2B2B2;
        }
    }
}
.inputForm{
    margin-bottom: 2em;
    & textarea{
        /* height: 30px; */
        padding: 5px 0;
        font-size: 14px !important;
        font-weight: 400;
        min-width: 100%;

        &::placeholder{
            font-size: 14px !important;

            @media(max-width: 768px){
                font-size: 12px !important;
            }
        }
    }
}
.supportText{
    color: rgb(35, 31, 32);
    font-weight: normal;
    font-size: 0.675rem;
    margin-top: 10px;
    margin-bottom: 20px;
    line-height: 1;
}
.errorMessage{
    color: var(--failColor);
    font-weight: 500;
    margin: 0;
}

.attachmentFieldSeparator{
    text-align: center;
}
.urlInputField{
    margin-bottom: 5px!important;
}
.urlInputContainer{
    position: relative;
}
.loader {
    width: 30px;
    padding: 4px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #0B96F5;
    --_m: 
      conic-gradient(#0000 10%,#000),
      linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
            mask: var(--_m);
    -webkit-mask-composite: source-out;
            mask-composite: subtract;
    animation: l3 1s infinite linear;
}
@keyframes l3 {to{transform: rotate(1turn)}}

.urlInputChecks{
    position: absolute;
    right: 0;
    top: 23px;
    background-color: #fff;
}

.checkSign{
    margin: auto 5px;
    height: 20px;
    width: 20px;
}

.checkboxContainer{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}

.checkbox{
    width: auto;
    cursor: pointer;
}