.root {
  margin: 0;
  padding: 0;
  border: none;
}

.list {
  margin: 0;
}

.twoColumns {
  @media (--viewportMedium) {
    columns: 2;
  }
}
.optionLabel{
  font-weight: 300;
}
.item {
  padding: 2px 0;
  max-width: 315px;
  page-break-inside: avoid;

  @media (--viewportMedium) {
    padding: 4px 0;
  }
}
