.root {}

.inputSuccess {
  border-bottom-color: var(--successColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.select {
  color: var(--matterColorAnti);
  border-bottom-color: var(--textFieldColor);
  padding-right: 20px;

  &>option {
    color: var(--matterColor);
  }

  &>option:disabled {
    color: var(--matterColorAnti);
  }

  &:focus,
  &:hover {
    border-bottom-color: var(--matterColor);
  }

  &:disabled {
    border-bottom-color: var(--matterColorNegative);
    color: var(--matterColorNegative);
    cursor: default;
  }
}

.selectSuccess {
  color: var(--matterColor);
  border-bottom-color: var(--textFieldColor);
}

.selectError {
  border-bottom-color: var(--failColor);
}