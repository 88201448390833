.root {
}

.select {
  color: var(--matterColorAnti);
  border: solid 1px var(--matterColorNegative);
  padding: 0px 15px;
  border-radius: 10px;
  height: 45px;
  padding-right: 40px;

  & > option {
    color: var(--matterColor);
  }

  & > option:disabled {
    color: var(--matterColorAnti);
  }
}

.selectSuccess {
  color: var(--matterColor);
  border-color: var(--successColor);
}

.selectError {
  border-color: var(--failColor);
}
.multiSelectBox {
  /* & :global(#react-select-3-listbox) {
    max-height: 600px !important;
    overflow: hidden;
    position: relative;
    z-index: 1000;
    padding-bottom: 30px;
    & div {
      max-height: 1000px !important;
    }
  } */
  & > div {
    /* background: #FFFFFF; */
    border-radius: 6px;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    /* height: 48px; */
    color: #525961;

    /* border-color: rgb(247, 180, 49); */
    /* &:not(:last-child) {
      min-height: 61px;
      @media (max-width: 767px) {
        min-height: 36px;
      }
    } */
    /* & > div {
      padding: 5px 8px;
    } */
    & input {
      height: auto;
    }
  }

  & > span + div {
    & > div:first-child {
      & > div:not(:last-child) {
        background: #fcf0d9;
        border-radius: 45px;
        width: max-content;
        display: inline-flex;
        align-items: center;
        padding: 0 4px;
        & div[role='button'] {
          border: 1px solid #98a8b7;
          border-radius: 50%;
          height: 20px;
          width: 20px;
        }
      }
    }
  }
}

.parent {
  display: flex;
  border-bottom: 1px solid;
}

.inputWrapper {
  display: flex;
  align-items: center;
  gap: 14px;
  height: 18px;
  & input {
    width: 15px;
    height: 15px;
  }
  & label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #445463;
    white-space: nowrap;
    margin: 0;
    padding: 0;
  }
  & input[type='checkbox'] {
    accent-color: rgb(247, 180, 49);
  }
}
