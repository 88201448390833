.paymentDetailsContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  column-gap: -200px;

  & h3 {
    font-weight: 700;
    line-height: 0px;
  }
}

.paymentDetails {
  font-family: var(--font-family);
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  /* line-height: 0px; */

}

.commissionTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -0.9px;
  color: #3D3D3D;
  margin-top: 0;
  margin-bottom: 0;
}

.commissionBox {


  & .progressBar {
    background: #fefbf4;
    padding: 20px;

    & .commissionAmount {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: #3D3D3D;
    }

    & .commissionDay {
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
      color: #858585;
    }

    & li {
      &:nth-child(1) {
        margin-bottom: 20px;
      }
    }

    & .pendingProgress {
      position: relative;
      padding-left: 23px;

      &:nth-child(1) {
        &:after {
          content: "";
          width: 1px;
          height: 115%;
          position: absolute;
          left: 7px;
          top: 18px;
          background-color: #3D3D3D;
        }
      }

      &:before {
        content: "";
        width: 15px;
        height: 15px;
        position: absolute;
        left: 0;
        top: 4px;
        background-color: #3D3D3D;
      }
    }

    & .completeProgress {
      position: relative;
      padding-left: 23px;

      &:nth-child(1) {
        &:after {
          content: "";
          width: 1px;
          height: 45px;
          position: absolute;
          left: 7px;
          top: 15px;
          background-color: #3D3D3D;
        }
      }

      &:before {
        content: "";
        background-image: url(../../assets/checkpay.png);
        position: absolute;
        background-color: #2ECC71;
        height: 15px;
        z-index: 9;
        width: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-position: center;
        left: 0;
        top: 4px;
      }
    }
  }

  & .totalCost {
    background: #fefbf4;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 6px;

    & .totalCostTitle {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #3D3D3D;
    }

    & .totalCostText {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #3D3D3D;
    }
  }
}

.lineTotal {
  background: #FFFFFF;
  border: 1px solid #E6E6E6;
  margin-top: 20px;
  padding: 20px;
}

.lineTotalMain {
  margin-top: 40px;
}