@import '../../styles/propertySets.css';

.content {
  @media (--viewportLarge) {
    max-width: 756px;
  }

  & h1 {
    @apply --marketplaceH1FontStyles;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 56px;
    margin: 0 0 40px;
  }
}

.root {
  & .subTitle {
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    text-transform: uppercase;
    margin-top: 40px;
  }

  & p {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    text-align: justify;
  }

  & li {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 10px;
    text-align: justify;
  }

  & strong {
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
  }

  & ol {
    padding-left: 30px;
  }

  & ul {
    list-style-type: disclosure-closed;
    padding-left: 30px;
  }
}