@import '../../styles/propertySets.css';

.notificationWrapper {
  background: #ffffff;
  padding: 30px 40px;
  border: 1px solid #e6e6e6;
  /* margin-bottom: 20px; */

  @media (max-width: 768px) {
   padding: 15px 20px;
 }

  & .notificationBarContainer {
      align-items: center;
      display: grid;
      grid-template-columns: 30% 69% 1%;

    & h4 {
      color: #3d3d3d;
      font-family: 'Poppins';
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
    & h3{
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    @media (max-width: 768px) {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: flex-start;

      & h4{
        font-size: 14px;
        line-height: 20px;
      }
      /* padding: 20px 25px; */
    }
  }

  & .dropdownContent {
    max-height: 128px;
    overflow-y: auto;
    width: 372px;
    margin: auto;

    & .dropdownWrapper ,.emptyDropdown{
      color: #000;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.087px;
      cursor: pointer;
      padding: 6px;
      border-radius: 6px;

      &:hover {
        background-color: #b2b2b2e3;
        font-weight: 500;
        -webkit-transition: background-color 0.5s ease-out;
        -moz-transition: background-color 0.5s ease-out;
        -o-transition: background-color 0.5s ease-out;
        transition: background-color 0.5s ease-out;
      }
    }

    & .formattedTime {
      color: #b2b2b2;
      font-size: 10px;
      }
  }
}

.notificationBarTextAndIcons{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  
  & .notificationIcon{   
    cursor: pointer;
  }
}