.affiliatedFirmPanel {
    padding-left: 20px;
    @media(max-width: 1024px) {
        padding:  0 20px;
    }
}

.affiliatedFirmPanelBox {
    margin-top: 0;
    padding: 43px 192px 43px 58px;
    border: 1px solid #E6E6E6;
    background: #fff;

    @media(max-width: 1220px) {
        padding: 30px;
    }

    @media(max-width: 500px) {
        padding: 15px;
    }
}

.firmTitle {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.11249999701976776px;
    margin-bottom: 10px;
    color: #B2B2B2;
}

.firmDescription {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.08749999850988388px;
    color: #3D3D3D;
}

.firmSerachBox {
    position: relative;
}

.affiliatedFirmTitle {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.11249999701976776px;
    color: #B2B2B2;
    margin-top: 0;
    margin-bottom: 20px;
}

.affiliatedFirmList {
    margin-top: 60px;
}

.tableRow {
    width: 100%;

    & table {
        & tr {
            background-color: #fff;
        }

        & th {
            border: none;
            padding: 0;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0px;
            color: #858585;
            padding-bottom: 10px;
        }

        & tbody {
            & tr {
                border-bottom: 1px solid #E6E6E6;
            }
        }

        & td {
            border: none;
            padding: 10px 0;

            &:nth-child(1) {
                width: 55%;
            }

            & .imageAndFirmName {
                display: flex;
                align-items: center;

                & img {
                    width: 38px;
                    height: 38px;
                    border: 1px solid #dadada;
                    object-fit: cover;
                    border-radius: 50%;
                    margin-right: 10px;
                    min-width: 38px;
                }

                & .firmBox {
                    position: relative;
                    margin-right: 50px;
                }

                & .firmName {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: -0.08888888359069824px;
                    color: #3D3D3D;
                    position: relative;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }

                & .notificationsDot {
                    position: absolute;
                    top: -16px;
                    right: -15px;
                }
            }

            & .inviteStatus {
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: -0.3333333432674408px;
                color: #B2B2B2;
            }

            & .firmButton {
                & button {
                    width: fit-content;
                    padding: 0 10px;
                    border: 1px solid #E6E6E6;
                    min-height: 25px;
                    width: fit-content;
                    padding: 0 10px;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 18px;
                    letter-spacing: -0.3333333432674408px;
                    text-align: center;
                }

                & .removeButton {
                    border: 1px solid #E6E6E6;
                    color: #FF0000;
                    background: #fff;

                    &:hover {
                        color: #ca3f3f;
                        border: 1px solid #c2bdbd;
                    }
                }

                & .approveButton {
                    border: 1px solid hsl(145, 60%, 38%);
                    color: #fff;
                    background: #2ECC71;

                    &:hover {
                        color: #fff;
                        border: 1px solid #2ECC71;
                    }
                }
            }
        }
    }
}

.firmModalTitle {
    margin: 0;
    font-size: 30px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: -0.8999999761581421px;
    text-align: center;
    color: #3d3d3d;
}

.modalBottomButtons {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;

    & .cancelButton {
        border: none;
        box-shadow: none;
        background-color: #fff0;
        color: #3d3d3d;
        font-family: Poppins;
        font-size: 20px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: -0.1111110970377922px;
        text-align: left;
    }

    & .yesButton {}
}