@import '../../styles/propertySets.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  height: 185px;
  position: relative;
  transition: var(--transitionStyleButton);
/* 
  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowListingCard);
  } */
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  border-radius: 18px 18px 0 0;
  background: #032943;
  height: 140px;
  display: flex;
  padding-top: 24px;
  padding-left: 15px;
  position: relative;

  & div, a{
    width: 150px;
    height: 150px;
    border: 2px solid white;
    pointer-events: none;

    & img{
      object-fit: cover;
    }
  }
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: 10px 10px 0 0;
  height: 100%;
  object-fit: cover;

  @media(max-width: 768px) {
    border-radius: 0;
  }
}

.info {
  /* Layout */
  display: flex;
  flex-direction: row;
  padding: 12px 15px;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-right: 18px;
}

.priceValue {
  /* Font */
  @apply --marketplaceH3FontStyles;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.title {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);
  line-height: 20px;
  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.authorTitle{
  display: inline-block;
  min-height: 45px;
}

.experience {
  padding: 8px 3px 2px;
  font-weight: var(--fontWeightRegular);
  color: var(--matterColorDark);
  font-size: 12px;
  line-height: 18px;
}

.certificateInfo {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.styleElement {
  display: flex;
  align-items: center;
  padding: 0 7px;
  /* height: 26px; */
  border-radius: 12px;
  margin-right: 6px;
  margin-bottom: 4px;
  text-transform: capitalize;
}

.checkBoxBlock {
  /* word-wrap: break-word;
  flex-wrap: wrap;
  display: flex;
  margin: 5px 0 0; */
  
  position: relative;
  max-height: 55px;
  /* Adjust this value to fit two lines */
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 5px 0 0;
}

.styleTitleCategory {
  /* width: max-content; */
  /* height: 20px; */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  padding: 3px 0;
  font-size: 12px;
  line-height: 18px;
  font-weight: var(--fontWeightRegular);
}

.borderStyleDomainExpertise {
  background-color: rgba(246, 180, 48, 0.2);
}

.borderStyleIndustrySector {
  background-color: rgba(11, 150, 245, 0.2);
}

.colorStyleDomainExpertise {
  color: rgba(228, 152, 0, 1);
}

.colorStyleIndustrySector {
  color: rgba(0, 129, 217, 1);
}

.reviewStar {
  width: 12px;
  height: 12px;
  margin-right: 2px;
}

.reviewsSubHeading {
  display: flex;
  margin-top: 7px;
  align-items: baseline;
  min-width: 100px;

  & span {
    margin-right: 5px;
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 18px;
    font-weight: var(--fontWeightRegular);
  }

  & .reviewTotalInfo {
    margin: 0;
    color: var(--matterColorAnti);
  }

  & .reviewTotalCount {
    color: var(--matterColor);
    margin-right: 7px;
  }
}

.reviewStars {
  display: block;
  margin-top: 4px;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.author {
  display: flex;
  justify-content: space-between;
}

.authorName {
  font-size: larger;
  font-weight: 600;
  margin-top: 0;
}

.location {
  margin: 8px 0 8px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: grey;
  font-size: 14px;
}

.favorite {
  cursor: pointer;

  &>span {
    line-height: 0;

    &>svg {
      width: 16px;
      height: 16px;
    }
  }
}

.authorIcon {
  position: relative;

  & a, div{
    right: 20px;
    top: -80px;
    position: absolute;
  }
}

.headingBoxMain {
  display: flex;
  justify-content: space-between;
}

.favIconBox {
  &>span {
    line-height: 0;

    &>svg {
      width: 16px;
      height: 16px;

      & path {
        fill: red;
      }
    }
  }
}

.firmLogo{
  height: 40px;
  width: 40px;
  border-radius: 50%;
  position: absolute;
  right: 15px;
  top: 24px;
  background: white;
  object-fit: fill;
  border: 2px solid white;
}