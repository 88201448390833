.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
    max-width: 564px;
  }
}

.error {
  color: var(--failColor);
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.title {
  composes: formMargins;

  & textarea {
    min-width: 100%;
  }
}

.description {
  composes: formMargins;
  flex-shrink: 0;

  & textarea {
    min-width: 100%;
  }
}

.certificate {
  composes: formMargins;
}

.submitButton {
  width: 241px !important;
  display: inline-block;
  margin: 30px 0 0px !important;

  @media(max-width: 1024px) {
    margin-bottom: 30px !important;
  }

  @media(max-width: 460px) {
    width: 100% !important;
  }
}