.contactUsForm {
    display: flex;
    border-radius: 20px;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 1rem;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(4.6px);
    -webkit-backdrop-filter: blur(4.6px);
    border: 1px solid rgba(255, 255, 255, 0.74);
    @media(min-width: 500px){
        width: 450px;
        margin: 0 auto;
    }
}
.mainForm{
    padding: 0 24px;
    width: 100%;
    /* margin: 0  auto; */
}
.submitButton {
    width: 20vw;
    padding: 1rem;
    margin-top: 1rem;
}

.fieldTextInput {
    margin: 1rem;
}

.phoneNumberLabel {
    margin-left: 1rem;
    margin-bottom: -1rem;
}

.submitButtonWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}
.invalidPhoneValue{
    color: red;
}
.successMessage{
    /* margin-bottom: 30px; */
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
    position: relative;
    padding: 0.75rem 1.25rem;
    /* margin-bottom: 1rem; */
    border: 1px solid transparent;
    border-radius: 0.25rem;
    text-align: center;
}
.meetingError {
    color: red;
    line-height: 22px;
    font-size: 16px;
    border-color: #c3e6cb;
    position: relative;
    padding: 0.75rem 1.25rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    text-align: center;
  }