.contentWrapper{
    & h2{
        font-size: 30px;
        font-weight: 600;
        line-height: 40px;
        letter-spacing: -0.9px;
        margin-bottom: 30px;
    }
    & p{
        display: inline;
        margin: 0;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
    }
    & label{
        width: 30%;
    }
    .row{
        display: flex;
        align-items: center;
        margin-bottom: 15px;
    }
}
.buttonWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-top: 5em;

    & .buttonCancel{
      font-size: 20px;
      font-weight: 400;
    }

    & .buttonSubmit{
      width: 228px;
    }
}