.questionnaireForm{
    padding: 20px 100px;
    background: white;
    width: fit-content;
    margin: auto;

    @media(max-width: 768px) {
        padding: 24px;
    }
}
.loadingdiv{
    height: 100vh;
    opacity:80%;
}
.loadingdivContainer{
    padding: 120px 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
}
.progressBarBorder{
    border:1px solid #f1f1f1;
    border-radius: 10px;
    height: 17px;
    width: 100%;
    padding:2px
}
.progressBarFill{
    border-radius: 10px;
    background-color: #f6b431;
    height: 11px;
}
.progressBarContainer{
    margin-bottom: 24px;
}
.steps{
    font-weight: 600;
    margin: 0;
}
.guidanceText{
    margin: 0;
    font-weight: normal;
}
.stepsContainer{
    margin-bottom: 32px;
}
.closeButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    margin-bottom: 32px;

    & span {
        font-weight: 700;
        font-size: 14px;
        line-height: 30px;
        letter-spacing: 1.5px;
    }

    & svg {
        fill: black;
        scale: 1.2;
        margin-left: 7px;
    }
}
.container{
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px !important;
    width: 100%;
    margin: 0 auto;
    padding: 40px 100px;
    border: 1px solid #c2c2c2;
    border-radius: 5px;

    @media(min-width: 1200px) {
        width: 800px;
    }

    @media(max-width: 768px) {
        padding: 20px;
    }
}
.responseContainer{
    width: 100%;
    margin: 0 auto;
    padding: 40px 0;
    border-radius: 5px;

    @media(min-width: 1200px) {
        width: 800px;
    }

    @media(max-width: 768px) {
        padding: 10px;
    }
}
.fieldTopMargin{
    /* margin-top: 40px; */
}
.horizontalRule{
    margin-top: 40px;
    background-color:#E6E6E6;
    height: 1px;
    border: none;
}
.questionAnswerContainer{
    background-color: #fff;
    border: 1px solid #E6E6E6;
    padding: 20px 30px;
    border-radius: 4px;
}
.introductionText{
    font-weight:200;
    margin:0;
    font-size:14px
}
.questionText{
    margin:0;
    font-size:14px
}
.answersContainer{
    display: flex;
    align-items: center;
}
.radioInput{
    width: auto;
    margin-right: 10px;
}
.tableHeadings td{
    text-align: center;
}
.exportButtons{
    display: flex;
    justify-content: flex-end;
  
    & button{
      border: 1px solid grey;
      height: 36px;
      padding: 0px 15px;
      border-radius: 3px;
      margin-left: 10px;
      transition: all .3s ease;
  
      &:hover{
        transform: scale(1.03);
        background-color: orange;
        border: none;
        color: white;
      }
    }
}
.briefGenerator{
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .generatorTitle{
        font-size: 36px;
        font-weight: 600;
        line-height: 56px;
        letter-spacing: -1.25px;
    }

    @media(max-width: 768px){
        margin-bottom: 3em;
        
        & .generatorTitle{
            font-size: 24px;
            line-height: 28px;
        }
    }
}
.row{
    margin-bottom: 30px;
    & label{
        margin-bottom: 5px;
    }
    & .inputBox{
        & input,
        & textarea {
            min-width: 100%;
            max-width: 100%;
            font-size: 14px !important;
            line-height: 22px;
            padding-bottom: 6px;

            &::placeholder{
                font-size: 14px;
            }
        }
    }
}
.fieldSelector{
   & select{
    padding: 0;
   }
}
.actionButtons{
    display: flex;
    margin-top: 3em;
    margin-bottom: 2em;
    gap: 10px;
    justify-content: space-between;

    @media(max-width: 460px) {
        flex-wrap: wrap;
        width: 100%;
    }
    
    & button{
        min-height: 45px;
        max-height: 45px;
        width: 165px;
        font-size: 14px;
        @media(max-width: 460px) {
            width: 100%;
        }
    }
    & .submitButton{
        &:disabled,[disabled]{
          background-color: #eeeded;
          color: #666666;
        }
    }
    & .disabledSubmitButton{
        background-color: #eeeded;
        color: #666666;
    }
}
.textContainer{
    background-color: #fff;
    border: 1px solid #E6E6E6;
    padding: 20px 30px;
    border-radius: 4px;
    position:relative
}
.questionContainer{
    display: flex;
    gap:4px;
    margin-bottom: 8px;
}
.option{
    display: flex;
    align-items: baseline;
    gap:12px;
}
.modalInputSection{
    margin-top: 45px;
}
.modalTitle{
    margin-bottom: 45px;
}

.optionMenuContainer{
  position: absolute;
  right: -8%;
  top: 10px;

  @media(max-width: 460px) {
    right: -11%;
  }
}

.optionMenu{
  width: max-content;
  position: absolute;
  padding: 10px 20px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.30);
  left: 0px !important;
  z-index: 10;

  & ul>li {
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.25px;
    margin-top: 7px;
    cursor: pointer;
  }

  &:first-child{
    background-color: green;
  }

  & .firstChild {
    font-weight: 600;
  }
}

.menuContainer{
  position: relative;
}

.sectionDragComponent{
    position: relative;
    background-color: #f8f8f8;
}

.questionDragComponent{
    position: relative;
}

.questionleft{
    position: absolute;
    left: -25px; 
    top: 18px;
}

.left{
    position: absolute;
    left: -25px;
    top: -5px;
}

.cursorPointer{
    cursor: pointer;
    margin-left: 10px;
}

.alertModal{
    & h2{
		text-align: center;
		font-size: 30px;
        line-height: 40px;
        letter-spacing: -0.9px;
	}
    & p{
      /* text-align: center; */
      font-size: 20px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.9px;
    }
    .closeButton{
        margin-top: 3em;
        justify-content: center;
    }
  }

  .labelMarginTop{
    margin-top: 20px;
  }

  .errorMessage{
    color: var(--failColor);
  }

.validationBorder{
    & select{
        border-color: var(--failColor);
    }
}

.vibrate {
    animation: vibrate 10s infinite;
}
  
@keyframes vibrate {
    0% { transform: translateX(0); }
    0.4% { transform: translateX(4px); }
    0.8% { transform: translateX(-4px); }
    1.2% { transform: translateX(3px); }
    1.6% { transform: translateX(-3px); }
    2.0% { transform: translateX(2px); }
    2.4% { transform: translateX(-2px); }
    2.8% { transform: translateX(1px); }
    3.2% { transform: translateX(-1px); }
    3.6% { transform: translateX(0); }
    25% { transform: translateX(0); }
    50% { transform: translateX(0); }
    75% { transform: translateX(0); }
    100% { transform: translateX(0); }
}