.successModalContainer{
	& h2{
		text-align: center;
		font-size: 30px;
    line-height: 40px;
    letter-spacing: -0.9px;
	}
	& p{
		text-align: center;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: -0.9px;
	}
}