.modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
    border: none;
  }
  
  .modal.open {
    display: block;
  
  }
  
  .modalContent {
    background-color: #fff;
    margin: 15% auto;
    padding: 20px;
    width: 80%;
    max-width: 600px;
    position: relative;
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  }