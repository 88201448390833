@import '../../styles/propertySets.css';

.root {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: auto;
}

.content {
  /* flex-grow: 1; */
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* 113 for footer and an extra 67 to get scrolling work in mobile safari */
  margin-bottom: 20px;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 24px;
  background-color: var(--matterColorLight);
  /*box-shadow: var(--boxShadowTop);*/
}

.avatar {
  /* Layout */
  flex-shrink: 0;
  margin: var(--TopbarMobileMenu_topMargin) 0 0 0;
}

.greeting {
  /* Font */
  @apply --marketplaceH1FontStyles;
  margin-bottom: 1px;
  margin-top: 16px;
}

.logoutButton {
  /* @apply --marketplaceLinkStyles;
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);
  margin: 4px 0 6px 0;
  padding: 0; */
  font-size: 20px;
  font-weight: 600;
  margin-top: 100px;
}

.inbox {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--successColorDark);
  position: relative;

  margin-top: 30px;
  margin-bottom: 13px;
}

.navigationLink {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--successColorDark);

  /* Layout */
  margin-top: 0;
  margin-bottom: 11px;
}

.currentPageLink {
  color: var(--marketplaceColorDark);
}

.notificationBadge {
  position: absolute;
  top: 4px;
  right: -26px;

  /* This is inside a link - remove underline */
  &:hover {
    text-decoration: none;
  }
}

.createNewListingLink {
  @apply --marketplaceButtonStyles;
  margin-top: 10px;
}

.authenticationGreeting {
  /* Font */
  @apply --marketplaceH1FontStyles;
  line-height: 36px;
  margin-bottom: 24px;
  color: #4a4a4a;
  margin-top: var(--TopbarMobileMenu_topMargin);
}

.authenticationLinks {
  white-space: nowrap;
}

.signupLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--successColorDark);
}

.loginLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--successColorDark);
}

.currentPage {
  color: var(--matterColorDark);

  /* black left border */
  border-left: 5px solid black;
  margin-left: -24px;
  padding-left: 19px;
}

.wrapperSwitcher {
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 300px;
  padding-right: 28px;
  margin-right: 10px;
}

.checkboxWrapper {
  position: relative;
  margin: 27px 16px;
  border-radius: 50px;
  font-family: var(--font-familyLite);
  width: 36px;
  height: 21px;
  border: 1px solid var(--marketplaceColor);
  background: rgba(225, 150, 0, 0.1);
}

.ball {
  position: absolute;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  top: 2px;
  left: 2px;
  transition: transform 0.5s ease;
  background-color: var(--marketplaceColor);

  &.toggled {
    transform: translateX(100%);
  }
}

.textSwitcher {
  font-size: 21px;
  font-weight: var(--fontWeightRegular);
  line-height: 20px;
}

.inputSearchStyle {}

.offerText {
  position: relative;

  & .offerMain {
    width: 230px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    position: relative;
    top: 10px;
    right: 0.5em;
    background: #0b96f5;
    border-radius: 10px;
    transition: box-shadow 0.3s ease-in-out;

    &:hover {
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    }

    &:after {
      content: "";
      position: absolute;
      height: 90%;
      width: 97%;
      top: 3px;
      left: 3px;
      border-radius: 10px;
      border: dashed;
      border-width: 1px;
      border-color: #fff;
    }

  }

  & .offerTopBox {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  & .offerHeadingText {
    display: inline-block;
    color: white;
    line-height: 0.99;
    font-size: 14px;
    font-weight: bold;
    margin-right: 5px;
    text-shadow: 0px 0px 6px #3c3636;
  }

  & .offerHeadingNumber {
    color: #ffffff;
    font-size: 36px;
    line-height: 43px;
    font-weight: bold;
    text-shadow: 0px 0px 6px #3c3636;
  }

  & .offerSubText {
    color: #ffd60a;
    font-size: 12px;
    line-height: 14px;
    font-weight: 900;
    width: 150px;
    margin-left: 12px;
    text-shadow: 0px 0px 6px #00000082;
    letter-spacing: 0.3px;
  }

  & .clickHere {
    position: absolute;
    left: -140px;
    top: 22px;
    animation: blinker 1s linear infinite;

    &:after {
      content: "→";
      position: absolute;
      font-size: 50px;
      line-height: 16px;

    }
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}