@import '../../styles/propertySets.css';

.root {
  position: relative;
  box-shadow: var(--boxShadowBottomForm);

  @media (--viewportLarge) {
    box-shadow: none;

    /* Clearfix */
    width: 100%;
    overflow: hidden;
  }
}

.textarea {
  flex: 2;
  @apply --marketplaceH4FontStyles;
  border-bottom-width: 0;
  max-width: 100%;

  /* Avoid text going behind the submit button */
  padding: 22px 52px 5px 0;

  margin: 0;
  min-height: 25px;
  /* We need to remove horizontal paddings,
   since textarea uses content-box (autosize library fix). */
  width: calc(100% - 52px);
  font-weight: var(--fontWeightRegular);
  border-bottom-width: 2px;
  border-bottom-color: var(--attentionColor);
  background-color: transparent;

  @media (--viewportMedium) {
    margin: 0;
  }

  @media (--viewportLarge) {
    padding: 0 0 5px 0;
    margin: 0;
    width: 100%;
  }

  @media(max-width: 768px) {
    min-width: 100%;
    padding-right: 0;
  }
}

.spinner {
  stroke: var(--matterColorLight);
  width: 18px;
  height: 18px;
  stroke-width: 4px;
}

.fillSuccess {
  fill: var(--successColor);
}

.strokeMatter {
  stroke: var(--matterColor);
}

.chatBottomMessageBar {
  display: flex;
  align-items: baseline;

  & .chat {
    /* flex: 1; */
    cursor: pointer;
  }

  & .textareaBox {
    flex: 2;
    margin: 0 20px 0 0;


    @media(max-width: 700px) {
      margin: 0;
    }

    & textarea {
      min-width: 100%;
    }
  }

  & .submitContainer {
    /* flex: 1; */
  }
}

.errorContainer {
  display: block;
  flex: 1;
  text-align: right;
  padding: 26px 24px 0 0;
}

.error {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--failColor);
  margin: 0;
}

.submitButton {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightRegular);
  font-size: 13px;
  float: right;
  padding: 0 16px;
  min-height: auto;
  min-width: 93px;
  height: 41px;
  line-height: 24px;

  display: inline-block;
  margin: 17px 0 0 0;
  width: auto;
}

.sendIcon {
  margin: -3px 5px 0 0;
}

.aspectRatioWrapper {
  &>img {}
}

.pin {
  /* margin: 14px 0; */
  background-image: url(../../assets/attachment.png);
  width: 25px;
  background-position: center;
  cursor: pointer;
  background-size: 100%;
  height: 31px;

  /* margin-left: -65px; */
  &>input {
    border: none !important;
  }

  @media(max-width: 460px) {
    /* margin-left: -30px; */
  }
}

.addImageInput {
  opacity: 0;
}

.sendMessage {
  margin: 0;

  @media(max-width: 700px) {
    display: none;
  }

  &:disabled {
    color: #00000069 !important;
  }
}

.actionButton {
  display: flex;
  /* margin-top: 10px; */
  flex-wrap: wrap;
  position: relative;
  justify-content: space-between;

  @media(max-width: 420px) {
    justify-content: space-between;
    row-gap: 10px;
  }

  & .chatTooltip {
    position: absolute;
    right: 9.5em;
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    line-height: 25px;

    @media(max-width: 768px) {
      position: relative;
      right: unset;
    }

    /* @media(max-width: 420px) {
      line-height: 40px;
      right: 8.8em;
    } */

    &:hover {
      & .tooltip {
        visibility: visible;
      }
    }

    & .tooltip {
      visibility: hidden;
      position: absolute;
      top: -25.5em;
      left: -27em;
      filter: drop-shadow(4px 4px 20px rgba(0, 0, 0, 0.05));
      border: 1px solid var(--Line-colour, #E6E6E6);
      background: var(--White, #FFF);
      padding: 10px 20px;
      width: 336px;

      @media(max-width: 540px) {
        left: -15em;
        width: 310px;
      }

      @media(max-width: 420px) {
        left: -13em;
        top: -26em;
      }

      & p {
        font-size: 12px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.25px;
        margin: 0;
        margin-bottom: 15px;
      }

    }
  }
}

.mobileSendMessage {
  display: none;

  @media(max-width: 700px) {
    margin: 0;
    display: block;
  }

  &:disabled {
    color: #00000069 !important;
  }
}

.pointerEvents {
  pointer-events: none;
  opacity: .5;
}

.emojiIcon {
  height: 23px;
  cursor: pointer;
}

.iconContainer {
  display: flex;
  column-gap: 12px;

  @media(max-width: 768px) {
    align-items: center;
    margin: 0;
  }
}

.gifPicker {
  position: absolute;
  bottom: 54px;
}

.attachmentFile {
  margin-right: 25px;
  border: 1px solid #B2B2B2;
  width: max-content;
  padding: 0 10px;
  border-radius: 4px;

  & svg {
    cursor: pointer;
    margin-left: 10px;
  }
}

.gifResults {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;

  & .gifList {
    width: 100%;

    & img {
      width: 100%;
      height: 100px;
      object-fit: cover;
      border-radius: 5px;
    }
  }
}

.gifLayout {
  height: 310px;
  overflow-y: auto;
  background-color: #fff;
  width: 290px;
  border-radius: 5px;
}

.categoriesList {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.categoriesDummy {
  position: relative;
  width: 100%;
  height: 100px;

  & img {
    width: 100%;
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    border-radius: 5px;
  }

  & .shadowText {
    align-items: center;
    border-radius: 5px;
    background-color: #0000003b;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    transition: background-color .15s ease-in-out;
    width: 100%;
    z-index: 9;

    & .catName {
      z-index: 9;
      position: relative;
      font-size: 16px;
      color: #fff;
      font-weight: bold;
    }
  }
}

.gifWrapper {
  padding: 9px;
  border: 1px solid #e1e1e1;
  background-color: #fff;

  & .searchBar {
    margin-bottom: 5px;
    position: relative;

    & svg {
      position: absolute;
      left: 19px;
      top: 0%;
      transform: translate(-50%, -50%);
      rotate: 275deg;
      width: 18px;
    }

    & input {
      border: 1px solid #e1e1e1;
      padding: 2px 10px;
      border-radius: 3px;
      padding-left: 30px;
    }
  }
}

.clearButton {
  position: absolute;
  right: -13px;
  top: 50%;
  transform: translate(-50%, -50%);

  & svg {
    position: unset !important;
    top: unset !important;
    transform: unset !important;
    right: unset !important;
    rotate: unset !important;
    left: unset !important;
  }
}

.searchInputWrapper {
  position: relative;
}

.bottomRight {
  display: flex;
  gap: 15px;
  align-items: center;
}