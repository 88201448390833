.csmAvatarIcon {
    width: 40px;
    height: 40px;
    position: relative;
    margin: 15px 0;

    & .csmProfile {
        width: 100%;
        object-fit: cover;
        background: none;
        display: flex;
        align-items: center;
        position: absolute;
        justify-content: center;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        border: 3px solid #FFAA00;
        border-radius: 50px;
        background-size: 100%;
        background: linear-gradient(180deg, #7AC9FF 0%, #0B96F5 100%);

        &::after {
            content: "CSM";
            position: absolute;
            left: 50%;
            bottom: -3px;
            transform: translate(-50%, 0);
            background-color: #FDECCA;
            width: 27px;
            height: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #E49800;
            text-align: center;
            font-family: Poppins;
            font-size: 6px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.087px;
            border-radius: 2px;
        }
    }

    & .profileIcon {
        width: 40px;
        height: 40px;

        & img {
            width: 40px;
            object-fit: cover;
            border-radius: 50px;
            border: 3px solid #ffaa00;
            position: absolute;
            left: 0;
            right: 0;
        }

        & .csmLogo {

            &::after {
                content: "CSM";
                position: absolute;
                left: 50%;
                bottom: -3px;
                transform: translate(-50%, 0);
                background-color: #FDECCA;
                width: 27px;
                height: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #E49800;
                text-align: center;
                font-family: Poppins;
                font-size: 6px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: -0.087px;
                border-radius: 2px;
            }
        }
    }
}

.csmMobile {
    width: 104px;
    height: 104px;
    position: relative;
    margin-top: 96px;

    & .csmProfile {
        width: 100%;
        object-fit: cover;
        background: none;
        display: flex;
        align-items: center;
        position: absolute;
        font-size: 22px;
        justify-content: center;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        padding-bottom: 16px;
        /* background-image: url('../../assets/csmavtar.png'); */
        background: linear-gradient(180deg, #7AC9FF 0%, #0B96F5 100%);
        background-size: 100%;
        border: 3px solid #FFAA00;
        border-radius: 50px;

        &::after {
            content: "CSM";
            position: absolute;
            left: 50%;
            bottom: -3px;
            transform: translate(-50%, 0);
            background-color: #FDECCA;
            border-radius: 2px;
            width: 79px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #E49800;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.087px;
        }

        & .profileImage {
            color: #FFF;
            font-size: 40px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.089px;
        }
    }

    & .profileIcon {
        width: 96px;
        height: 96px;

        & img {
            width: 100%;
            object-fit: cover;
            border-radius: 50px;
            border: 4px solid #FA0;
            position: absolute;
            left: 0;
            right: 0;
        }

        & .csmLogo {
            &::after {
                content: "CSM";
                position: absolute;
                left: 50%;
                bottom: -3px;
                transform: translate(-50%, 0);
                background-color: #FDECCA;
                width: 79px;
                height: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #E49800;
                text-align: center;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: -0.087px;
                border-radius: 2px;
            }
        }
    }
}

.profileImage {
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.089px;
}