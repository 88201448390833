.mainAside {
    display: none;
}

.contentPanel {
    & .coverImage {
        padding: 27px 40px 0;
        position: relative;

        @media(max-width: 1240px) {
            padding: 25px;
        }

        @media(max-width: 600px) {
            padding: 0;
        }

        & img {
            width: 100%;
            height: 570px;
            object-fit: cover;
            border: 1px solid #eee;
        }

        & .panelLogo {
            position: absolute;
            left: 10%;
            bottom: -53px;
            background: #fff;
            border-radius: 50%;

            & img {
                border-radius: 50%;
                height: 115px;
                width: 115px;
                border: 1px solid #d2d2d2;
                box-shadow: 0px 1px 5px 0px rgb(171 171 171);
                object-fit: contain;
            }

            & .noImage {
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                background: linear-gradient(180deg, #7AC9FF 0%, #0B96F5 100%);
                color: var(--matterColorLight);
                height: 115px;
                width: 115px;
                box-shadow: 0px 1px 5px 0px rgb(171 171 171);
            }
        }
    }
}

.contentBody {
    width: 100%;
    display: flex;
    margin: auto;
    padding: 60px 40px 46px;

    @media(max-width: 920px) {
        flex-wrap: wrap;
        padding: 60px 24px 46px 24px;
    }

    @media(min-width: 1600px) {
        padding: 76px 40px 76px 40px;
        width: 1600px;
    }

    & .contentLeft {
        width: 70%;
        border-right: 1px solid #E6E6E6;
        padding-right: 79px;

        @media(max-width: 1240px) {
            padding-right: 20px;
        }

        @media(max-width: 920px) {
            width: 100%;
            border-right: 0;
            padding-right: 0;
        }

        & .heading {
            & h2 {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 30px;
                line-height: 56px;
                letter-spacing: -0.9375px;
                color: #0B96F5;
                margin-top: 0;
                margin-bottom: 1px;
            }

            & .firmType {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.0875px;
                color: #3D3D3D;
            }
            & .listingCreatedTime{
                font-weight: 400;
                font-size: 15px;
                line-height: 24px;
                letter-spacing: -0.0875px;
                font-style: italic;
            }
        }

        & .projectRating {
            padding: 20px;
            background: rgb(246 180 48 / 9%);
            margin-top: 30px;

            @media(max-width: 600px) {
                margin-left: -25px;
                margin-right: -25px;
                margin-top: 20px;
            }
        }

        & .aboutSection {
            margin-top: 40px;

            @media(max-width: 600px) {
                margin-top: 20px;
            }

            & .aboutHeading {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 24px;
                letter-spacing: -0.1125px;
                color: #B2B2B2;
                margin-bottom: 10px;
            }

            & .aboutDetail {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.0888889px;
                color: #3D3D3D;
            }
        }

        & .locationBox {
            margin-top: 40px;

            & .locationHeading {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 24px;
                letter-spacing: -0.1125px;
                color: #B2B2B2;
                margin-bottom: 10px;
            }

            & .locationList {
                & ul {
                    margin: 0;
                    padding: 0;

                    & li {
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 32px;
                        letter-spacing: -0.0888889px;
                        color: #3D3D3D;

                        & span {
                            margin-right: 7px;
                        }
                    }
                }
            }
        }

        & .websiteLink {
            margin-top: 40px;

            & .websiteHeading {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 24px;
                letter-spacing: -0.1125px;
                color: #B2B2B2;
                margin-bottom: 10px;
            }

            & .linkWeb {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.0888889px;
                color: #0B96F5;
            }
        }

        & .domainExpertise {
            margin-top: 40px;

            & .domainHeading {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 24px;
                letter-spacing: -0.1125px;
                color: #B2B2B2;
                margin-bottom: 10px;
            }

            & .domainCards {
                display: flex;
                align-items: center;
                gap: 12px;
                flex-wrap: wrap;

                & span {
                    background: #ceeafd;
                    border-radius: 12px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    letter-spacing: -0.1px;
                    color: #0081D9;
                    padding: 4px 9px;
                    position: relative;
                }
            }
        }

        & .departmentBox {
            margin-top: 40px;

            & .departmentHeading {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 24px;
                letter-spacing: -0.1125px;
                color: #B2B2B2;
                margin-bottom: 10px;
            }

            & .departmentCards {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.0888889px;
                color: #3D3D3D;
            }
        }

        & .firmBox {
            margin-top: 40px;

            & .firmHeading {
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 24px;
                letter-spacing: -0.1125px;
                color: #B2B2B2;
                margin-bottom: 10px;
            }

            & .firmBody {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.0888889px;
                color: #3D3D3D;
            }
        }
    }

    & .contentRight {
        width: 30%;
        padding-left: 40px;

        @media(max-width: 1240px) {
            padding-left: 20px;
        }

        @media(max-width: 920px) {
            width: 100%;
            padding-left: 0;
            margin-top: 20px;
        }

        & .cardBox {
            background: #FFFFFF;
            padding: 20px;
            border: 1px solid #E6E6E6;
            margin-bottom: 5px;

            @media(max-width: 600px) {
                margin-bottom: 15px;
            }

            & .titleText {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 24px;
                letter-spacing: -0.1125px;
                color: #B2B2B2;
                margin-bottom: 16px;
            }

            & .cardBody {
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                &:hover {
                    text-decoration: none;
                }

                & .userDetails {
                    display: flex;
                    flex-direction: column;
                    grid-gap: 15px;
                    gap: 15px;
                    max-height: 145px;
                    overflow: auto;
                    overflow-x: hidden;
                    width: 100%;
                }

                & .firmMembersDetails {
                    display: flex;
                    align-items: center;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                & .cardImage {
                    margin-right: 10px;
                    border: 3px solid #fa00;

                    & img {
                        width: 38px;
                        height: 38px;
                        border-radius: 50%;
                        background-color: #42affa;
                        color: white;
                        /* margin-right: 5px; */
                        /* box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.5); */
                        text-align: center;
                        font-weight: 900;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }

                & .descriptionText {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: -0.0888889px;
                    color: #3D3D3D;
                }

                & .projectImage {
                    margin-right: 10px;

                    & img {
                        width: 88px;
                        height: 57px;
                        object-fit: cover;
                    }
                }
            }
        }
    }
}

.profileImage {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background-color: #42affa;
    color: white;
    /* margin-right: 5px; */
    /* box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.5); */
    text-align: center;
    font-weight: 900;
    display: flex;
    justify-content: center;
    align-items: center;
}

.publiclyProjectList {
    display: flex;
    align-items: center;
    gap: 6px;

    &>div{
        min-width: 85px;
        height: 50px;
        font-size: 5em;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        overflow: hidden;
        border-radius: 3px;
        color: rgba(228, 152, 0, 1);
        background-color: rgba(246, 180, 48, 0.2);
    }

    &:hover {
        background-color: rgb(246 180 48 / 9%);;
    }

    & .projectLink {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.1125px;
        color: #3D3D3D;
        width: auto;

        & .projectText {
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box !important;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            white-space: normal;
        }
    }
}

.publiclyBox {
    max-height: 205px;
    overflow-y: auto;
    gap: 15px;
    display: flex;
    flex-direction: column;
}

.projectRow {
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media(max-width: 600px) {
        display: flex;
        gap: 20px;
    }

    & p {
        margin: 0;
        color: var(--dark-grey, #3D3D3D);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.089px;
        text-transform: uppercase;

        @media(max-width: 600px) {
            font-size: 12px;
        }
    }

    & div {
        padding-left: 10em;
        color: var(--dark-grey, #3D3D3D);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.089px;

        @media(max-width: 1240px) {
            padding-left: 0;
            text-align: right;
        }

        @media(max-width: 600px) {
            font-size: 12px;
            font-weight: 400;
        }
    }

    & .countBox {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.0888889px;
        color: #3D3D3D;
        display: flex;
        align-items: center;

        @media(max-width: 1240px) {
            justify-content: flex-end;
        }

        @media(max-width: 600px) {
            font-size: 12px;
            font-weight: 400;
        }

        & svg {
            margin-right: 6px;
            width: 21px;
            height: 21px;

            & path {
                fill: #F6B430;
            }
        }

        & .reviewText {
            color: var(--grey, #B2B2B2);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.089px;
            margin-left: 5px;

            @media(max-width: 600px) {
                font-size: 12px;
                font-weight: 400;
            }
        }
    }
}

.csmCardImage {
    border: 3px solid #FA0 !important;
    border-radius: 50%;
    position: relative;
    cursor: pointer;

    &:hover{
        &::before{
          visibility: visible;
        }
    }

    &::before{
        visibility: hidden;
        content: attr(data-tooltip);
        background-color: white;
        color: #000;
        border-radius: 6px;
        padding: 10px;
        position: absolute;
        z-index: 1;
        width: 230px;
        top: -28px;
        left: 4em;
        box-shadow: 0px 8px 16px rgb(0 0 0 / 30%);
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.25px;
    }

    &::after {
        content: "CSM";
        position: absolute;
        left: 50%;
        bottom: -3px;
        transform: translate(-50%, 0);
        background-color: #FDECCA;
        border-radius: 2px;
        width: 27px;
        height: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #E49800;
        text-align: center;
        font-size: 6px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.087px;
    }
}