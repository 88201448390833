.contentAnalysisForm {
  padding: 40px;

  @media(max-width: 768px) {
    padding: 24px;
  }
}
.container {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px !important;
  width: 100%;
  margin: 0 auto;
  padding: 40px 100px;
  border: 1px solid #c2c2c2;
  border-radius: 5px;

  @media(min-width: 1300px) {
    width: 1000px;
  }

  @media(max-width: 768px) {
    padding: 20px;
  }
}
.questionContainer{
  border: none;
  /* padding: 0; */
  box-shadow: unset !important;

  @media(min-width: 1300px) {
    width: unset;
  }
}

.heading {
  margin-bottom: 40px;

  & span {
    font-size: 36px;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -1.25px;
  }

  @media(max-width: 768px){
    margin-bottom: 3em;
    
    & span{
      font-size: 24px;
      line-height: 28px;
    }
  }
}
.stepsLabel{
  margin-bottom: 2em;
  & span{
    font-size: 19px;
  }
  & h3{
    text-align: center;
    font-weight: 500;
  }
}
.textareaBox {
  & .inputBox {
    & input{
      /* max-width: 50%; */
    }
  }
}
.buttomButtonWrapper{
  display: flex;
  margin-top: 3em;
  justify-content: space-between;
  gap: 20px;
  
  & button{
    width: 160px;
    min-height: 45px;
  }
  & .disabledSubmitButton{
    background-color: #eeeded!important;
    color: #666666!important;
  }
}
.singleButtonWrapper{
  justify-content: flex-end;
}
.languageCheckbox{
  margin-top: 1em;
  padding-left: 20px;
}
.inputAttachment{
  /* width: 55%; */

  & .removeFile{
    cursor: pointer;
    color: red;
    font-weight: 100;
    letter-spacing: 0.25px;
  }

  & input {
    display: none;
  }

  & .errorMessage{
    color: var(--failColor);
    font-weight: 500;
  }
}
.attachmentLabel {
  border: 2px dashed gainsboro;
  border-radius: 2px;
  padding: 25px 20px;
  display: flex;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
  align-items: center;
  gap: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.177778px;

  @media(max-width: 768px){
    padding: 5px 20px;
    font-size: 12px;
  }

  & .dragDrop {
    
    color: #3D3D3D;
  }

  & .docType {
    color: #B2B2B2;
  }
}
.questionsHeading{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;

  & h3{
    font-weight: 600;
  }
}
.questionsWrapper{
  & .row{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 15px;
    margin-bottom: 18px;
    position: relative;
    
    & .left{
      display: flex;
      column-gap: 10px;
      align-items: center;

      & .dragIconWrapper{
        position: absolute;
        display: flex;
        gap: 10px;
        align-items: baseline;
        left: -40px;
      }

      & .count{
        font-size: 12px;
        font-weight: 600;
      }

      & .dragDots{
        cursor: grab;
      }
    }

    @media(max-width: 768px){
      display: block;
    }
    
    & .question, textarea{
      border: 1px solid gainsboro;
      border-radius: 5px;
      padding: 5px 12px;
      
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
    }
    & .question{
      opacity: 0.7;
      cursor: move;
      width: -webkit-fill-available;
      height: -webkit-fill-available;
    }
    & textarea{
      resize: none;
      min-width: 100%;
      box-sizing: border-box;
    }
  }
}

.error{
  color: red;
  font-weight: 100;
  text-align: end;
  margin: 0;
}

.alertModal{
  & h2 {
    font-size: 30px;
    line-height: 40px;
    letter-spacing: -0.9px;
    margin-bottom: 30px;
  }
  & p{
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.9px;
  }
  .closeButton{
    margin-top: 4em;
  }
}

.loader{
  display: flex;
  justify-content: center;
}

.optionMenuContainer{
  position: absolute;
  right: -4%;
  top: 10px;
}

.optionMenu{
  width: max-content;
  position: absolute;
  padding: 10px 20px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.30);
  right: 0px !important;
  z-index: 10;

  & ul>li {
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.25px;
    margin-top: 7px;
    cursor: pointer;
  }

  &:first-child{
    background-color: green;
  }

  & .firstChild {
    font-weight: 600;
  }
}

.buttonWrapper{
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin-top: 3em;

  @media(max-width: 460px) {
    flex-wrap: wrap;
    width: 100%;
  }
  & .cancelButton{
    padding: 16px 0;
    font-size: 19px;
    cursor: pointer;
    font-weight: 400;
  }
  & .submitButton{
    width: 185px;
    font-size: 19px;
  }
}

.textareaHeight{
  min-height: 150px;
}

.browserBack{
  cursor: pointer;
  &::after{
    content: '';
    display: inline-block;
    width: 15px;
    height: 2px;
    background: #3D3D3D;
    position: relative;
    top: -4px;
    left: -11px;
  }

  & svg{
    & path{
      fill: #3D3D3D;
    }
  }
}

.firstFieldTitle{
  display: flex;
  align-items: center;
  gap: 10px;
}

.fileViewContainer{
  margin-top: 10px;
}