.discourseCommunityForumPendingApproval {
   /* display: flex;
   justify-content: center;
   align-items: center; */
   /* border-radius: 10px; */
   margin: 5rem;
   /* padding: 1rem; */

   & h2 {
      font-size: 18px;
      font-weight: bold;
   }
}