.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }

  @media (--viewportLarge) {
    width: 571px;
  }
}

.error {
  color: var(--failColor);
}

.validationMessage{
  color: var(--failColor);
  margin-top: 6px;
  margin-bottom: 10px;
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.title {
  composes: formMargins;
}

.description {
  composes: formMargins;
  /*flex-shrink: 0;*/
}

.category {
  composes: formMargins;
}

.submitButton {
  margin: 20px 0 30px !important;
  flex-shrink: 0;
  display: inline-block;
  width: 241px !important;

  @media(max-width: 460px) {
    width: 100% !important;
  }
}

.yearsInput {
  position: relative;
  width: 124px;
  margin-right: 5px;

  &::before {
    content: "";
    height: 30px;
    width: 20px;
    background: var(--matterColorLight);
    position: absolute;
    top: 25px;
    right: 0;
  }
}

.yearsBlock {
  margin-bottom: 48px;
  display: flex;
  /*align-items: center;*/
}

.labelYears {
  padding-top: 22px;
}

.selectedLanguageContainer {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.selectedLanguages {
  background-color: green;
}

.inputDropdown {
  padding-bottom: 10px;
}

.languagesList{
  display: flex;
  gap: 7px;
  margin-top: 10px;
  flex-wrap: wrap;
}

.languageOutsideContainer{
  width: 100%;
}

.languagesContainer {
  margin-top: 24px;
  position: relative;
  margin-bottom: 100px;

  & p{
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.09px;
    margin: 6px 0;
  }

  & .addLanguagesButton {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.266667px;
    color: #0B96F5;
    border: 2px solid #0B96F5;
    height: 35px;
    cursor: pointer;
    padding: 0 10px;
    margin-top: 11px;
    border-radius: 4px;
}
  @media(--viewportMedium) {
    margin-top: 32px;
  }

  & .languagesDropdown {
      overflow: auto;
      height: 50vh;
      border: 1px solid gainsboro;
      margin-top: 5px;

      & input {
        display: none;
      }

      & label{
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
      }

      & .languageListItem{
        border-bottom: 1px solid gainsboro;
        padding: 5px 0 5px 5px;
      }

      & .selected{
        background: rgb(226 226 226);
        cursor: pointer;
      }
   }
 
  /* Style for the language badge */
 & .languageBadge {
    background-color: #ceeafd;
    color: #0B96F5;
    padding: 0px 5px 0 10px;
    border-radius: 12px;
    height: 26px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.1px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 13px;
  }

  /* Style for the remove button in the badge */
  & .removeBadge {
    margin: 5px;
    cursor: pointer;
    scale: 1.5;
  }
}

.inputSelect {
  margin-top: 12px;
  position: relative;

  & input {
    background: #FFFFFF;
    border: 2px solid #E6E6E6;
    padding: 3px 10px;

    &:hover,
    &:focus {
      border: 2px solid #0b95f4;
    }
  }
  & .clearSearchButton{
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-50%,-50%);
    & svg{ 
      & path{
        stroke: #0b95f4;
      }
    }
  }
}
.searchContainer{
  position: relative;
}
.languagesDropdownBox{
  overflow-y: auto;
  max-height: 200px;
  border: 2px solid #E6E6E6;
  margin-top: 5px;
  top: -6px;
  position: relative;
  width: 100%;
  background: #fff;
  
  & li{
    padding: 0 20px;
    cursor: pointer;
    color: #595959;

    &:hover{
      background-color: rgb(226 226 226 / 31%);
    }
  } 
  & .selectedList{
    background-color: rgb(226 226 226);
  }
}
.websiteInput{
  margin-top: 24px;
  @media(--viewportMedium){
    margin-top: 32px;
  }
}

.previewCard{
  width: 390px;
  height: 225px;
  padding: 10px;
  margin-top: 10px;
  box-shadow: 2px 2px 5px 0px rgb(0 0 0 / 40%);
  border-radius: 4px;
  transition: all ease-in-out 0.3s;

  @media(max-width:768px){
    width: 100%;
  }

  & .previewCardImage{
    width: 100%;
    height: 65%;
    object-fit: cover;

    @media(max-width:768px){
      height: 58%;
    }
  }

  & .previewCardContent{
    & .previewCardTitle{
      margin: 0;
      color: #0b95f4;
      font-size: 14px;
      line-height: normal;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    & .previewCardDescription{
      margin: 0;
      padding: 0;
      font-size: 12px;
      line-height: normal;
      color: #6f6f6f;
      margin-top: 7px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      margin-bottom: 7px;
    }
  }

  &:hover{
    scale: 1.02;
  }
}
.profileHeadlineAlert{
  color: #CC5858;
  position: relative;
  top: -25px;
}