@import '../../styles/propertySets.css';

.root {

& .subTitle {
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  text-transform: uppercase;
  margin-bottom: 40px;
}

& p {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  text-align: justify;
}

& li {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  text-align: justify;
}

& strong {
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
}

& em {
  text-decoration: underline;
  font-style: normal;
  font-size: 15px;
  line-height: 24px;
}

& ul {
  padding-left: 10px;
}

& .listStyleNone {
  padding-left: 0;
  list-style: none;
}

& ol {
  padding-left: 10px;
}

& .pointed {
  list-style: disc;
}

}