.root {
    /* Dimensions */
    width: 100%;
    height: auto;

    /* Layout */
    display: flex;
    flex: 1;
    flex-direction: column;

    @media(max-width: 420px) {
        padding: 20px 15px;
    }
}

.titleWrapper {
    display: grid;
    grid-template-columns: 70% 30%;
    grid-gap: 5px;
    gap: 5px;

    @media(max-width: 420px) {
        display: block;
    }

    & .copyText {
        color: var(--blue, #0B96F5);
        font-family: Poppins;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.178px;
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;

        @media(max-width: 420px) {
            margin-bottom: 20px;
        }

        & p {
            margin: 0;
            width: 13px;
            background: #bbb8b8;
            color: white;
            border-radius: 50%;
            height: 13px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 10px;
            position: relative;

            &::before {
                display: none;
                content: 'The text will be copied from the About Us section of the My Firm profile.';
                position: absolute;
                width: 272px;
                top: -60px;
                color: black;
                border-radius: 4px;
                background: #FFF;
                box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.30);
                font-size: 12px;
                font-weight: 400;
                letter-spacing: 0.25px;
                padding: 5px 10px;
                line-height: 20px;
            }

            &:hover::before {
                display: block;
            }
        }
    }
}

.error {
    color: var(--failColor);
}

.formMargins {
    margin-bottom: 24px;

    @media (--viewportMedium) {
        margin-bottom: 40px;
    }
}

.title {
    composes: formMargins;
}

.description {
    composes: formMargins;
    flex-shrink: 0;

    & textarea {
        margin-top: 10px;
        min-width: 100%;
        max-width: 100%;
    }
}

.submitButton {
    width: 241px !important;
    margin-top: 70px !important;

    @media (max-width: 768px) {
        margin-top: 40px !important;
    }
}

.inputSelectCard {
    display: flex;
    flex-wrap: wrap;
    column-gap: 12px;
    row-gap: 10px;

    & .servicesSelected {
        background: #fdf0d6;
        border-radius: 12px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.1px;
        color: #E49800;
        padding: 4px 9px;

        & .closeItem {
            margin-left: 10px;
            line-height: 0;
            cursor: pointer;

            & svg {
                width: 15px;
                height: 15px;
            }
        }
    }
}

.serviceContainer {
    margin-bottom: 10px;
    & .serviceHeading {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #3D3D3D;
        margin-bottom: 7px;
    }

    & .releventService {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.0875px;
        color: #3D3D3D;
        margin-bottom: 7px;
    }

    & .addServicesButton {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.266667px;
        color: #0B96F5;
        border: 1px solid #0B96F5;
        height: 35px;
        cursor: pointer;
        padding: 0 10px;
        margin-top: 11px;
        border-radius: 4px;
    }

    & ul {
        background: #FFFFFF;
        margin-top: 0;
        border: 1px solid #E6E6E6;
        width: 97.2%;
        border-top: none;

        & .activeSelect {
            background: rgb(226 226 226);
        }

        & li {
            border-top: 1px solid #E6E6E6;
            cursor: pointer;
            padding: 1px 10px;
        }
    }

    .inputSelect {
        margin-top: 12px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        column-gap: 5px;

        & div {
            width: 100%;
        }

        & span {
            font-size: 20px;
            cursor: pointer;
        }

        & input {
            background: #FFFFFF;
            border: 2px solid #E6E6E6;
            border-bottom: 0;
            padding: 3px 10px;
        }
    }
}

.inputsWrapper {
    display: flex;
    margin-top: 30px;
    align-items: flex-end;
    column-gap: 10px;

    @media (--viewportMedium) {
        padding-bottom: 5px;
    }

    & .priceSeparator {
        display: block;
        height: 3px;
        width: 15px;
        background: #3D3D3D;
        align-self: center;
        position: relative;
        top: 8px;
    }

    & input {
        text-align: center;
        width: 94px;
        border-color: var(--textFieldColor);

        &::placeholder {
            text-align: center;
        }
    }
}

.buttonsWrapper {
    display: flex;
    column-gap: 10px;
}