@import '../../styles/propertySets.css';

.modalProposal {
  max-width: 100vw;
  @apply --marketplaceModalBaseStyles;
  min-height: 100vh;
  height: 100%;

  @media (--viewportMedium) {
    flex-basis: 792px;
    min-height: auto;
    height: auto;
    padding: 60px;
  }
}

.title {
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
  /* identical to box height, or 133% */

  letter-spacing: -0.9px;
  color: var(--matterColor);
  margin-bottom: 42px;
}