@import '../../styles/propertySets.css';

.titleModal {
  @apply --marketplaceModalTitleStyles;
  margin: 35px 0 43px;
}

.buttonSection {
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;

  @media(max-width: 420px) {
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
}

.textRedirectToCreateBrief {
  cursor: pointer;
  margin: 30px 0 67px;
  @apply --marketplaceSmallFontStyles;
  line-height: 21px;
  font-weight: var(--fontWeightRegular);
  color: var(--successColorDark);

  &:hover {
    text-decoration: underline;
  }
}

.addBrief {
  opacity: 1;
}

.noBrief {
  pointer-events: none;
  opacity: 0;
}

.buttonCancel {
  font-size: 20px;
  font-weight: var(--fontWeightRegular);
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.buttonSubmit {
  width: 228px !important;
  font-size: 18px;
  line-height: 27px;

  @media(max-width: 420px) {
    width: 100% !important;
  }
}
.errorMessage{
  color:red;
  font-size: small;
}