.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  @media(max-width: 420px) {
    padding: 20px 15px;
  }
}
  
.error {
  color: var(--failColor);
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.title {
  composes: formMargins;
}

.description {
  composes: formMargins;
  flex-shrink: 0;

  & textarea{
    margin-top: 10px;
    min-width: 100%;
    max-width: 100%;
  }
}

.submitButton {
  width: 241px !important;
  margin-top: 70px !important;

  @media (max-width: 768px) {
    margin-top: 40px !important;
    width: auto !important;
  }
}
.location{
  margin-top: 30px;
}
.disabled{
  opacity: 0.5;
}
.checkbox{
  margin-top: 10px;
  & label>span{
    font-size: 14px;
  }
} 
.attachmentLabel {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin: 25px 0 10px;
}
.inputSection{
  & input {
    display: none;
  }
} 
.inputLabel {
  width: 100%;
  box-sizing: border-box;
  border: 2px dashed gainsboro;
  border-radius: 2px;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
  margin-bottom: 12px;
  align-items: center;
  gap: 10px;

  & .dragDrop {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.177778px;
      color: #3D3D3D;
  }

  & .docType {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.177778px;
      color: #B2B2B2;
  }
}
.removeFile {
  cursor: pointer;
  color: red;
  font-weight: 400;
}
.employment{
  margin-top: 30px;
  display: flex;
  align-items: flex-end;
  gap: 10px;

  & input{
    width: 69px;
    text-align: center;
  }
  & span{
    font-size: 16px;
    height: 35px;
    font-weight: 400;
    position: relative;
    left: -11em;
  }
}
.contractType{
  display: flex;
  column-gap: 4em;
}