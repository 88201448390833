.panelHeading {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 20px 0;

    & span {
        margin-left: 20px;
    }
    &:hover {
        text-decoration: underline;
    }

    & img {
        max-width: calc((100% - 40px)/1.5);
    }
}
.panelContainer{
  
}