.submitButton {
  margin: 30px 0 30px !important;
  flex-shrink: 0;
  display: inline-block;
  width: 241px !important;

  @media(max-width: 460px) {
    width: 100% !important;
  }
}

.packagesForm {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.HeadingTitle {
  font-size: large;
  border-radius: 1rem;
  border: .1rem lightgray solid;
  padding: .5rem;
  margin: 1rem;
  background-color: #1d72ae;
  cursor: pointer;
  color: white;
  width: fit-content;

  @media(max-width: 768px) {
    margin: 1rem 0;
  }
}

.packageBox {
  & .packageCard {
    & li {
      padding: 16px;
      cursor: pointer;
      border-radius: 4px;
      position: relative;
      margin-bottom: 20px;
      background: #FFFFFF;
      border: 1px solid #E6E6E6;
      box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 12%);

      &:hover {
        border: 1px solid #6b6b6b;
      }

      & span {
        &:nth-child(1) {
          & label {
            & span {
              &:nth-child(1) {
                height: 20px;
              }
            }
          }
        }

        &:nth-child(2) {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: -0.177778px;
          color: #3D3D3D;
        }

        &:nth-child(3) {
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: -0.0888889px;
          text-decoration-line: underline;
          color: #0B96F5;
        }
      }

      & p {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.0888889px;
        color: #3D3D3D;
        margin-top: 20px;
      }
    }
  }
}