
/* Mention Prefix Class */
.rc-mentions {
    display: inline-block;
    position: relative;
    white-space: pre-wrap;
    width: 100%;
  }
  
  /* Input Area */
  .rc-mentions > textarea,
  .rc-mentions-measure {
    font-size: inherit;
    /* font-size-adjust: inherit; */
    font-style: inherit;
    font-variant: inherit;
    font-stretch: inherit;
    font-weight: inherit;
    font-family: inherit;
    padding: 0;
    margin: 0;
    line-height: inherit;
    vertical-align: top;
    overflow: inherit;
    word-break: inherit;
    white-space: inherit;
    word-wrap: break-word;
    overflow-x: initial;
    overflow-y: auto;
    text-align: inherit;
    letter-spacing: inherit;
    white-space: inherit;
    tab-size: inherit;
    direction: inherit;

    @media(max-width: 420px) {
      min-width: 100%;
    }
  }
  
  /* Textarea */
  .rc-mentions > textarea {
    /* border: none; */
    width: 100%;
  }
  
  /* Measure */
  .rc-mentions-measure {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    pointer-events: none;
    color: transparent; /* or color: rgba(255, 0, 0, 0.3); */
    z-index: -1;
  }
  
  /* Dropdown */
  .rc-mentions-dropdown {
    position: absolute;
  }
  
  /* Dropdown Menu */
  .rc-mentions-dropdown-menu {
    list-style: none;
    margin: 0;
    padding: 0;
  
    /* Dropdown Menu Item */
    .rc-mentions-dropdown-menu-item {
      cursor: pointer;
  
      /* Dropdown Menu Item Active */
      &-active {
        background: #e6f7ff;
      }
  
      /* Dropdown Menu Item Disabled */
      &-disabled {
        opacity: 0.5;
      }
    }
  }
  
  /* Customize style */
  .rc-mentions {
    /* font-size: 18px; */
    /* border-bottom: 1px solid #999; */
    overflow: hidden;
  }
  
  /* Customize Dropdown */
  .rc-mentions-dropdown {
    border-radius: 3px;
    background: #FFF;
    box-shadow: 0px 8px 16px 0px #0000004D;

    & .divider{
      position: relative;
      top: -2px;
      border-top: 1px solid gainsboro;
    }
  }
  
  .mentionHeading {
    font-size: 10px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -0.09px;
    margin-left: 10px;
    pointer-events: none;
  }

  /* Customize Dropdown Menu Item */
  .rc-mentions-dropdown-menu-item {
    font-size: 10px;
    padding: 2px 8px 4px;
    font-family: 'poppins';

    
    & .row{
      padding: 0 10px;
      display: flex;
      align-items: center;
      margin: 0;
      width: 400px;
      height: 40px;

      @media(max-width: 768px){
        width: 300px;
      }

      & img{
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-right: 10px;
      }
      & span{
        width: 32px;
        height: 32px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-right: 10px;
        background-color: #42affa;
        color: white;
      }
    }

    & .mentionRow{
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: -0.09px;
      flex-grow: 1;

      & h3{        
        font-size: 12px;
      }
      & label{
        font-weight: 400;
        font-size: 9px;
      }
    }
  
    /* Customize Dropdown Menu Item Active */
    &-active {
      background-color: orange;
    }
  
    /* Customize Dropdown Menu Item Disabled */
    &-disabled {
      opacity: 0.5;
    }
  }
  .rc-mentions-dropdown-menu-item:active, .rc-mentions-dropdown-menu-item:focus, .rc-mentions-dropdown-menu-item:hover{
    background-color: orange;
  }