.panelHeading {
    & h2 {
        border: .1rem solid gray;
        padding: 1rem;
        border-radius: 1rem;
        background-color: cyan;
        text-align: center;
    }
}

.integrationPanelWrapper {
    margin-bottom: 100px;

    & h2 {
        text-align: center;
        margin-top: 49px;
        margin-bottom: 58px;
        color: var(--dark-grey, #3D3D3D);
        text-align: center;
        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        line-height: 56px;
        letter-spacing: -1.25px;

        @media(max-width: 768px) {
            margin-top: 30px;
            margin-bottom: 33px;
            color: var(--black, #464646);
            font-size: 24px;
            font-weight: 700;
            line-height: normal;
        }
    }

    & .integrationBox {
        display: grid;
        grid-template-columns: repeat(3, 2fr);
        column-gap: 25px;
        row-gap: 20px;
        padding: 0 20px;

        @media(max-width: 1200px) {
            gap: 15px;
        }

        @media(max-width: 1100px) {
            grid-template-columns: repeat(2, 2fr);
        }
        @media(max-width: 1024px) {
            grid-template-columns: repeat(3, 2fr);
        }
        @media(max-width: 920px) {
            grid-template-columns: repeat(2, 2fr);
        }
        @media(max-width: 560px) {
            grid-template-columns: repeat(1, 2fr);
        }
    }
}