* {
    box-sizing: border-box;
}
.pageLoader{
    padding: 20px;
}

.manageSubscriptionPanel {
    /* background: #fff; */
    max-width: 1600px;
    padding: 0 15px;
    margin: 0 auto;

    @media(max-width: 768px) {
        padding: 20px;
    }
}

.userDetals {
    display: flex;
    align-items: center;
    gap: 20px;
}

.avatar {
    width: 100px;
    height: 100px;
}

.billingInformationTitle {
    margin: 50px 0 70px 0;
    font-weight: 600;
    font-size: 40px;
    line-height: 56px;
    text-align: center;
    letter-spacing: -1.25px;
    color: #3D3D3D;

    @media(max-width: 1650px) {
        width: 100%;
        margin-bottom: 20px;
    }

    @media(max-width: 560px) {
        font-size: 32px;
        font-weight: 700;
        line-height: normal;
    }
}

.tableBox {
    background: #FFFFFF;
    border: 1px solid #E6E6E6;
    margin-bottom: 80px;
    overflow-x: auto;

    @media(max-width: 1200px) {
        /* padding: 20px; */
    }

    @media(max-width: 1360px) {
        display: block;
        width: 100%;
        overflow-x: auto;
    }

    & .tableStriped {
        overflow-x: auto !important;
        width: 100% !important;

        & td {
            border-left: 0;
            border-right: 0;
        }

        & th {
            border-left: 0;
            border-right: 0;
            border-top: 0;

            @media(max-width:1024px) {
                min-width: 300px;
            }

            @media(max-width:768px) {
                min-width: 200px;
            }
        }

        & tbody {
            & tr {
                background-color: #fff;
            }
        }

        & .subscriptionHeading {
            & th {
                padding: 20px 30px;
                color: var(--dark-grey, #3D3D3D);
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 32px;
                letter-spacing: -0.089px;
                text-align: left;
                border-bottom: 2px solid #E6E6E6;

                @media(max-width: 768px) {
                    font-size: 16px;
                    line-height: 32px;
                    padding: 18px 15px;
                }

                &:nth-child(1) {
                    /* padding-right: 0; */
                }

                &:nth-child(2) {
                    /* padding-left: 0;
                    padding-right: 0; */
                }

                &:nth-child(3) {
                    /* padding-left: 0; */
                }
            }
        }

        & .subscriptionData {
            & td {
                padding: 28px 30px;

                @media(max-width: 768px) {
                    padding: 18px 15px;
                }

                & .subscriptionPlanBody {
                    display: flex;
                    /* justify-content: center; */
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: -0.0888889px;
                    color: #3D3D3D;
                    /* text-align: center; */

                    @media(max-width: 768px) {
                        font-size: 14px;
                    }
                }

                & .awaitingPayment {
                    color: #FA0;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: -0.089px;

                    @media(max-width: 768px) {
                        font-size: 14px;
                    }
                }

                & .paidPayment {
                    color: #21A95B;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: -0.089px;

                    @media(max-width: 768px) {
                        font-size: 14px;
                    }
                }
            }


        }

        & .leftTd {
            width: 25%;
        }

        & .centerTd {
            /* text-align: center; */
            width: 25%;
            /* padding-left: 0 !important; */
        }

        & .rightTd {
            width: 25%;
            /* text-align: center; */

            /* background-color: red; */
            & h2 {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.0888889px;
                color: #3D3D3D;
            }
        }
    }
}

.subscriptionButton {
    min-height: 48px;
    padding: 10px;

    @media(max-width: 600px) {
        font-size: 12px;
        padding: 0 5px;
        min-height: 30px;
    }
}

.nameHeading {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.1125px;
    color: #3D3D3D;
    margin-bottom: 9px;
    margin-top: 0;
}

.planWrapper {
    & .planCard {
        margin-bottom: 60px;
        background: #FFFFFF;
        border: 1px solid #E6E6E6;

        /* padding: 23px 30px; */
        & .wrapperBox {
            padding: 23px 30px 30px;

            @media(max-width: 560px) {
                padding: 20px 15px;
                display: flex;
                flex-direction: column;
            }

            & .teamInformation {
                margin-top: 15px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                @media(max-width: 560px) {
                    order: 3;
                }

                & .teamProfileIcon {
                    display: flex;

                    & .iconBox {
                        &:nth-child(1) {
                            margin-left: 0 !important;
                        }

                        & img {
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                        }

                        &:nth-of-type(even) {
                            margin-left: -16px;
                        }

                        &:nth-of-type(odd) {
                            margin-left: -16px;
                        }
                    }
                }
            }

            & .teamCount {
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                letter-spacing: -0.1125px;
                color: #3D3D3D;
            }
        }

        & .mobilePlanHeading {
            display: none;

            @media(max-width: 920px) {
                display: flex;
                align-items: center;
                justify-content: space-between;

                @media(max-width: 560px) {
                    order: 2;
                }
            }

            & .statusSub {
                background: #fff1d5;
                border-radius: 12px;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: -0.1px;
                color: #E49800;
                padding: 4px 14px 4px 24px;
                position: relative;

                &:before {
                    content: "";
                    position: absolute;
                    background: #E19600;
                    height: 6px;
                    width: 6px;
                    left: 11px;
                    border-radius: 50px;
                }
            }

            & .amountSub {
                & .planPrice {
                    display: flex;
                    gap: 6px;
                    align-items: baseline;

                    & .dollarIcon {
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 21px;
                        letter-spacing: -0.0888889px;
                        color: #3D3D3D;
                    }

                    & .price {
                        font-weight: 600;
                        font-size: 40px;
                        line-height: 40px;
                        letter-spacing: -1.25px;
                        color: #3D3D3D;

                        @media(max-width: 560px) {
                            font-size: 20px;
                        }
                    }

                    & .planPeriod {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 50px;
                        letter-spacing: -0.1125px;
                        color: #858585;

                        @media(max-width: 560px) {
                            font-size: 12px;
                            line-height: 24px;
                        }
                    }
                }
            }

        }

        & .planHeading {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media(max-width: 560px) {
                order: 1;
            }

            & .planLeftSide {
                & .planHeadingBox {
                    display: flex;
                    align-items: center;
                    margin-bottom: 9px;



                    & h3 {
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 27px;
                        display: flex;
                        align-items: center;
                        letter-spacing: -0.0888889px;
                        color: #3D3D3D;
                        margin: 0 25px 0 0;
                    }

                    & .activeBadge {
                        /* background: #03a84e; */
                        border-radius: 12px;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 18px;
                        display: flex;
                        align-items: center;
                        text-align: center;
                        letter-spacing: -0.1px;
                        color: #ffffff;
                        padding: 4px 14px 4px 24px;
                        position: relative;

                        @media(max-width: 920px) {
                            display: none;
                        }

                        &:before {
                            content: "";
                            position: absolute;
                            background: #fff;
                            height: 6px;
                            width: 6px;
                            left: 11px;
                            border-radius: 50px;
                        }
                    }

                    & .inActiveBadge {
                        background: #a13232;
                        border-radius: 12px;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 18px;
                        display: flex;
                        align-items: center;
                        text-align: center;
                        letter-spacing: -0.1px;
                        color: #ffffff;
                        padding: 4px 14px 4px 24px;
                        position: relative;

                        @media(max-width: 920px) {
                            display: none;
                        }

                        &:before {
                            content: "";
                            position: absolute;
                            background: #fff;
                            height: 6px;
                            width: 6px;
                            left: 11px;
                            border-radius: 50px;
                        }
                    }
                }

                & .planDescription {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    letter-spacing: -0.1125px;
                    color: #3D3D3D;
                    margin: 0;
                }
            }

            & .planRightSide {
                @media(max-width: 920px) {
                    display: none;
                }

                & .planPrice {
                    display: flex;
                    gap: 6px;

                    & .dollarIcon {
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 21px;
                        letter-spacing: -0.0888889px;
                        color: #3D3D3D;
                    }

                    & .price {
                        font-weight: 600;
                        font-size: 40px;
                        line-height: 40px;
                        letter-spacing: -1.25px;
                        color: #3D3D3D;
                    }

                    & .planPeriod {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 50px;
                        letter-spacing: -0.1125px;
                        color: #858585;
                    }
                }
            }
        }

        & .planBody {
            /* border-top: 1px solid #E6E6E6; */
            padding: 17px 30px 17px 30px;
            position: relative;

            @media(max-width: 560px) {
                padding: 20px 15px;
            }

            @media(max-width: 768px) {
                flex-wrap: wrap;
                gap: 11px;
            }

            &::before {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                height: 1px;
                width: 96%;
                background-color: #E6E6E6;
                transform: translate(2%, -50%);

                @media(max-width: 768px) {
                    width: 92%;
                    transform: translate(4%, -50%);
                }
            }

            & .planBodyLeft {
                @media(max-width: 600px) {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                }

                & .planrenewText {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    letter-spacing: -0.1125px;
                    color: #3D3D3D;

                    @media(max-width: 600px) {
                        font-size: 12px;
                    }
                }

                & .planrenewDate {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    letter-spacing: -0.1125px;
                    color: #3D3D3D;
                    margin-left: 10px;

                    @media(max-width: 600px) {
                        font-size: 12px;
                        line-height: normal;
                        margin-left: 0;
                    }
                }
            }

            & .switchBilling {
                font-size: 14px;
                cursor: pointer;
                font-weight: 400;
                color: #0B96F5;
                text-align: right;
                font-style: normal;
                line-height: normal;
                letter-spacing: -0.112px;
                text-decoration-line: underline;

                @media(max-width: 600px) {
                    font-size: 12px;
                }
            }
        }

        & .planRow {
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            justify-content: space-between;
            gap: 5px;

            &>div {
                @media(max-width: 600px) {
                    flex: 1;
                }
            }
        }
    }
}

.mobileActive {
    /* background: #03a84e; */
    border-radius: 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.1px;
    color: #ffffff;
    padding: 4px 14px 4px 24px;
    position: relative;

    &:before {
        content: "";
        position: absolute;
        background: #fff;
        height: 6px;
        width: 6px;
        left: 11px;
        border-radius: 50px;
    }
}

.inMobileInActiveBadge {
    background: #a13232;
    border-radius: 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.1px;
    color: #ffffff;
    padding: 4px 14px 4px 24px;
    position: relative;

    &:before {
        content: "";
        position: absolute;
        background: #fff;
        height: 6px;
        width: 6px;
        left: 11px;
        border-radius: 50px;
    }
}

.profileImage {
    text-align: center;
    border-radius: 50%;
    margin: 0;
    padding: .5rem;
    background-color: rgb(47, 164, 248);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border: 1px solid #fff;
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: red;
}

.headingTopBox {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    @media(max-width: 1650px) {
        flex-wrap: wrap;
        margin-bottom: 50px;
    }
}

.subscriptionPageRedirectButton {
    position: absolute;
    right: 0;
    border: none;
    border-radius: var(--borderRadius);
    background-color: var(--successColorDark);
    color: var(--matterColorLight);
    text-align: center;
    text-decoration: none;
    transition: all var(--transitionStyleButton);
    cursor: pointer;
    min-height: 42px;
    padding: 10px;
    display: flex;
    align-items: center;

    @media(max-width: 1650px) {
        position: relative;
        right: unset;
    }

    &:hover {
        text-decoration: none;
        outline: none;
        color: var(--matterColorDark);
        background-color: var(--marketplaceColor);
        box-shadow: var(--boxShadowButton);
    }
}

.csmIconBox {
    border: 3px solid #FA0 !important;
    border-radius: 50%;
    position: relative;
    cursor: pointer;

    &:hover {
        &::before {
            visibility: visible;
        }
    }

    &::before {
        visibility: hidden;
        content: attr(data-tooltip);
        background-color: white;
        color: #000;
        border-radius: 6px;
        padding: 10px;
        position: absolute;
        z-index: 1;
        width: 400px;
        top: -12px;
        left: 7em;
        box-shadow: 0px 8px 16px rgb(0 0 0 / 30%);
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.25px;
    }

    &::after {
        content: "CSM";
        position: absolute;
        left: 50%;
        bottom: -3px;
        transform: translate(-50%, 0);
        background-color: #FDECCA;
        border-radius: 2px;
        width: 38px;
        height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #E49800;
        text-align: center;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.087px;
    }

    &>div {
        border: none;
    }
}
.buttonsContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 3em;

    & .submitButton {
      width: 228px;
    }
  }
  .successMessage {
    padding: 0.75rem 0;
    border: 1px solid transparent;
    text-align: left;
    font-size: 12px;
    line-height: 25px;
    font-weight: 500;
    & .tosterHeading {
        color: #3D3D3D;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 40px;
        letter-spacing: -0.9px;
        margin-bottom: 30px;
      }
      
     &  .tosterSubHeadig {
        & p {
          color: #3D3D3D;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
      
          & a {
            margin-left: 5px;
            color: #0B96F5;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            text-decoration-line: underline;
          }
        }
      }
  }
  .offlineSubscriptionConfirmationHeading {
    color: #3D3D3D;
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: -0.9px;
  }

.stausActive{
    background: #03a84e
}

.stausInactive{
    background: var(--failColor)
}

.stausExpiringSoon{
    background: #f6b430
}

.stausExpired{
    background: var(--failColor)
}