@import '../../styles/propertySets.css';

.root {
  position: relative;
  /* height: 100vh; */
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 20px;

  @media(max-width: 768px) {
    padding: 0;
  }

  @media (--viewportLarge) {
    max-width: 1300px;
    margin: 0 auto 57px;
  }

  @media (--viewportXLarge) {
    max-width: 1200px;
  }
}

.txInfo {
  margin-bottom: 210px;

  @media (--viewportLarge) {
    flex-basis: 538px;
    flex-grow: 0;
    flex-shrink: 1;
    margin-right: 56px;
    margin-bottom: 0;
  }
}

.genericError {
  color: var(--failColor);
  margin: 24px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 32px 24px 0 24px;
  }

  @media (--viewportLarge) {
    margin: 32px 0 0 0;
  }
}

/* DetailCardImage subcomponent */
.detailCardImageWrapper {
  /* Layout */
  display: none;
  width: 100%;
  position: relative;

  @media (--viewportLarge) {
    display: block;
    max-height: 268px;
    overflow-y: hidden;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 66.6667%;
  /* 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative);
  /* Loading BG color */
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  @media (--viewportLarge) {
    border-radius: 2px 2px 0 0;
  }
}

.avatarWrapper {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -30px;

  /* Rendering context to the same lavel as listing image */
  position: relative;
  /* Flex would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    margin-top: -32px;
    padding: 2px 0;
  }

  @media (--viewportLarge) {
    margin-left: 48px;
    width: unset;
    padding: 2px 0;
  }
}

/* Passed-in props for DetailCardImage subcomponent */
.imageWrapperMobile {
  position: relative;

  @media (--viewportLarge) {
    display: none;
  }
}

.avatarWrapperMobile {
  composes: avatarWrapper;

  @media (--viewportLarge) {
    display: none;
  }
}

.avatarWrapperDesktop {
  composes: avatarWrapper;
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.avatarWrapperProviderDesktop {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-top: 119px;
  }
}

.avatarDesktop {
  @media (--viewportLarge) {
    display: flex;
  }
}

/* PanelHeadings subcomponent */
.headingOrder {
  margin: 29px 24px 0 24px;

  @media (--viewportMedium) {
    max-width: 80%;
    margin: 24px 24px 0 24px;
    padding: 1px 0 7px 0;
  }

  @media (--viewportLarge) {
    max-width: 100%;
    margin: 152px 0 0 0;
    padding: 0;
  }
}

.headingSale {
  margin: 18px 24px 0 24px;
  padding: 5px 0 1px 0;

  @media (--viewportMedium) {
    max-width: 80%;
    margin: 24px 24px 0 24px;
    padding: 1px 0 7px 0;
  }

  @media (--viewportLarge) {
    max-width: 100%;
    margin: 42px 0 0 0;
    padding: 0;
  }
}

.mainTitle {
  display: block;
}

.transactionInfoMessage {
  margin: 18px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 23px 24px 0 24px;
  }

  @media (--viewportLarge) {
    margin: 23px 0 0 0;
  }
}

/* Container for booking details in mobile layout */
.bookingDetailsMobile {
  margin-top: 47px;

  @media (--viewportMedium) {
    margin-top: 40px;
    padding: 4px 0 0px 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

/* "aside" section in desktop layout */
.asideDesktop {
  margin: 1px 0 0 0;

  /**
 * Aside is visible on mobile layout too, since it includes BookingPanel component.
 * It might get rendered as a Modal in mobile layout.
 */

  @media (--viewportLarge) {
    margin: 123px 0 0 0;
  }
}

.detailCard {
  @media (--viewportLarge) {
    position: sticky;
    top: -200px;
    /* This is a hack to showcase how the component would look when the image isn't sticky */
    width: 409px;
    background-color: var(--matterColorLight);
    border: 1px solid var(--matterColorNegative);
    border-radius: 2px;
    z-index: 1;
  }
}

/* DetailCardHeadingsMaybe subcomponent */
.detailCardHeadings {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin: 0 48px 33px 48px;
  }
}

.detailCardTitle {
  margin-bottom: 10px;

  @media (--viewportLarge) {
    margin-top: 14px;
    margin-bottom: 0;
  }
}

.detailCardSubtitle {
  @apply --marketplaceH5FontStyles;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 9px;
    margin-bottom: 0;
  }
}

/* AddressLinkMaybe subcomponent */
.address {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  margin: 0;
}

/* Passed-in props for AddressLinkMaybe subcomponent */
.addressMobile {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  margin: 0 24px 24px 24px;
}

/* BreakdownMaybe subcomponent */
.breakdownMaybe {
  /* default "root" class for breakdown container */
}

.bookingBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin: 0 24px 0 24px;

  @media (--viewportLarge) {
    margin: 37px 48px 26px 48px;
    margin: 32px 48px 24px 48px;
    padding: 4px 0 4px 0;
  }
}

.breakdown {
  margin: 14px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 18px 24px 0 24px;
  }

  @media (--viewportLarge) {
    margin: 24px 48px 47px 48px;
    padding: 6px 0 2px 0;
  }
}

.breakdownContainer {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

/* FeedSection subcomponent */
.feedHeading {
  color: var(--matterColorAnti);
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.messageError {
  color: var(--failColor);
  margin: 13px 0 22px 0;

  @media (--viewportMedium) {
    margin: 13px 0 23px 0;
  }

  @media (--viewportLarge) {
    margin: 12px 0 23px 0;
  }
}

/* Passed-in props for FeedSection subcomponent */
.feedContainer {
  margin: 0 24px;

  @media (--viewportLarge) {
    margin: 0;
  }
}

/* Prop to be passed to SendMessageForm component */
.sendMessageForm {
  position: relative;
  margin: 46px 24px 0 24px;

  &.textarea {
    line-height: 36px;
  }

  @media (--viewportMedium) {
    margin: 49px 24px 0 24px;
    border-top: 0;
  }

  @media (--viewportLarge) {
    padding-right: 24px;
    margin: 47px 0 0 0;
  }
}

.sendingMessageNotAllowed {
  color: var(--matterColorAnti);

  position: relative;
  margin: 46px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 49px 24px 0 24px;
    border-top: 0;
  }

  @media (--viewportLarge) {
    margin: 47px 0 0 0;
  }
}

/* SaleActionButtonsMaybe subcomponent */
.actionButtons {
  /* Position action button row above the footer */
  z-index: 9;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 18px 24px 18px 24px;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  box-shadow: var(--boxShadowTop);
  background-color: white;

  @media (--viewportLarge) {
    z-index: unset;
    position: static;
    box-shadow: none;
    width: auto;
    margin: 0 48px 0 48px;
    padding: 0;
  }
}

.actionButtonWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;

  @media (--viewportLarge) {
    flex-direction: column;
  }

  & button:first-child {
    margin: 0 12px 0 0;

    @media (--viewportLarge) {
      margin: 8px 0 0 0;

      /* Switch order in desktop layout with accept button being on the top */
      order: 1;
    }
  }
}

.actionError {
  @apply --marketplaceH5FontStyles;
  color: var(--failColor);
  margin: 0 0 11px 0;

  @media (--viewportMedium) {
    margin: 0 0 10px 0;
  }

  @media (--viewportLarge) {
    margin: 0;
  }
}

.actionErrors {
  width: 100%;
  text-align: center;

  @media (--viewportLarge) {
    position: absolute;
    top: 151px;
  }
}

/* Container for SaleActionButtonsMaybe subcomponent */
.mobileActionButtons {
  display: block;

  @media (--viewportLarge) {
    display: none;
  }
}

.desktopActionButtons {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-bottom: 48px;
  }
}

/* BookingPanel subcompnent */
.bookingPanel {
  margin: 16px 48px 48px 48px;
}

.bookingTitle {
  /* Font */
  color: var(--matterColor);
  margin-top: 0;
  margin-bottom: 1px;
}

.tabIcon {
  margin-right: 10px;

  @media(max-width: 1024px) {
    display: none;
  }
}

.tabBoxWrapper {
  display: flex;
  margin-top: 40px;
  gap: 8px;
  margin-bottom: 30px;

  @media(max-width: 1024px) {
    flex-wrap: wrap;
    gap: 0;
    margin-top: 0px;
  }

  & .mobileOnlineBox {
    display: none;

    @media(max-width: 768px) {
      display: block;
      text-align: right;
      width: 100%;
      /* box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 5%); */
      border-bottom: 1px solid #E6E6E6;
      padding: 20px 22px;
    }
  }

  & .tabBarLinks {
    display: flex;
    flex-direction: column;
    width: 264px;

    &::-webkit-scrollbar {
      display: none;
    }

    @media(max-width: 1024px) {
      flex-direction: row;
      width: 100%;
      align-items: baseline;
      gap: 20px;
      overflow-x: auto;
      padding-bottom: 6px !important;
      border-bottom: 1px solid #E6E6E6;
      margin-bottom: 4px;
    }

    @media(max-width: 768px) {
      padding: 10px 24px;
      box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 5%);
    }

    & .tabsSelected {
      background-color: #f4f8fb;
      padding: 7px 0 7px 21px;
      position: relative;
      display: flex;
      align-items: center;

      @media(max-width: 1024px) {
        background-color: #fff;
        padding: 0;
        border-bottom: 2px solid #0B96F5;
      }

      &:before {
        content: "";
        position: absolute;
        left: 0;
        height: 100%;
        background: #0B96F5;
        width: 3px;
        top: 0;
        bottom: 0;

        @media(max-width: 1024px) {
          display: none;
        }
      }

      & .tabName {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.25px;
        color: #000;

        @media(max-width: 768px) {
          font-size: 16px;
          font-weight: 500;
          line-height: normal;
        }
      }
    }



    & .tabNavLink {
      cursor: pointer;
      padding-left: 20px;
      padding: 7px 0 7px 21px;
      display: flex;
      align-items: center;

      @media(max-width: 1024px) {
        padding: 0;
      }

      & .tabName {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.25px;
        color: #3D3D3D;
        display: flex;
        align-items: center;

        @media(max-width: 768px) {
          font-size: 16px;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
  }

  & .rightSideTabsBar {
    width: 80%;

    @media(max-width: 1024px) {
      width: 100%;
    }

    & .accordionWrapper {
      & .statusBar {
        background: #FFFFFF;
        padding: 30px 40px;
        border: 1px solid #E6E6E6;
        display: flex;
        align-items: center;
        margin-top: 5px;

        @media(max-width: 1024px) {
          flex-wrap: wrap;
          padding: 20px 25px;
          position: relative;
        }

        & h4 {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 32px;
          letter-spacing: -0.0888889px;
          color: #3D3D3D;
          margin: 0;
          min-width: 30%;

          @media(max-width: 768px) {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 20px;
          }
        }

        & .rightSideStatus {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          gap: 19px;

          @media(max-width: 768px) {
            flex-wrap: wrap;
            row-gap: 10px;
          }

          & .responseText {
            display: flex;

            & .statusIcon {
              margin-right: 19px;

              @media(max-width: 768px) {
                margin-right: 9px;
              }

              & svg {
                @media(max-width: 768px) {
                  width: 36px;
                }
              }
            }

            & .statusText {
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: #3D3D3D;
              align-self: center;

              & p {
                margin: 0;
                font-weight: 400;
                font-size: 14px;
              }

              @media(max-width: 768px) {
                font-size: 14px;
              }
            }
          }

          & .actionButton {
            display: flex;
            align-items: center;

            @media(max-width: 768px) {
              width: 100%;
              flex-wrap: wrap;
              gap: 10px;
            }

            & button {
              height: 48px;
              width: max-content;
              padding: 0 20px;
              min-height: 48px;
              margin-left: 15px;

              @media(max-width: 768px) {
                margin-left: 0;
                margin-right: 10px;
              }

              @media(max-width: 420px) {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

.askedModalBox {
  left: unset !important;
  right: 0 !important;

  @media(max-width: 768px) {
    width: fit-content !important;
  }

  @media(max-width: 539px) {
    left: 0 !important;
  }
}

.briefCardWrapper {
  background: #FFFFFF;
  margin-top: 4px;
  border: 1px solid #E6E6E6;
  padding: 30px 40px;

  @media(max-width: 768px) {
    padding: 20px 25px;
  }

  & .briefCard {
    display: grid;
    margin-bottom: 20px;
    grid-template-columns: 1.3fr 3fr;

    @media(max-width: 768px) {
      row-gap: 10px;
      grid-template-columns: 2fr 3fr;
    }

    & h4 {
      font-family: 'Poppins';
      color: var(--dark-grey, #3D3D3D);
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: -0.089px;
      margin: 0;
      min-width: 30%;

      @media(max-width: 1024px) {
        min-width: 40%;
      }

      @media(max-width: 560px) {
        font-size: 14px;
        line-height: 20px;
        width: 100%;
      }
    }

    & h5 {
      color: var(--dark-grey, #3D3D3D);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      margin: 0;
      min-width: 30%;

      @media(max-width: 1024px) {
        min-width: 40%;
      }

      @media(max-width: 560px) {
        width: 100%;
        font-size: 12px;
      }
    }
  }

  & .mainHeading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: -0.0888889px;
    color: #3D3D3D;
    margin: 0;
    min-width: 30%;
    /* margin-bottom: 20px; */
  }

  & .mediaAttachment {
    display: flex;
    flex-direction: column;
    gap: 10px;

    & .fileList {
      display: flex;
      gap: 10px;

      &:hover {
        text-decoration: none;
      }

      & .documentImage {

        & img {
          width: 30px;
          height: 41px;

          @media(max-width: 560px) {
            width: 20px;
            height: 25px;
          }
        }
      }

      & .documentName {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.0875px;
        color: #3D3D3D;
      }

      & .documentDate {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: -0.0875px;
        color: #B2B2B2;
      }
    }
  }

  & .rightSideDetails {
    width: 80%;
  }
}

.briefDescription {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.0888889px;
  color: #3D3D3D;
  /* width: 68%; */

  @media(max-width: 560px) {
    font-size: 12px;
  }
}

.containerChatBlock {
  /*max-width: 611px;*/
  /* margin: 0 13px; */
  background: var(--matterColorLight);
  display: flex;

  @media(max-width: 768px) {
    flex-direction: column;
  }

  & .chatMembersMobile {
    display: block !important;
    padding-top: 10px !important;

    & .memberCard {
      left: 3em !important;
      z-index: 10;
    }
  }

  & .chatMembers {
    padding: 0 10px 0 16px;
    background: #fcfcfc;
    flex: 1;

    @media(max-width: 768px) {
      display: none;
    }

    & h3 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 32px;
      margin-bottom: 7px;
      margin-top: 0;
    }

    & .memberTab {
      display: flex;
      align-items: flex-end;
      margin-bottom: 10px;
      position: relative;
      cursor: pointer;

      &:hover {
        & .memberCard {
          visibility: visible;
        }
      }
    }

    & .memberCard {
      visibility: hidden;
      width: 223px;
      /* height: 174px; */
      background: #FFFFFF;
      /* border: 1px solid #E6E6E6; */
      position: absolute;
      left: -14em;
      top: 3px;
      padding: 10px;
      box-sizing: border-box;
      box-shadow: 0px 8px 16px 0px #0000004D;

      & .memberAvatar {
        display: flex;
        column-gap: 10px;

        & h3 {
          margin: 0;
          grid-column: span 2;
          font-weight: 400;
          font-size: 16px;
          margin-bottom: -4px;
        }

        & a {
          color: #B2B2B2;
        }

        & h4 {
          margin: 0;
          grid-column: span 2;
          font-weight: 400;
          font-size: 12px;
          color: #B2B2B2;
        }
      }

      & .dividerLine {
        display: block;
        width: 100%;
        height: 1.7px;
        background: #E6E6E6;
        margin: 8px 0 8px;
      }

      & .memberDetails {
        margin-bottom: 10px;
        word-wrap: break-word;

        & h4 {
          margin: 0;
          font-weight: 400;
          font-size: 12px;
        }
      }
    }
  }

  & .csmBadge {
    border: 3px solid #FA0;
    border-radius: 50%;
    height: fit-content;

    &::after {
      content: "CSM";
      position: absolute;
      left: 13%;
      bottom: -4px;
      background-color: #FDECCA;
      border-radius: 2px;
      width: 35px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #E49800;
      font-size: 10px;
      font-weight: 600;
    }
  }

  & .csmTooltip {
    &:hover {
      &::before {
        visibility: visible;
      }
    }

    &::before {
      visibility: hidden;
      content: attr(data-tooltip);
      background-color: white;
      color: #000;
      border-radius: 6px;
      padding: 10px;
      position: absolute;
      z-index: 1;
      width: 400px;
      left: -15em;
      top: -6em;
      box-shadow: 0px 8px 16px rgb(0 0 0 / 30%);
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.25px;
    }
  }

  @media (--viewportLarge) {
    margin: 0;
    /* width: 494px; */
  }
}

.chatContentMessage {
  height: auto;

  @media (--viewportLarge) {
    margin: 0;
    max-width: 494px;
  }
}

.chatContainerBlock {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid var(--matterColorNegative);
  flex: 2;
  padding: 20px;

  @media(max-width: 920px) {
    padding: 0;
  }

  @media (--viewportLarge) {
    margin: 0;
    /* max-width: 494px; */
  }

  @media(max-width: 460px) {
    /* padding: 0 0 15px 0; */
  }
}

.header {
  display: flex;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #E6E6E6;
  padding: 28px 57px;
  justify-content: space-between;
  margin-top: 23px;

  @media(max-width: 768px) {
    padding: 30px 24px;
    margin-top: 0;
    flex-wrap: wrap;
    gap: 20px;
  }

  & .leftHeader {
    & .profileBox {
      display: flex;
      align-items: center;

      @media(max-width: 460px) {
        flex-wrap: wrap;
        gap: 10px;
      }
    }

    & h1 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 56px;
      letter-spacing: -0.9375px;
      color: #3D3D3D;
      margin-top: 0;
      margin-bottom: 19px;

      @media(max-width: 768px) {
        color: var(--black, #464646);
        font-size: 24px;
        font-weight: 500;
        line-height: normal;
      }
    }

    & .meetingImage {
      position: relative;
      margin-right: 20px;

      & a,
      img,
      div {
        width: 64px;
        height: 64px;
        object-fit: cover;
      }

      & .activeIcon {
        position: absolute;
        bottom: 4px;
        height: 14px;
        width: 14px;
        background-color: #2ECC71;
        border-radius: 50%;
        border: 2px solid #fff;
        right: 0px;
        z-index: 1;
      }

      & .offlineIcon {
        position: absolute;
        bottom: 4px;
        height: 14px;
        width: 14px;
        background-color: red;
        border-radius: 50%;
        border: 2px solid #fff;
        right: 0px;
        z-index: 1;
      }
    }

    & .meetingDetail {
      & .name {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;
        color: #0B96F5;
      }

      & .location {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.0875px;
        color: #3D3D3D;
        margin: 6px 0;

        & .locationIcon {
          margin-right: 10px;
        }
      }

      & .review {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: -0.1px;
        color: #3D3D3D;

        & .starIcon {
          margin-right: 10px;
        }

        & .reviewCount {
          color: #B2B2B2;
        }
      }
    }
  }
}

.toolsGrid {
  display: grid;
  grid-template-columns: repeat(3, 2fr);
  column-gap: 25px;
  row-gap: 20px;

  @media(max-width: 1200px) {
    gap: 15px;
  }

  @media(max-width: 1024px) {
    margin-top: 30px;
  }

  @media(max-width: 768px) {
    grid-template-columns: repeat(2, 2fr);
    padding: 0 24px;
  }

  @media(max-width: 560px) {
    grid-template-columns: repeat(1, 2fr);
  }
}

.accordionItem {
  background: var(--matterColorLight);
}

.titleContentBlock {
  line-height: 32px;
  color: var(--successColorLight);
  font-weight: var(--fontWeightSemiBold);
}

.titleName {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 32px;
  letter-spacing: -0.0888889px;
  color: #3D3D3D;
}

.cardImage {
  position: relative;
  height: fit-content;

  & img {
    width: 47px;
    height: 47px;
    border-radius: 50%;
  }

  & .profileImage {
    width: 47px;
    height: 47px;
  }

  & .offlineDot,
  .onlineDot {
    bottom: 3px;
    right: 0px;
    z-index: 10;
  }
}

.imagesBox {
  margin-right: 11px;
  position: relative;
  border: 3px solid transparent;
  border-radius: 50%;

  & img {
    width: 31px;
    height: 31px;
    border-radius: 50%;
  }

  &::after {
    display: none !important;
  }
}

.csmImageBox {
  & img {
    border-radius: 50%;
    border: 3px solid #FA0;
  }

  &:hover {
    &::before {
      visibility: visible;
    }
  }

  &::before {
    visibility: hidden;
    content: attr(data-tooltip);
    background-color: white;
    color: #000;
    border-radius: 6px;
    padding: 20px 10px;
    position: absolute;
    z-index: 1;
    width: 400px;
    top: -5px;
    left: 7em;
    box-shadow: 0px 8px 16px rgb(0 0 0 / 30%);
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.25px;

    @media(max-width: 768px){
      width: 250px;
    }
  }

  &::after {
    content: "CSM";
    position: absolute;
    left: 50%;
    bottom: -3px;
    transform: translate(-50%, 0);
    background-color: #FDECCA;
    border-radius: 2px;
    width: 54px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #E49800;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.087px;
  }
}

.accordionTitle {
  display: flex;
  height: 70px;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--matterColorNegative);
  width: 100%;
  /* margin-top: 10px; */
  padding: 0 24px;
  user-select: none;
}

.accordionTitleContent {
  display: flex;
  align-items: center;
}

.accordionStatusContent {
  margin-left: 38px;
}



.briefInfoBlock {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* border: 1px solid var(--matterColorNegative); */
  width: 100%;
  /* padding: 20px 42px; */

  & .accordionContent {
    margin-top: 12px;

    @media(max-width: 768px) {
      padding: 0 20px;
    }
  }
}

.itemAccordionBox {
  width: 100%;
  display: flex;

  & p {
    margin-top: 9px;
  }
}

.itemAccordionTitle {
  width: 170px;
  min-width: 170px;
  font-size: 14px;
  margin-right: 19px;
  line-height: 20px;
  font-weight: var(--fontWeightSemiBold);
}

.accordionContent {
  font-size: 14px;
  line-height: 20px;
  font-weight: var(--fontWeightRegular);
  word-break: break-word;
}

.titleContainer {
  text-align: center;

  @media (--viewportLarge) {
    margin-left: 0;
    text-align: left;
  }
}

.wrapperButtonProposal {
  display: flex;
  margin-top: 11px;
  height: 64px;
  justify-content: flex-end;
}

.buttonDecline {
  @apply --marketplaceButtonStyles;
  max-width: 150px;
  width: 150px;
  margin-left: 15px;
  color: var(--matterColorDark);
  background: var(--matterColorLight);
  border: 1px solid #E6E6E6;
  box-sizing: border-box;
  border-radius: 4px;
  min-height: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: var(--matterColorLight);
  }
}

.buttonAction {
  @apply --marketplaceButtonStyles;
  max-width: 150px;
  width: 150px;
  min-height: 48px;
  display: flex;
  height: 48px;
  align-items: center;
  justify-content: center;
}

.tdTitle {
  padding-top: 9px;
}

.trCheckbox {
  & label {
    & span {
      & svg {
        /* height: 10px; */
      }

      &:nth-child(1) {
        height: unset;
        margin: 3px 21px 0 0;
      }

      &:nth-child(2) {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #3D3D3D;
      }
    }
  }
}

.wrapperMilestones {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    width: 100%;
    /*padding: 0 27px 0 24px;*/
    border-bottom: 1px solid #E6E6E6;
    user-select: none;
  }
}

.lastItemMilestone {
  &:last-child {
    border-bottom: none;
  }
}

.tableWrapper {
  width: 100%;

  & th {
    border: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #858585;
    padding-bottom: 11px;
    text-align: center;

    @media(max-width: 768px) {
      font-size: 12px;
    }

    &:nth-child(1) {
      text-align: left;
      padding-left: 51px;

      @media(max-width: 768px) {
        padding: 10px 55px;
        width: 100%;
        min-width: 100%;
      }
    }

    &:nth-child(2), &:nth-child(3), &:nth-child(4) {
      @media(max-width: 768px) {
        display: none;
      }
    }
  }

  & tbody {
    @media (max-width: 768px) {
      & td{
        display: none;
      }
      & .firstTd, .lastTd{
        display: block;
        width: 100% !important;
      }
    }

    & .firstTd {
      position: relative;

      & .openMilestone {
        position: absolute;
        right: 0;
        top: 14px;
        cursor: pointer;

        @media(max-width: 768px) {
          right: 15px;
        }

        & svg {
          @media(max-width: 768px) {
            width: 46px;
          }
        }
      }
    }

    & .selectSuccess {
      background-color: #e8f7ee;

      & td {
        &:nth-child(1) {
          border-left: 6px solid #2ECC71;
        }
      }
    }

    & .dangerRed {
      background-color: #fff;

      & td {
        &:nth-child(1) {
          border-left: 6px solid #F6B430;
        }
      }
    }

    & .unSelect {
      background-color: #fff;

      & td {
        &:nth-child(1) {
          border-left: 6px solid #FF0000;
        }
      }
    }

    & tr {
      background-color: #fff;

      & td {
        text-align: center;
        /* background-color: #fff; */
        border-left: 0;
        border-right: 0;
        border-top: 1px solid #E6E6E6;
        border-bottom: 1px solid #E6E6E6;
        padding: 14px 10px;
        width: 20%;

        &:nth-child(1) {
          text-align: left;
          border-left: 6px solid #E6E6E6;
          padding-left: 15px;
          width: 40%;
        }

        &:last-child {
          border-right: 1px solid #E6E6E6;
        }

        & .selectOwnerBox {
          & img {
            max-width: 40px;
            max-height: 40px;
            min-width: 40px;
            width: 40px;
            height: 40px;
            margin: auto;
          }

          & .noImageWithText {
            max-width: 40px;
            max-height: 40px;
            min-width: 40px;
            width: 40px;
            height: 40px;
            margin: auto;
          }
        }

        & .addMilestone {
          border: none;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: -0.0888889px;
          color: #858585;
          padding-left: 35px;

          @media(max-width: 768px) {
            font-size: 12px;
          }
        }
      }

      & .milestoneBox {
        display: flex;
        align-items: center;
        gap: 20px;
        padding-right: 70px;

        & input[type=checkbox] {
          accent-color: #3f9f68;
        }

        & .milestonesTitle {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: -0.0888889px;
          color: #3D3D3D;
          text-transform: capitalize;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          cursor: pointer;

          &:hover {
            &::after {
              visibility: visible;
            }
          }

          &::after {
            visibility: hidden;
            content: attr(data-tooltip);
            width: fit-content;
            padding: 5px 15px;
            position: absolute;
            top: 40px;
            left: 56px;
            background: #FFFFFF;
            box-shadow: 0px 8px 16px rgb(0 0 0 / 30%);
            border-radius: 4px;
            margin-top: 8px;
            z-index: 10;
            text-align: center;
            font-size: 13px;
          }

          @media(max-width: 768px) {
            font-size: 12px;
          }
        }
      }

      & .profileDate {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #3D3D3D;

        @media(max-width: 768px) {
          font-size: 12px;
        }
      }

      & .dependencyBox {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #3D3D3D;
        text-transform: capitalize;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;

        @media(max-width: 768px) {
          font-size: 12px;
        }
      }
    }
  }
}

.dangerAlert {
  background-color: #FFE5E5;
  padding: 11px 0;
  width: 100%;
  margin-top: 15px;
  position: relative;
  padding-right: 24px;

  & .dueBox {
    margin: 0;
    font-style: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.0888889px;
    color: #3D3D3D;

    & .dueBold {
      font-weight: 700;
    }

    & .descriptionDue {
      font-weight: 400;
    }
  }

  & .closeButton {
    top: 5px;
    position: absolute;
    left: 0;
    right: 15px;
    margin-left: auto;
    width: 15px;
    cursor: pointer;

    & svg {
      width: 15px;
      height: 15px;

      & path {
        fill: #000;
        stroke: #000;
      }
    }
  }
}

.warningAlert {
  background-color: #FDF0D6;
  padding: 11px 0;
  width: 100%;
  margin-top: 15px;
  position: relative;

  & .dueBox {
    margin: 0;
    font-style: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.0888889px;
    color: #3D3D3D;

    & .dueBold {
      font-weight: 700;
    }

    & .descriptionDue {
      font-weight: 400;
    }
  }

  & .closeButton {
    top: 5px;
    position: absolute;
    left: 0;
    right: 15px;
    margin-left: auto;
    width: 15px;
    cursor: pointer;

    & svg {
      width: 15px;
      height: 15px;

      & path {
        fill: #000;
        stroke: #000;
      }
    }
  }
}

.progressBox {
  width: 300px;
  margin-top: 16px;

  @media(max-width: 768px) {
    margin-left: 20px;
  }

  & .progressHeading {
    color: var(--black, #000);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    @media(max-width: 768px) {
      font-size: 10px;
    }
  }

  & .progressBarBox {
    display: flex;
    align-items: center;
    gap: 9px;

    & .progressBar {
      background-color: #E6E6E6;
      width: 100%;
      height: 10px;
    }

    & .progressPercent {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #000000;
    }
  }
}

.modalHeading {
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -0.9px;
  margin: 20px 0;
}

.milestoneDetailsHeading{
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  margin-top: 3em;
}

.milestoneWrapper {
  & .descriptionText {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.0888889px;
    margin: 0;
  }

  & .toggleMilestoneOwnerPanel {
    margin-top: 30px;

    & .milesList {
      display: flex;
      /* align-items: center; */
      margin-bottom: 25px;

      & .milesHeading {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #3D3D3D;
        min-width: 120px;
      }

      & .headingNameImage {
        display: flex;
        align-items: center;
        gap: 6px;

        & .ownerName {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #3D3D3D;
        }

        & img {}
      }
    }

    & .bottomButton {
      display: flex;
      align-items: center;
      margin-top: 50px;

      & button {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.266667px;
        color: #3D3D3D;

        @media(max-width: 768px) {
          min-height: 40px;
        }
      }

      & .withOutBorder {
        border: none;
        text-align: left;

        &:hover {
          box-shadow: none;
        }
      }
    }
  }
}

.trCheckboxText {
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  width: 100px;
}

.trCheckboxTextDecorate {
  text-decoration: line-through;
  color: #858585 !important;
}

.tdContentBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.trTitleContent {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.0888889px;
  color: #3D3D3D;
  margin-bottom: 10px;
}

.trContent {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.0888889px;
  color: #3D3D3D;
}

.buttonAddMilestone {
  @apply --marketplaceButtonStyles;
  font-size: 13px;
  width: 138px;
  min-height: 40px;
  margin-bottom: 2px;
  padding: 0;
  background: var(--matterColorLight);
  color: var(--successColorDark);
  border: 1px solid var(--successColorDark);

  &:hover,
  &:focus {
    background: var(--matterColorLight);
    text-decoration: underline;
    color: var(--successColorDark);
  }
}

.submitButtonModalBlock {
  position: relative;
  display: flex;
}

.modalWrapperFirstRow {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 13px;
  justify-content: space-between;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.wrapperModalAddMilestone {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding: 18px 39px 20px 20px;
  height: auto;
  border: 1px solid #E6E6E6;
  margin-bottom: 8px;

  @media (--viewportLarge) {
    /* width: 560px; */
  }
}

.submitModalButton {
  @apply --marketplaceButtonStyles;
  font-size: 13px;
  line-height: 20px;
  padding: 0;
  height: 30px;
  min-height: 30px;
  width: 81px;
  background: var(--matterColorLight);
  color: var(--successColorDark);
  border: 1px solid var(--successColorDark);

  &:hover,
  &:focus {
    background: var(--matterColorLight);
    text-decoration: underline;
    color: var(--successColorDark);
  }

  &:disabled {
    color: var(--successColorLight);
    background: var(--matterColorLight);
    border: 1px solid var(--successColorLight);
    text-decoration: none;
  }
}

.closeButtonModal {
  cursor: pointer;
  position: absolute;
  left: 95px;
  top: 4px;
  width: 17px;
  height: 17px;
}

.closeButtonModal:hover {
  opacity: 0.6;
}

.closeButtonModal:before,
.closeButtonModal:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 22px;
  width: 1px;
  background-color: #333;
}

.closeButtonModal:before {
  transform: rotate(45deg);
}

.closeButtonModal:after {
  transform: rotate(-45deg);
}

.milestoneDateInput {
  /* margin-bottom: 20px; */
  width: 174px;

  & input {
    padding-top: 5px !important;
    padding-left: 0 !important;
    background: none !important;
  }

  & [class*="directionLeft"] {
    width: 313px;
    min-height: 330px
  }
}

.milestoneDescription {
  width: 100%;

  @media (--viewportMedium) {
    margin-left: auto;
    width: 80%;
  }

  & textarea {
    padding: 4px 0 0 0;
    max-width: 100%;
  }
}

.wrapperTitleMilestoneItem {
  display: flex;
  justify-content: space-between;

  & [class*="MenuContent_isOpen"] {
    border-radius: 4px;
    min-width: 238px !important;
    right: 0 !important;
    left: auto !important;

    &>div {
      display: none;
    }
  }

  & [class*="MenuContent_isClose"] {
    right: 0 !important;
    min-width: 238px !important;
    left: auto !important;

    &>div {
      display: none;
    }
  }
}

.pointsMilestoneModal {
  max-height: 4px;
  cursor: pointer;
}

.pointsMenuContent {
  min-width: 238px;
  right: 0;

}

.rootClassName {
  right: 0;
}

.titleModalPoints {
  height: 41px;
  font-weight: var(--fontWeightSemiBold);
  text-align: right;
  margin: 0 auto;
  width: 220px;
  padding-right: 12px;
  border-bottom: 1px solid #E6E6E6;
}

.titleModalText {
  padding-right: 65px;
}

.itemPoints {
  cursor: pointer;
  padding: 3px 17px;
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  line-height: 36px;

  &:disabled {
    color: var(--matterColorLight);
  }

  &:hover {
    color: #b2b2b2;
  }
}

.closeModal {
  cursor: pointer;
  position: absolute;
  right: 30px;
  top: 25px;
  width: 9px;
  height: 9px;
}

.closeModal:hover {
  opacity: 0.6;
}

.closeModal:before,
.closeModal:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 15px;
  width: 1px;
  background-color: #3D3D3D;
}

.closeModal:before {
  transform: rotate(45deg);
}

.closeModal:after {
  transform: rotate(-45deg);
}

.table {
  width: 100%;
  margin-bottom: 20px;
}

.tableNoContent {
  width: 100%;
  margin-bottom: 0;
}

.errorTitle {
  font-size: 14px;
  color: var(--marketplaceColorLight);
}

.wrapperTitleMobileBlock {
  display: flex;
  justify-content: space-between;

  @media (--viewportMedium) {
    /* padding-top: 9px; */
  }
}

.trMobileTitleContent {
  word-break: break-word;
  font-weight: var(--fontWeightRegular);
  width: 100%;
}

.desktopPoints {
  display: none;

  @media (--viewportMedium) {
    height: 0;
    display: block;
  }
}

.blockDesktopTitle {
  /* display: flex;
  justify-content: space-between; */
}

.mobilePoints {
  display: block;

  @media (--viewportMedium) {
    display: none;
  }
}

.wrapperMobileBlock {
  border-bottom: 1px solid #E6E6E6;
  display: block;
  gap: 52px;
  padding: 20px 0;

  & [class*="MenuContent_isOpen"] {
    border-radius: 4px;
    width: 238px !important;
    min-width: 238px !important;
    right: 0 !important;
    left: auto !important;

    &>div {
      display: none;
    }
  }

  & [class*="MenuContent_isClose"] {
    right: 0 !important;
    width: 238px !important;
    min-width: 238px !important;
    left: auto !important;

    &>div {
      display: none;
    }
  }

  @media (--viewportMedium) {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #E6E6E6;
    user-select: none;
  }
}

.accordionStatusButton {
  background: var(--matterColorLight);
  /* Line colour */
  border: 1px solid var(--matterColorNegative);
  box-sizing: border-box;
  border-radius: 2px;
  font-size: 16px;
  line-height: 24px;
  color: var(--matterColorDark);
  padding: 7.5px 13.5px;
  cursor: pointer;

  & svg {
    margin-right: 5px;
  }
}

.panelTop {
  margin: 0 13px;
  display: flex;
  justify-content: space-between;

  /*align-items: center;*/
  @media (--viewportLarge) {
    margin-top: 24px;
    margin-bottom: 15px;
  }
}

.panelTopControl {
  padding: 0;
  flex: 1 0;
  /* margin-top: 24px; */
  display: flex;
  justify-content: flex-end;
}

.scheduleHeading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.266667px;
  color: #3D3D3D;
  background: #FFFFFF;
  border: 2px solid #E6E6E6;
  border-radius: 4px;
  height: 45px;
  padding: 0 13px;
  cursor: pointer;

  /* & .scheduleIcon {
    margin-right: 12px;

    & svg {
      & path {
        fill: #fff;
      }
    }
  } */
}

.successMessage {
  position: relative;
  display: flex;
  justify-content: center;
}

.statusDeclined {
  color: var(--marketplaceColorLight);
  font-size: 18px;
  font-weight: var(--fontWeightSemiBold);
}

.statusAccepted {
  font-size: 18px;
  font-weight: var(--fontWeightSemiBold);
}

.buttonMarkProject {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 15px; */
  padding: 0 11px;
  min-height: 54px;
  width: max-content;
  background: #0B96F5;
  color: white;
  border-radius: 4px;

  @media(max-width: 560px) {
    min-height: 40px;
  }
}

.completePopup {
  font-size: 14px;
  line-height: 23px;
  color: #444444;
  text-align: center;
  display: block;
  padding: 0 24px;
}

.modalAskedUser {
  text-align: center;
  padding: 0 10px;
  width: max-content;
  min-width: 330px;
  margin: 10px auto;

  @media(max-width: 470px) {
    width: unset;
  }
}

.userMessage {
  @media(max-width: 768px) {
    width: fit-content !important;
  }

  @media(max-width: 470px) {
    left: 0 !important;
  }
}

.blockButtonModalAsked {
  width: 200px;
  display: flex;
  column-gap: 7px;
  justify-content: center;
  margin: 0 auto;
  margin-top: 20px;

  @media(max-width: 460px) {
    flex-wrap: wrap;
    margin: 0;
    margin-top: 20px;
    width: 100%;
    gap: 10px;
  }

  & button {
    @apply --marketplaceButtonStylesPrimary;
    min-width: 150px;
    min-height: 40px;
    padding: 0;
    /* margin-top: 15px; */

    @media(max-width: 460px) {
      margin: 0 !important;
    }

    @media(max-width: 460px) {
      margin: 0 !important;
    }

    &.buttonAskCancel {
      background: #b2b2b2;
      /* margin-right: 20px; */
    }
  }
}

.meetingError {
  color: red;
  text-align: flex-end;
  line-height: 22px;
  font-size: 16px;
}

.meetingHeading {
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -0.9px;
  color: #3D3D3D;
  margin-top: 1em; 
}

.interviewSubHeading {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 25px;
}

.chatLink {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;

  & span {
    color: #0B96F5;
    font-weight: 600;
    cursor: pointer;
  }
}

.meetingButtonsBox {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 46px;
  align-items: center;
  justify-content: center;
}

.meetingButtonsBox .meetLnk {
  display: flex;
  padding: 34px 29px;
  grid-column-gap: 10px;
  column-gap: 10px;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #E6E6E6;
  box-shadow: 0px 2px 2px rgb(0 0 0 / 5%);
  justify-content: center;
  height: 120px;
  width: 276px;
  position: relative;
  cursor: pointer;
}

.meetingButtonsBox .meetLnk .hoverBox {
  visibility: hidden;
  padding: 9px 11px;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 0;
  background: #FFFFFF;
  box-shadow: 0px 8px 16px rgb(0 0 0 / 30%);
  border-radius: 4px;
  width: 309px;
  height: auto;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: -0.177778px;
  color: #3D3D3D;
}

.meetingButtonsBox .meetLnk:hover .hoverBox {
  visibility: visible;
}

.checkoutModalButton {
  min-height: 48px;
  padding: 10px;
  width: 145px;
  background: #0B96F5;
  color: white;
  border-radius: 4px;
}

.paymentButtonContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: column;
  position: relative;
}

.buttonSection {
  margin-top: 73px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;

  & .buttonCancel {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.111111px;
    color: #3D3D3D;
    cursor: pointer;
  }

  & .buttonSubmit {
    width: 228px;
  }
}

.confirmBox {
  display: flex;
  background: rgba(246, 180, 48, 0.2);
  padding: 10px 40px;
  justify-content: space-between;
  margin: 20px 0;

  @media(max-width: 600px) {
    flex-wrap: wrap;
    padding: 10px 24px;
    gap: 10px;
    margin-top: 10px;
  }

  & p {
    font-size: 14px;
    margin: 0;
    padding: 0;
  }
}

.buttons {
  align-self: center;
  font-size: 14px;

  & button {
    width: 86px;
    height: 35px;

  }
}

.acceptButton {
  background: #0B96F5;
  border-radius: 4px;
  color: white;
}

.rejectButton {
  background: #FFFFFF;
  border: 2px solid #0B96F5;
  border-radius: 4px;
  color: #0B96F5;
  margin-left: 12px;
}

.profileImage {
  width: 31px;
  height: 31px;
  border-radius: 50%;
  background-color: #42affa;
  color: white;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.onlineDot {
  height: 10px;
  width: 10px;
  background-color: #2ECC71;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  bottom: 0;
  border: 1.7px solid white;
}

.offlineDot {
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  bottom: 0;
  border: 1.7px solid white;
}

.meetingBoxBorder {
  border: 3px solid #0B96F5 !important;
}

.customBreakdown {
  & img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
}

.profileImageMilestone {
  max-width: 32px;
  max-height: 32px;
  border-radius: 50%;
  overflow: hidden;
  min-width: 32px;
  border: 1px solid #d5d5d5;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.noImageWithText {
  width: 32px;
  height: 32px;
  max-width: 32px;
  max-height: 32px;
  min-width: 32px;
  border-radius: 50%;
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #7AC9FF 0%, #0B96F5 100%);
  color: var(--matterColorLight);
}

.customDropDown {
  background-color: lightgray;

}

.milstoneCheboxWrapper {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: left;
}

.pointerEvents {
  pointer-events: none;
  opacity: .5;
}

.toolsGridFiles {
  width: 100%;
  /* padding-top: 20px; */

  & iframe {
    pointer-events: unset;
    width: 100%;
  }
}

.menu{
  @media(max-width: 768px) {
    position: absolute;
    top: 14px;
    right: 10px;
    height: auto;
  }
}

.hideMenu {
  display: none;
}

.menuContent {
  position: absolute;
  padding: 10px 20px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.30);
  right: 0px !important;
  width: max-content;

  & .content {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    margin: 0;
  }

  & ul>li {
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.25px;
    margin-top: 7px;
    cursor: pointer;
    position: relative;

    & .menuItem {
      display: flex;
      grid-gap: 5px;
      gap: 5px;
      align-items: center;
      padding-left: 0;

      & input {
        width: 12px;
        accent-color: #3e9865;
        border: none;
      }
    }

    & .disabled {
      pointer-events: none;
    }

    & .topBorder {
      border-top: 1px solid #E6E6E6;
      padding-top: 10px;
    }
  }

  & .firstChild {
    font-weight: 600;
  }

  & .lastChild {
    border-top: 1px solid #E6E6E6;
    padding: 10px 0 4px;
  }

  & .disable {
    color: #B2B2B2;
    pointer-events: none;
    cursor: auto;
  }
}

.expertMenu {
  width: 200px;
}

.declineJobBox {
  position: absolute;
  width: 280px;
  background: white;
  right: 0%;
  bottom: -84px;
  padding: 10px 10px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.50);
  border-radius: 5px;

  & p {
    font-size: 13px;
    font-weight: 400;
    margin: 0;
  }

  & div {
    display: flex;
    align-items: center;
    justify-content: space-around;

    & button {
      background: #0B96F5;
      color: white;
      padding: 4px 18px;
      border-radius: 4px;
      font-size: 14px;
    }
  }
}

.skipStepModal {
  & h2 {
    font-size: 30px;
    line-height: 40px;
    letter-spacing: -0.9px;
    margin-bottom: 30px;
  }

  & p {
    font-size: 20px;
    font-weight: 400;
  }

  & .confirmStep {
    margin-top: 3em;
  }

  & label {
    font-size: 16px;
    letter-spacing: 0.2px;
    margin-bottom: 5px;
  }

  & .row {
    margin-bottom: 15px;

    & label {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      display: inline;
      margin-right: 15px;
    }

    & p,
    span {
      margin: 0;
      font-size: 16px;
      font-weight: 400;
    }
  }

  & .buttonWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-top: 3em;

    & .buttonCancel {
      font-size: 20px;
      font-weight: 400;
    }

    & .buttonSubmit {
      width: 228px;
    }
  }
}

.subMenuLabel {
  text-align: left;
  padding-left: 0;
}

.subMenu {
  position: absolute;
  padding: 10px 20px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.30);
  right: 0px !important;
  width: max-content;

  & ul {
    flex-direction: row;
    column-gap: 25px;

    & li {
      background: #0B96F5;
      color: white;
      padding: 4px 18px;
      border-radius: 4px;
      font-size: 14px;
    }
  }
}

.inviteButtonSection {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.266667px;
  color: #0B96F5;
  background: #FFFFFF;
  border: 2px solid #0B96F5;
  border-radius: 4px;
  width: 134px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* margin-top: 20px; */

  @media(max-width: 768px) {
    margin-left: 20px;
  }
}

.csmInviteSuccessMessage {
  margin-bottom: 30px;
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  width: 336px;
  text-align: center;
}


.viewContractRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.viewContractRow>.alertMark {
  color: wheat;
  font-size: 12px;
  font-weight: 700;
  border: 1px solid orange;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: flex;
  animation-name: wiggle;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
  align-items: center;
  justify-content: center;
  background: orange;
  margin-left: 12px;
}

.outsideButton{
  position: absolute;
  box-shadow: 0px 8px 16px rgb(0 0 0 / 30%);
  top: 19px;
  right: 2px;
  background: white;
  padding: 12px 16px;
  border-radius: 4px;
  z-index: 10;

  & button {
    background: #0B96F5;
    color: white;
    padding: 8px 20px;
    border-radius: 3px;
    font-size: 14px;
    width: 180px;
    text-align: center;

    &:hover {
      background-color: #F6B430;
    }
  }

  & label{
    font-weight: 100;
    width: max-content;
  }
}

@keyframes wiggle {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}

.notificationDot {
  color: var(--matterColorLight);
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #F6B430;
  display: block;
  margin-left: 5px;
  margin-top: -9px;
}

.JobDescriptionHeading {
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  & .mainHeading {
    display: inline;
    margin-right: 14px;
    min-width: 0;
  }

  & .briefType {
    padding: 3px 15px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.1px;
    border-radius: 12px;
    background: #ededed;
    display: block;
    /* height: 25px; */
  }
}

.noMilestonesContent{
  font-size: 16px;
  font-weight: 400;
}