.contractForm{
    padding: 40px;

    @media(max-width: 768px) {
        padding: 24px;
    }
}
.container{
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px !important;
    width: 100%;
    margin: 0px auto;
    padding: 40px 100px;
    border: 1px solid #c2c2c2;
    border-radius: 5px;

    @media(min-width: 1200px) {
        width: 800px;
    }

    @media(max-width: 768px) {
        padding: 20px;
    }
}
.briefGenerator{
    margin-bottom: 40px;

    & .generatorTitle{
        font-size: 36px;
        font-weight: 600;
        line-height: 56px;
        letter-spacing: -1.25px;
    }

    @media(max-width: 768px){
        margin-bottom: 3em;

        & .generatorTitle{
            font-size: 24px;
            line-height: 28px;
        }
    }
}
.row{
    margin-bottom: 2em;

    & label{
        margin-bottom: 10px;
    }

    & .textAreaBox{ 
        & textarea{
            min-height: 40px;
            min-width: 100%;
            &:focus{
                border-color: var(--marketplaceColor);
            }
            &::placeholder{
                font-size: 13px;
            }
        }
    }
    & .inputBox{
        & input{
            padding: 0;
            &:focus{
                border-color: var(--marketplaceColor);
            }
            &::placeholder{
                font-size: 13px;
            }
        }
    }
}
.fieldSelector{
   & select{
    border: 1px solid #e0dede;
    border-radius: 0.375rem !important;
    padding: 0px 14px;
    height: 35px;
    background-position: 96%;
   }
}
.actionButtons{
    display: flex;
    margin-top: 3em;
    gap: 10px;
    justify-content: space-between;

    @media(max-width: 768px) {
        flex-wrap: wrap;
        width: 100%;
    }
    
    & button{
        min-height: 45px;
        max-height: 45px;
        width: 165px;
        font-size: 14px;
        
        @media(max-width: 768px) {
            width: 100%;
        }
    }
    & .submitButton{
        &:disabled,[disabled]{
          background-color: #eeeded;
          color: #666666;
        }
    }
    & .disabledSubmitButton{
        background-color: #eeeded;
        color: #666666;
    }
}
.attachment{
    margin-top: 10px;

    & .addImageInput {
        display: none;
    }
    & .close{
        cursor: pointer;
    }
    & .attachmentLabel{
        width: 100%;
        box-sizing: border-box;
        border: 2px dashed gainsboro;
        border-radius: 2px;
        padding: 25px 20px;
        display: flex;
        flex-direction: column;
        text-align: center;
        cursor: pointer;
        margin-bottom: 12px;
        align-items: center;
        gap: 10px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.177778px;

        @media(max-width: 768px){
            padding: 5px 20px;
            font-size: 12px;
        }
      
        & .dragDrop {
            color: #3D3D3D;
        }
      
        & .docType {
            color: #B2B2B2;
        }
    }
}
.datesRow{
    display: flex;
    column-gap: 50px;
    margin-top: 2em;

    @media(max-width: 768px){
        column-gap: 5px;
    }
}
.fieldDateInput{
    /* display: flex; */
    column-gap: 8px;
    width: 270px;
    align-items: flex-end;

    @media(max-width: 768px){
        column-gap: 0;
    }

    & label{
        padding: 0;
    }

    & input{
        /* border: 1px solid #e0dede !important;
        border-radius: 0.375rem !important; */
        background-image: none !important;
        padding-left: 10px !important;
        height: 32px;
        width: 137px !important;
        margin-top: 10px !important;

        &:focus{
            border-color: var(--marketplaceColor) !important;
        }
    }
}
.fieldsWrapper{
    margin-bottom: 2em;

    & label{
        margin-bottom: 5px;
    }
}
.fieldsContainer{
    border-bottom: 3px solid #0A95FC;
    position: relative;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;

    & p{
        margin: 0;
        font-weight: 100;
    }

    & .fieldsItem{
        border: 1px solid gainsboro;
        padding: 0 5px;
        border-radius: 20px;
        margin-bottom: 5px;
        font-weight: 100;
        font-size: 14px;

        & .removeIcon{
            cursor: pointer;
            margin-left: 10px;
            & svg{
                height: 13px;
                width: 13px;
            }
        }
    }

    & .toggleIcon{
        cursor: pointer;
        position: absolute;
        right: 5px;
    }
}
.fieldsCheckbox{
    border: 1px solid gainsboro;
    padding: 10px 20px;
    border-radius: 4px;
    width: 100%;
    box-shadow: 3px 4px 8px 0 rgba(0, 0, 0, .1);
}
.modalTitle{
    font-size: 36px;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -1.25px;
    margin-top: 30px;
}
.modalContext{
    margin-top: 40px;
    font-size: 14px;
    font-weight: normal;
}
.okbtn{
    background-color: var(--textFieldColor);
    color: white;
    border-radius: 5px;
    padding: 10px 60px;
    font-size: 18px;
    font-weight: 400;
}
.modalbtnDiv{
    margin-top: 50px;
    width: 100%;
    text-align: center;
}

.extensionError{
    color: var(--failColor);
    margin: 10px auto;
}

.requiredFieldBorder{
    border-color: var(--failColor);
}

.vibrate {
    animation: vibrate 10s infinite;
}

@keyframes vibrate {
    0% { transform: translateX(0); }
    0.4% { transform: translateX(4px); }
    0.8% { transform: translateX(-4px); }
    1.2% { transform: translateX(3px); }
    1.6% { transform: translateX(-3px); }
    2.0% { transform: translateX(2px); }
    2.4% { transform: translateX(-2px); }
    2.8% { transform: translateX(1px); }
    3.2% { transform: translateX(-1px); }
    3.6% { transform: translateX(0); }
    25% { transform: translateX(0); }
    50% { transform: translateX(0); }
    75% { transform: translateX(0); }
    100% { transform: translateX(0); }
}