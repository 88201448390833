@import '../../styles/propertySets.css';

.organisationForm {

  & .organisationTopBox {
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media(max-width: 500px) {
      flex-wrap: wrap;
      gap: 10px;
    }
  }

  & .organisationHeading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.1125px;
    color: #B2B2B2;
    margin: 0;
  }

  & .partnerForm {
    width: 700px;

    @media(max-width: 1500px) {
      width: 600px;
    }

    @media(max-width: 1024px) {
      width: 100%;
    }
  }

  & .viewProfile {
    background: #FFFFFF;
    border: 1px solid #E6E6E6;
    border-radius: 4px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: #3D3D3D;
    padding: 9px 23px;

    &:hover {
      text-decoration: none;
    }
  }
}