.fieldRoot {
  cursor: pointer;
}

.inputRoot {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.selectWrapper {
  flex-basis: calc(33% - 12px);
}

.select {
  display: inline-block;

  /* Border */
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-bottom-color: var(--textFieldColor);

&:hover,
&:focus {
   border-bottom-color: var(--matterColorDark);
   outline: none;
 }
}

.notSet {
  color: var(--matterColorAnti);
}

.selectSuccess {
  border-bottom: 3px solid var(--textFieldColor);
}

.selectError {
  border-bottom: 3px solid var(--failColor);
}

.mainDatePicker{
  /*border-bottom: 3px solid var(--marketplaceColor);*/
  padding: 10px 0 5px;
}
