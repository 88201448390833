@import '../../styles/propertySets.css';
.zohoModal{
    @apply --marketplaceModalBaseStyles;
    flex-basis: 700px;
    border-bottom: none;
}
button {
    cursor: pointer;
    border: none;
    outline: none;
}

.contracts {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
    border: 1px solid #E6E6E6;
    padding: 33px 40px;

    & p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.25px;
        color: #3D3D3D;
        margin-top: 0;
        margin-bottom: 10px;
        text-align: center;
    }
}

.acceptButton {
    width: 229px;
    min-height: 48px;
}

.container {
    border: 1px solid #E6E6E6;
    padding: 18px 30px;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    margin-bottom: 30px;
}

.header {
    display: flex;
    justify-content: space-between;

    & h4 {
        cursor: pointer;
    }
}

.sendButton {
    cursor: pointer;
    border: none;
    background: #0B96F5;
    color: white;
    border-radius: 3px;
    align-self: end;
    display: block;
    padding: 5px 20px;
    position: relative;
    margin-top: 15px;

    &:hover {
        &::before {
            visibility: visible;
        }
    }
}

.sendButton::before {
    visibility: hidden;
    content: 'Alert! Once document is sent for Signature, you cannot reupload or delete it.';
    position: absolute;
    top: -65px;
    left: 11px;
    background: whitesmoke;
    color: black;
    font-size: 13px;
    padding: 5px;
    border-radius: 3px;
    line-height: 17px;
}

.success {
    color: green;
    margin: 5px;
}

.error {
    color: red;
    margin: 5px;
}

.cancelButton {
    width: 100%;
    text-align: left;
    font-weight: 400;
    font-size: 20px;
}
.pdfRight {
    /* margin-bottom: 10px; */

    & .pdfName {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.0875px;
        color: #3D3D3D;
    }

    & .pdfDate {
        font-weight: 400;
        font-size: 10px;
        line-height: 21px;
        letter-spacing: -0.0875px;
        color: #B2B2B2;

        & .fileSize {
            margin-left: 5px;
            position: relative;
            padding-left: 8px;

            &:before {
                content: "";
                position: absolute;
                left: 0;
                background-color: #B2B2B2;
                height: 3px;
                width: 3px;
                top: 5px;
                border-radius: 50%;
            }
        }
    }
}
.download {
    cursor: pointer;
    color: #0B96F5;
}

.pdfBox {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    align-items: flex-end;

    & .pdfImage {
        & img {
            width: 30px;
            height: 41px;
        }
    }
}

.zohoDoc {
    border-bottom: 1px solid #E6E6E6;
    
    &>div {
        margin-bottom: 20px;
    }
}

.deleteConfirmBox {
    & h1 {
        text-align: center;
        font-weight: 600;
        font-size: 30px;
        margin: 2em 10px 0;
    }

    & p {
        text-align: center;
        font-weight: 400;
        font-size: 20px;
        margin-bottom: 3em;
    }
}

.zohoDetailTable {
    & table {
        & th {
            width: 50%;
            border: none;
            border-top: 1px solid #E6E6E6;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: -0.25px;
            color: #3D3D3D;
            padding: 22px 0;
        }

        & tr {
            & td {
                border: none;
                border-top: 1px solid #E6E6E6;
                background-color: #fff;
                padding: 10px 0;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                letter-spacing: -0.25px;
                color: #3D3D3D;

                & .boldText {
                    font-weight: 500;
                    margin-right: 3px;
                }
            }

            & .download {
                text-align: right;

                & span {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 18px;
                    letter-spacing: -0.25px;
                    color: #0B96F5;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            &:nth-child(2) {
                & td {
                    border-top: none;
                }
            }
        }
    }
}
.buttonsContainer {
    display: flex;
    margin-top: 15px;
}