.input {
  border-bottom-color: var(--textFieldColor);
}

.inputSuccess {
  border-bottom-color: var(--textFieldColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}
