.integrationCard {
    background: #FFFFFF;
    border: 1px solid #E6E6E6;
    padding: 40px 14px 20px 14px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media(max-width: 1500px) {
        /* width: 276px; */
        height: 298px;
    }

    @media(max-width: 1200px) {
        width: 100%;
    }

    & .cardIcon {
        text-align: center;
    }

    & .cardHeading {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: -0.0888889px;
        color: #3D3D3D;
        margin-top: 50px;
    }

    & .cardsubHeading {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: -0.0888889px;
        color: #3D3D3D;
        margin-top: 7px;
    }

    & .cardBadge {
        margin-top: 6px;
        margin-bottom: 10px;

        & span {
            background: #E6E6E6;
            border-radius: 12px;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.1px;
            color: #3D3D3D;
            padding: 4px 20px;
        }
    }

    & .submitButton {
        min-height: 44px;
    }
}