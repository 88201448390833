@import '../../styles/propertySets.css';

.root {
  position: relative;
}

.input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  /* Display the "check" when checked */
  &:checked+label .checked {
    display: inline;
  }

  &:checked+label .checkedCsm {
    display: inline;
  }

  /* Highlight the borders if the checkbox is hovered, focused or checked */
  &:hover+label .notChecked,
  &:hover+label .required,
  &:focus+label .notChecked,
  &:focus+label .required,
  &:checked+label .notChecked,
  &:checked+label .required {
    stroke: var(--textFieldColor);
  }

  &:hover+label .notCheckedCsm,
  &:hover+label .required,
  &:focus+label .notCheckedCsm,
  &:focus+label .required,
  &:checked+label .notCheckedCsm,
  &:checked+label .required {
    stroke: var(--textFieldColor);
  }

  /* Hightlight the text on checked, hover and focus */
  &:focus+label .text,
  &:hover+label .text,
  &:checked+label .text {
    color: var(--matterColorDark);
  }
}

.label {
  display: flex;
  align-items: center;
  padding-top: 6px;
}

.radioButtonWrapper {
  /* This should follow line-height */
  height: 32px;
  margin-top: -4px;
  margin-right: 12px;
  align-self: baseline;

  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.checked {
  display: none;
  stroke: var(--textFieldColor);
  fill: var(--textFieldColor);
  & circle{
    fill: var(--textFieldColor);
  }
}

.checkedCsm {
  display: none;
  stroke: #0B96F5;
  fill: #0B96F5;
}

.notChecked {
  stroke: var(--textFieldColor);

  &:hover {
    stroke: pink;
  }
}

.notCheckedCsm {
  stroke: #B2B2B2;

  &:hover {
    stroke: pink;
  }
}

.required {
  stroke: var(--textFieldColor);

  &:hover {
    stroke: pink;
  }
}

.text {
  @apply --marketplaceListingAttributeFontStyles;
  color: var(--matterColor);
  margin-top: -1px;
  margin-bottom: 1px;
  cursor: pointer;
}