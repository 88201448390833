.submitButton {
    margin-top: 1rem;
    width: 241px !important;

    @media(max-width: 460px) {
        width: 100% !important;
    }
}

.logoUpload {
    margin-top: 50px;

    & .logoLable {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #3D3D3D;
        margin-bottom: 10px;
    }

    & .logoFullUpload {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media(max-width: 600px) {
            flex-wrap: wrap;
        }
    }

    & .replaceButton {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 32px;
        letter-spacing: -0.0888889px;
        color: #FF0000;
        cursor: pointer;
    }

    & .uploadBox {
        border: 2px dashed #E6E6E6;
        height: 115px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        & .browseInput {
            width: 100%;
            height: 100%;
        }

        & .dragDrop {
            position: absolute;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: -0.177778px;
            color: #3D3D3D;
            padding: 0 10px;
        }

        & img {
            border: 2px solid #E6E6E6;
            object-fit: fill;
        }
    }

    & .uploadExtension {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #B2B2B2;
    }
}

.uploadBoxMain {
    display: flex;
    align-items: center;
    gap: 18px;
}

.backgroundImage {
    margin-top: 55px;

    & .logoLable {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #3D3D3D;
        margin-bottom: 15px;
    }

    & .logoBox {
        border: 2px dashed #E6E6E6;
        border-radius: 2px;
        width: 459px;
        height: 131px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        cursor: pointer;
        position: relative;
    }

    & .backgroundImageUploaded {
        width: 459px;
        height: 220px;
        object-fit: contain;
        padding: 5px 0;
        border: 1px solid #eee;

        @media(max-width: 1024px) {
            width: 100%;
        }
    }

    & .mainBackgroundBox {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media(max-width: 600px) {
            flex-wrap: wrap;
        }
    }

    & .replaceBack {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 32px;
        letter-spacing: -0.0888889px;
        color: #FF0000;
        cursor: pointer;
    }

    & .beforeUpload {
        & .uploadIcon {}

        & .dragText {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.177778px;
            color: #3D3D3D;
            margin: 10px 0;
        }

        & .extension {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.177778px;
            color: #B2B2B2;
        }
    }
}

.topInputsBox {
    margin-top: 48px;

    & .inputBox {
        margin-bottom: 48px;
    }

    & textarea {
        max-width: 100%;
        min-width: 100%;
    }
}

.inputLocation {
    & .locationBoxWraper {
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: 40px;

        &>div {
            width: 100%;
        }

        & .locationInput {
            width: 100%;

            & input {
                @media(max-width: 1024px) {
                    padding-right: 20px;
                }
            }
        }

        & .removeLocation {
            position: absolute;
            right: -30px;

            @media(max-width: 1024px) {
                right: 0;
            }
        }
    }
}

.firmWebsite {
    margin: 48px 0;
}

.selectedQueries {
    margin-bottom: 48px;

    & .serviceHeading {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #3D3D3D;
        margin-bottom: 7px;
    }

    & .releventService {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.0875px;
        color: #3D3D3D;
        margin-bottom: 7px;
    }

    & .inputSelect {
        position: relative;
    }

    & ul {
        background: #FFFFFF;
        margin-top: 0;
        border: 1px solid #E6E6E6;
        border-radius: 2px;

        & li {
            border-top: 1px solid #E6E6E6;
            cursor: pointer;
            padding: 1px 10px;
        }
    }

    & .inputSelectCard {
        display: flex;
        flex-wrap: wrap;
    }

    & .industrySelected {
        background: #ceeafd;
        border-radius: 12px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.1px;
        color: #0081D9;
        padding: 4px 9px;
        position: relative;
        margin-right: 12px;
        margin-bottom: 5px;

        & .closeItem {
            margin-left: 5px;

            & svg {
                & path {
                    stroke: #0081D9;
                }
            }
        }
    }
}

.addServicesButton {
    margin-top: 9px;
    background: #FFFFFF;
    border: 2px solid #0B96F5;
    border-radius: 4px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.266667px;
    color: #0B96F5;
    height: 35px;
}

.packageBox {
    margin-bottom: 50px;
}

.departmentName {
    margin-bottom: 48px;
}

.employeeSize {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    &>div {
        &:nth-child(1) {
            width: 84%;

            @media(max-width: 1024px) {
                width: 65%;
            }
        }
    }

    & .employeeRight {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 32px;
        letter-spacing: -0.0888889px;
        color: #3D3D3D;
        position: absolute;
        right: 0;
        top: 37px;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.locationInputWrap {
    & label {
        margin-bottom: 10px;
    }
}

.pointerEvents {
    pointer-events: none;
    opacity: .5;
}
.inputWrapperBox{
    display: flex;
    margin: 35px 0 0px;
    grid-gap: 20px;
    gap: 20px;

    & .inputBox{
        width: 200%;
    }
}
.logoShapeContainer{
    margin-bottom: 20px;

    & label{
        /* max-width: ; */
    }
}