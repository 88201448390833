.briefGeneratorForm {
  padding: 40px;

  @media (max-width: 768px) {
    padding: 24px;
  }
}
.container {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px !important;
  width: 100%;
  margin: 0 auto;
  padding: 40px 100px;
  border: 1px solid #c2c2c2;
  border-radius: 5px;

  @media (min-width: 1200px) {
    width: 800px;
  }

  @media (max-width: 768px) {
    padding: 20px;
  }

  & .inputBox {
    margin-bottom: 30px;

    & input,
    & textarea {
      min-width: 100%;
      max-width: 100%;
      font-size: 14px !important;
      line-height: 22px;
      padding-bottom: 6px;

      &::placeholder {
        font-size: 14px;
      }
    }

    & label {
      margin-bottom: 5px;
      margin-right: 0px;
      color: rgb(0, 0, 0);
      font-size: 1rem;
      font-weight: 600;
      font-style: normal;
    }
  }
}

.budgetBox {
  display: flex;
  margin-bottom: 30px;
}

.textareaBox {
  & label {
    margin-bottom: 5px;
  }
}

.briefGenerator {
  margin-bottom: 40px;

  & .generatorTitle {
    font-size: 36px;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -1.25px;
  }

  @media (max-width: 768px) {
    margin-bottom: 3em;

    & .generatorTitle {
      font-size: 24px;
      line-height: 28px;
    }
  }
}

.attachment {
  margin-bottom: 20px;

  & .close {
    cursor: pointer;
  }

  & .addImageInput {
    display: none;
  }

  & .inputLabel {
    width: 100%;
    box-sizing: border-box;
    border: 2px dashed gainsboro;
    border-radius: 2px;
    padding: 25px 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
    margin-bottom: 12px;
    align-items: center;
    gap: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.177778px;

    @media (max-width: 768px) {
      padding: 5px 20px;
      font-size: 12px;
    }

    & .dragDrop {
      color: #3d3d3d;
    }

    & .docType {
      color: #b2b2b2;
    }
  }
}

.budgetInputBox {
  & input {
    padding-left: 10px;
    margin-left: 6px;
  }
}

.currencySelector {
  & select {
    width: 50px;

    &:disabled {
      border-bottom-color: var(--textFieldColor);
    }
  }
}

.tooltip {
  background: #223133;
  border-radius: 50%;
  width: 16px;
  display: inline-block;
  height: 18px;
  color: white;
  padding-left: 5px;
  margin-left: 4px;
  font-size: 13px;
  cursor: help;
  position: relative;

  &:hover {
    &::before {
      display: block;
    }
  }

  &::before {
    display: none;
    content: 'Free-form text field, specify the timelines by just typing a specific or an approximation value.';
    width: 335px;
    position: absolute;
    top: -5.8em;
    left: -12em;
    background: #223133;
    padding: 10px 12px;
    border-radius: 5px;
    text-align: center;
  }
}
.actionButtons {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin-top: 3em;

  @media (max-width: 460px) {
    flex-wrap: wrap;
    width: 100%;
  }

  & button {
    min-height: 45px;
    max-height: 45px;
    width: 165px;
    font-size: 14px;

    @media (max-width: 460px) {
      width: 100%;
    }
  }

  & .submitButton {
    &:disabled,
    & [disabled] {
      background-color: #eeeded;
      color: #666666;
    }
  }
  & .disabledSubmitButton{
    background-color: #eeeded;
    color: #666666;
  }
}

.checkboxWrapper {
  margin: 2em 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px 20px;

  & div {
    display: flex;
    flex-direction: row-reverse;
    column-gap: 5px;

    & label {
      font-size: 14px;
      font-weight: 100;
      cursor: pointer;
    }

    & input {
      width: 13px;
    }
  }
}

.documentSwitcher {
  display: flex;
  align-items: center;
  margin-bottom: 2em;

  & .switcherWrapper {
    cursor: pointer;
    border-radius: 50px;
    font-family: var(--font-familyLite);
    width: 30px;
    height: 15px;
    border: 1px solid #e6e6e6;
    background: #e6e6e6;
    margin: 0 6px;

    & .ball {
      border-radius: 50px;
      width: 13px;
      height: 13px;
      transition: transform 0.3s ease;
      background-color: white;
    }
  }

  & .toggled {
    background-color: #0b96f5;

    & .ball {
      transform: translateX(15px);
    }
  }
}

.fileSizeError{
  color: var(--failColor);
  margin: 0 auto;
}