.countryHeading {
    color: #3D3D3D;
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.57px;
    margin-bottom: 18px;
    margin-top: 0;
  }
  
  /* RadioButtons.module.scss (or any SCSS file you prefer) */

.radioSelect {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 148px;
  
    @media(max-width: 768px) {
      gap: 50px;
    }
  
    & label {
      display: flex;
      align-items: center;
      margin-right: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
  
      color: #3D3D3D;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.48px;
    }
  }
  
  .radioSelectedOption {
    appearance: none;
    margin-right: 5px;
    font: inherit;
    width: 15px;
    height: 15px;
    border: 0.15em solid #f6b431;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .radioSelectedOption:checked {
    background-color: #f6b431;
  }
  
  .radioSelectedOption:hover,
  .radioSelectedOption:focus {
    border-bottom-color: #f6b431;
  }
  
  .buttonContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  
    & button {
      width: 366px;
      height: 65px;
      margin-top: 45px;
  
    }
  }
  
  .nextStepButton {
    width: 366px;
    height: 65px;
    margin-top: 45px;
  
    @media(max-width: 460px) {
      width: 100%;
    }
  }