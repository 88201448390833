.firmMemberExpertsPanel {
  /* display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center; */
}

.expertMemberList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  @media(max-width: 768px){
    display: block;
  }
}

.dropdownBox {
  position: absolute;
  top: 45px;
  background-color: #ffffff;
  width: 300px;
  display: flex;
  flex-direction: column;
  z-index: 9;
  padding: 15px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  /* border: 1px solid #ddd; */
  border-radius: 4px;
}

.dropdownList {
  padding: 10px 0;
  display: flex;
  column-gap: 12px;
  align-items: center;

  & img {
    width: 31px;
    height: 31px;
    border-radius: 50%;
  }

  .profileImage {
    width: 31px;
    height: 31px;
    border-radius: 50%;
    background-color: #42affa;
    color: white;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .fullNameBox {
    font-weight: var(--fontWeightRegular);
    cursor: pointer;
    margin: 0;
  }

  & input {
    width: 15px;
    height: 15px;
    cursor: pointer;
  }
}

.filterBox {
  margin: 30px 0;
  position: relative;

  & label{
    margin-bottom: 10px;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: rgba(0, 0, 0, 1);
  }
}

.selectExpert{
  position: relative;
}

.userNameDropdown {
  background-color: var(--matterColorLight);
  color: var(--matterColor);
  padding: 5px 15px;
  border: 1px solid gainsboro;
  width: fit-content;
  cursor: pointer;
  border-radius: 4px;
  font-weight: 100;
  height: 42px;
  width: 300px;
  display: flex;
  justify-content: space-between;

  &:hover {
    background-color: var(--matterColorLight);
    border-color: var(--matterColorAnti);
    box-shadow: var(--boxShadowButton);
  }

  @media(max-width: 768px){
    width: 100%;
    margin-top: 20px;
  }
}

.bottomWrapper {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 20px;

  & span {
    cursor: pointer;

    &:nth-child(2) {
      color: #0B96F5;
    }
  }
}

.tagsSection {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.filtersContainer{
  /* display: grid;
  grid-template-columns: 80% 20%; */
}

.filters {
  display: flex;
  gap: 12px 8px;
  margin: 0;
  flex-wrap: wrap;

  & li {
    border-radius: 20px;
    padding: 0px 30px;
    font-weight: 400;
    font-size: 13px;
    text-transform: capitalize;
    cursor: pointer;
    transition: all 0.3s;
    height: 32px;

    &:hover {
      background-color: #3D3D3D;
      color: white;
      transform: scale(1.05);
    }
  }
  @media(max-width: 768px){
    overflow-x: auto; 
    flex-wrap: nowrap;

    & li{
      padding: 0 10px;
      min-width: fit-content;
    }
  }
}

.selected {
  background: black;
  color: white;
}

.unSelected {
  background: #efecec;
}

.skeletonLoader{
  margin-top: 20px;
  & svg{
    width: 100%;
  }
}