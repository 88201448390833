@import '../../styles/propertySets.css';

.disableModalBorder {
  & [class*="Modal_container"] {
    border: none;
    border-radius: 0;
  }
}

.containerClassName {
  width: 576px;
  min-height: 576px;
}
