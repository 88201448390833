.researchTranslatorForm {
    padding: 40px;

    @media(max-width: 768px) {
        padding: 24px;
    }
}

.container {
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px !important;
    width: 100%;
    margin: 0px auto;
    padding: 40px 100px;
    border: 1px solid #c2c2c2;
    border-radius: 5px;

    @media(min-width: 1200px) {
        width: 800px;
    }

    @media(max-width: 768px) {
        padding: 20px;
    }
}

.generator {
    margin-bottom: 40px;

    & .generatorTitle {
        font-size: 30px;
        font-weight: 600;
        line-height: 56px;
        letter-spacing: -1.25px;
    }

    @media(max-width: 768px) {
        margin-bottom: 3em;

        & .generatorTitle {
            font-size: 24px;
            line-height: 28px;
        }
    }
}

.attachment {
    & label {
        margin-bottom: 5px;
        margin-top: 2em;
    }

    & .close {
        cursor: pointer;
    }

    & .addImageInput {
        display: none;
    }

    & .attachmentLabel {
        width: 100%;
        box-sizing: border-box;
        border: 2px dashed gainsboro;
        border-radius: 2px;
        padding: 25px 20px;
        display: flex;
        flex-direction: column;
        text-align: center;
        cursor: pointer;
        margin: 0px 0 0;
        align-items: center;
        gap: 10px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.177778px;

        @media(max-width: 768px) {
            padding: 5px 20px;
            font-size: 12px;
        }

        & .dragDrop {
            color: #3D3D3D;
        }

        & .docType {
            color: #B2B2B2;
        }
    }
}

.actionButtons {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    margin-top: 3em;

    @media(max-width: 460px) {
        flex-wrap: wrap;
        width: 100%;
    }

    & button {
        min-height: 45px;
        max-height: 45px;
        width: 165px;
        font-size: 14px;

        @media(max-width: 460px) {
            width: 100%;
        }
    }

    & .submitButton {

        &:disabled,
        & [disabled] {
            background-color: #eeeded;
            color: #666666;
        }
    }
    & .disabledSubmitButton{
        background-color: #eeeded;
        color: #666666;
    }
}

.checkBoxGroup{
    & li {
        padding:0
    }

    & label {
        margin: 0
    }
}

.alertModal{
    & h2{
		text-align: center;
		font-size: 30px;
        line-height: 40px;
        letter-spacing: -0.9px;
	}
    & p{
      /* text-align: center; */
      font-size: 20px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.9px;
    }
    .closeButton{
        margin-top: 3em;
        margin-bottom: 2em;
    }
}

.fileSizeError{
    color: var(--failColor);
    margin: 5px auto;
}

.filesErrorContainer{
    margin-top: 6px;
}
