.content {
  padding: 60px;

  @media(max-width: 768px) {
    padding: 25px 15px;
  }
}

.briefGenerator {
  margin-bottom: 20px;

  & .generatorTitle {
    font-size: 36px;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -1.25px;
  }

  @media(max-width: 768px) {
    margin-bottom: 2em;

    & .generatorTitle {
      font-size: 24px;
      line-height: 28px;
    }
  }
}
.subFolderdiv{
  margin-left: 10px
}

.chatContainer {
  margin-top: 1em;
  margin-bottom: 2em;
  width: 100%;
  border: 1px solid gainsboro;
  border-radius: 4px;
  padding: 10px 20px;
  max-height: 500px;
  overflow: auto;
  min-height: 300px;  
}

.chat {
  /* display: grid; */
  /* grid-template-columns: 6% 94%; */
  margin-bottom: 20px;
  padding-top: 20px;
  border-top: 1px solid gainsboro;

    @media(max-width: 768px) {
      /* grid-template-columns: 8% 94%; */
    }

    & label {
      font-weight: bold;
      font-size: 18px;
      line-height: 36px;
    }

  & p {
    /* font-size: 14px; */
    font-weight: 100;
    margin: 0;
    line-height: 28px;
    letter-spacing: -0.25px;
  }

  & strong {
    display: block;
    font-weight: 700;
    font-size: 14px;
  }

  /* & .avatar {
    font-size: 11px;
    font-weight: 100;
    border: 1px solid gainsboro;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    background: #989898;
    color: white;
  }

  & img {
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
  } */
}

.questionInputWrapper{
  margin-top: 1em;

  & .questionBar{
    display: flex;
    justify-content: space-between;
    gap: 20px;

    @media(max-width: 768px){
      flex-direction: column;
    }

    & button {
      min-height: 45px;
      max-height: 45px;
      width: max-content;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 28px;
  
      @media(max-width: 460px) {
        width: 100%;
        padding: 0;
      }
    }
  }
}

.files{
  max-height: 205px;
  overflow-y: auto;
  border-radius: 5px;
  padding: 5px;

  & label {
    margin-bottom: 10px;
  }
}

.buttonWrapper {
  margin-top: 2em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;

  & .chatButton{
    display: flex;
    gap: 15px;
  }

  & button {
    min-height: 45px;
    max-height: 45px;
    width: max-content;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 28px;

    @media(max-width: 460px) {
      width: 100%;
      padding: 0;
    }
  }

  & .clearButton{
    color: #0B96F5;
    border: 2px solid #0B96F5;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;

    &:hover {
      transform: translateY(-2px);
      transition: .2s;
  
      &:after {
        margin-left: 5px;
        transform: translateX(4px);
        vertical-align: middle;
      }
    }
  }

  & .loadingDots {
    --_g: no-repeat radial-gradient(circle closest-side, white 90%, #0000);
  }
}

.fileView {
  & a {
    pointer-events: none;
  }
}

.textarea{
  min-width: 87%;
  resize: none;
  height: 40px;
  padding: inherit;
}

.disableInput {
  opacity: 0.5;
}

.error {
  text-align: end;
  color: red;
  font-weight: 100;
}

.exportButtons{
  display: flex;
  justify-content: flex-end;
  margin-top: 1em;
  & button{
    border: 1px solid grey;
    height: 36px;
    padding: 0px 15px;
    border-radius: 3px;
    margin-left: 10px;
    transition: all .3s ease;

    &:hover{
      transform: translate(2px, -2px);
      background-color: orange;
      border: none;
      color: white;
    }
  }
}

.askQuestionButton{
  &:disabled{
    background-color: #0B96F5;
  }
}

.convoQuestionsContainer{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  margin-top: 20px;

  .downArrow{
    visibility: hidden;
    border: 1px solid #000000;
    border-radius: 8px;
    position: absolute;
    right: 8px;
    padding: 1px 4px;
  }

  & div {
    border: 1px solid gainsboro;
    border-radius: 12px;
    font-size: 13px;
    font-weight: 100;
    padding: 8px 16px;
    padding-right: 27px;
    line-height: normal;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;

    &:hover{
      background-color: #f4f2f2;

      & .downArrow{
        visibility: visible;
      }
    }
  }
}

.fontStyling *{
  font-family: var(--font-family) !important;
}

.fontStyling .toastui-editor-contents {
  font-family: var(--font-family) !important;
}
.fontStyling .toastui-editor-contents p{
  font-family: var(--font-family) !important;
}
.fontStyling em{
  font-style: normal !important;
}

.typedtext {
  /* display: flex;
  align-items: center; */
  max-width: -moz-fit-content;
  max-width: fit-content;
  border-right: 2px solid gray;
  font-size: 16px;
  /* height: 30px; */
  color: gray;
  overflow: hidden;
  cursor: pointer;
  /* padding: 2px 10px; */
  font-weight: 100;
  line-height: 25px;
  /* white-space: nowrap; */
  animation: blinkTextCursor 500ms infinite;
}

@keyframes blinkTextCursor {
  0% {
    border-right-color: gray;
  }
  50% {
    border-right-color: transparent;
  }
  100% {
    border-right-color: gray;
  }
}

.loader {
	position: relative;
	&:before,
	&:after {
		content: '';
		width: 20px;
		height: 20px;
		border-radius: 50%;
		background-color: black;
		opacity: 0.6;
		position: absolute;
		top: 0;
		left: 0;
		animation: loaderAnimation 2.0s infinite ease-in-out;
	}
	&:after {
		animation-delay: -1.0s;
	}
}

@keyframes loaderAnimation {
	0%,
	100% {
		transform: scale(0.0);
	}
	50% {
		transform: scale(1.0);
	}
}

.toastui-editor-contents p{
  font-family: poppins;
}

.stopButton{
  display: flex;
  justify-content: flex-end;

  & div{
    width: 30px;
    height: 30px;
    border: 2px solid red;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .5s;
    margin-top: 10px;
    
    & span{
      display: inline-block;
      height: 15px;
      width: 15px;
      border-radius: 3px;
      background: red;
      transition: all .5s;
    }
  }
}

.sessionExpiryText {
  margin-top: 2em;
  font-family: var(--font-familyLite);
  font-weight: var(--fontWeightSemiBold);
  font-size: 14px;

  & button {
    color: #0b96f5;
    transform: translateX(5px);
    display: inline-block;
    font-family: var(--font-familyLite);
    font-weight: var(--fontWeightSemiBold);
    font-size: 14px;
  }

  & .tooltip {
    background: #223133;
    border-radius: 50%;
    width: 18px;
    display: inline-block;
    height: 18px;
    color: white;
    padding-left: 5px;
    line-height: 20px;
    margin-left: 4px;
    font-size: 14px;
    cursor: help;
    position: relative;
    top: -1px;

    &:hover {
      &::before {
        display: block;
      }
    }

    &::before {
      display: none;
      content: attr(data-tooltip);
      width: 335px;
      position: absolute;
      top: -4em;
      left: -12em;
      background: white;
      padding: 10px 12px;
      border-radius: 6px;
      color: #3D3D3D;
      box-shadow: 0px 8px 16px rgb(0 0 0 / 30%);
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.25px;
    }
  }
}