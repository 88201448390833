.root {
    /* Dimensions */
    width: 100%;
    height: auto;
  
    /* Layout */
    display: flex;
    flex: 1;
    flex-direction: column;
  
    @media(max-width: 420px) {
        padding: 20px 15px;
    }
}
    
.error {
    color: var(--failColor);
}

.formMargins {
    margin-bottom: 24px;

    @media (--viewportMedium) {
        margin-bottom: 32px;
    }
}

.title {
    composes: formMargins;
}

.description {
    composes: formMargins;
    flex-shrink: 0;

    & textarea{
        margin-top: 10px;
        min-width: 100%;
        max-width: 100%;
    }
}

.submitButton {
    width: 241px !important;
    margin-top: 70px !important;

    @media (max-width: 768px) {
        margin-top: 40px !important;
        width: auto !important;
    }
}
.experienceLabel{
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
}
.experienceInputs{
    display: flex;
    align-items: center;
    
    & input{
        width: 50px;
        text-align: center;
        &::placeholder{
            text-align: center;
        }
    }
}
.checkbox{
    margin-top: 15px;
}
.experienceDisabled{
    opacity: 0.5;
}
.inputSelectCard {
    display: flex;
    flex-wrap: wrap;
    column-gap: 12px;
    row-gap: 10px;

    & .servicesSelected {
      background: #fdf0d6;
      border-radius: 12px;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.1px;
      color: #E49800;
      padding: 4px 9px;
      text-transform: capitalize;

      & .closeItem {
        margin-left: 10px;
        line-height: 0;
        cursor: pointer;

        & svg {
            width: 15px;
            height: 15px;
          }
      }
    }
}
.serviceContainer{
    margin-bottom: 25px;
    & .serviceHeading {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #3D3D3D;
        margin-bottom: 7px;
    }
    & .addServicesButton {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.266667px;
        color: #0B96F5;
        border: 1px solid #0B96F5;
        height: 35px;
        cursor: pointer;
        padding: 0 10px;
        margin-top: 11px;
        border-radius: 4px;
    }
    & ul {
        background: #FFFFFF;
        margin-top: 0;
        border: 1px solid #E6E6E6;
        width: 96.2%;
        border-top: none;
    
        & .activeSelect {
          background: rgb(226 226 226);
        }
    
        & li {
          border-top: 1px solid #E6E6E6;
          cursor: pointer;
          padding: 1px 10px;
        }
    }
    .inputSelect {
        margin-top: 12px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        column-gap: 5px;
        & div{
            width: 100%;
        }
        & span{
            font-size: 20px;
            cursor: pointer;
        }
        & input {
          background: #FFFFFF;
          border: 2px solid #E6E6E6;
          border-bottom: 0;
          padding: 3px 10px;
        }
    }
    & p{
        font-size: 13px;
        font-weight: 200;
        margin: 0;
        margin-bottom: -13px;
        color: red;
    }
}