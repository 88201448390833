@import '../../styles/propertySets.css';

.root {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
}

.imagesField {
  flex-shrink: 0;
  margin: 0;
}

.imageLabel {
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
}

.thumbnail {
  margin: 24px 0 0 0;

  @media (--viewportLarge) {
    width: calc(50% - 12px);
    margin: 0 24px 24px 0;

    &:nth-child(even) {
      margin: 0 0 24px 0;
    }
  }
}

.addImageWrapper {
  /* float: left; */
  position: relative;
  width: 100%;
  /* margin: 24px 0 0 0; */
  overflow: hidden;

  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  @media (--viewportMedium) {
    max-width: 456px;
    /* margin: 0 0 24px 0; */
  }
} 

.errorMessage {
  font-size: 11px;
  color: red;
}

.errorMessage{
  font-size: 11px;
  color: red;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectRatioWrapper {
  padding-bottom: 58px;
}

.addImage {
  /* Layout */
  /* Maintain aspect ratio */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;

  /* Colors */
  background-color: var(--matterColorLight);

  @media (--viewportLarge) {
    background-color: var(--matterColorBright);
  }

  border-style: dashed;
  border-color: var(--matterColorNegative);
  border-width: 2px;
  border-radius: 2px;

  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.docName {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.chooseImageText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chooseImage {
  @apply --marketplaceH4FontStyles;
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.imageTypes {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.addImageInput {
  display: none;
}

.imageRequiredWrapper {
  width: 100%;
  clear: both;
}

.tip {
  @apply --marketplaceH5FontStyles;
  flex-shrink: 0;
  color: var(--matterColorAnti);
  margin-top: 24px;
  margin-bottom: 60px;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.error {
  color: var(--failColor);
}

.submitButton {
  margin: 20px 0 30px !important;
  flex-shrink: 0;
  display: inline-block;
  width: 241px !important;

  @media(max-width: 460px) {
    width: 100% !important;
  }
}



.contentWrapper {
  display: flex;
  flex-wrap: wrap;
  /*max-width: 300px;*/
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 0px;
}

@media (--viewportMedium) {
  .contentWrapper {
    padding-top: 0px;
    padding-bottom: 8px;
    margin-top: 0px;
  }
}

.currencySelector {
  margin-right: 18px;

  & select {
    border-bottom-color: var(--marketplaceColor);
    padding: 4px 20px 4px 0;

    @media (--viewportMedium) {
      padding: 2px 20px 2px 0;
    }
  }
}

.label {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightSemiBold);
  padding: 11px 0 13px 0;
  margin: 0 5px 0 0;
}

@media (--viewportMedium) {
  .label {
    padding: 8px 0 16px 0;
  }
}

.inputsWrapper {
  display: flex;

  @media (--viewportMedium) {
    padding-bottom: 5px;
  }
}

.minPrice {
  @apply --marketplaceSearchFilterSublabelFontStyles;
  width: 55px;
  text-align: center;
  border-bottom-color: var(--attentionColor);
  border-bottom-width: 3px;

  -moz-appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media (--viewportMedium) {
    padding: 1px 0 4px 0;
  }
}

.customerMaxValue {
  width: 80px !important;
}

.maxPrice {
  @apply --marketplaceSearchFilterSublabelFontStyles;
  width: 56px;
  text-align: center;
  border-bottom-color: var(--attentionColor);
  border-bottom-width: 3px;

  -moz-appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media (--viewportMedium) {
    padding: 1px 0 4px 0;
  }
}

.priceSeparator {
  margin: 4px 8px 0 8px;
}

@media (--viewportMedium) {
  .priceSeparator {
    margin: 2px 8px 0 8px;
  }
}

.sliderWrapper {
  display: flex;
  padding: 17px 0 25px 0;
  max-width: 456px;
}

@media (--viewportMedium) {
  .sliderWrapper {
    padding: 16px 0 24px 0;
    margin-bottom: 24px;
  }
}

.projectTimelines {
  margin-bottom: 50px;
  max-width: 456px;
}

.countries {
  & legend {
    margin-bottom: 24px;
  }
}

.closer {
  font-size: 18px;
  opacity: 0.5;
}

.closer:hover {
  opacity: 1; 
  cursor: pointer;
}

.docBox {
  margin-bottom: 20px;
}

.documentHeader {
  width: 320px;
  display: flex;
  justify-content: space-between;
}

.buttonBox {
  position: relative;
  display: inline-block;
  margin-top: 100px;

  &:hover {
    & .toolTipText {
      visibility: visible;
    }
  }

  & .toolTipText {
    visibility: hidden;
    width: 390px;
    background-color: whitesmoke;
    color: #000;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    left: -81%;
    margin-left: -60px;
    margin-bottom: 5px;
    line-height: 16px;
    font-size: 12px;
    bottom: 65px;
  }
}

.submit {
  margin-right: 12px;
}

.privateButton {
  position: relative;
  display: inline-block;
  margin-top: 100px;

  &:hover {
    & .toolTipText {
      visibility: visible;
    }
  }

  & .toolTipText {
    visibility: hidden;
    width: 390px;
    background-color: whitesmoke;
    color: #000;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    right: -100%;
    left: unset;
    margin-left: -60px;
    margin-bottom: 5px;
    line-height: 16px;
    font-size: 12px;
    bottom: 65px;
  }
}

.fieldButtons {
  display: flex;
  flex-direction: row;

  @media(max-width: 768px) {
    flex-direction: column;
    flex-wrap: wrap;
  }
}

.video {
  width: 320px;
  height: 200px;

  @media(max-width: 460px) {
    width: 100%;
  }
}

.videoBox {
  & object {
    width: 320px;
    height: 200px;

    @media(max-width: 460px) {
      width: 100%;
    }

  }

  & iframe {
    width: 320px;
    height: 200px;

    @media(max-width: 460px) {
      width: 100%;
    }
  }
}

.fileUploadLimitMessage {
  color: red;
}