@import '../../styles/propertySets.css';

.root {
  @apply --marketplaceModalFormRootStyles;

  justify-content: space-around;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  flex-wrap: wrap;

  @media (--viewportMedium) {
    margin-top: 32px;
  }

  @media(max-width: 460px) {
    gap: 24px
  }
}

.firstNameRoot {
  width: calc(34% - 9px);

  @media(max-width: 460px) {
    width: 100%;
  }
}

.lastNameRoot {
  width: calc(66% - 9px);

  @media(max-width: 460px) {
    width: 100%;
  }
}

.Email {
  @apply --marketplaceModalPasswordMargins;
}

.password {
  @apply --marketplaceModalPasswordMargins;
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;
  margin-top: 14px;

  & button {
    min-height: 61px;
  }
}

.bottomWrapperText {
  @apply --marketplaceModalBottomWrapperText;
}

.termsText {
  @apply --marketplaceModalHelperText;
  text-align: left;
  color: #3D3D3D;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.termsLink {
  @apply --marketplaceModalHelperLink;
  color: #0B96F5;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.roleRadioBox {
  display: flex;
  flex-direction: column;

  & span {
    & label {
      padding: 0 0 15px 0;

      & span {
        &:nth-child(1) {
          height: 27px;
        }
      }
    }
  }
}

.privacyPolicybox {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  margin-top: 40px;
}

.hearAbout {
  & select {
    color: black;
  }
}

.contactPreference {
  & h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #3D3D3D;
    margin-top: 32px;
    margin-bottom: 19px;
  }

  & .contactCheck {
    display: flex;
    gap: 50px;

    & label {
      & span {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: -0.0888889px;
        color: #3D3D3D;
      }
    }
  }
}

.csmActionButtons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 10px;

  & button {
    width: 150px;
    min-height: 40px;
  }
}

.csmHeading {
  text-align: center;
  margin-bottom: 20px;
}

.loginTopbar {
  position: relative;
  margin-bottom: 40px;
}

.backArrow {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.loginTitle {
  text-align: center;
  color: #3D3D3D;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.selectRole {
  & .roleLabel {
    color: #3D3D3D;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 14px;
  }
}

.thirdStep {
  margin-bottom: 64px;

  & .hearAbout {
    margin-top: 24px;

    @media(--viewportMedium) {
      margin-top: 27px;
    }
  }
}

.fourthStep {
  margin-bottom: 64px;
  position: relative;

  & .showHide {
    position: absolute;
    right: 0;
    top: 36px;
    cursor: pointer;
  }
}

.brandName {
  color: #3D3D3D;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.9px;
  margin-top: 0;
  margin-bottom: 36px;
}

.tooltip {
  display: flex;
  align-items: center;
  
  & .tooltipText{
    position: relative;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.09px;
    cursor: pointer;

    &:hover {
      &::before {
        visibility: visible;
      }
    }
  
    &::before {
      visibility: hidden;
      content: attr(data-tooltip);
      background-color: white;
      color: #000;
      border-radius: 6px;
      padding: 10px;
      position: absolute;
      z-index: 1;
      width: 200px;
      top: -53px;
      left: 100%;
      box-shadow: 0px 8px 16px rgb(0 0 0 / 30%);
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.25px;
    }
  }
}