.bottomWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 3rem;
    gap: 75px;

    @media(max-width: 460px) {
        flex-wrap: wrap;
    }

    & button {
        @media(max-width: 460px) {
            width: 100%;
        }
    }
}

.modalHeadingName {
    font-size: 20px;
    font-weight: 500;
    color: gray;
    line-height: 28px;
}

.userAlreadyConnectedToFirmErrorMessage,
.userRoleErrorMessage {
    color: red;
}