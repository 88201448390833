@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

.root {}

.pageRoot {
  display: flex;
  padding-bottom: 90px;

  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--failColor);
  margin: 24px;
}

.sectionImages {
  @media (--viewportLargeWithPaddings) {
    margin: 30px 36px;
  }

  &>div {
    margin-bottom: 10px;
  }

  & .actionBar {
    margin: 0;

    @media(max-width: 768px) {
      margin: 15px 5px 0;
    }
  }
}

.documentsBox {
  margin: 10px 0;
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;

  @media(max-width: 420px) {
    padding: 0 5px;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  position: relative;
  /* allow positioning own listing action bar */
  padding-bottom: 66.6667%;
  /* 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative);
  /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;

  @media (--viewportMedium) {
    padding-bottom: 0;
    /* No fixed aspect on desktop layouts */
  }
}

.actionBar {
  display: flex;
  justify-content: space-between;
  color: var(--matterColorNegative);
  background-color: var(--matterColor);
  z-index: 1;
  cursor: initial;
  border-radius: var(--borderRadius);
  margin: 15px 20px 0;

  @media(max-width: 768px) {
    margin: 15px;
  }
}

.ownListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 24px;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
  }
}

.ownListingTextPendingApproval {
  color: var(--attentionColor);
}

.closedListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  @apply --marketplaceH4FontStyles;
  flex-shrink: 0;
  margin: 0;
  padding: 14px 24px 11px 12px;
  color: var(--matterColorNegative);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorLight);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 25px 24px 22px 12px;
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  transition: var(--transitionStyleButton);

  @media(min-width: 1440px) {
    height: 569px !important;
    max-height: unset !important;
  }

  @media (--viewportMedium) {
    position: static;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    height: calc(1.41 * 100vw);
    max-height: 58vh;
    object-fit: cover;
    /*border-radius: var(--borderRadius);*/

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.rootForImageContain {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  transition: var(--transitionStyleButton);

  @media(min-width: 1440px) {
    height: 569px !important;
    max-height: unset !important;
  }

  @media (--viewportMedium) {
    position: static;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    height: calc(1.41 * 100vw);
    max-height: 58vh;
    object-fit: contain;
    /*border-radius: var(--borderRadius);*/

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.rootForImageFill {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  transition: var(--transitionStyleButton);

  @media(min-width: 1440px) {
    height: 569px !important;
    max-height: unset !important;
  }

  @media (--viewportMedium) {
    position: static;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    height: calc(1.41 * 100vw);
    max-height: 58vh;
    object-fit: fill;
    /*border-radius: var(--borderRadius);*/

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.viewPhotos {
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightRegular);

  /* Position and dimensions */
  position: absolute;
  width: 121px;
  height: 32px;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 0;
  line-height: 18px;
  /*padding: 8px 13px 6px 13px;*/

  /* Colors */
  background-color: var(--matterColorLight);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentContainer {

  @media (--viewportMedium) {
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    width: 1128px;
    padding: 0;
    margin: 0 auto 0;
    padding-bottom: 25px;
  }

  @media (--viewportXLarge) {
    width: 1056px;
    padding: 0;
  }

  @media(max-width: 1220px) {
    padding: 0 20px;
  }

  @media(max-width: 920px) {
    flex-wrap: wrap;
    padding: 0;
    /* z-index: 10; */
  }

  @media(max-width: 840px) {
    /* padding: 0 24px; */
  }

  & .clientDocuments {
    /* background-color: red; */
    text-align: left;
  }
}

.mainContent {
  /* flex-basis: 100%; */
  margin-bottom: 23px;
  border-right: 1px solid #E6E6E6;
  width: 65%;
  padding-right: 20px;

  @media(max-width: 920px) {
    width: 100%;
    padding-right: 0;
    border-right: 0;
  }

  @media(max-width: 920px) {
    margin-bottom: 0 !important;
    margin-top: 35px;
  }

  @media (--viewportMedium) {
    margin-top: 35px;
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 44px;
    margin-bottom: 0px;
    /* contentContainer's full width - (bookingColumn + two paddings + margin + border) */
    /* max-width: calc(100% - 440px);
    flex-basis: calc(100% - 440px); */
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.mainContentBrief {
  flex-basis: 100%;
  margin-bottom: 23px;
  /* padding-right: 20px; */

  @media (--viewportMedium) {
    /* margin-top: 35px; */
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    /* margin-top: 40px; */
    margin-bottom: 0px;
    /* contentContainer's full width - (bookingColumn + two paddings + margin + border) */
    /* max-width: calc(100% - 440px);
    flex-basis: calc(100% - 440px); */
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.editText {
  @media(max-width: 460px) {
    display: none;
  }
}

.bookingPanel {

  /* width: 35%; */
  @media (--viewportLarge) {
    /* padding: 0 0 0 52px; */
    display: block;
    /* margin-top: 30px; */
    /* margin-left: 60px; */
    /*border-left-style: solid;*/
    /*border-left-width: 1px;*/
    /*border-left-color: var(--matterColorNegative);*/

    /* padding-left: 60px; */

    /* The calendar needs 312px */
    /* flex-basis: 380px; */
    /* flex-shrink: 0; */
  }

  /* @media(max-width: 1220px) {
    padding-left: 15px;
  }

  @media(max-width: 768px) {
    padding-left: 0;
  }

  @media(max-width: 920px) {
    width: 100%;
    padding-left: 0;
  }

  & button {
    letter-spacing: -0.27px;
  } */
}

.sectionAvatar {
  /* Position (over the listing image)*/
  /* margin-left: 24px;
  margin-top: -72px; */

  /* Rendering context to the same lavel as listing image */
  /* position: relative; */

  /* Flex would give too much width by default. */
  /* width: 60px;
  position: absolute;
  top: -80px;
  margin-left: 0;
  margin-top: 0; */

  /* @media(max-width: 768px) {
    margin-top: -52px;
    top: -30px !important;
  }

  @media(max-width: 920px) {
    margin-left: 0;
  } */

  @media (--viewportMedium) {}

  & img {
    border: 3px solid #FFFFFF;
  }
}

.avatarMobile {
  display: flex;
  width: 118px;
  height: 118px;

  @media (--viewportMedium) {
    display: none;
    /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none;
  /* Hide the large avatar from the smaller screens */
  width: 118px;
  height: 118px;

  @media (--viewportMedium) {
    display: flex;
  }
}

.initialsDesktop {
  font-size: 30px;
  line-height: 48px;
  font-weight: var(--fontWeightRegular);
  padding-bottom: 5px;
}

.sectionHeading {
  /* margin-top: 22px; */
  /* margin-bottom: 34px; */
  /* cursor: pointer; */

  @media (--viewportMedium) {
    /* display: flex; */
    /* margin-bottom: 31px; */
  }

  & .arrowButton {
    margin-left: 23px;

    & svg {
      width: 23px;
      height: 14px;
    }
  }
}

.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  @apply --marketplaceModalTitleStyles;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  @apply --marketplaceH5FontStyles;
  color: var(--matterColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin: 0 0;
  padding: 0 24px;

  @media(max-width: 768px) {
    padding: 0;
    margin: 0;
  }

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  @apply --marketplaceModalTitleStyles;
  color: var(--matterColor);
  font-weight: var(--fontWeightRegular);
  line-height: 56px;
  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }
}

.author {
  width: 100%;
  @apply --marketplaceH5FontStyles;
  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--matterColor);
  }
}

.authorNameLink {
  color: var(--matterColor);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH5FontStyles;
  margin: 0;
}

.sectionDescription {
  /* padding: 0 24px;
  margin-bottom: 35px;
  margin-top: 30px; */

  @media(max-width: 768px) {
    /* padding: 0; */
  }

  @media (--viewportMedium) {
    /* padding: 0;
    margin-top: 40px;
    margin-bottom: 40px; */
  }

  & .descriptionlabel {
    margin-top: 0;
    font-size: 22px;
    font-weight: 500;
    line-height: 33px;
    margin-bottom: 15px;
    letter-spacing: -0.9375px;
  }
}

.descriptionTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 13px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.sectionFeatures {
  /* padding: 0 24px;
  margin-bottom: 32px; */

  @media(max-width: 768px) {
    /* padding: 0; */
  }

  @media (--viewportMedium) {
    /* padding: 0;
    margin-bottom: 40px; */
  }

  & .sectionHeading {
    margin-top: 0;
    display: flex;
    cursor: pointer;
    font-size: 22px;
    font-weight: 500;
    line-height: 33px;
    letter-spacing: -0.9375px;
    color: #3D3D3D;
    margin-bottom: 0;
    justify-content: space-between;
  }
}

.uspRow {
  margin-bottom: 10px;

  & .uspHeading {
    color: #3d3d3d;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-right: 9px;
    letter-spacing: -0.08888888359069824px;
  }

  & .uspValue {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    padding: 4px 10px;
    border-radius: 12px;
    cursor: pointer;
    letter-spacing: -0.10000000149011612px;
    position: relative;

    &:hover {
      &::after {
        display: block;
      }
    }

    &::after {
      display: none;
      content: attr(data-tooltip);
      top: -55px;
      right: -205px;
      border: 1px solid #eee;
      box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 14%);
      width: 205px;
      background-color: white;
      color: #3D3D3D;
      text-align: left;
      border-radius: 6px;
      line-height: 1.5;
      padding: 5px;
      position: absolute;
      font-size: 12px;

      @media(max-width: 768px) {
        right: -150px;
      }
    }
  }

  & .domainExp {
    background-color: #F6B430;
  }

  & .industryUSP {
    background-color: #0B96F5;
  }
}

.featuresTitle {
  margin-top: 0;
  font-size: 22px;
  color: #3d3d3d;
  font-weight: 500;
  line-height: 33px;
  margin-bottom: 15px;
  letter-spacing: -0.9375px;
}

.descriptionLabel {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 16px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.domainExpertiseElementBlock {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.domainExpertiseParentsElement {
  display: flex;
  align-items: center;
  padding: 3px 10px;
  /* height: 26px; */
  background-color: rgba(246, 180, 48, 0.2);
  border-radius: 12px;

  &:last-of-type {
    margin-right: 0;
  }
}


.domainExpertiseElementTitle {
  color: rgba(228, 152, 0, 1);
  line-height: 18px;
  font-weight: var(--fontWeightRegular);
  letter-spacing: -0.1px;
  text-transform: capitalize;
}

.engagementRoleElementBlock {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 15px 10px;

  @media(max-width: 768px) {
    row-gap: 20px;
  }
}

.industrySectorParentsElement {
  display: flex;
  align-items: center;
  padding: 3px 10px;
  background-color: #0b96f536;
  border-radius: 12px;
}

.industrySectorBlock {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
}

.industrySectorElementTitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.10000000149011612px;
  color: #0081D9;
}

.description {
  line-height: 20px;
  font-weight: var(--fontWeightRegular);
  margin-top: 0;
  margin-bottom: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  color: var(--dark-grey, #3D3D3D);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.089px;


  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {
    padding-right: 36px;
  }
}

.sectionMap {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.locationTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 20px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 28px;
  }
}

.reviewsWrapper {
  display: flex;
  justify-content: space-between;
}

.active {
  padding-bottom: 10px;
  border-bottom: 3px solid #000000;
}

.sectionReviews {
  cursor: pointer;
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.reviewsHeading {
  @apply --marketplaceH3FontStyles;
  font-size: 22px;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: -0.9375px;

  cursor: pointer;
  margin: 0 0 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & .arrowButton {
    /* margin-left: 23px; */

    & svg {
      width: 23px;
      height: 14px;
    }
  }
}

.packageContainerModal {
  & .packageLabel {
    margin: 0;
    color: #0081D9;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.1px;
    background: #0b96f538;
    border-radius: 12px;
    padding: 5px 10px;
    margin: 20px 0;
  }

  & .packageTitle {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.177778px;
    color: #3D3D3D;
    margin: 0;
  }

  & .packageDescription {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.0888889px;
    color: #3D3D3D;
    margin: 0;
  }
}

.sectionHost {
  position: relative;
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.yourHostHeading {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin: 54px 0 23px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }

  @media (--viewportLarge) {
    margin: 53px 0 27px 0;
  }
}

.enquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.enquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.enquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 640px;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.blockInfo {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.177778px;

  @media (--viewportMedium) {
    /* margin-top: 10px; */
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.blockTitle {
  font-weight: var(--fontWeightSemiBold);
}

.blockCompany {
  margin-top: 3px;
  color: var(--dark-grey, #3D3D3D);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.178px;
}

.blockExperience {
  margin-top: 3px;
  color: #858585;
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: var(--fontWeightRegular);
}

.blockDescription {
  /* white-space: pre-wrap; */
  color: var(--dark-grey, #3D3D3D);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.089px;
  word-break: break-all;
}

.userInfoBlock {
  font-size: 16px;
  /* margin-top: 5px; */
  line-height: 20px;
  font-weight: var(--fontWeightRegular);

  & .subHeadingBox {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    @media(max-width:640px) {
      justify-content: center;
    }

    & .subhHeadingtext {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #fff;
      letter-spacing: -0.08749999850988388px;

      @media(max-width:920px) {
        /* font-size: 13px; */
      }

      & .spaceDot {
        margin: 0 4px 0 1px;
      }
    }
  }
}

.userName {
  font-size: 30px;
  font-weight: 500;
  line-height: 45px;
  letter-spacing: -0.9375px;
  color: #000000;

  & a {
    color: #fff;

    &:hover {
      text-decoration: none;
    }
  }
}

.pointDelimiter {
  color: #fff;
  margin: 0 5px;
}

.documentItem a {
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 14px;
  color: var(--matterColor);
  text-decoration: underline;
}

.empty {
  height: 35px;

  @media (--viewportMedium) {
    height: 35px;
  }

  @media(max-width: 420px) {
    /* display: none; */
    height: 55px;
  }
}

.briefBox {
  /* display: flex; */
  /* border-right: 1px solid #E6E6E6; */
  width: 65%;

  @media(max-width: 1024px) {
    width: 100%;
    border-right: none;
  }
}

.averageRating {
  margin-right: 27px;
  position: relative;
  display: inline-block;

  @media(max-width: 1024px) {
    margin-right: 0;
  }
}

.reviewPopup {
  /* visibility: hidden; */
  width: 600px;
  background-color: white;
  color: #fff;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: -5px;
  padding: 60px 20px 20px;
  right: 0;
  left: 50%;
  transform: translate(-50%, 0);
  /* animation: up-bump 1s ease; */
  animation: fadeIn 0.8s;
  -webkit-box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 75%);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 75%);

  /* &:after {
    content: "";
    background-color: red;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  } */
  & .popupClose {
    & svg {
      width: 20px;
      height: 20px;
      position: absolute;
      right: 20px;
      top: 20px;
      cursor: pointer;

      & path {
        stroke: #333;
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.averageRating:hover .reviewPopup {
  /* visibility: visible; */
}

.reviewBoxWrapper {
  & .reviewBody {
    display: flex;
    gap: 22px;
    margin-top: 20px;
  }

  & .reviewMessage {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #3D3D3D;
  }

  & .heading {
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    letter-spacing: -0.9px;
    color: #3D3D3D;
  }

  & .reviewDateRate {
    display: flex;
    margin-top: 9px;

    & .dateText {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #B2B2B2;
    }

    & .ratingStar {
      margin-left: 9px;
      line-height: 20px;

      & svg {
        height: 12px;
        width: 12px;
      }
    }
  }
}

.reviewText {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.1px;
  color: #3D3D3D;
  display: flex;
  justify-content: space-between;

  & .arrowButton {
    cursor: pointer;
  }
}

.averageBoxLeft {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #E6E6E6;
  padding-bottom: 6px;
  margin-bottom: 6px;

  & .reviewTotalCount {
    margin-right: 7px;
    color: #3D3D3D;
    font-size: 18px;
    font-weight: 600;
  }

  & .reviewTotalInfo {
    color: #B2B2B2;
    font-size: 16px;
    font-weight: 400;
  }
}

.briefBookingPanel {
  /* padding-left: 93px; */
}

.rightBriefBox {
  padding: 0 40px;
  width: 35%;

  @media(max-width: 1200px) {
    padding: 0 0 0 15px;
  }

  @media(max-width: 1024px) {
    width: 100%;
  }
}

.actionBarSingle {
  /* top: 75px;
  left: 26px;
  width: calc(100% - 52px); */
}

.reviewStar {
  width: 18px;
  height: 18px;
  margin-right: 2px;
}

.mobileworkRating {
  display: none;

  @media(max-width: 920px) {
    display: block;
  }
}

.ratingHeading {
  color: #3d3d3d;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.10000000149011612px;
}

.reviewsSubHeading {
  min-width: 100px;
  /* border-bottom: 1px solid #E6E6E6; */
  /* padding-bottom: 10px; */

  @media(max-width: 920px) {
    background: rgba(246, 180, 48, 0.20);
    /* padding: 0 24px;
    margin-left: -20px;
    margin-right: -20px; */
  }

  & .averageRating {
    display: flex;
    margin: 0;
    /* margin-bottom: 20px; */
    align-items: baseline;
    justify-content: space-between;
    cursor: pointer;

    @media(max-width: 920px) {
      padding: 0
    }

    &:first-child {
      /* border-top: 1px solid #E6E6E6;
      margin-top: 40px;
      padding: 20px 0 0 0; */
      margin-bottom: 20px;

      /* border-bottom: 1px solid #E6E6E6; */
      @media(max-width: 920px) {
        /* padding: 10px 0 0 0;
        margin-top: 25px; */
      }
    }

    &:nth-child(3) {
      /* padding-bottom: 20px;
      border-bottom: 1px solid #E6E6E6;

      @media(max-width: 768px) {
        padding-bottom: 10px;
      } */
    }

    & span {
      /* display: flex;
      font-size: 12px;
      margin-right: 5px;
      line-height: 18px;
      align-items: center;
      font-weight: var(--fontWeightRegular);

      @media(max-width: 920px) {
        color: var(--dark-grey, #3D3D3D);
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.089px;
        text-transform: uppercase;
      }

      @media(max-width: 460px) {
        flex-wrap: wrap;
        justify-content: flex-end;
      } */
    }

    & .reviewTotalInfo {
      font-size: 16px;
      color: #B2B2B2;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: -0.10000000149011612px;


      & span {
        /* color: var(--grey, #B2B2B2);
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.089px; */
      }
    }

    & .reviewTotalCount {
      margin-right: 7px;
      font-weight: 600;
    }
  }
}

.reviewStars {
  display: block;
  /* margin-top: 4px; */
  margin-right: 6px;
  margin-top: -5px;

  @media (--viewportMedium) {
    /* margin: 0; */
  }
}

.daysText {
  font-weight: var(--fontWeightRegular);
}

.someDaysBlock {
  display: flex;
  padding: 3px 0;
  align-items: baseline;
}

.daysSection {
  line-height: 20px;
  display: flex;
  flex-direction: column;
}

.titleDays {
  line-height: 20px;
  width: 110px;
  margin-right: 20px;
}

.labelDays {
  line-height: 20px;
}

.labelDaysText {
  font-weight: var(--fontWeightRegular);
  line-height: 20px;
}

.packageContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  background: #FFFFFF;
  padding: 20px 16px;
  border: 1px solid #E6E6E6;
  border-radius: 4px;
  width: 100%;
  height: 232px;

  @media(max-width: 1100px) {
    width: 100%;
  }

  & .packageTitle {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 600;
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.177778px;
    color: #3D3D3D;

  }

  & .packageDescription {
    margin-top: 0;
    font-size: 16px;
    color: #3D3D3D;
    overflow: hidden;
    margin-bottom: 0;
    font-weight: 400;
    line-height: 24px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    letter-spacing: -0.0888889px;
  }
}

.reviewBox {
  margin-top: 20px !important;
}

.packagesCard {
  display: grid;
  row-gap: 20px;
  margin-top: 20px;
  column-gap: 14px;
  grid-row-gap: 14px;
  grid-column-gap: 14px;
  justify-content: flex-start;
  grid-template-columns: auto auto;

  @media(max-width: 1100px) {
    grid-template-columns: auto;
  }
}

.packageTitle {
  font-weight: bold;
  line-height: normal;
}

.centerBox {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
}

.parentLabel {
  color: #0081D9;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.1px;
  background: #0b96f538;
  border-radius: 12px;
  padding: 5px 10px;
  margin: 20px 0;
}

.moreOption {
  color: #0b96f5;
  margin-top: 20px;
  margin-bottom: 0;
}

.video {
  width: 335px;
  height: 211px;
}

.documentUpload {
  position: relative;
  /* width: 319px;
  height: 211px; */
  cursor: pointer;

  &::-webkit-scrollbar {
    display: none;
  }

  & .hoverView {
    display: none;
  }

  & .mediaObject {
    /* border: 1px solid #e6e6e6; */

    /* &:before {
      content: '';
      position: absolute;
      left: 1px;
      width: 99%;
      top: 2px;
      height: 20px;
      background: #ffffff;
      z-index: 99;
    } */

    &:hover {
      & .defaultView {
        display: none;
      }

      & .hoverView {
        display: flex;
        position: absolute;
        top: 0;
        width: 100%;
        height: 60px;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
      }
    }
  }

  & .defaultView {
    border: 1px solid #E6E6E6;
    margin-top: 15px;

    & .defaultBody {
      background: #FFFFFF;

      & .defaultBox {
        padding: 0 20px;

        & .documentInformation {
          line-height: 23px;

          & .documentHeading {
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: -0.0875px;
            color: #000000;
            margin-right: 3px;
          }

          & .documentData {
            font-weight: 400;
            font-size: 10px;
            line-height: 16px;
            letter-spacing: -0.0875px;
            color: #000000;
          }
        }
      }

    }

    & h4 {
      font-weight: 700;
      font-size: 20px;
      line-height: 26px;
      letter-spacing: -0.0875px;
      color: #000000;
      margin: 0 0 20px 0;
      padding: 20px 20px 0 20px;
    }
  }
}

.mediaObject:hover:before {
  display: none;
}

.documentFooterWrapper {
  background-color: #F6F6F6;
  padding: 7px 12px;
  margin-top: 15px;

  & .documentFooterBar {
    display: flex;
    align-items: center;
  }

  & .documentTypeIcon {
    background: #F6B430;
    border-radius: 2px;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    letter-spacing: -0.0875px;
    color: #FFFFFF;
    width: 40px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
  }

  & .documentDetail {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.0875px;
    color: #3D3D3D;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  & .documentNameDetail {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.0875px;
    color: #3D3D3D;
  }
}

.hoverView {
  background-color: #F6F6F6;
  padding: 20px 12px;
  display: flex;
  position: absolute;
  top: 0;
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  & .documentFullScreen {
    display: flex;
    align-items: flex-start;
  }

  & .documentNavIcon {
    margin-left: 45px;

    & span {
      margin-left: 5px;

      & svg {
        width: 25px;
        height: 25px;
      }
    }
  }

  & .documentTypeIcon {
    background: #F6B430;
    border-radius: 2px;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    letter-spacing: -0.0875px;
    color: #FFFFFF;
    width: 50px;
    height: 24px;
    display: flex;
    padding: 0 5px;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
  }

  & .documentDetail {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.0875px;
    color: #3D3D3D;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  & .documentNameDetail {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.0875px;
    color: #3D3D3D;
    word-break: break-all;

    & .fileSize {
      margin-top: 4px;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.0875px;
      color: #858585;
    }
  }
}

.documentTypeIcon {
  & img {
    width: 30px;
    height: 41px;
  }
}

.mediaUpload {
  margin-bottom: 16px;
  height: 50px;

  &:last-child {
    margin-bottom: 0;
  }

  & .mediaLink {
    &:hover {
      color: #333;
    }

    & .mediaUrl {
      margin-top: 4px;
      font-size: 12px;
      color: #333;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.briefLocation {
  margin: 11px 0 18px;

  & .loactionHeading {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.1125px;
    color: #B2B2B2;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  & .locationBox {
    font-size: 16px;
    font-weight: 400;
    color: #C0C0C0;
    line-height: 20px;
    letter-spacing: -0.08749999850988388px;

    & img {
      width: 30px;
      /* height: 14px; */
      margin-right: 15px;
    }

    & .locationCard {
      & .locationText {
        position: relative;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 5px;
        margin-top: 0;
        font-weight: var(--fontWeightRegular);
        letter-spacing: -0.09px;
      }
    }
  }
}

.postedlabel {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.1125px;
  color: #B2B2B2;
  /* margin-top: 50px;
  margin-bottom: 10px; */
  margin-right: 5px;
  margin-bottom: 10px;
  margin-top: 40px;
}

.companyDetails {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.0888889px;
  color: #B2B2B2;
  margin-top: 14px;

  & .nameText {
    position: relative;
  }
}

.postedlabelName {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.1125px;
  margin-right: 5px;
  margin-bottom: 0;
}

.postedDateBox {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  margin-top: 40px;
}

.postedDate {
  /* margin-top: 50px;
  margin-bottom: 10px; */
}

.documents {
  /* margin-top: 5px;
  width: 312px;
  height: 190px; */

  &::-webkit-scrollbar {
    display: none;
  }

  /* overflow-y: hidden !important; */

}

.documentsHeading {
  margin-top: 0;
  font-size: 22px;
  color: #3d3d3d;
  font-weight: 500;
  line-height: 33px;
  margin-bottom: 20px;
  letter-spacing: -0.9375px;
}

.moreOption {
  cursor: pointer;
}

.documentsWrapper {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;

  & .documentRightBox {
    & .documentName {
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: -0.0875px;
      color: #3D3D3D;
    }

    & .documentDate {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: -0.0875px;
      color: #B2B2B2;
      margin-top: 3px;

      & .fileSize {
        margin-left: 5px;
        position: relative;
        padding-left: 8px;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          background-color: #B2B2B2;
          height: 3px;
          width: 3px;
          top: 5px;
          border-radius: 50%;
        }
      }
    }
  }
}

.firmName {
  font-weight: 400;
  font-size: 30px;
  line-height: 56px;
  letter-spacing: -0.9375px;
  color: #0B96F5;
}

.hideScroll {
  display: inline-block;
  width: 10px;
  height: 100%;
  background: white;
  position: absolute;
  right: 2px;
}

.documentWrapper {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
  /* margin-bottom: 20px; */

  & .docName {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.08749999850988388px;
  }

  & .docDate {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: -0.0875px;
    color: #B2B2B2;
    margin-top: 3px;
  }
}

.addTagButton {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #0B96F5;
  border: 1px solid #0B96F5;
  height: 35px;
  cursor: pointer;
  padding: 0 10px;
  margin-top: 11px;
  border-radius: 3px;
}

.disabled {
  color: gray;
  border-color: gray;
  cursor: not-allowed;
}

.error {
  font-size: 13px;
  font-weight: 400;
  color: red;
  margin: 0;
}

.addButton {
  width: auto;
  padding: 0 30px;
  min-height: 39px;
  height: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputContainer {
  display: flex;
  column-gap: 20px;
  align-items: baseline;

  & input {
    width: 400px;
  }

  & .addTagButton {
    padding: 0 40px;
    border-radius: 3px;
  }

  & ul {
    background: #FFFFFF;
    margin-top: 0;
    border: 1px solid #E6E6E6;
    border-radius: 2px;

    & li {
      border-top: 1px solid #E6E6E6;
      cursor: pointer;
      padding: 1px 10px;
      text-transform: capitalize;
    }
  }
}

.clientTagsContainer {
  /* margin-bottom: 40px; */
}

.tagParentsElement {
  display: flex;
  align-items: center;
  padding: 5px 8px;
  background-color: #afafaf;
  color: white;
  border-radius: 18px;
  position: relative;

  & label {
    cursor: pointer;
  }

  &:last-of-type {
    margin-right: 0;
  }
}

.tagParentsElement label {
  line-height: 18px;
  font-weight: var(--fontWeightRegular);
  letter-spacing: -0.1px;
  text-transform: capitalize;
  padding: 0;
}

.tagParentsElement span {
  cursor: pointer;
  height: 17px;
  width: 17px;
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
}

.closeItem {
  & svg {
    & path {
      stroke: #fff;
    }
  }
}

.tagParentsElement::before {
  content: attr(tag-count);
  font-size: 12px;
  width: 20px;
  height: 20px;
  background-color: #adaaaa;
  color: white;
  border-radius: 50%;
  position: absolute;
  top: -12px;
  right: -8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.closeTag {
  cursor: pointer;
  margin: 11px 0;
  color: red;
  font-size: 24px;
}

.maxExpertWarningModal {
  margin: 1em 0 0;

  & .heading {
    font-family: Poppins;
    font-size: 30px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: -0.8999999761581421px;
    text-align: left;
  }

  & .subHeading {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
  }

  & .buttonWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 3em 0 1em;

    & .buttonCancel {
      font-size: 19px;
      font-weight: 400;
      cursor: pointer;
    }

    & .submitButton{
      font-size: 19px;
      font-weight: 400;
      width: 228px;
    }
  }
}

.firmTitleLink {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.0875px;

  /* @media(max-width:920px) {
    font-size: 13px;
    line-height: 24px;
  } */

  &:hover {
    text-decoration: none;
  }
}

.briefFirmTitleLink {
  & span {
    color: #B2B2B2;
  }

  &:hover {
    text-decoration-color: #B2B2B2;
  }
}

.pointerEvents {
  opacity: .5;
  cursor: not-allowed !important;
}

.jobListingtTitle {
  margin-top: 30px;
}

.fieldHeader {
  margin: 20px 0 10px;
  color: var(--grey, #B2B2B2);
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.112px;
}

.descriptionRow {
  margin-top: 20px;

  & h3 {
    color: var(--grey, #B2B2B2);
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.112px;
    margin: 0;

    @media(max-width: 420px) {
      font-size: 17px;
    }
  }

  & p {
    color: var(--dark-grey, #3D3D3D);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.089px;
    margin: 5px 0;

    @media(max-width: 420px) {
      font-size: 15px;
    }
  }

  & .employementText {
    width: fit-content;
    padding: 3px 15px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.1px;
    border-radius: 12px;
    background: #ededed;
  }
}

.inline {
  display: flex;
  align-items: center;
  gap: 12px;
}

.industryLabel {
  display: flex;
  padding: 0 20px;
  position: relative;
  font-size: 16px;
  line-height: 32px;
  font-weight: var(--fontWeightRegular);
  letter-spacing: -0.09px;
  text-transform: capitalize;

  &:before {
    content: ' ';
    position: absolute;
    top: 12px;
    left: 0;
    width: 8px;
    height: 8px;
    background-image: url('../../assets/cheked.png');
  }
}

.files {
  display: flex;
  justify-content: space-between;
  margin: 0;

  & .removeFile {
    cursor: pointer;
    color: red;
    font-weight: 400;
  }
}

.pdfBox {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  align-items: flex-end;

  & .pdfImage {
    & img {
      width: 30px;
      height: 41px;
    }
  }
}

.pdfRight {
  /* margin-bottom: 10px; */

  & .pdfName {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.0875px;
    color: #3D3D3D;
  }

  & .pdfDate {
    font-weight: 400;
    font-size: 10px;
    line-height: 21px;
    letter-spacing: -0.0875px;
    color: #B2B2B2;

    & .fileSize {
      margin-left: 5px;
      position: relative;
      padding-left: 8px;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        background-color: #B2B2B2;
        height: 3px;
        width: 3px;
        top: 8px;
        border-radius: 50%;
      }
    }
  }
}

.inputSelectCard {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  margin-top: 10px;

  & .servicesSelected {
    background: #fdf0d6;
    border-radius: 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.1px;
    color: #E49800;
    padding: 4px 9px;
    text-transform: capitalize;
  }
}

.reviewModal {
  @apply --marketplaceModalBaseStyles;
  flex-basis: 630px;
  border-bottom: none;
  min-height: auto;
}

.listingCsm {
  width: 104px !important;
  height: 104px !important;
  cursor: pointer;
  position: relative;

  &>div {
    width: 104px !important;
    height: 104px !important;
    margin: 0;

    & span {
      width: 104px !important;
      height: 104px !important;

      & img {
        width: 100% !important;
        height: 100% !important;
      }

      &::after {
        color: #FFF;
        font-size: 18px !important;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.089px;
        width: 79px !important;
        height: 25px !important;
      }
    }

    & div {
      color: #FFF;
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.089px;
    }
  }

  &:hover {
    &::before {
      visibility: visible;
    }
  }

  &::before {
    visibility: hidden;
    content: attr(data-tooltip);
    background-color: white;
    color: #000;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    width: 350px;
    top: -60px;
    left: 8em;
    box-shadow: 0px 8px 16px rgb(0 0 0 / 30%);
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.25px;

    @media(max-width: 768px) {
      width: 240px;
    }
  }
}

.profileViewsReachedModalHeading {
  color: #3D3D3D;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.9px;
  margin-bottom: 30px;
  margin-top: 0;
  text-align: center;
}

.profileViewsReachedModalDescription {
  color: #3D3D3D;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  text-align: center;
}

.hereLink {
  margin-left: 5px;
}

.profileViewsReachedModalWraper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;

  & button {
    width: 228px;
  }
}

.blueBackground {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #032943;
  border-radius: 3px;
  position: relative;
  margin: 20px 20px 0;
  padding: 98px 0 35px;

  @media(max-width: 840px) {
    padding: 40px 24px;
  }

  @media(max-width: 768px) {
    height: 100%;
    padding: 24px;
    margin: 0;
  }

  & .expertBackgroundImage {
    height: 100%;
    width: 100%;
    position: absolute;
    object-fit: cover;
    filter: brightness(0.8) blur(1px);
    border-radius: 0;
  }

  & .expertCover {
    gap: 26px;
    width: 100%;
    display: flex;

    @media(max-width: 640px) {
      flex-wrap: wrap;
      justify-content: center;
      text-align: center;
    }

    & .coverRight {
      width: 100%;
    }
  }
}

.sendBriefButtonWrapper {
  gap: 8px;
  display: flex;
  
  @media(max-width: 768px) {
    flex-wrap: wrap;
    min-width: 100%;
    justify-content: space-between;
  }

  & .tooltip {
    cursor: no-drop;
    background: gainsboro;

    &:hover {
      &::after {
        display: block;
      }
    }

    &::after {
      display: none;
      content: attr(data-tooltip);
      background-color: white;
      color: var(--dark-grey, #3D3D3D);
      border-radius: 4px;
      padding: 8px 14px;
      position: absolute;
      z-index: 1;
      top: 4em;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.25px;
      box-shadow: 0px 8px 16px 0px #000000;

      @media(max-width: 768px) {
        width: 90%;
      }
    }
  }
}

.scheduleButton {
  color: #0B96F5;
  background-color: #fff;
  border: 1px solid #0B96F5;
}

.bottomButton {
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: space-between;
  position: relative;

  @media(max-width: 640px) {
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
  }

  & button {
    height: 41px;
    padding: 0 30px;
    min-height: 41px;
    width: fit-content;
    border-radius: 4px;

    @media(max-width: 460px) {
      padding: 0 15px;
    }
  }
}

.favourites{
  display: flex;
  column-gap: 10px;

  @media(max-width: 769px){
    width: 100%;
    justify-content: space-between;
  }

  & .favouriteIcon{
    height: 41px;
    width: 41px;
    background: white;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;

    &:hover{
      &::before{
        display: block;
      }
    }

    &::before{
      display: none;
      content: 'Add to favourites';
      position: absolute;
      background-color: white;
      font-size: 12px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0.25px;
      padding: 3px 12px;
      min-width: max-content;
      top: -45px;
      border-radius: 4px;
      right: 0px;
      transition: 0.5s all;
    }
  }

  & .addedFavouriteIcon{
    & svg{
      & path{
        fill: red;
        stroke: none;
      }
    }
  }

  & .compareButton{
    background-color: white;
  }
}

.bottomButtonExpert {
  & button {
    padding: 0 15px;
  }

  & .comingSoonBadge {
    cursor: no-drop;
    position: relative;

    &:hover {
      &::before {
        transform: translateX(0px);
        color: white;
      }
    }
  }
}

.badgeButtonWrapper {
  display: flex;
  width: 187px;
  overflow: hidden;
  border-radius: 3px;

  & button {
    transition: 0.2s all;
    min-width: 188px;
  }

  &:hover {
    & .comingSoonBadge {
      border-radius: 3px;
      transform: translateX(-188px);
    }
  }
}

.shadowBox {
  padding: 30px 20px;
  border-radius: 10px;
  margin-bottom: 30px;
  background-color: #fff;
  box-shadow: 0px 8px 8px 5px #0000000D;

  @media(max-width: 920px) {
    box-shadow: none;
    border-radius: 0;
    margin: 20px 0;
  }
}

.bodyGrid {
  gap: 30px;
  width: 100%;
  display: flex;
  margin-top: 30px;

  @media(max-width: 960px) {
    flex-wrap: wrap;
    background-color: #F5F5F5;
    margin-top: 0;
    gap: 0;
  }

  & .bodyLeft {
    width: 60%;

    @media(max-width: 960px) {
      width: 100%;
    }

    & .shadowBox {

      & .openTo {
        & .headingName {
          margin-top: 0;
          font-size: 22px;
          color: #3d3d3d;
          font-weight: 500;
          line-height: 33px;
          margin-bottom: 15px;
          letter-spacing: -0.9375px;
        }

        & .openWrapper {
          & .semiBoldText {
            font-size: 16px;
            color: #3D3D3D;
            font-weight: 500;
            line-height: 24px;
            margin-right: 5px;
            letter-spacing: -0.08888888359069824px;
          }

          & .detailText {
            font-size: 16px;
            font-weight: 400;
            color: #3D3D3D;
            line-height: 24px;
            letter-spacing: -0.08888888359069824px;
          }
        }
      }

      & .hocProject {
        font-size: 16px;
        margin-top: 4px;
        font-weight: 500;
        line-height: 24px;
        color: #3D3D3D;
        letter-spacing: -0.08888888359069824px;
      }
    }
  }

  & .bodyRight {
    width: 40%;

    @media(max-width: 960px) {
      width: 100%;
    }
  }
}

.ratingRow {
  display: flex;
  align-items: center;
}

.coverMain {
  height: 400px;
  border-radius: 10px;
  width: 1128px;
  padding: 0;
  margin: 10px auto 0;
  /* background-color: #ddd; */

  & .coverProfile {
    /* background-image: url(../../assets/1424.png); */
    width: 1128px;
    margin: 10px auto 0;
    display: flex;
    background-color: #0081D9;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 0;
      height: 0;
      border-left: 25px solid #7eb4e2;
      border-top: 25px solid transparent;
      border-bottom: 25px solid transparent;
    }

    &:before {
      content: '';
      position: absolute;
      right: -25px;
      bottom: 0;
      width: 0;
      height: 0;
      border-left: 25px solid #32557f;
      border-top: 25px solid transparent;
      border-bottom: 25px solid transparent;
    }

    & .coverLeft {
      width: 55%;
    }

    & .coverRight {
      width: 45%;

      & .expertImage {
        & img {
          width: 100%;
        }
      }
    }
  }
}

.halfImage {
  width: 50%;
}

.profileCard {
  width: 100%;
  height: 400px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: grid;
  place-items: center;
  position: relative;
  transition: all 1s;
  z-index: 1;
  overflow: hidden;
  background-size: 50% 100%;
  background-position: left;
}

.info h2,
.info p {
  color: #fff;
  opacity: 0;
  transition: all 0.6s;
}

.profileCard .info h2,
.profileCard .info p {
  opacity: 1;
}

.info::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0095FC;
  transform: skew(-30deg) translateX(100%);
  opacity: 0.3;
  z-index: -1;
  transition: all 0.6s ease;
}

.info::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0095FC;
  transform: skew(-30deg) translateX(100%);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
  opacity: 0.3;
  z-index: -1;
  transition: all 0.6s ease;
}

.profileCard .info::before {
  transform: skew(-30deg) translateX(50%);
}

.profileCard .info::after {
  transform: skew(-30deg) translateX(40%);
  opacity: 0.7;
}

.profileCard::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  background: #0095FC;
  opacity: 0.3;
  transform: skew(30deg) translateX(30%);
  transition: all 0.6s ease;
  z-index: -1;
}

.profileCard::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  background: #0095FC;
  opacity: 0.3;
  transform: skew(-30deg) translateX(20%);
  transition: all 0.6s ease;
  z-index: -1;
}

/* .profileCard:before {
  transform: skew(30deg) translateX(30%);
}

.profileCard:after {
  transform: skew(-30deg) translateX(20%);
} */
.mainWrapper {
  width: 1128px;
  margin: 0 auto 0;
}

.mainWrapperClient {
  margin: 40px auto 0;

  & .layoutRow {
    background: linear-gradient(21deg, #F6B430 0%, rgba(255, 255, 255, 1) 50%, #0095FC 100%);
  }
}


.layoutRow {
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
  border-radius: 10px;
  flex-direction: row;
  min-height: fit-content;
  box-shadow: 0px 8px 8px 5px #0000000D;

  background: linear-gradient(329deg, #F6B430 0%, rgba(255, 255, 255, 1) 50%, #0095FC 100%);
  border: 1px solid #ffe894;

  /* background: rgb(246, 180, 48);
  background: linear-gradient(280deg, #f6b430 27%, #0097ff 73%); */

  & .layoutLeft {
    flex: 0 0 50%;
    max-width: 50%;
    min-width: 50%;
    min-height: 440px;
    /* background-color: #fff; */

    & .leftContainerLayout {
      flex: 1;
      display: flex;
      max-width: 100%;
      position: relative;
      flex-direction: column;
      padding: 0 20px 30px 50px;
      justify-content: flex-end;

      & img {
        width: 100%;
        height: 460px;
        margin-bottom: 0;
        margin-top: -35px;
        object-fit: cover;
        border-radius: 10px;
        box-shadow: 5px -6px 20px 0 rgba(0, 0, 0, 0.25);
      }
    }
  }

  & .layoutRight {
    flex: 0 0 50%;
    max-width: 50%;
    min-width: 50%;
    min-height: 440px;
    /* background-color: #fff; */

    & .rightContainerLayout {
      flex: 1;
      height: 100%;
      display: flex;
      max-width: 100%;
      position: relative;
      padding: 30px 50px;
      flex-direction: column;
      justify-content: center;

      & .imageBorder {
        width: 125px;
        height: 125px;
        margin: 0 0 10px 0;
        background-image: none;
        border: 10px solid #000000;

        &>div {
          margin: -20px 0 0 -20px;

          &>div {
            &>div {
              border-radius: 0;

              & img {
                border: none;
              }
            }
          }
        }
      }
    }
  }
}
.maxBriefLimitModal{
  @apply --marketplaceModalBaseStyles;
	/* flex-basis: 756px; */
	border-bottom: none;
	min-height: auto;
}

.firmLogo{
  /* width: 50px; */
  height: 50px;
  position: absolute;
  right: 10px;
  top: 0px;
  object-fit: fill;
  border: 2px solid white;
  background-color: #FFF;
  /* border-radius: 50%; */
}