@import '../../styles/propertySets.css';

.root {
  background: var(--matterColorLight);
  border: 1px solid var(--matterColorNegative);
  border-radius: 8px;
  padding: 20px 20px 10px;
  color: var(--matterColor);
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowListingCard);
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  border-radius: 10px 10px 0 0;
  padding-bottom: 66.6667%;
  /* 3:2 Aspect Ratio */
  background: var(--matterColorNegative);
  /* Loading BG color */
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: 10px 10px 0 0;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: row;
  padding-bottom: 2px;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-right: 18px;
}

.priceValue {
  /* Font */
  @apply --marketplaceH3FontStyles;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  width: 100%;
  flex-direction: column;
}

@keyframes trendingAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.trendingBadge {
  color: #f7b513;
  font-size: large;
  font-weight: 900;
  text-align: center;
  animation-name: trendingAnimation;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
}


.title {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);
  line-height: 20px;
  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  padding-right: 4em;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media(max-width: 600px) {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.112px;
    padding-right: 0;
  }
}

.experience {
  padding-top: 9px;
  font-weight: var(--fontWeightRegular);
  color: var(--matterColorDark);
  font-size: 12px;
  line-height: 18px;
}

.certificateInfo {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.styleElement {
  display: flex;
  line-height: 18px;
  align-items: center;
  padding: 0 10px;
  height: 20px;
  border-radius: 12px;
  margin-right: 9px;
  margin-bottom: 0;
}

.checkBoxBlock {
  word-wrap: break-word;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  margin: 1px 0;
  gap: 10px;
  line-height: 18px;
}

.industryTag {
  & li {
    &:nth-child(odd) {
      background-color: rgba(246, 180, 48, 0.2);

      & label {
        color: rgba(228, 152, 0, 1);
      }
    }
  }
}

.styleTitleCategory {
  width: max-content;
  /* height: 20px; */
  font-size: 12px;
  line-height: 18px;
  padding: 0;
  font-weight: var(--fontWeightRegular);
}

.borderStyleDomainExpertise {
  background-color: rgba(246, 180, 48, 0.2);
}

.borderStyleIndustrySector {
  background-color: rgba(11, 150, 245, 0.2);
}

.colorStyleDomainExpertise {
  color: rgba(228, 152, 0, 1);
}

.colorStyleIndustrySector {
  color: rgba(0, 129, 217, 1);
}

.priceTitle {
  @apply --marketplaceH5FontStyles;
  margin-bottom: 0;
  margin-top: 0;
  margin-right: 5px;

  @media(max-width: 600px) {
    min-width: 155px;
    display: block;
  }
}

.textListingCard {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightRegular);
  margin: 10px 0 10px;

  @media(max-width: 600px) {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.089px;
  }
}

.priceTitleCount {
  color: var(--dark-grey, #3D3D3D);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.089px;
}

.footerBoxCard {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #E6E6E6;
  padding-top: 10px;
  margin-top: 20px;

  @media (--viewportLarge) {
    align-items: center;
    flex-direction: row;
    gap: 45px;
  }
}

.jobListingFooterBox {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #E6E6E6;
  padding-top: 10px;
  margin-top: 20px;

  @media (--viewportLarge) {
    align-items: center;
    justify-content: space-between;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.categoryBoxCard {
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (--viewportSmall) {
    flex-direction: column;
  }
}

.priceBox {
  display: flex;
  align-items: center;
  /* width: 180px;
  margin-right: 39px; */
}

.showMoreDescription {
  margin-left: 5px;
  color: var(--successColorDark);
}

.priceMargin {
  margin: 0 5px;
}