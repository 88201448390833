.root {
  width: 100%;
  padding-left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  overflow: hidden;
  margin-right: 20px;
  text-align: center;

  & + a {
    flex: 1;
    text-align: right;
    & button{
      padding: 7px 30px 7px;
      border-radius: 5px;
      background-color: #5e6daf;
      color: #fff;
      cursor: pointer;
      border: none;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      min-height: 40px;
    }
  }
  &::after {
    /* content: '.'; */
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }
}

.container {
  width: 100%;
  margin: 0 12px 12px 0;
  border: 1px solid #dedede;
  transition: box-shadow 0.25s ease-in-out;
  height: 100%;
  overflow: hidden;
  min-height: 205px;
  @media (--viewportMedium) {
    width: 100%;
  }
}

.container:hover {
    box-shadow: 0px 5px 5px rgba(90, 90, 90, 0.15), 
  0px -1px 5px rgba(90, 90, 90, 0.15);
  }

.title {
  margin: 0 !important;
  padding: 5px 5px;
  font-size: 18px;
  min-height: 41px;

  & + div {
    padding-bottom: 10px;
  }
}

.thumbnail {
  display: block;
  float: left;
  position: relative;
  width: 100%;
  margin: 0;
  overflow: hidden;
  background-color: var(--matterColorNegative); /* Loading BG color */
}


.threeToTwoWrapper {
  position: relative;
}

.removeFileLink {
  position: absolute;
  /* top: 0;
  right: 0; */
  width: 33px;
  height: 33px;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 0;
  border-bottom-left-radius: 2px;
  cursor: pointer;

  & svg {
    position: absolute;
    /* top: 12px;
    left: 12px; */
    width: 10px;
    height: 10px;
    /* fill: var(--matterColorAnti);
    stroke: var(--matterColorAnti); */
  }

  /* &:hover svg {
    fill: var(--matterColorLight);
    stroke: var(--matterColorLight);
  } */
}

.aspectWrapper {
  padding-bottom: calc(100% * (2 / 3));
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--borderRadius);
}

.thumbnailLoading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(211, 211, 211, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlayContainer {
  display: inline-flex;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;

  position: absolute;
  top: 0;
  left: 0;

}


.certificateImage {
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  height: 195px;
  & > img {
    max-width: 100%;
    min-height: 192px;
  }
}

.certificateImage:hover .overlayContainer{
  
  opacity: 1;
  background: rgba(0, 0, 0, 0.5);
  
}
