.mainInfo {
    margin-bottom: 24px;
    flex-basis: 100%;
    background: #fcfcfc;
    box-shadow: 0px 0px 9px -4px rgb(0 0 0 / 26%);
    padding: 20px;
    border-radius: 5px;
    border: 1px solid #43434324;

    & .titleWrapper{
        opacity: 0.5;
    }

   @media (--viewportMedium) {
        flex-basis: calc(50% - 12px);
        margin-right: 24px;
   }
}
.root {
    /* Layout */
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: space-between;
  
    &:hover {
      text-decoration: none;
    }
}
.title {
    color: var(--matterColor);
    text-align: left;
  
    padding-top: 3px;
    padding-bottom: 3px;
    margin-top: 0;
    margin-bottom: 0;

    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  
    & span{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
    @media (--viewportMedium) {
      padding: 4px 0;
  
      margin-top: 0;
      margin-bottom: 0;
    }
    &:hover{
        text-decoration: underline;
    }
}
.description{
    margin: 5px 0 13px;
    font-weight: var(--fontWeightRegular);
    color: var(--matterColorDark);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.089px;
}
.buttonsWrapper{
    border-top: 1px solid #e6e6e6;
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
}
.openListingButton,
.finishListingDraftLink {
    padding: 0;
    display: block;
    width: 117px;
    height: 31px;
    background: #0B96F5;
    color: white;
    border-radius: 3px;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.267px;
    text-align: center;
}
.tags{
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 14px;

    & .tag{
        font-size: 12px;
        font-weight: 500;
        line-height: 18px; /* 150% */
        letter-spacing: -0.1px;
        color: #E49800;
        background-color: rgba(246, 180, 48, 0.2);
        padding: 2px 10px;
        border-radius: 17px;

        &:nth-child(even){
            color: rgba(0, 129, 217, 1);
            background-color: rgba(11, 150, 245, 0.2);
        }
    }
}