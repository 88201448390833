.fieldRoot {}

.inputRoot {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  @media(max-width: 460px) {
    flex-wrap: wrap;
    gap: 20px;
  }
}

.selectWrapper {
  flex-basis: calc(33% - 12px);

  @media(max-width: 460px) {
    flex-basis: calc(50% - 12px);
  }
}

.select {
  display: inline-block;

  /* Border */
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: var(--textFieldColor);

  &:hover,
  &:focus {
    border-bottom-color: #000000;
    outline: none;
  }
}

.notSet {
  color: var(--matterColorAnti);
}

.selectSuccess {
  color: var(--matterColor);
  border-bottom-color: var(--textFieldColor);
}

.selectError {
  border-bottom-color: var(--failColor);
}