@import '../../styles/propertySets.css';

.root {
  @apply --marketplaceModalFormRootStyles;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: calc(34% - 9px);
}

.lastNameRoot {
  width: calc(66% - 9px);
}

.password {
  @apply --marketplaceModalPasswordMargins;
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;
}

.bottomWrapperText {
  @apply --marketplaceModalBottomWrapperText;
}

.termsText {
  @apply --marketplaceModalHelperText;
}

.termsLink {
  @apply --marketplaceModalHelperLink;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.roleRadioBox {
  display: flex;
  justify-content: space-between;

  @media (--viewportSmall) {
    padding-right: 50px;
    margin-bottom: 8px;
  }
}

.contactPreference {
  & h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #3D3D3D;
    margin-top: 32px;
    margin-bottom: 19px;
  }

  & .contactCheck {
    display: flex;
    gap: 50px;

    & label {
      & span {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: -0.0888889px;
        color: #3D3D3D;
      }
    }
  }
}

.hearAboutUs {
  & h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #3D3D3D;
    margin-top: 32px;
    margin-bottom: 14px;
  }

  & .radioOptions {
    & label {
      display: flex;
      align-items: center;

      & input {
        width: 15px;
        height: 15px;
      }

      &>div {
        margin-left: 16px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: -0.0888889px;
        color: #3D3D3D;
      }
    }
  }
}

.loginTopbar {
  position: relative;
  margin-bottom: 40px;
}

.backArrow {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.loginTitle {
  text-align: center;
  color: #3D3D3D;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.roleWrapper {}

.roleRadioBox {
  display: flex;
  flex-direction: column;

  & span {
    & label {
      padding: 0 0 15px 0;

      & span {
        &:nth-child(1) {
          height: 27px;
        }
      }
    }
  }
}

.googleText {
  padding: 30px 0;
  color: #3D3D3D;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  position: relative;
  margin-bottom: 30px;

  &::after {
    right: 0;
    bottom: 0;
    left: 50%;
    width: 118%;
    height: 1px;
    content: "";
    position: absolute;
    background-color: #E6E6E6;
    transform: translate(-50%, -50%);
  }
}

.roleLabel {
  color: #3D3D3D;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 14px;
}
.hearAboutUs{
  & select{
    color: black;
  }
  margin-top: 24px;

}

.tooltip {
  display: flex;
  align-items: center;
  
  & .tooltipText{
    position: relative;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.09px;
    cursor: pointer;

    &:hover {
      &::before {
        visibility: visible;
      }
    }
  
    &::before {
      visibility: hidden;
      content: attr(data-tooltip);
      background-color: white;
      color: #000;
      border-radius: 6px;
      padding: 10px;
      position: absolute;
      z-index: 1;
      width: 200px;
      top: -53px;
      left: 100%;
      box-shadow: 0px 8px 16px rgb(0 0 0 / 30%);
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.25px;
    }
  }
}