.title{
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -0.9px;
  margin-bottom: 30px;
}
.buttonWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-top: 8em;

    & .buttonCancel{
      font-size: 20px;
      font-weight: 400;
    }

    & .buttonSubmit{
      width: 228px;
    }
  }