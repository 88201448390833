@import '../../styles/propertySets.css';
.modalContainer{
    max-width: 100vw;
    @apply --marketplaceModalBaseStyles;
    min-height: 100vh;
    height: 100%;
    border: none;

    @media (--viewportMedium) {
        flex-basis: 680px;
        min-height: auto;
        height: auto;
        padding: 60px;
    }
}
.title {
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    /* identical to box height, or 133% */
  
    letter-spacing: -0.9px;
    color: var(--matterColor);
    margin-bottom: 42px;
    width: 100%;
}
.description{
    @media (max-width: 768px) {
        & label{
            margin-bottom: 10px;
        }
    }
    & textarea{
        min-width: 100%;
        max-width: 100%;
    }
}
.inputSection{
    & input {
      display: none;
    }
  } 
  .inputLabel {
    width: 100%;
    box-sizing: border-box;
    border: 2px dashed gainsboro;
    border-radius: 2px;
    padding: 25px 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
    margin-bottom: 12px;
    align-items: center;
    gap: 10px;
  
    & .dragDrop {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.177778px;
        color: #3D3D3D;
    }
  
    & .docType {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.177778px;
        color: #B2B2B2;
    }
  }
.removeFile {
    cursor: pointer;
    color: red;
    font-weight: 400;
}
.attachmentLabel {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin: 30px 0 10px;
}
.buttonWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: 65px;
    margin-top: 50px;
}
.submitButton {
    max-width: 228px;
    @media (max-width: 768px) {
        max-width: 160px;
    }
}
.cancelButton {
    font-size: 20px;
    font-weight: 400;
    cursor: pointer;
  
    @media (max-width: 768px) {
      max-width: 228px;
    }
}
.createResumeLink{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    & span{
        font-weight: 600;
        color: #0B96F5;
        cursor: pointer;
    }
}
.selectLabel{
    margin-top: 30px;
}
.inputsWrapper {
    display: flex;
    align-items: flex-end;
    column-gap: 10px;

    @media (--viewportMedium) {
      padding-bottom: 5px;
    }
    & select{
        width: 83px;
        text-align: center;
    }
    & input{
        border-color: var(--textFieldColor);

        &::placeholder{
            text-align: left;
        }
    }
}
.disable{
    opacity: 0.5;
    pointer-events: none;
}
.errorMessage{
    color: red;
    font-weight: 100;
    font-size: 14px;
    text-align: center;
    margin: 0;
}