@import '../../styles/propertySets.css';

/* Booking form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  @apply --marketplaceModalInMobileBaseStyles;

  height: 100vh;

  @media (--viewportMedium) {
    flex-basis: 576px;
    height: 100%;
    padding: var(--modalPaddingMedium);
    background-color: var(--matterColorLight);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
  }

  @media (--viewportLarge) {
    padding: 0;
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 0;
  }

  @media(max-width: 1024px) {
    padding: 0;
  }
}

.modalHeading {
  margin-top: 89px;
  margin-bottom: 36px;
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.title {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.author {
  width: 100%;
  @apply --marketplaceH4FontStyles;
  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--matterColor);
  }
}



.bookingHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
    /* margin-bottom: 30px; */
  }
}

.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 6px;
  }
}

.desktopPerUnit {
  /* Font */
  @apply --marketplaceH5FontStyles;
  color: var(--matterColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
    margin-left: 6px;
  }
}

.bookingTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 2px;
}

.bookingHelp {
  display: none;
  @apply --marketplaceH5FontStyles;

  @media (--viewportMedium) {
    color: var(--matterColor);
    display: block;
    margin-top: 0;
  }
}

.bookingForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  /* Mobile phones introduce bottom-bar, which takes 102px vertical space.
     In addition to that we have fixed button that takes 84px vertical space.
  */
  margin: 0 0 186px 0;

  @media (--viewportMedium) {
    margin: 0;
    min-height: 400px;
  }

  @media (--viewportLarge) {
    min-width: 312px;
    min-height: auto;
  }
}

.submitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
    position: static;
    bottom: unset;
    background-color: transparent;
  }
}

.openBookingForm {
  /* Ensure that mobile button is over Footer too */
  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  justify-content: center;
  padding: 18px 24px 18px 16px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
  display: flex;
  justify-content: space-between;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);
  gap: 20px;

  @media (--viewportMedium) {
    padding: 18px 24px;
  }

  @media (--viewportLarge) {
    display: none;
  }

  @media(max-width: 560px) {
    flex-wrap: wrap;
  }

  @media(max-width: 768px) {
    padding: 20px;
    gap: 10px;
  }
}

.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  margin-right: 22px;
  padding: 5px 12px;
}

.priceValue {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.desktopDocuments {
  @media(max-width: 920px) {
    display: none;
  }
}

.toolTip {
  position: relative;
  width: 336px;
  height: 64px;

  &::after {
    visibility: hidden;
    content: attr(data-tooltip);
    background-color: rgba(246, 180, 48, 1);
    color: #000;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    line-height: 24px;
    font-size: 14px;
    top: -7em;
    width: 123%;
    left: -3em;
    font-weight: 500;
  }
}

.toolTip:hover {
  &::after {
    visibility: visible;
  }
}

.addPreferredInsightPartner {
  margin-top: 20px;

  & button {
    width: 336px;
    height: 64px;
  }
}

.closedListingButton {
  width: 100%;
  text-align: center;
  padding: 15px 24px 15px 24px;
  border-left: 1px solid var(--matterColorNegative);
}

.desktopWorkRating {
  display: block;

  @media(max-width: 920px) {
    display: none;
  }
}

.reviewStar {
  width: 12px;
  height: 12px;
  margin-right: 2px;
}

.successMessage {
  margin-bottom: 30px;
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  /* width: 336px; */
  text-align: center;
}

.googleMeetingModal {
  /* background-color: red; */
}

.meetingError {
  color: red;
  line-height: 22px;
  font-size: 16px;
}

.meetingHeading {
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -0.9px;
  color: #3D3D3D;
  margin-top: 20px;

  @media(max-width: 768px) {
    font-size: 22px;
    line-height: normal;
    margin-top: 50px;
  }
}

.meetingButtonsBox {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 46px;
  align-items: center;
  justify-content: center;
}

.meetingButtonsBox .meetLnk {
  display: flex;
  padding: 34px 29px;
  grid-column-gap: 10px;
  column-gap: 10px;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #E6E6E6;
  box-shadow: 0px 2px 2px rgb(0 0 0 / 5%);
  justify-content: center;
  height: 120px;
  width: 276px;
  position: relative;
  cursor: pointer;
}

.meetingButtonsBox .meetLnk .hoverBox {
  visibility: hidden;
  padding: 9px 11px;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 0;
  background: #FFFFFF;
  box-shadow: 0px 8px 16px rgb(0 0 0 / 30%);
  border-radius: 4px;
  width: 309px;
  height: auto;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: -0.177778px;
  color: #3D3D3D;
}

.meetingButtonsBox .meetLnk:hover .hoverBox {
  visibility: visible;
}

.buttonSection {
  margin-top: 73px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;

  @media(max-width: 460px) {
    flex-wrap: wrap;
  }

  & .buttonCancel {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.111111px;
    color: #3D3D3D;
    cursor: pointer;

    @media(max-width: 460px) {
      width: 100%;
      text-align: center;
    }
  }

  & .buttonSubmit {
    width: 228px;

    @media(max-width: 460px) {
      width: 100%;
    }
  }
}

.meetingBoxBorder {
  border: 3px solid #0B96F5 !important;
}

.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  border: none;
}

.csmShareBriefOpen {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}

.modalContent {
  background-color: #fff;
  margin: 15% auto;
  padding: 20px;
  width: 80%;
  max-width: 600px;
  position: relative;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.mobileButtonWrapper {
  display: flex;
  width: 100%;
  column-gap: 10px;
}

.desktopWorkRating {
  padding: 30px 20px;
  border-radius: 10px;
  margin-bottom: 30px;
  background-color: #fff;
  box-shadow: 0px 8px 8px 5px #0000000D;

  @media(max-width: 920px) {
    box-shadow: none;
    border-radius: 0;
    margin-bottom: 20px;
  }

  & .ratingHeading {
    margin-top: 0;
    font-size: 22px;
    color: #3d3d3d;
    font-weight: 500;
    line-height: 33px;
    margin-bottom: 15px;
    letter-spacing: -0.9375px;
  }
}

.showAdditionalInfo{
  flex-wrap: wrap;

  @media(max-width: 768px){
    width: 100%;
  }
}

.SoftwareCard {
  padding: 30px 5px;
  border-radius: 10px;
  margin-bottom: 30px;
  background-color: #fff;
  box-shadow: 0px 8px 8px 5px #0000000D;

  @media(max-width: 920px) {
    box-shadow: none;
    border-radius: 0;
    margin-bottom: 20px;
  }

  & .ratingHeading {
    margin-top: 0;
    padding: 0 15px;
    font-size: 22px;
    color: #3d3d3d;
    font-weight: 500;
    line-height: 33px;
    margin-bottom: 15px;
    letter-spacing: -0.9375px;
  }

  & .softwareDetail {
    & .softwareLine {
      font-size: 16px;
      padding: 0 15px;
      font-weight: 400;
      line-height: 24px;
      padding-bottom: 20px;
      border-bottom: 1px solid #E6E6E6;
      letter-spacing: -0.08888888359069824px;
      display: flex;
      gap: 4px 10px;
      flex-wrap: wrap;

      @media(max-width: 920px) {
        width:100%;
      }

      & span{
        display: block;
        min-width: max-content;
      }
    }

    & .showAll {
      font-size: 14px;
      font-weight: 400;
      padding: 0 15px;
      line-height: 24px;
      padding-top: 15px;
      color: #858585;
      letter-spacing: -0.08888888359069824px;
      cursor: pointer;
    }
  }
}

.languageCard {
  padding: 30px 20px;
  border-radius: 10px;
  margin-bottom: 30px;
  background-color: #fff;
  box-shadow: 0px 8px 8px 5px #0000000D;

  @media(max-width: 920px) {
    box-shadow: none;
    border-radius: 0;
    margin-bottom: 20px;
  }

  & .languageHeading {
    margin-top: 0;
    font-size: 22px;
    color: #3d3d3d;
    font-weight: 500;
    line-height: 33px;
    margin-bottom: 15px;
    letter-spacing: -0.9375px;
  }

  & .languageDetail {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.08888888359069824px;
  }
}

.shadowBox {
  padding: 30px 20px;
  border-radius: 10px;
  margin-bottom: 30px;
  background-color: #fff;
  box-shadow: 0px 8px 8px 5px #0000000D;

  @media(max-width: 920px) {
    box-shadow: none;
    border-radius: 0;
    margin-bottom: 20px;
  }
}

.personalCard {
  & h2 {
    margin-top: 0;
    font-size: 22px;
    font-weight: 500;
    line-height: 33px;
    margin-bottom: 15px;
    letter-spacing: -0.9375px;
  }

  & .personalDetail {
    & .linkName {
      font-size: 16px;
      font-weight: 400;
      color: #0B96F5;
      line-height: 24px;
      letter-spacing: -0.08888888359069824px;
    }

    & .dot {
      margin: 0 10px;
      font-size: 16px;
      color: #B2B2B2;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.08888888359069824px;
    }
  }
}