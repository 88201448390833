@import '../../styles/propertySets.css';

.root {
  margin: 0;
  overflow-y: auto;
  max-height: 565px;
  /* Clearfix */
  @apply --clearfix;

  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #b2b2b2;
    border-radius: 5px;
  }
}

.profileIcon {
  position: relative;
}

.messageItem {
  /* padding: 0 20px 0 0; */
  margin-bottom: 20px;

  @media(max-width: 460px) {
    /* padding: 0 5px 0 0; */
  }

  /* Clearfix */
  @apply --clearfix;

  @media (--viewportMedium) {
    margin-bottom: 17px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.message,
.ownMessage {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  max-width: 90%;
  /* background-color: red; */
}

.message {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  /* float: left; */
  align-items: flex-start !important;

  & .avatar {
    margin-right: 10px;
  }
  & .bottomWrapper{
    flex-direction: row-reverse;
  }
}

.ownMessage {
  float: right;
  display: flex;
  flex-direction: row-reverse;

  & .avatar {
    margin-left: 10px;
  }
}

.avatar {
  flex-shrink: 0;
  /* margin-left: 12px; */

  @media (--viewportMedium) {
    /* margin-l; */
  }
}

.ownMessageContentWrapper {
  @apply --clearfix;
  display: flex;
  flex-direction: column;
}

.messageContent,
.ownMessageContent {
  @apply --marketplaceMessageFontStyles;

  display: inline-block;
  margin: 0;
  word-break: break-word;
  padding: 8.5px 14px 8.5px 14px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  @media (--viewportMedium) {
    padding: 8.5px 14px 8.5px 14px;
    margin: 0;
  }
}

.messageContent {
  flex: 1;
}

.ownMessageContent {
  background-color: rgba(46, 204, 113, 0.1);

  float: right;
}

.messageDate,
.ownMessageDate {
  @apply --marketplaceMessageDateFontStyles;
  line-height: 18px;
  font-weight: var(--fontWeightRegular);
  margin: 10px 0 0 0;
  color: var(--matterColorAnti);

  @media (--viewportMedium) {
    margin: 10px 0 0 0;
  }
}

.ownMessageDate {
  text-align: right;
}

.transitionItem {
  margin-bottom: 18px;

  /* Clearfix */
  @apply --clearfix;

  @media (--viewportMedium) {
    margin-bottom: 16px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.transition {
  display: flex;
  flex-direction: row;
  padding: 3px 0 2px 0;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.transitionContent {
  @apply --marketplaceTxTransitionFontStyles;
  margin: 0;
  font-weight: var(--fontWeightRegular);
  line-height: 32px;

  @media(max-width: 560px) {
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
  }
}

.transitionDate {
  @apply --marketplaceMessageDateFontStyles;
  color: var(--matterColorAnti);
  line-height: 18px;
  font-weight: var(--fontWeightRegular);
  margin: 10px 0 0 0;

  @media(max-width: 560px) {
    font-size: 10px;
    font-weight: 400;
    line-height: 18px;
    margin-top: 4px;
  }
}

.bullet {
  margin-right: 6px;
}

.reviewContent {
  @apply --marketplaceH4FontStyles;
  font-style: italic;
  white-space: pre-line;
  margin: 8px 0 0 0;

  @media (--viewportMedium) {
    max-width: 500px;
    margin: 7px 0 0 0;
  }
}

.reviewStars {
  display: block;
  margin-top: 4px;

  @media (--viewportMedium) {
    margin: 5px 0;
  }
}

.reviewStar {
  width: 12px;
  height: 12px;
  margin-right: 2px;
  font-styles: initial;
}

.showOlderWrapper {
  text-align: center;
  margin-bottom: 1px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.imageWrapper {
  display: flex;
  justify-content: right;
  position: relative;

  & .imageDownload {
    width: 35px;
    height: 35px;
    position: absolute;
    background: #000000b0;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 10px;
    right: 10px;
    cursor: pointer;

    &:hover {
      & svg {
        & path {
          fill: #fff;
        }
      }
    }

    & svg {
      width: 20px;
      height: 20px;

      & path {
        fill: #d8d1d1;
      }
    }
  }

  & img {
    width: 300px;
    height: 200px;
    border-radius: 6px;
    object-fit: cover;

    @media (max-width: 768px) {
      width: 234px;
      height: 150px;
    }
  }

  &:hover {
    /* filter: contrast(0.5); */
  }
}

.videoWrapper{
  & video {
    width: 300px;
    height: 200px;
    border-radius: 6px;
    object-fit: cover;

    @media (max-width: 768px) {
      width: 234px;
      height: 150px;
    }
  }
}


.attachmentUpload {
  position: relative;
  /* height: 55px; */
  padding: 10px;
  border-radius: 5px;
  background-color: #fdfffd;
  cursor: pointer;
  width: 100%;
  box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
  border: 1px solid #ebebeb;

  & a {
    color: #3d3d3d;

    &:hover {
      text-decoration: none;
    }
  }

  & .pdfUpload {
    word-break: break-all;
    /* position: absolute; */
    /* bottom: 0; */
    display: flex;
    padding: 5px 10px;
    border: 1px solid #dedede;
    background: #dedede5c;
    align-items: center;
    border-radius: 5px 5px 10px 10px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    & span {
      & img {
        width: 22px;
        height: 25px;
      }
    }

    & .urlName {
      padding: 0 10px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 400;
      font-size: 14px;
      width: 80%;
    }
  }

  & .downloadButton {
    height: 30px;
    right: 20px;
    top: 15px;
    /* z-index: 999; */
    position: absolute;

    & svg {
      color: #fff;
      & path {
        fill: #302e2e;
      }
    }

    &:hover {
      & svg {
        & path {
          fill: #000;
        }
      }
    }
  }
}

.uploadWraper {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  width: 75%;

  & .ownMessage {
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    max-width: 100%;
  }

  & .message {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start !important;
    max-width: 100%;
    min-width: 100%;

    & .avatar {
      margin-right: 10px;
    }
  }

  .bottomWrapper{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 0 10px;

    & span{
      font-weight: 600;
      cursor: pointer;
      font-size: 11px;
      color: #0B96F5;
      transform: translateY(3px);
      padding: 0 10px;
    }
  }
}

.onlineDot {
  height: 10px;
  width: 10px;
  background-color: #2ECC71;
  border-radius: 50%;
  position: absolute;
  right: 10px;
  top: 27px;
  border: 1px solid white;
}

.offlineDot {
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 50%;
  position: absolute;
  right: 10px;
  top: 27px;
  border: 1px solid white;
}

.folderContainer{
  & h2{
    margin-bottom: 0;
  }

  & p{
    font-weight: 100;
    margin: 0 0 20px;
  }

  & .folderItem{
    display: flex;
    gap: 15px;
    font-weight: 400;
    cursor: pointer;
    border-bottom: 1px solid gainsboro;
    margin-bottom: 8px;
    position: relative;

    &:hover{
      background-color: rgba(0, 0, 0, 0.1);
    }

    & p{
      margin: 0;      
      position: absolute;
      right: 0;
      font-size: 13px;
    }
    & .success{
      color: green;
    }
    & .error{
      color: red;
    }
  }
}