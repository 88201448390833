@import '../../styles/propertySets.css';

.modalWrapperFirstRow {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 13px;
  justify-content: space-between;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

input,
select {
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  color: #3D3D3D;
}

textarea {
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  color: #3D3D3D;
}

.wrapperModalAddMilestone {
  & .titleInputBox {
    margin-bottom: 18px;

    & input {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 32px;
      letter-spacing: -0.0888889px;
      color: #3D3D3D;
      /* padding-bottom: 9px; */

      &::placeholder {
        font-weight: 400;
        font-size: 14px;
        line-height: 32px;
      }
    }
  }

  & textarea {
    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      line-height: 32px;
    }
  }

  & .blankDescription {
    margin-bottom: 28px;

    & textarea {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.0888889px;
      color: #3D3D3D;
      max-width: 100%;
      border-bottom: 3px solid var(--attentionColor);
      min-width: 100% !important;
      width: 100%;
    }
  }

  & .fillDescription {
    margin-bottom: 28px;

    & textarea {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 32px;
      letter-spacing: -0.0888889px;
      color: #3D3D3D;
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      min-height: 36px;
      /* border-bottom: 3px solid #2ECC71; */

      @media(max-width: 768px) {
        padding: 5px 0 5px;
      }
    }
  }

  & .dateBox {
    display: flex;
    margin-bottom: 34px;
    gap: 18px;
    cursor: pointer;

    & label {
      min-width: 130px;
    }

    &>div {
      width: 100%;
      position: relative;
    }
  }

  & .dropDownBox {
    display: flex;
    margin-bottom: 34px;
    gap: 18px;
    cursor: pointer;
    align-items: center;

    @media(max-width: 500px) {
      flex-wrap: wrap;
      gap: 5px;
    }

    & label {
      min-width: 105px;
    }

    &>div {
      width: 100%;
      position: relative;

      &>div {
        border-bottom: 3px solid var(--successColor);
      }
    }
  }

  & .blankDropdown {
    display: flex;
    margin-bottom: 34px;
    gap: 18px;
    cursor: pointer;

    /* background-color: red; */
    @media(max-width: 500px) {
      flex-wrap: wrap;
      gap: 5px;
    }

    & label {
      min-width: 105px;
    }

    &>div {
      width: 100%;
      position: relative;
    }

    & .fullNameOwner {
      color: #B2B2B2;

      @media(max-width: 500px) {
        margin-left: 0;
      }
    }
  }

  & .dateBox {
    display: flex;
    gap: 18px;
    align-items: center;

    @media(max-width: 500px) {
      flex-wrap: wrap;
      gap: 5px;
    }

    & label {
      min-width: 105px;
    }

    & select {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;

      & option {
        white-space: nowrap;
      }
    }

    &>div {
      width: 100%;
      position: relative;
    }
  }

  & .fillDate {
    display: flex;
    gap: 18px;
    align-items: center;

    @media(max-width: 500px) {
      flex-wrap: wrap;
      gap: 5px;
    }

    & label {
      min-width: 105px;
    }

    & select {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      border-bottom: 3px solid var(--successColor);

      & option {
        white-space: nowrap;
      }
    }

    &>div {
      width: 100%;
      position: relative;
    }
  }

  & .dependencyNote {
    font-style: italic;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.0888889px;
    color: #858585;
    margin-left: 19%;

    @media(max-width: 500px) {
      margin-left: 0;
      padding: 0;
    }
  }

}

.addMilestoneButton {
  margin-top: 50px;
}

.customDropDownInput {
  border-bottom: 3px solid var(--textFieldColor);
  /* border-bottom: 3px solid #2ECC71; */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3D3D3D;
  position: relative;
  padding-bottom: 6px;
  display: flex;
  align-items: center;
  height: 40px;

  & img {
    height: 32px;
    width: 32px;
    max-width: 32px;
    max-height: 32px;
    min-width: 32px;
  }

  & .noImageWithText {
    height: 32px;
    width: 32px;
    max-width: 32px;
    max-height: 32px;
    min-width: 32px;
  }

  & .fullNameOwner {
    margin-left: 6px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #3D3D3D;
  }

  & .topBottomArrow {
    /* position: absolute;
    right: 0;
    top: 7px; */


  }
}

.upArrow {
  content: "";
  position: absolute;
  background-image: url(../../assets/Dropdown.png);
  right: 0;
  width: 14px;
  height: 10px;
  background-size: 14px 8px;
  background-position: center right;
}

.downArrow {
  content: "";
  position: absolute;
  background-image: url(../../assets/Dropdown1.png);
  right: 0;
  width: 14px;
  height: 10px;
  background-size: 14px 8px;
  background-position: center right;
}

.customDropDownOptions {
  background: #FFFFFF;
  border: 1px solid #E6E6E6;
  border-radius: 2px;
  margin-top: 0;
  position: absolute;
  width: 100%;
  z-index: 9;
  display: flex;
  flex-direction: column;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));

  & li {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 14px 20px;
    cursor: pointer;

    &:hover {
      background-color: #eee;
    }

    & .fullName {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #3D3D3D;
    }

    & .noImageWithText {
      max-width: 30px;
      height: 30px;
      min-height: 30px;
      max-height: 30px;
      min-width: 30px;
    }

    & img {
      max-width: 30px;
      height: 30px;
      min-height: 30px;
      max-height: 30px;
      min-width: 30px;
    }
  }
}

.profileImage {
  max-width: 38px;
  max-height: 38px;
  border-radius: 50%;
  overflow: hidden;
  min-width: 38px;
  border: 1px solid #d5d5d5;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.noImageWithText {
  width: 38px;
  height: 38px;
  max-width: 38px;
  max-height: 38px;
  min-width: 38px;
  border-radius: 50%;
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #7AC9FF 0%, #0B96F5 100%);
  color: var(--matterColorLight);
}

.errorTitle {
  font-size: 14px;
  color: var(--marketplaceColorLight);
}

.blankDate {
  display: flex;
  margin-bottom: 34px;
  grid-gap: 18px;
  gap: 18px;

  @media(max-width: 500px) {
    flex-wrap: wrap;
    gap: 5px;
  }

  & input {
    border-bottom: 3px solid var(--textFieldColor);
    width: 100%;
    padding-left: 0;

    &::placeholder {
      font-size: 14px;
    }
  }

  & label {
    min-width: 105px;
  }

  &>div {
    width: 100%;

    &>div {
      width: 100%;

      &>div {
        &>div {
          border: none;
          width: 100%;

          &>div {
            width: 100%;
          }
        }
      }
    }
  }
}

.fillDate {
  display: flex;
  margin-bottom: 34px;
  grid-gap: 18px;
  gap: 18px;

  & input {
    border-bottom: 3px solid #2ECC71;
    width: 100%;
    padding-left: 0;
  }

  & label {
    min-width: 130px;
  }

  &>div {
    &>div {
      width: 100%;

      &>div {
        &>div {
          border: none;
          width: 100%;

          &>div {
            width: 100%;
          }
        }
      }
    }
  }
}

.warningAlert {
  color: #ff7884;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 20px;
}

.pickerBox {}