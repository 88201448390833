.title{
    font-size: 30px;
    line-height: 40px;
    letter-spacing: -0.9px;
    margin-bottom: 30px;
}
.currencyLabel{
    display: flex;
    margin-bottom: 30px;
    column-gap: 15px;
}
.salaryInput{
    display: flex;
    & input{
        width: 60%;
        padding-left: 15px;
        border-bottom-color: var(--textFieldColor);
        align-self: end;
    }
}
.calenderWrapper{
    display: flex;
    margin-bottom: 30px;
    justify-content: space-between;
}
.attachmentLabel {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin: 30px 0 10px;
}
.inputSection{
    & input {
      display: none;
    }
}
.inputLabel {
    width: 100%;
    box-sizing: border-box;
    border: 2px dashed gainsboro;
    border-radius: 2px;
    padding: 25px 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
    align-items: center;
    gap: 10px;
  
    & .dragDrop {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.177778px;
        color: #3D3D3D;
    }
  
    & .docType {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.177778px;
        color: #B2B2B2;
    }
}
.removeFile {
  cursor: pointer;
  color: red;
  font-weight: 400;
}
.buttonWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-top: 5em;

    & .buttonCancel{
      font-size: 20px;
      font-weight: 400;
    }

    & .buttonSubmit{
      width: 228px;
    }
}
.monthCounter{
    & label{
        margin-bottom: 6px;
    }
    & p{
        margin: 0;
        display: inline;
        font-size: 17px;
        font-weight: 400;
    }
    & div{
        border-bottom: 3px solid var(--textFieldColor);
        display: flex;
        column-gap: 20px;
        align-items: center;
    }
    & span{
        width: 28px;
        height: 24px;
        border-radius: 3px;
        border: 1px solid #B2B2B2;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
    }
}
.endDateLabel{
    & label{
        margin-bottom: 6px; 
    }
    & span{
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.089px;
    }
}
.errorMessage{
    color: red;
    font-weight: 100;
    font-size: 14px;
    text-align: center;
    margin: 0;
}