/* * {
    font-family: 'Poppins';
    font-style: normal;
} */

.root {
    flex-grow: 1;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 30px 24px 0 24px;
}

.description {
    margin-bottom: 40px;
    font-weight: 400;
    font-size: 14px;
}

.title {
    letter-spacing: -1.25px;

    @media(max-width: 768px) {
        font-size: 24px;
        line-height: 36px;
        letter-spacing: -0.4px;
    }
}