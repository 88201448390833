@import '../../styles/propertySets.css';

.root {
  @apply --marketplaceModalFormRootStyles;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.password {
  @apply --marketplaceModalPasswordMargins;
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;

  & button {
    min-height: 61px;
  }
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  @apply --marketplaceModalBottomWrapperText;
}

.recoveryLink {
  @apply --marketplaceModalHelperLink;
}

.recoveryLinkInfo {
  @apply --marketplaceModalHelperText;
}

.passwordInput {
  position: relative;

  & .password {
    & input{
      border-bottom-color: #0095FC;
    }
  }

  & .showHide {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, 0%);
    color: #3D3D3D;
    cursor: pointer;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-decoration-line: underline;
  }
}

.backArrow {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.loginTitle {
  text-align: center;
  color: #3D3D3D;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.09px;
}

.loginTopbar {
  position: relative;
  margin-bottom: 20px;
  letter-spacing: 0.05px;
}

.subHeading{
  text-align: center;
  margin-bottom: 20px;
}

.brandName {
  color: #3D3D3D;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.9px;
  margin-top: 0;
  margin-bottom: 15px;
  text-align: center;
}

.emailInput{
  & input{
    border-bottom-color: #0095FC;
  }
}