.researchInstrumentForm {
    padding: 40px;

    @media(max-width: 768px) {
        padding: 24px;
    }
}

.editListContainer {
    padding: 60px;
    background-color: #FFF;
    @media(max-width: 768px) {
        padding: 24px;
    }
}

.container {
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px !important;
    width: 100%;
    margin: 0px auto;
    padding: 40px 100px;
    border: 1px solid #c2c2c2;
    border-radius: 5px;

    @media(min-width: 1200px) {
        width: 800px;
    }

    @media(max-width: 768px) {
        padding: 20px;
    }
}

.questionContainer{
    border: none;
    padding: 0;
    box-shadow: unset !important;
}

.fileSizeError {
    color: var(--failColor);
    margin: 0;
}

.attachment {
    & label {
        margin-bottom: 10px;
        margin-top: 2em;
    }

    & .close {
        cursor: pointer;
    }

    & .addImageInput {
        display: none;
    }

    & .attachmentLabel {
        width: 100%;
        box-sizing: border-box;
        border: 2px dashed gainsboro;
        border-radius: 2px;
        padding: 25px 20px;
        display: flex;
        flex-direction: column;
        text-align: center;
        cursor: pointer;
        margin: 0px 0 0;
        align-items: center;
        gap: 10px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.177778px;

        @media(max-width: 768px) {
            padding: 5px 20px;
            font-size: 12px;
        }

        & .dragDrop {
            color: #3D3D3D;
        }

        & .docType {
            color: #B2B2B2;
        }
    }
}

.fileViewContainer {
    margin-top: 20px
}

.alertModal{
    & h2{
		text-align: center;
		font-size: 30px;
        line-height: 40px;
        letter-spacing: -0.9px;
	}
    & p{
      /* text-align: center; */
      font-size: 20px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.9px;
    }
    .closeButton{
        margin-top: 3em;
    }
  }

.generator {
    margin-bottom: 40px;

    & .generatorTitle {
        font-size: 30px;
        font-weight: 600;
        line-height: 56px;
        letter-spacing: -1.25px;
    }

    @media(max-width: 768px) {
        margin-bottom: 3em;

        & .generatorTitle {
            font-size: 24px;
            line-height: 28px;
        }
    }
}

.actionButtons {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    margin-top: 3em;

    @media(max-width: 460px) {
        flex-wrap: wrap;
        width: 100%;
    }

    & button {
        min-height: 45px;
        max-height: 45px;
        width: 165px;
        font-size: 14px;

        @media(max-width: 460px) {
            width: 100%;
        }
    }

    & .submitButton {

        &:disabled,
        & [disabled] {
            background-color: #eeeded;
            color: #666666;
        }
    }
    & .disabledSubmitButton{
      background-color: #eeeded;
      color: #666666;
    }
}

.questionsHeading{
    display: grid;
    grid-template-columns: 1fr 1fr;
  
    & h3{
      font-weight: 600;
    }
}

.questionsWrapper{
  & .row{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 15px;
    margin-bottom: 18px;
    position: relative;
    
    & .left{
      display: flex;
      column-gap: 10px;
      align-items: center;

      & .count{
        font-size: 12px;
        font-weight: 600;
      }

      & .dragDots{
        cursor: grab;
      }
    }

    @media(max-width: 768px){
      display: block;
    }
    
    & .question, textarea{
      border: 1px solid gainsboro;
      border-radius: 5px;
      padding: 5px 12px;
      
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
    }
    & .question{
      opacity: 0.7;
      cursor: move;
      width: -webkit-fill-available;
      height: -webkit-fill-available;
    }
    & textarea{
      resize: none;
      min-width: 100%;
    }
  }
}

.loader{
    display: flex;
    justify-content: center;
}

.optionMenuContainer{
    position: absolute;
    right: -8%;
    top: 10px;
}

.optionMenu{
    width: max-content;
    position: absolute;
    padding: 10px 20px;
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.30);
    left: 0px !important;
    z-index: 10;
  
    & ul>li {
      font-size: 12px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.25px;
      margin-top: 7px;
      cursor: pointer;
    }
  
    &:first-child{
      background-color: green;
    }
  
    & .firstChild {
      font-weight: 600;
    }
  }

.alertModal{
  & h2 {
    font-size: 30px;
    line-height: 40px;
    letter-spacing: -0.9px;
    margin-bottom: 30px;
  }
  & p{
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.9px;
  }
  .closeButton{
    margin-top: 4em;
  }
}

.buttonWrapper{
    display: flex;
    gap: 10px;
    justify-content: space-between;
    margin-top: 3em;
  
    @media(max-width: 460px) {
      flex-wrap: wrap;
      width: 100%;
    }
    & .cancelButton{
      padding: 16px 0;
      font-size: 19px;
      cursor: pointer;
      font-weight: 400;
    }
    & .submitButton{
      width: 185px;
      font-size: 19px;
    }
  }