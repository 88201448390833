.form {
    display: flex;
    flex-direction: column;
    row-gap: 13px;

    & h3 {
        margin: 0px;
    }
}

.clientSignCheck {
    & h4 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #3D3D3D;
    }

    & .checkList {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin: 10px 0 25px;
        row-gap: 15px;

        & .checkBoxTag {
            display: flex;
            align-items: center;

            & input {
                width: 20px;
                height: 20px;
            }

            & label {
                margin-left: 12px;
            }
        }
    }
}

.suggestionBox {
    & h1 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 40px;
        letter-spacing: -0.9px;
        color: #3D3D3D;
        margin-bottom: 26px;
        margin-top: 0;
    }

    & h4 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #3D3D3D;
        margin-bottom: 5px;
        margin-top: 0;
    }

    & p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #3D3D3D;
        margin-bottom: 10px;
        margin-top: 0;

        & .boldText {
            font-weight: 500;
            margin: 0 5px;
        }
    }

    & ul {
        list-style-type: disc;
        margin-left: 25px;

        & li {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #3D3D3D;
            margin-bottom: 3px;

            & span {
                margin: 0 3px;
            }
        }
    }
}

.boldText {
    font-weight: 500;
    margin-right: 3px;
}

.unSureHeading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #3D3D3D;
    margin-bottom: 15px;

    & a {
        color: #0B96F5;
        margin-left: 3px;
    }
}

.dividerLine {
    background: #E6E6E6;
    height: 0.5px;
    width: 100%;
    margin: 16px 0;
}

.object {
    position: absolute;
    height: 500px;
    width: 1000px;
    left: -10em;
    z-index: 100;
}

.inputSection input {
    display: none;
}

.inputLabel {
    width: 100%;
    box-sizing: border-box;
    border: 2px dashed gainsboro;
    border-radius: 2px;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
    margin-bottom: 12px;
    align-items: center;
    gap: 10px;

    & .dragDrop {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.177778px;
        color: #3D3D3D;
    }

    & .docType {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.177778px;
        color: #B2B2B2;
    }
}

.files {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.pdfBox {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    align-items: flex-end;

    & .pdfImage {
        & img {
            width: 30px;
            height: 41px;
        }
    }
}

.pdfRight {
    /* margin-bottom: 10px; */

    & .pdfName {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.0875px;
        color: #3D3D3D;
    }

    & .pdfDate {
        font-weight: 400;
        font-size: 10px;
        line-height: 21px;
        letter-spacing: -0.0875px;
        color: #B2B2B2;

        & .fileSize {
            margin-left: 5px;
            position: relative;
            padding-left: 8px;

            &:before {
                content: "";
                position: absolute;
                left: 0;
                background-color: #B2B2B2;
                height: 3px;
                width: 3px;
                top: 5px;
                border-radius: 50%;
            }
        }
    }
}

.removeFile {
    color: red;
    font-weight: 400;
}

.buttonsContainer {
    display: flex;
    margin-top: 20px;
}

.cancelButton {
    width: 100%;
    text-align: left;
    font-weight: 400;
    font-size: 20px;
}
.checkbox label span svg{
    scale: 1.5;
}
.maxFileError{
    text-align: center;
    color: red;
    font-size: smaller;
    margin-bottom: 9px;
}