.root {
    flex-grow: 1;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 30px 24px 0 24px;
}

.panelDescription {
    text-align: justify;
}

.title {
    @media (max-width: 768px) {
        font-size: 24px;
        line-height: 36px;
        letter-spacing: -0.4px;
    }

    & .headingTooltip {
        position: relative;
        display: inline-block;
        transition: all 0.15s ease;
        cursor: pointer;

        & .infoLink {
            margin-left: 10px;

            & svg {
                transform: rotateZ(180deg);

                & path {
                    fill: #1d72ae;
                }
            }
        }

        & .infoTooltip {
            visibility: hidden;
            width: 400px;
            background-color: #0b96f5;
            color: #ffffff;
            text-align: center;
            border-radius: 6px;
            padding: 5px;
            position: absolute;
            z-index: 1;
            top: 126%;
            left: -100%;
            margin-left: -60px;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: normal;
            font-weight: normal;
        }

        &:hover {
            & .infoTooltip {
                visibility: visible;
            }
        }
    }
}