@import '../../styles/propertySets.css';

.root {
  @apply --marketplaceH4FontStyles;
  margin-top: 6px;
  margin-bottom: 12px;
  color: var(--failColor);
  overflow: hidden;
  line-height: normal;

  @media (--viewportMedium) {
    margin-top: 6px;
    margin-bottom: 10px;
  }
}

.vibrate {
  animation: vibrate 10s infinite;
}

@keyframes vibrate {
  0% { transform: translateX(0); }
  0.4% { transform: translateX(4px); }
  0.8% { transform: translateX(-4px); }
  1.2% { transform: translateX(3px); }
  1.6% { transform: translateX(-3px); }
  2.0% { transform: translateX(2px); }
  2.4% { transform: translateX(-2px); }
  2.8% { transform: translateX(1px); }
  3.2% { transform: translateX(-1px); }
  3.6% { transform: translateX(0); }
  25% { transform: translateX(0); }
  50% { transform: translateX(0); }
  75% { transform: translateX(0); }
  100% { transform: translateX(0); }
}