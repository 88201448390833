@import '../../styles/propertySets.css';

.root {
  margin-top: 24px;
}

@media (--viewportMedium) {
  .root {
    margin-top: 48px;
  }
}

.disabled {
  opacity: 0.5;
}

.sectionContainer {
  margin-bottom: 35px;

  @media (--viewportMedium) {
    margin-bottom: 56px;
  }
}

.subTitle {
  color: #3D3D3D;
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.57px;
}

.formRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
}

.bottomSubmit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 70px;

  @media(max-width: 500px) {
    flex-wrap: wrap;
    gap: 10px;
  }

  & button {
    width: 200px;

    @media(max-width: 500px) {
      width: 100%;
    }
  }

  & .borderButton {
    border: 1px solid #0B96F5;
    color: #0B96F5;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.267px;
    display: block;
    /* width: 100%; */
    min-height: 64px;
  }
}

.radioButtonRow {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 24px;
  white-space: nowrap;
}

.radioButtonRow> :first-child {
  margin-right: 36px;
}

.selectCountry {
  margin-bottom: 24px;
}

.error {
  @apply --marketplaceModalErrorStyles;
}

.termsText {
  @apply --marketplaceModalHelperText;
  margin-top: 30px;
  margin-bottom: 12px;
  font-size: 13px;
  /* text-align: center; */

  @media (--viewportMedium) {
    margin-bottom: 28px;
  }
}

.termsLink {
  @apply --marketplaceModalHelperLink;


  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.bankDetailsStripeField p {
  @apply --marketplaceH4FontStyles;
}

.missingStripeKey {
  color: var(--failColor);
}

.accountInformationTitle {
  @apply --marketplaceH3FontStyles;
  margin: 0;
  padding-top: 5px;
  padding-bottom: 1px;
}

@media (--viewportMedium) {
  .accountInformationTitle {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.savedCountry {
  padding-top: 6px;
  padding-bottom: 0px;
}

@media (--viewportMedium) {
  .savedCountry {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.savedInformation {
  margin-bottom: 24px;
}

.savedBankAccount {
  width: 100%;
  border-bottom: 2px solid var(--successColor);
  margin-bottom: 24px;
  padding: 4px 0 10px 0;
  color: var(--matterColor);
  text-align: left;

  &:hover {
    cursor: text;
    text-decoration: none;
    border-color: var(--matterColor);
  }

  &:focus {
    outline: none;
    border-color: var(--matterColor);
  }
}

.titleBlock {
  color: var(--matterColorAnti);
}

.wrapperBlock {
  margin-bottom: 50px;

  &:last-of-type {
    margin-bottom: 80px;
  }
}

.itemInputBlock {
  margin-bottom: 30px;
}