.briefLocationBox {
    & h2{
        margin-top: 0;
        font-size: 22px;
        font-weight: 500;
        line-height: 33px;
        margin-bottom: 20px;
        letter-spacing: -0.9375px;
    }

    & ul {
        list-style: disc;
        padding-left: 20px;
    }

    & .headingName {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.09px;
    }

    & .locationList {
        & p {
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 5px;
            margin-top: 0;
            font-weight: var(--fontWeightRegular);
            letter-spacing: -0.09px;

        }
    }
}

.locationLength {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #3D3D3D;
    margin-left: 10px;
}

.locationButton {
    background: #0B96F5;
    border-radius: 4px;
    border: 1px solid #0B96F5;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    letter-spacing: -0.3px;
    color: #FFFFFF;
    margin-right: 10px;
    width: 100px;
    cursor: pointer;
}

.actionButton {
    margin-top: 10px;
}